import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Backdrop,
  Snackbar,
  Alert,
} from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { getDatabase, ref, remove, update } from "firebase/database";
import Divider from '@mui/material/Divider';
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { KEY, KEY_MASTER } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { BASE_PROD } from '../../../integration/FirebaseConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datetime, priorities } from "../../../services/script";


const database = getDatabase(firebaseApp);



const EditPaciente = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = location.state;
  const [nomesocial, setNomeSocial] = useState(userData.nomesocial || "");
  const [isDateFocused, setDateFocused] = useState(false);
  const [nome, setNome] = useState(userData.nome || '');
  const [nomecompleto, setNomeCompleto] = useState(userData.nomecompleto || '');
  const [cpf, setCpf] = useState(userData.cpf || '');
  const [rg, setRg] = useState(userData.rg || '');
  const [cns, setCns] = useState(userData.cns || '');
  const [telefone, setTelefone] = useState(userData.telefone || '');
  const [email, setEmail] = useState(userData.email || '');
  const [estadoCivil, setEstadoCivil] = useState(userData.estadoCivil || '');
  const [datanascimento, setDataNascimento] = useState(userData.datanascimento || '');
  const [genero, setGenero] = useState(userData.genero || '');
  const [prioridade, setPrioridade] = useState(userData.prioridade || '');
  const [portador, setPortador] = useState(userData.portador || '');
  const [cep, setCep] = useState(userData.cep || '');
  const [estado, setEstado] = useState(userData.estado || '');
  const [cidade, setCidade] = useState(userData.cidade || '');
  const [logradouro, setLogradouro] = useState(userData.logradouro || '');
  const [numero, setNumero] = useState(userData.numero || '');
  const [UID, setUID] = useState(userData.id || '');
  const [complemento, setComplemento] = useState(userData.complemento || '');
  const [localidade, setLocalidade] = useState(userData.localidade || '');
  const [owner, setOwner] = useState(userData.owner || '');
  const [bairro, setBairro] = useState(userData.bairro || '');
  const [sucesso, setSucesso] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tipoPrioridade, setTipoPrioridade] = useState(userData.cor || '');
  const [isRemoving, setIsRemoving] = useState(false);
  const [dependenteId, setDependenteId] = useState('');

  const handleRemoveClick = () => {
    setIsRemoving(true);
  };


  const handleSaveClick = () => {
    if (KEY_MASTER == true) {
      console.log("Salvando com dependenteId:", dependenteId);

      if (dependenteId && dependenteId.trim() !== '') {
        handleCopyDependente(dependenteId);
        setIsRemoving(false);
      } else {
        console.log("dependenteId inválido, não executando handleCopyDependente.");
      }
    }

  };

  const handleTelefoneChange = (event) => {
    const { value } = event.target;
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2')
    setTelefone(formattedValue);
  };

  const handleNavigate = () => {
    setTimeout(() => {
      setLoading(false)
      navigate(`/pacientes`);
    }, 500);
  };

  const handleNavigateBack = () => {

    navigate(`/pacientes`);

  };

  const formatarData = (data) => {
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const checkCEP = async (event) => {
    const novoCEP = event.target.value.replace(/\D/g, '').slice(0, 8);

    try {
      const response = await fetch(`https://viacep.com.br/ws/${novoCEP}/json/`);
      const data = await response.json();
      console.log(data);
      if (!data.erro) {
        setEstado(data.uf);
        setCidade(data.localidade);
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
      } else {
        setEstado("");
        setCidade("");
        setLogradouro("");
        setBairro("");
        console.error("CEP não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };


  const handleCEPChange = (event) => {
    let novoCEP = event.target.value;
    novoCEP = novoCEP.replace(/\D/g, '').slice(0, 8);
    setCep(novoCEP);
    if (novoCEP.length === 8) {
      checkCEP(event);
    }
  };


  const handleSalvar = async () => {
    const telefoneSemMascara = telefone.replace(/\D/g, '');
    const dataFormatada = formatarData(datanascimento);

    let cachePacientes = JSON.parse(localStorage.getItem('cachePacientes')) || [];
    cachePacientes = cachePacientes.map(paciente => {
      if (paciente.cpf === cpf && paciente.telefone === telefoneSemMascara) {
        return {
          ...paciente,
          nome,
          nomecompleto,
          nomesocial,
          datetime: datetime(),
          estadoCivil,
          datanascimento,
          genero,
          prioridade,
          portador,
          cep,
          estado,
          cidade,
          logradouro,
          bairro,
          numero,
          complemento,
          localidade,
          cor: tipoPrioridade
        };
      }
      return paciente;
    });

    localStorage.setItem('cachePacientes', JSON.stringify(cachePacientes));

    let db;
    if (userData.dependente === true) {
      db = ref(database, `${BASE_PROD}/${KEY}/dependentes/` + userData.id);
    } else {
      db = ref(database, `${BASE_PROD}/${KEY}/users/` + telefoneSemMascara);
    }

    await update(db, {
      nome,
      nomecompleto,
      nomesocial,
      datetime: datetime(),
      estadoCivil,
      datanascimento,
      genero,
      prioridade,
      portador,
      cep,
      estado,
      cidade,
      logradouro,
      bairro,
      numero,
      complemento,
      localidade,
      cor: tipoPrioridade,
      divergente: false,
      dadosdivergentes: null
    });

    setSucesso(true);
    handleNavigate();
  };


  const handleCopyDependente = async () => {
    const dbRef = ref(database, `/${BASE_PROD}/${KEY}/users/${dependenteId}`);

    const dependenteData = {
      nome,
      nomecompleto,
      datetime: datetime(),
      nomesocial,
      cpf,
      rg,
      cns,
      telefone: dependenteId,
      email,
      estadoCivil,
      datanascimento,
      genero,
      prioridade,
      portador,
      cep,
      estado,
      cidade,
      logradouro,
      bairro,
      numero,
      complemento,
      localidade,
      cor: tipoPrioridade,
      lifeBan: 3,
      localidade: "",
      nameStart: false,
      homeCheck: false,
      cadastroDependente: false,
      ativo: false,
      banido: false,
      bloqueado: false,
      cadastrado: true,
      lock: false,
      divergente: false,
      dadosDivergentes: null
    };






    try {
      console.log("Tentando definir no Firebase com os dados:", dependenteData);
      await update(dbRef, dependenteData);
      console.log("Definição no Firebase concluída com sucesso");


      const dependentesRef = ref(database, `/${BASE_PROD}/${KEY}/dependentes/${UID}`);
      await remove(dependentesRef);

      setSucesso(true);
      handleNavigate();
    } catch (error) {
      console.error("Erro ao copiar dependente: ", error);

    }
  };




  const handleCloseSnackbar = () => {
    setSucesso(false);
  };




  return (
    <>
      <HelmetProvider>     <Helmet>
        <title>Painel DHF - Editar Paciente</title>
      </Helmet></HelmetProvider>


      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
        <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">EDITAR PACIENTE</Divider>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Primeiro Nome"
                fullWidth
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome Completo"
                fullWidth
                value={nomecompleto}
                onChange={(e) => setNomeCompleto(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Nome Social"
                fullWidth
                value={nomesocial}
                onChange={(e) => setNomeSocial(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="CPF"
                fullWidth
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                sx={{ bgcolor: '#3338' }}

                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
                InputProps={{
                  readOnly: true,
                  style: { color: '#686868', fontWeight: 'bold' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Registro Geral (RG)"
                fullWidth
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>



            <Grid item xs={12} sm={4}>
              <TextField
                label="CNS"
                fullWidth
                value={cns}
                onChange={(e) => setCns(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },

                }}
                InputProps={{
                  readOnly: true,
                  style: { color: '#686868', fontWeight: 'bold' }
                }}
              />
            </Grid>






            <Grid item xs={12} sm={2}>
              <TextField
                label="Telefone"
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={telefone}
                onChange={handleTelefoneChange}
                onFocus={() => setDateFocused(true)}
                onBlur={() => setDateFocused(false)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: isDateFocused,
                }}
                InputProps={{
                  readOnly: true,
                  style: { color: '#686868', fontWeight: 'bold' }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label={"Data de Nascimento"}

                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={datanascimento}
                onChange={(event) => setDataNascimento(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },

                }}

              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="E-mail"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },

                }}
                InputProps={{
                  readOnly: true,
                  style: { color: '#686868', fontWeight: 'bold' }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Estado Civil"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={estadoCivil}
                onChange={(event) => setEstadoCivil(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="solteiro">Solteiro</MenuItem>
                <MenuItem value="casado">Casado</MenuItem>
                <MenuItem value="divorciado">Divorciado</MenuItem>
                <MenuItem value="outro">Outro</MenuItem>
              </TextField>
            </Grid>






            <Grid item xs={12} sm={1}>
              <TextField
                label="CEP"
                fullWidth
                value={cep}
                onChange={handleCEPChange}
                sx={{ bgcolor: '#3338' }}
                inputProps={{
                  maxLength: 8,
                  inputMode: 'numeric',
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <TextField
                label="UF"
                fullWidth
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Município"
                fullWidth
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Endereço"
                fullWidth
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Número"
                fullWidth
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Bairro"
                fullWidth
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Complemento"
                fullWidth
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>




            <Grid item xs={12} sm={2}>
              <TextField
                label="Pessoa com deficiência?"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={portador}
                onChange={(event) => setPortador(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Prioridade"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={prioridade}
                onChange={(event) => setPrioridade(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </TextField>
            </Grid>

            {prioridade === 'sim' ? (
              <>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Tipo de Prioridade"
                    select
                    fullWidth
                    sx={{ bgcolor: '#3338' }}
                    value={tipoPrioridade}
                    onChange={(event) => setTipoPrioridade(event.target.value)}
                    InputLabelProps={{
                      style: { color: 'white' },
                      focused: false,
                    }}
                  >
                    {priorities.map((priority) => (
                      <MenuItem key={priority.value} value={priority.value}>
                        {priority.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>Gênero</Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"

                    value={genero}
                    onChange={(event) => setGenero(event.target.value)}
                  >
                    <FormGroup row>
                      <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                      <FormControlLabel value="feminino" control={<Radio />} label="Feminino" />
                      <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                    </FormGroup>
                  </RadioGroup>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={8}>
                <Typography>Gênero</Typography>
                <RadioGroup
                  aria-label="gender"
                  name="gender"

                  value={genero}
                  onChange={(event) => setGenero(event.target.value)}
                >
                  <FormGroup row>
                    <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                    <FormControlLabel value="feminino" control={<Radio />} label="Feminino" />
                    <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                  </FormGroup>
                </RadioGroup>
              </Grid>
            )}



            <Grid item xs={12} sm={11} style={{ padding: 15, textAlign: 'left', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#4444", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7, fontVariantCaps: 'normal' }}
                onClick={handleNavigateBack}
              >

                <CancelIcon style={{ marginRight: 2 }} fontSize="large" />
                Cancelar
              </Button>

              {owner !== '' && (
                <>
                  {KEY_MASTER == true && (<>
                    {isRemoving ? (
                      <div>
                        <TextField
                          label="ID do Dependente"
                          value={dependenteId}
                          onChange={(e) => setDependenteId(e.target.value)}
                          variant="outlined"
                          style={{ marginRight: 5 }}
                        />
                        <Button
                          style={{ backgroundColor: "#4444", color: 'white', fontWeight: 'bold', padding: 7 }}
                          onClick={handleSaveClick}
                        >
                          Salvar
                        </Button>
                      </div>
                    ) : (
                      <Button
                        style={{ backgroundColor: "#4444", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7, fontVariantCaps: 'normal', marginLeft: 5 }}
                        onClick={handleRemoveClick}
                      >
                        <AssignmentIndIcon style={{ marginRight: 2 }} fontSize="large" />
                        Remover dependência
                      </Button>
                    )}
                  </>)}

                </>
              )}

            </Grid>


            <Grid item xs={12} sm={1} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                onClick={handleSalvar}
              >
                <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />

              </Button>
            </Grid>



          </Grid>
        </form>
      </Box>

      <Snackbar
        open={sucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>
          ALTERAÇÃO FEITO COM SUCESSO!
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditPaciente;
