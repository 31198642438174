import React, { useState, useEffect } from 'react';
import { Grid, Box, Checkbox, FormControlLabel, Typography, TextField, Button, Select, MenuItem, Snackbar, IconButton } from '@mui/material';
import firebaseApp, { UPDATE, BASE_PROD } from '../integration/FirebaseConfig';
import { getDatabase, ref, get, onValue, set, update, off, push } from 'firebase/database';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { TablePagination } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import { handleCopyClick } from './script';
import { format } from 'date-fns';
const database = getDatabase(firebaseApp);

const TerminalJsx = () => {
    const [password, setPassword] = useState('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [editedCity, setEditedCity] = useState({});
    const [users, setUsers] = useState([]);
    const [versoes, setVersoes] = useState([]);
    const [usersByKey, setUsersByKey] = useState([]);
    const [usersByAdm, setUsersByAdm] = useState([]);
    const [userData, setUserData] = useState([]);
    const [KEY, setKEY] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [chats, setChats] = useState([]);
    const [erros, setErros] = useState([]);
    const [errorData, setErrorData] = useState(null);
    const [errosServer, setErrosServer] = useState([]);
    const [manutencaoAtiva, setManutencaoAtiva] = useState(false);
    const [manutencaoChat, setManutencaoChat] = useState(false);
    const [emailGestor, setEmailGestor] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchErros, setSearchErros] = useState('');
    const [searchNumber, setSearchNumber] = useState('');
    const [searchInputUserJS, setSearchInputUserJS] = useState('');
    const [userDataSearch, setUserDataSearch] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showVersionFields, setShowVersionFields] = useState(false);
    const [versionNumber, setVersionNumber] = useState('');
    const [description, setDescription] = useState('');
    const [userJson, setUserJson] = useState(null);
    const [numberJson, setNumberJson] = useState(null);


    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };



    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };



    const handleSubmit = () => {

        if (password === process.env.REACT_APP_PASSWORD_MASTER) {
            setIsPasswordCorrect(true);
            console.log('TOKEN TYPE 1')

        } else if (password === process.env.REACT_APP_PASSWORD_MAIN) {

            setIsPasswordCorrect(true);
            console.log('OKEN TYPE 2')

        }
    };



    const handleAlterarAcessoButtonClick = async () => {
        if (emailGestor.trim() === '') {
            setSnackbarMessage('Por favor, insira um email, CPF ou UID do usuário.');
            setOpenSnackbar(true);
            return;
        }

        const usuariosRef = ref(database, 'usuarios');
        const usuariosSnapshot = await get(usuariosRef);

        if (usuariosSnapshot.exists()) {
            const usuarios = usuariosSnapshot.val();


            let userId = Object.keys(usuarios).find(
                (userId) => usuarios[userId].email === emailGestor ||
                    usuarios[userId].cpf === emailGestor ||
                    userId === emailGestor
            );

            if (userId) {
                const acessoAtual = usuarios[userId].acesso;
                await update(ref(database, `usuarios/${userId}`), {
                    acesso: !acessoAtual, token: 0
                });
                setSnackbarMessage(`Acesso do usuário ${emailGestor} alterado com sucesso.`);
                setOpenSnackbar(true);
            } else {
                setSnackbarMessage('Nenhum usuário encontrado com o email, CPF ou UID fornecido.');
                setOpenSnackbar(true);
            }
        } else {
            setSnackbarMessage('Nenhum usuário encontrado.');
            setOpenSnackbar(true);
        }

        setEmailGestor('');
    };


    const handleSearch = async () => {
        try {
            setUserDataSearch(null);

            const usuariosRef = ref(database, 'usuarios');
            const usuariosSnapshot = await get(usuariosRef);

            if (usuariosSnapshot.exists()) {
                const usuarios = usuariosSnapshot.val();
                const cleanCPF = searchInput.replace(/[.-]/g, '');


                const foundUser = Object.entries(usuarios).find(([userId, user]) =>
                    user.email === searchInput ||
                    (user.cpf && user.cpf.replace(/[.-]/g, '') === cleanCPF) ||
                    userId === searchInput || user.licensa === searchInput
                );

                if (foundUser) {
                    const [userId, userData] = foundUser;

                    setUserDataSearch({ ...userData, uid: userId });
                } else {
                    console.log('Usuário não encontrado.');
                }
            } else {
                console.log('Nenhum usuário encontrado.');
            }
        } catch (error) {
            console.error('Erro ao buscar usuário:', error);
        }
    };


    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleCopy = (text) => {

        const input = document.createElement('input');

        input.value = text;

        document.body.appendChild(input);

        input.select();

        document.execCommand('copy');

        document.body.removeChild(input);
    };


    useEffect(() => {
        const manutencaoRef = ref(database, 'panel/manutencao');

        const onManutencaoValue = (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {

                const { ativo, chat } = data;
                setManutencaoAtiva(ativo);
                setManutencaoChat(chat);
            }
        };


        onValue(manutencaoRef, onManutencaoValue);


        return () => {
            off(manutencaoRef, onManutencaoValue);
        };
    }, []);


    const handleManutencaoChange = (event) => {
        const newValue = event.target.checked;


        update(ref(database, 'panel/manutencao'), {
            ativo: newValue
        }).then(() => {

            setManutencaoAtiva(newValue);
        }).catch((error) => {
            console.error('Erro ao atualizar a manutenção:', error);
        });
    };

    const handleManutencaoChat = (event) => {
        const newValue = event.target.checked;


        update(ref(database, 'panel/manutencao'), {
            chat: newValue
        }).then(() => {

            setManutencaoChat(newValue);
        }).catch((error) => {
            console.error('Erro ao atualizar a manutenção:', error);
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        const fetchData = async () => {
            const database = getDatabase();
            const usersRef = ref(database, '/usuarios/');

            try {
                const snapshot = await get(usersRef);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const usersArray = Object.entries(data)
                        .map(([key, value]) => ({
                            id: key,
                            nome: value.nome,
                            cidade: value.cidade,
                            categoria: value.categoria,
                            horaLogin: value.horaLogin,
                            acesso: value.acesso
                        }))
                        .filter(user => user.categoria === 'Gestão');

                    setUsers(usersArray);
                } else {
                    setUsers([]);
                }
            } catch (error) {
                console.error('Erro ao obter dados dos usuários:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const database = getDatabase();
        const versoesRef = ref(database, `/panel/versoes`);

        onValue(versoesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const getArray = Object.entries(data)
                    .map(([key, value]) => ({
                        id: key,
                        versionNumber: value.versionNumber,
                        description: value.description,
                        createdAt: value.createdAt
                    }));

                setVersoes(getArray);
            }
        });
    }, []);


    useEffect(() => {
        const cidadesRef = ref(database, 'codigo_cidade');
        const usersRef = ref(database, `/usuarios/`);

        const fetchUserData = async () => {
            try {
                const citiesSnapshot = await get(cidadesRef);
                const usersSnapshot = await get(usersRef);

                const citiesData = citiesSnapshot.val();
                const usersData = usersSnapshot.val();

                if (citiesData && usersData) {
                    const citiesArray = Object.entries(citiesData).map(([key, value]) => ({
                        id: key,
                        codigo: value.codigo,
                        nome: value.nome,
                        ativo: value.ativo,
                        bloqueado: value.bloqueado,
                        userActive: 0
                    }));

                    Object.values(usersData).forEach(user => {
                        if (user.ativo && user.cidade) {
                            const cityIndex = citiesArray.findIndex(city => city.codigo === user.cidade);
                            if (cityIndex !== -1) {
                                citiesArray[cityIndex].userActive++;
                            }
                        }
                    });

                    setCities(citiesArray);
                }
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchUserData();
    }, []);

    const handleLaunchVersionChange = (event) => {
        setShowVersionFields(event.target.checked);
    };

    const handleCreateVersion = () => {
        if (versionNumber.trim() === '' || description.trim() === '') {
            alert('Por favor, preencha todos os campos.');
            return;
        }

        const versionsRef = ref(database, '/panel/versoes');
        const formattedDate = format(new Date(), 'dd/MM/yyyy');

        const newVersionData = {
            versionNumber: versionNumber,
            description: description,
            createdAt: formattedDate
        };


        push(versionsRef, newVersionData)
            .then((newVersionRef) => {

                const atualRef = ref(database, '/panel/versoes/atual');
                set(atualRef, {
                    id: 'atual',
                    versionNumber: versionNumber,
                    description: description,
                    createdAt: formattedDate
                });

                setVersionNumber('');
                setDescription('');
            })
            .catch((error) => {
                console.error('Erro ao criar versão:', error);
                alert('Erro ao criar versão. Verifique o console para mais detalhes.');
            });
    };



    const handleCitySelection = (event) => {
        setSelectedCity(event.target.value);
        const cityRef = ref(getDatabase(), `codigo_cidade/${event.target.value}`);
        const cityToUpdate = cities.find(city => city.id === event.target.value);
        if (!cityToUpdate.ativo) {
            update(cityRef, { ...cityToUpdate, ativo: true })
                .then(() => {
                    console.log("Cidade ativada com sucesso.");


                    setCities(prevCities => {
                        return prevCities.map(city => {
                            if (city.id === event.target.value) {
                                return { ...city, ativo: true };
                            } else {
                                return city;
                            }
                        });
                    });
                })
                .catch((error) => console.error("Erro ao ativar a cidade:", error));
        }
    };


    useEffect(() => {
        const errorRef = ref(database, '/panel/erros');

        const fetchErrosServer = () => {
            onValue(errorRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const usersArray = Object.entries(data)
                        .map(([key, value]) => ({
                            id: key,
                            erroSend: value.erroSend,
                            errorMessage: value.errorMessage,
                            errorType: value.errorType,
                            errorLineTrace: value.errorLineTrace,
                            string: value.string,
                            user_token_id: value.user_token_id,
                            responseMsg: value.responseMsg,
                            data: value.data,
                            erro: value.erro,
                            hora: value.hora,
                            responseStatus: value.responseStatus,
                            msg: value.msg,
                            numberSend: value.numberSend,
                            userCause: value.userCause,
                            ocultar: value.ocultar || null

                        }))


                    setErrosServer(usersArray);

                }
            });
        };

        fetchErrosServer();
    }, []);

    const handleSearchErros = async () => {
        try {
            const errorRef = ref(database, `/panel/erros/${searchErros}`);
            const errorSnapshot = await get(errorRef);


            if (errorSnapshot.exists()) {
                const errorData = errorSnapshot.val();
                setErrorData(errorData);
            } else {
                console.log('Erro não encontrado.');
                setErrorData(null);
            }
        } catch (error) {
            console.error('Erro ao buscar erro:', error);

        }
    };

    const handleSearchUserJson = async () => {
        try {
            const usjRef = ref(database, `/usuarios/${searchInputUserJS}`);
            const errorSnapshot = await get(usjRef);


            if (errorSnapshot.exists()) {
                const userJs = errorSnapshot.val();
                setUserJson(userJs);
            } else {
                console.log('Erro não encontrado.');
                setErrorData(null);
            }
        } catch (error) {
            console.error('Erro ao buscar erro:', error);

        }
    };

    const handleSearchNumber = async () => {
        try {

            const errorRef = ref(database, `/${BASE_PROD}/${KEY}/users/${searchNumber}`);
            const errorSnapshot = await get(errorRef);


            if (errorSnapshot.exists()) {
                const errorData = errorSnapshot.val();
                setNumberJson(errorData);
            } else {
                console.log('Erro não encontrado.');
                setNumberJson(null);
            }
        } catch (error) {
            console.error('Erro ao buscar erro:', error);

        }
    };



    const handleKEY = (event) => {
        setKEY(event.target.value);



        try {
            const usersRef = ref(database, `${BASE_PROD}/${event.target.value}/users`);
            const chatRef = ref(database, `/${BASE_PROD}/${event.target.value}/chats`);
            const usersRefDHF = ref(database, `/usuarios/`);


            const fetchUsers = async () => {
           
          
                try {
                    const snapshot = await get(usersRef);
                    const data = snapshot.val();
            
                    const userList = data
                        ? Object.keys(data)
                            .map((userId, index) => {
                                const user = { id: index + 1, ...data[userId] };
            
                                if (user.logradouro === "") {
                                    user.endereco = `Endereço a atualizar, ${user.numero}, ${user.cidade}, ${user.estado}`;
                                } else {
                                    user.endereco = `${user.logradouro}, ${user.numero}, ${user.cidade}, ${user.estado}`;
                                }
            
                                if (user.nomesocial !== "" && user.nomesocial !== undefined) {
                                    user.nomecompleto = user.nomecompleto + ' (' + user.nomesocial + ')';
                                }
            
                                return user;
                            })
                            .filter((user) => user.ativo === true || user.ativo === false)
                        : [];
            
                    setUserData(userList);
                } catch (error) {
                    console.error('Erro ao obter dados do Firebase:', error);
                }
            };

            const fetchChats = async () => {

                try {
                    const snapshot = await get(chatRef);
                    if (snapshot.exists()) {
                        const chatsData = snapshot.val();
                        const chatsArray = Object.keys(chatsData)
                            .map((key) => ({
                                id: key,
                                nome: chatsData[key]?.nome || 'DESCONHECIDO',
                                msg: chatsData[key]?.msg || '',
                                cpf: chatsData[key]?.cpf || '',
                                cns: chatsData[key]?.cns || '',
                                email: chatsData[key]?.email || '',
                                nomecompleto: chatsData[key]?.nomecompleto || '',
                                visualizado: chatsData[key]?.visualizado,
                                hora: chatsData[key]?.hora,
                                data: chatsData[key]?.data,
                                notificou: chatsData[key]?.notificou,
                            }))
                            .filter(chat => chat.nome !== 'DESCONHECIDO');

                        setChats(chatsArray);
                    } else {
                        setChats([]);
                    }
                } catch (error) {
                    console.error('Erro ao obter dados dos chats:', error);
                }
            };

            const fetchErrors = async () => {
                const errorChatsRef = ref(database, `/${BASE_PROD}/${event.target.value}/chats`);
            
                try {
                    const snapshot = await get(errorChatsRef);
                    const errorChatsData = snapshot.val();
                    if (errorChatsData) {
                        const errorConversations = [];
            
                        Object.keys(errorChatsData).forEach((chatId) => {
                            const conversations = errorChatsData[chatId]?.conversas || {};
                            Object.keys(conversations).forEach((conversationId) => {
                                const conversation = conversations[conversationId];
            
                                if (conversation.error !== null) {
                                    errorConversations.push({ id: chatId, uid: conversationId, ...conversation });
                                }
                            });
                        });
            
                        setErros(errorConversations);
                    } else {
                        setErros([]);
                    }
                } catch (error) {
                    console.error('Erro ao obter dados dos chats com erros:', error);
                }
            };

            const usersAdm = async () => {
             
                try {
                    const snapshot = await get(usersRef);
                    const data = snapshot.val();
                    if (data) {
                        const userList = Object.keys(data)
                            .map((userId, index) => {
                                const user = { id: index + 1, ...data[userId] };
            
                                if (user.logradouro === "") {
                                    user.endereco = `Endereço a atualizar, ${user.numero}, ${user.cidade}, ${user.estado}`;
                                } else {
                                    user.endereco = `${user.logradouro}, ${user.numero}, ${user.cidade}, ${user.estado}`;
                                }
            
                                if (user.nomesocial !== "" && user.nomesocial !== undefined) {
                                    user.nomecompleto = user.nomecompleto + ' (' + user.nomesocial + ')';
                                }
            
                                return user;
                            })
                            .filter((user) => user.adm === true);
                            
                        setUsersByAdm(userList);
                    } else {
                        setUsersByAdm([]);
                    }
                } catch (error) {
                    console.error('Erro ao obter dados dos usuários administrativos:', error);
                }
            };

            const fetchAllUsers = async () => {
              
                try {
                    const snapshot = await get(usersRefDHF);
                    const data = snapshot.val();
                    if (data) {
                        const usersArray = Object.entries(data)
                            .map(([key, value]) => ({
                                id: key,
                                nome: value.nome,
                                cidade: value.cidade,
                                categoria: value.categoria,
                                horaLogin: value.horaLogin,
                                cpf: value.cpf,
                                acesso: value.acesso,
                                dualAcess: value.dualAcess
                            }))
                            .filter(user => user.cidade === event.target.value);
            
                        setUsersByKey(usersArray);
                    } else {
                        setUsersByKey([]);
                    }
                } catch (error) {
                    console.error('Erro ao obter dados dos usuários:', error);
                }
            };


            usersAdm();
            fetchAllUsers();
            fetchChats();
            fetchErrors();
            fetchUsers()

            return () => {
                fetchUsers()
            };
        } catch (error) {
            console.error('Erro ao obter dados do Firebase:', error);
        }
    };


    const handleHideError = async (errorId) => {
        try {
            const errorRef = ref(database, `/panel/erros/${errorId}`);
            await update(errorRef, { ocultar: true });
            console.log(`Erro com ID ${errorId} ocultado com sucesso.`);
        } catch (error) {
            console.error('Erro ao ocultar erro:', error);
        }
    };


    const handleEditClick = (cityId) => {
        const cityToEdit = cities.find(city => city.id === cityId);
        setEditedCity(cityToEdit);
        setEditMode(true);
    };

    const handleSaveClick = () => {
        const storageRef = firebaseApp.storage().ref();
        if (selectedFile) {

            const fileRef = storageRef.child(`cidades/${editedCity.codigo}/logo.png`);
            fileRef.put(selectedFile).then(snapshot => {
                console.log('Imagem salva com sucesso.');

                snapshot.ref.getDownloadURL().then(downloadURL => {
                    setEditedCity({ ...editedCity, logo: downloadURL });
                    updateCityWithImage(downloadURL);
                });
            }).catch(error => {
                console.error('Erro ao salvar a imagem:', error);
            });
        } else {

            updateCityWithoutImage();
        }
    };


    const handleCancelClick = () => {
        setEditMode(false);
        setEditedCity({});
    };


    const updateCityWithImage = (downloadURL) => {
        const cityRef = ref(database, `codigo_cidade/${editedCity.id}`);
        update(cityRef, { ...editedCity, logo: downloadURL })
            .then(() => {
                console.log('Dados da cidade atualizados com sucesso.');

                setCities(prevCities => {
                    return prevCities.map(city => {
                        if (city.id === editedCity.id) {
                            return { ...editedCity, logo: downloadURL };
                        } else {
                            return city;
                        }
                    });
                });

                setEditMode(false);
            })
            .catch((error) => {
                console.error('Erro ao atualizar os dados da cidade:', error);
            });
    };


    const updateCityWithoutImage = () => {
        const cityRef = ref(database, `codigo_cidade/${editedCity.id}`);
        update(cityRef, editedCity)
            .then(() => {
                console.log('Dados da cidade atualizados com sucesso.');

                setCities(prevCities => {
                    return prevCities.map(city => {
                        if (city.id === editedCity.id) {
                            return editedCity;
                        } else {
                            return city;
                        }
                    });
                });

                setEditMode(false);
            })
            .catch((error) => {
                console.error('Erro ao atualizar os dados da cidade:', error);
            });
    };


    return (
        <>

            <Box sx={{
                bgcolor: '#161616',
                color: 'white',
                p: 2,

            }}>

                {!isPasswordCorrect && (
                    <>

                        <div className="login-container">
                            <div className="logo-container">
                                <img src="/images/logo.webp" alt="Logo" width="200" height="65" />
                            </div>
                            <div className="login-form">
                                <h1>MASTER ACCESS</h1>


                                <form >

                                    <div className="input-group">
                                        <label>Token:</label>
                                        <input
                                            type="password"
                                            placeholder="Token Access"
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>


                                    <button onClick={handleSubmit} className="login-button">Entrar</button>

                                </form>


                            </div>
                        </div>


                    </>

                )}
                {isPasswordCorrect && (
                    <Box
                        sx={{

                            p: 2,

                        }}
                    >


                        <Grid container spacing={2}>

                            <Grid item xs={12} md={4}>
                                <Typography style={{ color: 'cyan' }} gutterBottom>SELECIONE UMA KEY * ATIVO = TRUE</Typography>
                                <Select
                                    value={KEY}
                                    onChange={handleKEY}
                                    fullWidth
                                >
                                    {cities.filter(city => city.ativo === true).map((city, index) => (
                                        <MenuItem key={city.id} value={city.codigo}>{city.codigo} - {city.nome}</MenuItem>
                                    ))}
                                </Select>


                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>CIDADES EM USO * ATIVO = TRUE</Typography>
                                    <Grid container spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: 1 }}>

                                        {cities.filter(city => city.ativo === true).map((city, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Box bgcolor="#323232" borderRadius={2} p={1}>
                                                    <div key={index}>
                                                        {editMode && editedCity.id === city.id ? (
                                                            <>
                                                                <TextField
                                                                    label="Código"
                                                                    value={editedCity.codigo}
                                                                    onChange={(e) => setEditedCity({ ...editedCity, codigo: e.target.value })}
                                                                />
                                                                <TextField
                                                                    label="Nome"
                                                                    value={editedCity.nome}
                                                                    onChange={(e) => setEditedCity({ ...editedCity, nome: e.target.value })}
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={editedCity.ativo} onChange={(e) => setEditedCity({ ...editedCity, ativo: e.target.checked })} />}
                                                                    label="Ativo"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={editedCity.bloqueado} onChange={(e) => setEditedCity({ ...editedCity, bloqueado: e.target.checked })} />}
                                                                    label="Bloqueado"
                                                                />
                                                                <input type="file" accept="image/png, image/jpeg" onChange={handleFileChange} />
                                                                <Button sx={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold' }} onClick={handleSaveClick}>Salvar</Button>
                                                                <Button sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }} onClick={handleCancelClick}>Cancelar</Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography onClick={() => handleEditClick(city.id)}>
                                                                    {`Código: ${city.codigo}, Nome: ${city.nome}, Ativo: ${city.ativo ? 'Sim' : 'Não'}, Bloqueado: ${city.bloqueado ? 'Sim' : 'Não'}`}
                                                                </Typography>
                                                                <Typography>
                                                                    {`Usuários Ativos: ${city.userActive}`}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        ))}

                                    </Grid>
                                </Box>

                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2}>

                                    <Typography style={{ color: 'greenyellow' }} gutterBottom>VERSION CONTROL UPDATE</Typography>

                                    <Grid container spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: 1 }}>

                                        {versoes.map((user, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Box bgcolor="#323232" borderRadius={2} p={1}>

                                                    <Typography style={{ color: 'white' }}>
                                                        Version: {user.versionNumber} | Date: {user.createdAt} | UID: {user.id}
                                                        <IconButton onClick={() => handleCopyClick(user.id)} aria-label="copy">
                                                            <ContentCopyIcon style={{ color: 'white' }} />
                                                        </IconButton>
                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2}>

                                    <Typography style={{ color: 'cyan' }} gutterBottom>USERS PANEL HISTORY * BY KEY {KEY}</Typography>

                                    <Grid container spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: 1 }}>

                                        {usersByKey.map((user, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Box bgcolor="#323232" borderRadius={2} p={1}>
                                                    <Typography style={{ color: 'white' }}>
                                                        {user.cpf} | {user.horaLogin} | Access: {user.acesso ? "yes" : "not"}
                                                    </Typography>
                                                    <Typography style={{ color: 'white' }}>
                                                        {user.categoria} | UID: {user.id}
                                                        <IconButton onClick={() => handleCopyClick(user.id)} aria-label="copy">
                                                            <ContentCopyIcon style={{ color: 'white' }} />
                                                        </IconButton>
                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>LASTED NOTIFICATION PANEL * BY KEY {KEY}</Typography>
                                    <Grid container spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: 1 }}>

                                        {chats.map((chat, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Box bgcolor="#323232" borderRadius={2} p={1}>
                                                    <Typography style={{ color: 'white' }}>
                                                        <IconButton onClick={() => handleCopy(chat.id)} sx={{ borderRadius: 1, fontSize: 12 }}>
                                                            ID: {chat.id} <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        NotificationSend?: {chat.notificou ? 'Sim' : 'Não'} |  viewed?: {chat.visualizado ? 'Sim' : 'Não'}

                                                    </Typography>
                                                    <Typography>
                                                        <IconButton onClick={() => handleCopy(chat.data)} sx={{ borderRadius: 1, fontSize: 12 }}>
                                                            DATE REGISTER: {chat.data} - {chat.hora} <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>

                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>



                                </Box>

                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2}>
                                    <Typography style={{ color: 'red' }} gutterBottom>USER ADMIN WHATSAPP * BY KEY {KEY}</Typography>
                                    <TableContainer component={Paper} mb={2}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>KEY_UID</TableCell>
                                                    <TableCell>KEY_EMAIL</TableCell>
                                                    <TableCell>AUTOBAN</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {usersByAdm.map((chat, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{chat.telefone}</TableCell>
                                                        <TableCell>{chat.email}</TableCell>
                                                        <TableCell>{chat.ban}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>



                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>


                                <Typography style={{ color: 'cyan' }} gutterBottom>ATIVAR NOVA CIDADE</Typography>
                                <Select
                                    value={selectedCity}
                                    onChange={handleCitySelection}
                                    fullWidth
                                >
                                    {cities.map(city => (
                                        <MenuItem key={city.id} value={city.id}>{city.nome}</MenuItem>
                                    ))}
                                </Select>
                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={1}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>CONTROLES AVANÇADOS = VERSION CODE: {UPDATE}</Typography>
                                {/*     <FormControlLabel control={<Checkbox />} label="Bloquear Acessos *" /> */}
                                    <FormControlLabel
                                        control={<Checkbox color='success' checked={manutencaoAtiva} onChange={handleManutencaoChange} />}
                                        label="Ativar Manutenção"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color='success' checked={manutencaoChat} onChange={handleManutencaoChat} />}
                                        label="Ativar Manutenção Chat"
                                    />
                                  {/*   <FormControlLabel control={<Checkbox />} label="Bloquear novos cadastros" />
                                    <FormControlLabel control={<Checkbox />} label="Liberar novo Gestor" />
                                    <FormControlLabel control={<Checkbox />} label="Criar CNS" />
                                    <FormControlLabel control={<Checkbox />} label="Rodar Scripts" />
                                    <FormControlLabel control={<Checkbox />} label="Banir IP/UID" /> */}
                                    <FormControlLabel control={<Checkbox />} label="Lançar Versão" onChange={handleLaunchVersionChange} />


                                    {showVersionFields && (
                                        <div style={{ marginTop: 2 }}>
                                            <TextField label="Número da versão" value={versionNumber} onChange={(e) => setVersionNumber(e.target.value)} />
                                            <TextField label="Descrição" value={description} onChange={(e) => setDescription(e.target.value)} />
                                            <Button style={{ marginTop: 5, backgroundColor: 'greenyellow', color: 'black', fontWeight: 'bold' }} variant="contained" onClick={handleCreateVersion}>Criar Versão</Button>
                                        </div>
                                    )}
                                </Box>


                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2} >
                                    <Typography style={{ color: 'red' }} gutterBottom>SERVER ERROR HISTORY *</Typography>
                                    <Grid container spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: 1 }}>
                                        {errosServer
                                            .filter(chat => chat.ocultar === null || chat.ocultar === false)
                                            .reverse()
                                            .map((chat, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <Box bgcolor="#323232" borderRadius={2} p={1}>
                                                        <Typography>
                                                            {chat.userCause} | {chat.data} - {chat.hora} - {chat.errorType}
                                                        </Typography>
                                                        <Typography>
                                                            ResponseStatus: {chat.responseStatus} | UID:
                                                            <IconButton onClick={() => handleCopy(chat.id)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                                {chat.id} <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleHideError(chat.id)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                                <NotInterestedIcon fontSize='small' />
                                                            </IconButton>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ))}

                                    </Grid>
                                </Box>



                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={2}>
                                    <Typography style={{ color: 'red' }} gutterBottom>MESSAGES ERROR HISTORY * BY KEY {KEY}</Typography>
                                    <Grid container spacing={2} sx={{ maxHeight: '300px', overflowY: 'auto', marginTop: 1 }}>

                                        {erros.map((chat, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Box bgcolor="#323232" borderRadius={2} p={1}>
                                                    <Typography >
                                                        {chat.id} | {chat.data} - {chat.hora}
                                                    </Typography>
                                                    <Typography>
                                                        console.log: {chat.error ? "error" : 'no'}, Code: {chat.code}
                                                        <IconButton onClick={() => handleCopy(chat.uid)} sx={{ borderRadius: 1, fontSize: 12 }}>
                                                            UID: {chat.uid} <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>



                                </Box>

                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={1}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>
                                        SEARCH ERROS SERVER HISTORY JSON *
                                    </Typography>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Search by Uid"
                                                variant="outlined"
                                                value={searchErros}
                                                onChange={(e) => setSearchErros(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" onClick={handleSearchErros}>
                                                <SearchIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {errorData && (
                                        <Box mt={2} bgcolor="#323232" borderRadius={2} p={2}>

                                            <pre style={{ color: 'white', overflowX: 'auto' }}>
                                                {JSON.stringify(errorData, null, 2)}
                                            </pre>
                                        </Box>
                                    )}
                                </Box>



                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={1}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>
                                        SEARCH USERS JSON DATA*
                                    </Typography>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Search by Uid"
                                                variant="outlined"
                                                value={searchInputUserJS}
                                                onChange={(e) => setSearchInputUserJS(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" onClick={handleSearchUserJson}>
                                                <SearchIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {userJson && (
                                        <Box mt={2} bgcolor="#323232" borderRadius={2} p={2}>

                                            <pre style={{ color: 'white', overflowX: 'auto' }}>
                                                {JSON.stringify(userJson, null, 2)}
                                            </pre>
                                        </Box>
                                    )}
                                </Box>


                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={1}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>
                                        SEARCH USER NUMBERS * BY KEY {KEY}
                                    </Typography>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Search by Uid"
                                                variant="outlined"
                                                value={searchNumber}
                                                onChange={(e) => setSearchNumber(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" onClick={handleSearchNumber}>
                                                <SearchIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {numberJson && (
                                        <Box mt={2} bgcolor="#323232" borderRadius={2} p={2}>

                                            <pre style={{ color: 'white', overflowX: 'auto' }}>
                                                {JSON.stringify(numberJson, null, 2)}
                                            </pre>
                                        </Box>
                                    )}
                                </Box>







                            </Grid>

                            <Grid item xs={12} md={4}>

                                <Typography style={{ color: 'cyan' }} gutterBottom>NEW KEY REGISTER</Typography>
                                <Select
                                    value={selectedCity}
                                    onChange={handleCitySelection}
                                    fullWidth
                                >
                                    {cities.map(city => (
                                        <MenuItem key={city.id} value={city.id}>{city.codigo} | {city.nome}</MenuItem>
                                    ))}
                                </Select>
                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={1}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>Revogar Acessos *</Typography>
                                    <TextField
                                        label="Email, CPF ou UID"
                                        variant="outlined"
                                        value={emailGestor}
                                        onChange={(e) => setEmailGestor(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Button variant="contained" color="primary" onClick={handleAlterarAcessoButtonClick}>
                                        Alterar Acesso
                                    </Button>
                                    <Snackbar
                                        open={openSnackbar}
                                        autoHideDuration={6000}
                                        onClose={handleSnackbarClose}
                                        message={snackbarMessage}
                                    />
                                </Box>

                                <Box bgcolor="#1E1E1E" borderRadius={2} p={2} marginTop={1}>
                                    <Typography style={{ color: 'cyan' }} gutterBottom>
                                        SEARCH ALL USERS *
                                    </Typography>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Email, UID ou CPF do Usuário"
                                                variant="outlined"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" onClick={handleSearch}>
                                                <SearchIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {userDataSearch && (
                                        <>
                                            <Grid item xs={12} >
                                                <Box bgcolor="#323232" borderRadius={2} p={1}>
                                                    <Typography >
                                                        <IconButton onClick={() => handleCopy(userDataSearch.nome)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.nome}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.cidade)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.cidade}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.horaLogin)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.horaLogin}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.uid)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.uid}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.cpf)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.cpf}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.datanascimento)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.datanascimento}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.email)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.email}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleCopy(userDataSearch.categoria)} sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.categoria}  <ContentCopyIcon sx={{ marginLeft: 1 }} fontSize='small' />
                                                        </IconButton>
                                                        <IconButton sx={{ borderRadius: 1, fontSize: 10 }}>
                                                            {userDataSearch.acesso ? 'Access True' : 'Access False'}
                                                        </IconButton>
                                                    </Typography>

                                                </Box>
                                            </Grid>



                                        </>
                                    )}
                                </Box>

                                <Typography marginTop={1} style={{ color: 'cyan' }} gutterBottom>GESTORES *</Typography>
                                <TableContainer component={Paper} mb={2}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NAME_USER</TableCell>
                                                <TableCell>KEY_ID</TableCell>
                                                <TableCell>LOGIN/REFRESH</TableCell>
                                                <TableCell>ACCESS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map((user, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{user.nome}</TableCell>
                                                    <TableCell>{user.cidade}</TableCell>
                                                    <TableCell>{user.horaLogin}</TableCell>
                                                    <TableCell>{user.acesso ? 'True' : 'False'}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography marginTop={1} style={{ color: 'cyan' }} gutterBottom>USUARIOS * BY KEY {KEY}</Typography>
                                <TableContainer component={Paper} mb={2}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>CPF</TableCell>
                                                <TableCell>UID</TableCell>
                                                <TableCell>ACTIVE</TableCell>
                                                <TableCell>BANNED?</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(rowsPerPage > 0
                                                ? userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : userData
                                            ).map((user, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{user.cpf}</TableCell>
                                                    <TableCell>{user.telefone}</TableCell>
                                                    <TableCell>{user.ativo ? 'Sim' : 'Não'}</TableCell>
                                                    <TableCell>{user.banido ? 'Sim' : 'Não'}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[20, 50, 100]}
                                    component="div"
                                    count={userData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>


                        </Grid>

                    </Box>
                )}
            </Box>


        </>

    );
};

export default TerminalJsx;
