import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Divider,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../../theme";
import InfoIcon from '@mui/icons-material/Info';
import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { KEY } from '../../../integration/FirebaseConfig';
import { BASE_PROD } from '../../../integration/FirebaseConfig';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputMask from 'react-input-mask';
import ClearIcon from '@mui/icons-material/Clear';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { datetime } from "../../../services/script";

const database = getDatabase(firebaseApp);

function gerarID() {
  const numeroAleatorio = Math.floor(10000000 + Math.random() * 90000000);
  return numeroAleatorio.toString();
}


const allSpecialties = [
  "",
  "Angiologia",
  "Cardiologia",
  "Cirurgia Geral",
  "Cirurgia Pediátrica",
  "Cirurgia Plástica",
  "Citologia",
  "Clínica Médica",
  "Dermatologia",
  "Eletrocardiografia",
  "Eletroencefalografia",
  "Endocrinologia",
  "Endoscopia",
  "Fisioterapia",
  "Fonoaudiologia",
  "Gastroenterologia",
  "Genética",
  "Geriatria",
  "Ginecologia",
  "Hematologia",
  "Hemodinâmica",
  "Hemoterapia",
  "Homeopatia",
  "Imaginologia",
  "Imunologia",
  "Medicina Nuclear",
  "Nefrologia",
  "Neurocirurgia",
  "Neurologia",
  "Nutrição",
  "Obstetrícia",
  "Odontologia",
  "Oftalmologia",
  "Oncologia",
  "Ortopedia",
  "Otorrinolaringologia",
  "Pediatria",
  "Psicologia",
  "Psicoterapia",
  "Pneumologia",
  "Proctologia",
  "Psiquiatria",
  "Quimioterapia",
  "Radiologia",
  "Radioterapia",
  "Reumatologia",
  "Ressonância Magnética",
  "Tomografia",
  "Traumatologia",
  "Ultra-sonografia",
  "Urologia",
];

const NovoProfissional = () => {

  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [buscaCPF, setBuscaCPF] = useState('');
  const [nome, setNome] = useState('');
  const [matricula, setMatricula] = useState('');
  const [nomeProfissional, setNomeProfissional] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [cargo, setCargo] = useState('');
  const [categoria, setCategoria] = useState(allSpecialties[0]);
  const [userData, setUserData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [emailProf, setEmailProf] = useState('');

  const handleCategoriaChange = (event, value) => {
    setCategoria(value);
  };


  const handleCPFChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, '');
    const formattedValue = value
      .slice(0, 11)
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    setBuscaCPF(formattedValue);
  };

  const handleBuscarPaciente = async () => {
    try {
      const database = getDatabase();
      const snapshot = await get(child(ref(database), `usuarios`));

      let userFound = false;
      snapshot.forEach((childSnapshot) => {
        const user = childSnapshot.val();
        if (user.cpf === buscaCPF) {
          setUserData(user);
          setShowForm(true);
          userFound = true;
          console.log("Paciente encontrado");

          setNome(user.nome);

          setCpf(user.cpf);

          setEmail(user.email);

          setCargo(user.categoria);


        }
      });

      if (!userFound) {
        setUserData(null);
        setShowForm(false);
        console.log("Paciente não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar paciente:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleBuscarPaciente()
      console.log('Buscando com CPF:', buscaCPF);
    }
  };

  const handleSalvar = async () => {
    setIsDisabled(true);
    const db = ref(database, `${BASE_PROD}/${KEY}/profissionais/` + gerarID());



    await set(db, {
      nome: nomeProfissional,
      cadastrante: cpf + " | " + nome,
      matricula: matricula,
      especialidade: categoria,
      email: emailProf,
      datetime: datetime()
    });

    navigate(`/profissionais`);

  };





  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
      <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">CADASTRO DE NOVO PROFISSIONAL</Divider>



      {showForm && userData ? (
        <>

          <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17 }}> Cadastrante</Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Nome"
                  fullWidth
                  value={nome}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="CPF"
                  fullWidth
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  sx={{ bgcolor: '#3338' }}

                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>



              <Grid item xs={12} sm={3}>
                <TextField
                  label="E-mail"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Cargo"
                  fullWidth
                  value={cargo}
                  onChange={(e) => setCargo(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>


            </Grid>
          </form>

          <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}> Dados do Profissional</Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome"
                  fullWidth
                  value={nomeProfissional}
                  onChange={(e) => setNomeProfissional(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  label="CRM ou Matrícula"
                  fullWidth
                  value={matricula}
                  onChange={(e) => setMatricula(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="E-mail"
                  fullWidth
                  value={emailProf}
                  onChange={(e) => setEmailProf(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Autocomplete
                  options={allSpecialties}
                  value={categoria}
                  onChange={(event, value) => handleCategoriaChange(event, value)}
                  inputValue={categoria}
                  onInputChange={(event, newInputValue) => {
                    setCategoria(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Especialidades"
                      sx={{ bgcolor: '#3338' }}
                      InputLabelProps={{
                        style: { color: 'white' },
                        focused: false,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
                <Button
                  style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                  onClick={handleSalvar}
                  disabled={isDisabled}  
                >
                  <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                  CONCLUIR
                </Button>
              </Grid>

            </Grid>
          </form>




        </>
      ) : (
        <Grid container spacing={2} style={{ justifyContent: "center", alignContent: 'center', alignItems: 'center' }}>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Digite seu CPF"
              fullWidth
              value={buscaCPF}
              onChange={handleCPFChange}
              onKeyPress={handleKeyPress}
              sx={{ bgcolor: '#3338' }}
              InputLabelProps={{
                style: { color: 'white' },
                focused: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button onClick={handleBuscarPaciente} style={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00", fontWeight: 'bold',
              color: 'white', alignContent: 'center',
              alignItems: 'center', padding: 10
            }}>
              <DoneOutlineIcon />
            </Button>
          </Grid>
        </Grid>
      )
      }
    </Box >
  );
};

export default NovoProfissional;
