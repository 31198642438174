import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Grid
} from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDatabase, ref, get, update } from '@firebase/database';
import HeaderCargos from "../../utils/HeaderCargos";
import firebaseApp, { BASE_PROD, KEY, ROTA_USUARIOS } from '../../integration/FirebaseConfig';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GoogleIcon from '@mui/icons-material/Google';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Atendente, Coodernador, datetime, Gestao, removeAccents } from "../../services/script";
import CloseIcon from '@mui/icons-material/Close';
import { useFuncoes } from "../../services/funcoes";


const Cargos = () => {
  const database = getDatabase(firebaseApp);
  const [loading, setLoading] = useState(true);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [editedName, setEditedName] = useState("");
  const [editedCategory, setEditedCategory] = useState('');
  const [editedActive, setEditedActive] = useState(false);
  const [editedAccess, setEditedAccess] = useState(false);
  const [dados, setDados] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [showNotification, setShowNotification] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [novosCargos, setNovosCargos] = useState([]);


  const cargosPadrao = ["Gestão", "Coordenação", "Atendimento"];
  const {
    funcEditarUsuarios
  } = useFuncoes();

  const fetchCargosCriados = async () => {
    const cargosRef = ref(database, `${BASE_PROD}/${KEY}/cargos`);
    const snapshot = await get(cargosRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const cargosCriados = Object.keys(data).map(key => data[key].nome);
      setNovosCargos(cargosCriados);
    }
  };

  useEffect(() => {
    fetchCargosCriados();
  }, []);

  const handleFilterApply = (newFilters) => {

    const filteredData = userData.filter((user) => {
      return Object.keys(newFilters).every((key) => {
        if (key === 'data') {
          const formattedFilterDate = newFilters[key] ? new Date(newFilters[key]).toISOString().substr(0, 10) : '';
          const formattedUserData = user[key] ? new Date(user[key]).toISOString().substr(0, 10) : '';
          return !formattedFilterDate || formattedUserData.includes(formattedFilterDate);
        }
        const filterValue = key !== 'data' ? removeAccents(newFilters[key]?.toLowerCase().trim()) : newFilters[key].toLowerCase();
        const userValue = key !== 'data' && user[key] ? removeAccents(user[key]?.toLowerCase().trim()) : user[key];
        return !newFilters[key] || (typeof userValue === 'string' && userValue.includes(filterValue));
      });
    });

    if (filteredData.length <= 0) {
      setShowNotification(true);
    }
    setFilteredTableData(filteredData);
  };



  const fetchUsers = async () => {
    const database = getDatabase();
    const usersRef = ref(database, ROTA_USUARIOS);
    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const filteredUsers = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }))
          .filter((user) => user.cidade === KEY && user.cpf && user.cpf !== '');

        setUserData(filteredUsers);

        setLoading(false);



      } else {
        setTimeout(() => {
          setLoading(false);
          setUserData([]);
        }, 600);
      }
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchUsers();
  }, [KEY]);





  const CustomRenderCell = ({ value }) => {
    if (value === null || value === undefined) {
      return (
        <Typography style={{ color: 'white', fontWeight: 'bold' }}>
          -
        </Typography>
      );
    }

    if (typeof value === 'boolean') {
      return (
        <Typography
          style={{ color: value ? '#FF4B00' : 'red', fontWeight: 'bold' }}
        >
          {value ? <CheckCircleOutlineIcon fontSize="large" /> : ''}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{ color: 'white' }}
        >
          {value}
        </Typography>
      );
    }
  };


  const columns = [
    {
      field: "authByGoogle",
      headerName: "Login",
      flex: 0.1,

      filterable: true,
      renderCell: ({ value }) => (
        value ? (
          <GoogleIcon fontSize="large" />

        ) : (
          <AlternateEmailIcon fontSize="large" />
        )
      ),
    },
    {
      field: 'nome',
      headerName: 'Nome',

      flex: 0.8,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'cpf',
      headerName: 'CPF',

      flex: 0.5,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'categoria',
      headerName: 'Cargo',

      flex: 0.5,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 0.6,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'horaLogin',
      headerName: 'Ultimo Login',
      flex: 0.5,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },

    // {
    //   field: 'datetime',
    //   headerName: 'Modificado às',
    //   flex: 0.5,
    //   filterable: true,
    //   renderCell: ({ value }) => <CustomRenderCell value={value} />,
    // },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid container spacing={2} >

            <Grid item xs={12} sm={4}>
              <Typography variant="h6" sx={{ mb: 1, color: "white", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "10px", fontWeight: 'bold' }}>
                Ativo
              </Typography>
              <CustomRenderCell value={row.ativo} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" sx={{ mb: 1, color: "white", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "10px", fontWeight: 'bold' }}>
                Acesso
              </Typography>

              <CustomRenderCell value={row.acesso} />


            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="h6" sx={{ mb: 1, color: "white", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "10px", fontWeight: 'bold' }}>
                Editar
              </Typography>
              <IconButton
                onClick={() => handleEdit(row.id)}
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #FF4B00",
                  borderRadius: '4px',
                  padding: '3px',
                }}
                disabled={!funcEditarUsuarios}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>

        </Box>
      ),
    },
  ];

  const handleSaveEdit = async () => {
    const userRef = ref(database, `usuarios/${selectedUser.id}`);
    let funcoesCargo = [];
    let config = [];

    if (editedCategory == "Gestão") {
      funcoesCargo = Gestao;
    } else if (editedCategory == "Coordenação") {
      funcoesCargo = Coodernador;
    } else if (editedCategory == "Atendimento") {
      funcoesCargo = Atendente;
    } else {
      const cargosRef = ref(database, `${BASE_PROD}/${KEY}/cargos`);
      const snapshot = await get(cargosRef);

      if (snapshot.exists()) {
        const data = snapshot.val();

        const cargo = Object.keys(data).find(key => data[key].nome === editedCategory);
        if (cargo) {
          funcoesCargo = data[cargo].funcoes || [];
          config = {
            "acessoTemporario": data[cargo].acessoTemporario || false, "acessoExterno": data[cargo].acessoExterno || false,
            "timeAcessoTemporario": data[cargo].timeAcessoTemporario || "", "nomeAcessoExterno": data[cargo].nomeAcessoExterno || ""
          }

        }
      }
    }


    const userDataToUpdate = {
      nome: editedName,
      categoria: editedCategory,
      ativo: editedActive,
      acesso: editedAccess,
      datetime: datetime(),
      funcoes: funcoesCargo,
      config: config
    };


    if (dados === false) {
      userDataToUpdate.cpf = null;
    }

    try {
      await update(userRef, userDataToUpdate);
      console.log("Usuário atualizado com sucesso");
      await fetchUsers();
      handleCloseEditDialog();
    } catch (error) {
      console.error("Erro ao atualizar usuário:", error);
    }
  };


  const handleEdit = (id) => {
    const userToEdit = userData.find((user) => user.id === id);
    setSelectedUser(userToEdit);
    setEditedName(userToEdit.nome);
    setEditedCategory(userToEdit.categoria);
    setEditedActive(userToEdit.ativo);
    setEditedAccess(userToEdit.acesso);
    setDados(true)
    setDialogEditOpen(true);
  };

  const handleCloseEditDialog = () => {
    setDialogEditOpen(false);
    setEditedName("");
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Painel DHF - Cargos</title>
        </Helmet>
      </HelmetProvider>

      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <Box m="10px">
        <Dialog open={dialogEditOpen} onClose={handleCloseEditDialog}>
          <DialogTitle>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>Editar Usuário</span>
              <IconButton style={{ marginLeft: 'auto' }} onClick={handleCloseEditDialog}>
                <CloseIcon />
              </IconButton>
            </span>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" sx={{ mb: 1, color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "1rem", fontWeight: 'bold' }}>
              Nome do Usuário
            </Typography>
            <TextField
              label="Nome"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              fullWidth
              variant="outlined"
            />
            <Typography variant="h6" sx={{ mb: 1, color: "#FF4B00", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "1rem", fontWeight: 'bold' }}>
              Cargo do Usuário
            </Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Cargo</InputLabel>
              <Select
                value={editedCategory}
                onChange={(e) => setEditedCategory(e.target.value)}
                label="Cargo"
              >

                {cargosPadrao.map((cargo, index) => (
                  <MenuItem key={index} value={cargo}>
                    {cargo}
                  </MenuItem>
                ))}


                {novosCargos.map((cargo, index) => (
                  <MenuItem key={index + cargosPadrao.length} value={cargo}>
                    {cargo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="h6" sx={{ mb: 1, color: "#FF4B00", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "1rem", fontWeight: 'bold' }}>
              Permissões
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedActive}
                  onChange={(e) => setEditedActive(e.target.checked)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: "#FF4B00" } }}
                />
              }
              label="Ativo"
            />
            <Typography variant="body2" sx={{ color: "#ededed", fontSize: "0.8rem" }}>
              Permite o usuário logar no sistema. Se desativado, o usuário será 'banido' e não poderá acessar o sistema.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedAccess}
                  onChange={(e) => setEditedAccess(e.target.checked)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: "#FF4B00" } }}
                />
              }
              label="Acesso"
            />
            <Typography variant="body2" sx={{ color: "#ededed", fontSize: "0.8rem" }}>
              Permite o usuário logar, mas sem acesso às funcionalidades do dashboard. Ao entrar, verá uma mensagem informando que está sem acesso.
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={dados}
                  onChange={(e) => setDados(e.target.checked)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: "#FF4B00" } }}
                />
              }
              label="Recadastramento"
            />
            <Typography variant="body2" sx={{ color: "#ededed", fontSize: "0.8rem" }}>
              Ao salvar com essa informação desmarcada, você forçara o usuário a realizar uma atualização nos dados cadastrados.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: "white", fontWeight: 'bold' }} onClick={handleCloseEditDialog}>Cancelar</Button>
            <Button onClick={handleSaveEdit} color="primary" style={{ backgroundColor: "#FF4B00", color: "#FFF", fontWeight: 'bold' }}>Salvar</Button>
          </DialogActions>
        </Dialog>


        <HeaderCargos title={`USUÁRIOS DO SISTEMA`} onFilterApply={handleFilterApply}></HeaderCargos>

        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid rows={filteredTableData} columns={columns} rowHeight={80} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            ) : (
              <DataGrid rows={filteredTableData.length > 0 ? filteredTableData : userData} columns={columns} rowHeight={80} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            )}
          </Box>
        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Cargos;
