import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Slide } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import '../../styles/ChatWhatsApp.css';
import firebaseApp, { KEY_NAME_CITY, KEY_UF} from '../../integration/FirebaseConfig';
import Chat from './Chat';
import ConfigChat from './ConfigChat';
import Chats from './Chats';
import SideBarTittle from './SideBarTittle';
import { getDatabase, onValue, ref } from 'firebase/database';
import { reloadPage } from '../../services/script';

const ChatWhatsApp = ({ idClick }) => {
  const location = useLocation();
  const database = getDatabase(firebaseApp);
  const [chatAberto, setChatAberto] = useState(null);
  const [chatSelecionadoId, setChatSelecionadoId] = useState(null);
  const [manutencaoAtiva, setManutencaoAtiva] = useState(false);
  const dbRefAtivo = ref(database, `/panel/manutencao/chat`);


  useEffect(() => {
    const unsubscribe = onValue(dbRefAtivo, (snapshot) => {
      const ativo = snapshot.val();

      if (ativo) {
        setManutencaoAtiva(true);
      } else {
        setManutencaoAtiva(false);
      }
    });

    return () => {

      unsubscribe();
    };
  }, []);

  const handleChatClick = (id) => {

    setChatSelecionadoId(id);

    if (id === null) {
      setChatAberto(false);
    } else {
      setChatAberto(true);
    }
  };

  useEffect(() => {
    if (location.state?.idClick != null) {
      handleChatClick(location.state.idClick);

    }
  }, [location.state?.idClick]);

  return (
    <>
      <HelmetProvider>  <Helmet>
        <title>Painel DHF - Chat</title>
      </Helmet></HelmetProvider>


      {manutencaoAtiva === true ? (<>
        <div className="login-container">
          <div className="logo-container">
            <img src="/images/logo.webp" alt="Logo" width="200" height="65" />
          </div>
          <div className="login-form" style={{
            backgroundColor: "#333333",
            border: "2px solid #FF4B00", borderRadius: 18, paddingTop: 20, paddingBottom: 20
          }}>

            <h1>Manutenção do CHAT</h1>
            <Box m="20px" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography>Prezado usuário,</Typography>

              <Typography style={{ textAlign: 'justify', maxWidth: 500 }}>
                O chat está temporariamente indisponível devido a uma breve manutenção. Por favor, saiba que estamos trabalhando diligentemente para melhorar e garantir a estabilidade do nosso sistema. Geralmente, a manutenção leva apenas alguns minutos e estaremos de volta logo. Pedimos desculpas pelo inconveniente e agradecemos sua compreensão e paciência.
              </Typography>

              <Typography style={{ marginTop: 10 }}>Atenciosamente,</Typography>
              <Typography>Equipe de desenvolvimento Data Health Flow</Typography>

              <Button onClick={reloadPage} variant="contained" sx={{
                marginTop: '20px', backgroundColor: "transparent",
                border: "1px solid #FF4B00", color: 'white', fontWeight: 'bold'
              }}>ATUALIZAR</Button>

            </Box>
          </div>


        </div>






      </>) : (<>
        <Box display="flex">
          <Box
            sx={{
              minWidth: '50vh',
              width: { xs: '100%', sm: '75vh', md: '54vh' },
              height: "100vh",
              borderRight: "1px solid black"
            }}
          >
            <SideBarTittle onChatClick={handleChatClick} />
            <Chats onChatClick={handleChatClick} />
            <ConfigChat onChatClick={handleChatClick} />
          </Box>

          <Box flex="1" style={{ position: 'relative', backgroundColor: '#1D1D1D' }}>
            {!chatAberto && (
              <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1 }} className={chatAberto ? 'logo hidden' : 'logo'}>
                <img src="/images/logo.webp" alt="Logo" className="logochat" />
                <Typography
                  style={{
                    marginTop: 40,
                    color: '#424242',
                    fontWeight: 'lighter',
                    letterSpacing: KEY_NAME_CITY.length >= 13 ? 5 : 10,
                    fontSize: KEY_NAME_CITY.length >= 13 ? 20 : 16,
                    fontFamily: "Source Sans 3, sans-serif",
                  }}
                >
                  A SERVIÇO DE {(KEY_NAME_CITY).toUpperCase()} - {KEY_UF}
                </Typography>
              </Box>
            )}


            {chatAberto && (
              <Slide direction="down" in={chatAberto} mountOnEnter unmountOnExit>
                <Box
                  key={chatSelecionadoId}
                  style={{ position: 'relative', backgroundColor: '#1D1D1D', width: '100%', height: '100vh', overflow: 'hidden' }}
                >
                  {chatSelecionadoId && <Chat id={chatSelecionadoId} />}
                </Box>
              </Slide>
            )}
          </Box>
        </Box>
      </>)}




    </>
  );
};

export default ChatWhatsApp;
