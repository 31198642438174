import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Button,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
  Popover,
} from "@mui/material";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import FilterIcon from "@mui/icons-material/FilterList";
import DatePicker from "@mui/lab/DatePicker";
import Calendar from "react-calendar";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const HeaderMarcacao = ({ title, onFilterApply }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({
    cpf: "",
    nome: "",
    categoria: "",
    procedimento: "",
    protocolo: "",
    data: null,
    cor: ""
  });
  const [filtersApplied, setFiltersApplied] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event, filterType) => {
    const value = event.target.value;
    setFilterValues((prevValues) => ({
      ...prevValues,
      [filterType]: value,
    }));


  };

  const handleDateChange = (date) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      data: date,
    }));
    setFiltersApplied(true);
  };

  const handleFilterApply = () => {
    onFilterApply(filterValues);
    handleMenuClose();
    setFiltersApplied(true);
  };

  const handleFilterReset = () => {
    setFilterValues({
      cpf: "",
      nome: "",
      categoria: "",
      protocolo: "",
      procedimento: "",
      data: null,
      cor: ""
    });
    onFilterApply({
      cpf: "",
      nome: "",
      categoria: "",
      protocolo: "",
      procedimento: "",
      data: null,
      cor: ""
    });
    handleMenuClose();
    setFiltersApplied(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleFilterApply()

    }
  }

  return (
    <Box mb="30px" display="flex" alignItems="center" backgroundColor={colors.tabelas.blackCinza} padding={2} borderRadius={3} >
      <img src="/images/brasao2.webp" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        mb="5px"
        flexGrow={1}
      >
        {title}
      </Typography>


      {filtersApplied && (
        <Link to={"#"}>
          <Button
            variant="contained"
            onClick={handleFilterReset}
            style={{ backgroundColor: "#FF4B00", marginRight: "10px", fontWeight: 'bold' }}
          >
            <RestartAltIcon style={{ marginBottom: 3 }}></RestartAltIcon>
            LIMPAR FILTROS
          </Button>
        </Link>
      )}

      <IconButton
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #FF4B00",
          color: "white",
          borderRadius: "4px",
          padding: "8px",
          display: "flex",
          alignItems: "center",

        }}
        onClick={handleMenuOpen}
      >
        <FilterIcon />
        <Typography variant="button" ml={1} style={{ color: 'white' }}>
          FILTRO AVANÇADO
        </Typography>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 5 }}>
          <MenuItem>
            <TextField sx={{ minWidth: 300 }}
              label="CPF"
              value={filterValues.cpf}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "cpf")}
            />
          </MenuItem>
          <MenuItem>
            <TextField sx={{ minWidth: 300 }}
              label="Nome"
              value={filterValues.nome}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "nome")}
            />
          </MenuItem>

          <MenuItem>
            <TextField sx={{ minWidth: 300 }}
              label="Protocolo"
              value={filterValues.protocolo}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "protocolo")}
            />
          </MenuItem>
          <MenuItem>

            <TextField sx={{ minWidth: 300 }}
              label="Especialidade"
              value={filterValues.categoria}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "categoria")}
            />


          </MenuItem>



          <MenuItem>
            <FormControl sx={{ minWidth: 300 }} variant="filled">
              <InputLabel style={{ color: 'white' }}>Procedimento</InputLabel>
              <Select
                value={filterValues.procedimento}
                onChange={(event) => handleFilterChange(event, "procedimento")}
              >
                <MenuItem value="Consulta">Consulta</MenuItem>
                <MenuItem value="Cirugia">Cirugia</MenuItem>
                <MenuItem value="Exames">Exames</MenuItem>
                <MenuItem value="Retorno">Retorno</MenuItem>
                <MenuItem value="Outros">Outros</MenuItem>
              </Select>
            </FormControl>
          </MenuItem>

          <MenuItem>

          <FormControl sx={{ minWidth: 300 }} variant="filled">
              <InputLabel style={{ color: 'white' }}>Prioridade</InputLabel>
              <Select
                value={filterValues.cor}
                onChange={(event) => handleFilterChange(event, "cor")}
              >
                <MenuItem value="verde">Sem Prioridade</MenuItem>
                <MenuItem value="amarelo">Prioridade (Idoso)</MenuItem>
                <MenuItem value="laranja">Prioridade (Gestante)</MenuItem>
                <MenuItem value="azul">Prioridade (Portador de Dificiência)</MenuItem>
                <MenuItem value="vermelho">Prioridade (Outras Emergência)</MenuItem>

              </Select>
            </FormControl>
          </MenuItem>


          <MenuItem>
            <Button
              style={{ backgroundColor: "#ff4b00", fontWeight: "bold", marginRight: "10px", color: 'white' }}
              onClick={handleFilterApply}
            >
              Filtrar
            </Button>
            <Button
              style={{ backgroundColor: "white", fontWeight: "bold" }}
              onClick={handleFilterReset}
            >
              Voltar
            </Button>
          </MenuItem>
        </Box>
      </Popover>

    </Box>
  );
};

export default HeaderMarcacao;
