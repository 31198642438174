import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton } from "@mui/material";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import { KEY } from "../integration/FirebaseConfig";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useFuncoes } from "../services/funcoes";


const HeaderProfissionais = ({ title, subtitle, onFilterClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    funcCriarNovosProfissionais
  } = useFuncoes();

  return (
    <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
      <img src="/images/brasao2.webp" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        mb="5px"
        flexGrow={1}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>

      {funcCriarNovosProfissionais && (
        <Link to={`/profissionais/novo`}>
          <IconButton variant="contained" style={{ backgroundColor: "#FF4B00", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }} >
            <PeopleAltIcon style={{ marginRight: 4 }} /> NOVO PROFISSIONAL
          </IconButton>
        </Link>

      )}




    </Box>
  );
};

export default HeaderProfissionais;
