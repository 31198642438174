import React, { useState } from 'react';
import { Typography, IconButton, Slide, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import '../../styles/ChatWhatsApp.css';

const SideBarTittle = ({ onChatClick }) => {
  const [hover, setHover] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        paddingTop: 19,
        marginBottom: 10,
        backgroundColor: '#1d1d1d',
      }}
    >
      <Box

        display="flex"
        alignItems="center"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img
          src='/images/brasao.webp'
          alt="Brasão"
          style={{ width: '30px', height: '28px', marginLeft: 19, marginTop: '-15px' }}
        />
         <Typography
            onClick={() => onChatClick(null)}
            variant="h5"
            style={{
              textAlign: 'center',
              paddingTop: 3,
              marginLeft: 7,
              marginBottom: 20,
              fontWeight: 'bold',
              fontSize: 20,
              color: 'white',  cursor: 'pointer',
            }}
          >
            {hover ? (<> PÁGINA INICIAL</>) : (<> CHAT</>)}

          </Typography>
        
      </Box>
    </div>
  );
};

export default SideBarTittle;
