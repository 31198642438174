import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, FormControl, InputLabel, Select, FormControlLabel, Checkbox, MenuItem, TextField, IconButton, Autocomplete } from "@mui/material";
import { get, getDatabase, ref, set } from "firebase/database";
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { allCargos, datetime } from "../../../services/script";
import firebaseApp, { BASE_PROD, KEY } from '../../../integration/FirebaseConfig';

const database = getDatabase(firebaseApp);

function gerarID() {
  const numeroAleatorio = Math.floor(10000000 + Math.random() * 90000000);
  return numeroAleatorio.toString();
}

const NovoCargo = ({ handleClose, fetch }) => {
  const [nome, setNome] = useState('');
  const [categoria, setCategoria] = useState(allCargos[0]);
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState([]);
  const [acessoInstituicao, setAcessoInstituicao] = useState(false);
  const [cargoTemporario, setCargoTemporario] = useState(false);
  const [tempoAcesso, setTempoAcesso] = useState('');
  const [instituicoes, setInstituicoes] = useState([]);
  const [idAcessoExterno, setIdAcessoExterno] = useState('');
  const [nomeAcessoExterno, setNomeAcessoExterno] = useState('');

  useEffect(() => {
    if (acessoInstituicao) {
      get(ref(database, `${BASE_PROD}/${KEY}/instituicoes/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const instituicoesData = snapshot.val();
            setInstituicoes(Object.values(instituicoesData));
          }
        })
        .catch((error) => {
          console.error("Error fetching instituições:", error);
        });
    }
  }, [acessoInstituicao]);

  const handleCategoriaChange = (event, value) => {
    if (value && !especialidadesSelecionadas.includes(value)) {
      setEspecialidadesSelecionadas([...especialidadesSelecionadas, value]);
    }
    setCategoria('');
  };

  const handleRemoveEspecialidade = (especialidade) => {
    const updatedEspecialidades = especialidadesSelecionadas.filter(item => item !== especialidade);
    setEspecialidadesSelecionadas(updatedEspecialidades);
  };

  const handleSalvar = async () => {
    const uid = gerarID();
    const db = ref(database, `${BASE_PROD}/${KEY}/cargos/` + uid);

    const currentDate = new Date();
    const addedDays = parseInt(tempoAcesso, 10);
    const timeAcessoTemporario = new Date(currentDate);
    timeAcessoTemporario.setDate(currentDate.getDate() + addedDays);

    const formattedDate = timeAcessoTemporario.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });

    await set(db, {
      nome,
      datetime: datetime(),
      funcoes: especialidadesSelecionadas,
      id: uid,
      nomeAcessoExterno,
      idAcessoExterno,
      acessoExterno: acessoInstituicao,
      acessoTemporario: cargoTemporario,
      timeAcessoTemporario: cargoTemporario ? formattedDate : ""
    });
    handleClose();
    fetch();
  };

  return (
    <Box p="10px" bgcolor="#1D1D1D" borderRadius="20px" >
      <form>
        <FormControlLabel
          control={
            <Checkbox
              checked={acessoInstituicao}
              onChange={(e) => setAcessoInstituicao(e.target.checked)}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: "#FF4B00" } }}
            />
          }
          label="Acesso Externo?"
        />




        {acessoInstituicao && (
          <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }} >
            <InputLabel htmlFor="instituicoes" style={{ color: 'white' }}>Instituições</InputLabel>
            <Select
              value={idAcessoExterno}
              onChange={(e) => {
                const selectedInstituicao = instituicoes.find(inst => inst.id === e.target.value);
                setIdAcessoExterno(e.target.value);
                setNomeAcessoExterno(selectedInstituicao ? selectedInstituicao.nome : '');
              }}
            >
              {instituicoes.map((instituicao) => (
                <MenuItem key={instituicao.id} value={instituicao.id}>
                  {instituicao.nome}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={cargoTemporario}
              onChange={(e) => setCargoTemporario(e.target.checked)}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: "#FF4B00" } }}
            />
          }
          label="Cargo Temporário?"
        />

        {cargoTemporario && (
          <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }} >
            <InputLabel htmlFor="tempo-acesso" style={{ color: 'white' }}>Tempo de Acesso</InputLabel>
            <Select
              value={tempoAcesso}
              onChange={(e) => setTempoAcesso(e.target.value)}
            >
              <MenuItem value="15">15 dias</MenuItem>
              <MenuItem value="30">30 dias</MenuItem>
              <MenuItem value="60">60 dias</MenuItem>

            </Select>
          </FormControl>
        )}

        <Grid item xs={12}>
          <Typography
            style={{
             
              fontWeight: 'bold',
              marginTop: 10,
              marginBottom: 15
            }}
          >
            Configurações básicas
          </Typography>
        </Grid>

        <Grid container spacing={2} >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nome do cargo"
              fullWidth
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              sx={{ bgcolor: '#3338' }}
              InputLabelProps={{ style: { color: 'white' }, focused: false }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={allCargos}
              value={categoria}
              onChange={(event, value) => handleCategoriaChange(event, value)}
              inputValue={categoria}
              onInputChange={(event, newInputValue) => {
                setCategoria(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Funções"
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    marginBottom: '0.6em',
                    marginTop: 10
                  }}
                >
                  Funções permitidas
                </Typography>
              </Grid>
              {especialidadesSelecionadas.map((especialidade, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: "transparent",
                      border: "1px solid #FF4B00",
                      padding: '0.2em',
                      borderRadius: 10,
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '50px',
                    }}
                  >
                    <IconButton
                      style={{ fontSize: 13, borderRadius: 4, fontWeight: 'bold' }}
                      onClick={() => handleRemoveEspecialidade(especialidade)}
                    >
                      {especialidade}
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
            <Button
              style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
              onClick={handleSalvar}
            >
              <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
              CONCLUIR
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NovoCargo;
