import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import firebaseApp, { KEY, API_DEV, auth } from '../../../integration/FirebaseConfig';
import ProgressCircle from "./ProgressCircle";
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";


const TotalMarcadas = ({ onUpdate, integridade }) => {
    const [totalSolicitados, setTotalSolicitados] = useState(0);
    const [totalMarcadas, setMarcadas] = useState(0);
    const [totalAguardandoAnalise, setAguardandoAnalise] = useState(0);
    const [totalCancelados, setCancelados] = useState(0);

    const fetchDataFromFirebase = async () => {
        const userId = auth.currentUser.uid
        const email = auth.currentUser.email
        const token = await auth.currentUser.getIdToken();
        const result = tokenCrypt(userId, email, token)

        try {
            const response = await axios.get(`${API_DEV}/dashboard/metricas`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { userId, cidade: KEY }
            });

            if (response.status === 200) {
                const { solicitadosStatus, solicitadosMetricas } = response.data;

                const totalSolicitados = solicitadosMetricas.TotalSolicitados;
                integridade(100)
                setTotalSolicitados(totalSolicitados);
                setMarcadas(solicitadosStatus["Marcada"]);
                setAguardandoAnalise(solicitadosStatus["Em Análise"] + solicitadosStatus["Aguardando vagas"]);
                setCancelados(solicitadosStatus["Cancelado pelo usuário"] + solicitadosStatus["Cancelado pela Instituição"])

                localStorage.setItem('cacheTotalMarcadas', JSON.stringify({
                    totalSolicitados: totalSolicitados,
                    totalMarcadas: solicitadosStatus["Marcada"],
                    totalAguardandoAnalise: solicitadosStatus["Em Análise"] + solicitadosStatus["Aguardando vagas"],
                    totalCancelados: solicitadosStatus["Cancelado pelo usuário"] + solicitadosStatus["Cancelado pela Instituição"],
                }));
            } else {
                integridade(10)
                
            }
        } catch (error) {
            setTotalSolicitados(0);
            setMarcadas(0);
            setAguardandoAnalise(0);
            setCancelados(0);
            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }


    };


    useEffect(() => {
        const cachedData = localStorage.getItem('cacheTotalMarcadas');
        if (onUpdate === false) {
            if (cachedData) {
                const cached = JSON.parse(cachedData);
                setTotalSolicitados(cached.totalSolicitados);
                setMarcadas(cached.totalMarcadas);
                setAguardandoAnalise(cached.totalAguardandoAnalise);
                setCancelados(cached.totalCancelados);
                integridade(100)
            } else {
                integridade(0)
            }
        } else if (onUpdate === true) {
            fetchDataFromFirebase();
        }
    }, [onUpdate]);

    return (
        <Box
            gridColumn={"span 2"}
            gridRow="span 2"
            backgroundColor={'#1d1d1d'}
            p="15px"
            borderRadius={5}
        >
            <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center' }}>
                Status de Protocolos (2024)
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
            >
                <ProgressCircle
                    value1={totalMarcadas}
                    value2={totalAguardandoAnalise}
                    value3={totalCancelados}
                    geral={totalSolicitados}
                    color1="#ff4b00"
                    color2="#ea916e"
                    color3="#762e10"
                    size={150}
                />
                <Box style={{ marginTop: '-100px', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography style={{ fontSize: 20 }}>Total </Typography>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{totalSolicitados}</Typography>
                </Box>
                <Box style={{ justifyContent: 'flex-start', marginTop: '40px' }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: 2 }}>
                        <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#ff4b00' }} />
                        <span>Marcadas: ({totalMarcadas})</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#ea916e' }} />
                        <span>Aguardando vagas/análise: ({totalAguardandoAnalise})</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#762e10' }} />
                        <span>Cancelados: ({totalCancelados})</span>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TotalMarcadas;
