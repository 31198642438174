import { useEffect, useState } from 'react';
import { ref, get, getDatabase, onValue } from 'firebase/database';
import { getIdToken, signOut } from 'firebase/auth';
import { format } from 'date-fns';
import firebaseApp, { KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, url_api_gzappy, ROTA_USUARIOS, auth, KEY, secretKey } from '../integration/FirebaseConfig';
import CryptoJS from 'crypto-js';

export function useLastVersion() {
  const [lastVersionNumber, setLastVersionNumber] = useState(null);

  useEffect(() => {
    const database = getDatabase(firebaseApp);
    const versionsRef = ref(database, 'panel/versoes/atual');


    const unsubscribe = onValue(versionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.versionNumber) {
        setLastVersionNumber(data.versionNumber);
      }
    });
    return () => unsubscribe();
  }, []);

  return lastVersionNumber;
}

export const reloadPage = () => {
  window.location.reload();
};

export const generateToken = (value) => {
  const tokenLength = value;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  for (let i = 0; i < tokenLength; i++) {
    if (i > 0 && i % 5 === 0) {
      token += '-';
    }
    token += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return token;
};

export function formatarTelefone(telefone) {

  const numerosTelefone = telefone.replace(/\D/g, '');

  if (numerosTelefone.length === 12) {
    return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 4)}) ${numerosTelefone.slice(4, 8)}-${numerosTelefone.slice(8)}`;
  } else if (numerosTelefone.length === 11) {
    return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 3)}) ${numerosTelefone.slice(3, 7)}-${numerosTelefone.slice(7)}`;
  } else if (numerosTelefone.length === 10) {
    return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 4)}) ${numerosTelefone.slice(4, 8)}-${numerosTelefone.slice(8)}`;
  }
}

export const maskCPF = (value) => {
  const cpfNumeros = value.replace(/\D/g, '');

  if (cpfNumeros.length <= 11) {
    const formattedCpf = cpfNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
    return formattedCpf;

  }
};

export const checkToken = async (database, auth, userId) => {
  const userTokenRef = ref(database, `${ROTA_USUARIOS}/${userId}/token`);

  try {
    const snapshot = await get(userTokenRef);
    const savedToken = snapshot.val();
    const TOKEN = await getIdToken(auth.currentUser);

    if (TOKEN !== savedToken) {
      console.log('AUTH - Token da Sessão não corresponde ao Token Armazenado.');
      await signOut(auth);
    } else {
      // console.log('Tokens correspondem. Usuário autenticado.');
    }
  } catch (error) {
    console.error('Erro ao verificar a validade do token:', error);
  }
};

export function tokenCrypt(userId, email, token) {

  const dataToEncrypt = `${userId}:${email}:${token}`;
  const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();

  return encryptedData

};



export function datetime() {
  const now = new Date();
  let date = now.toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return date;

}

export function formatUTCDateToUTCPlusThree(dateString) {
  const selectedEventDate = new Date(dateString);
  selectedEventDate.setUTCHours(selectedEventDate.getUTCHours() + 6);
  const formattedDate = format(selectedEventDate, '(dd/MM/yyyy)');
  return formattedDate;
}

export const categoryColors = {
  azul: 'blue',
  verde: 'green',
  amarelo: 'yellow',
  laranja: 'orange',
  vermelho: 'red',
  ciano: '#00ffc3'
};

export const priorities = [
  { value: 'verde', label: 'Sem prioridade' },
  { value: 'amarelo', label: 'Prioridade (Idoso)' },
  { value: 'laranja', label: 'Prioridade (Gestante))' },
  { value: 'vermelho', label: 'Prioridade (Emergência)' },
  { value: 'azul', label: 'Prioridade (Portador de deficiência)' },
  { value: 'ciano', label: 'Prioridade (Oncologia)' },
];

export const getPrioridadeLabel = (tipoPrioridade) => {
  switch (tipoPrioridade) {
    case 'verde':
      return 'Sem Prioridade';
    case 'amarelo':
      return 'Prioridade (Idoso)';
    case 'laranja':
      return 'Prioridade (Gestante)';
    case 'azul':
      return 'Prioridade (Portador de Dificiência)';
    case 'vermelho':
      return 'Prioridade (Outras Emergência)';
    case 'ciano':
      return 'Prioridade (Oncologia)';
    default:
      return 'Sem Prioridade';
  }
};

export function paramsAuth() {
  let userId = auth.currentUser.uid;
  const token = CryptoJS.AES.encrypt(userId, secretKey).toString();
  return { userId, token };
}


export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const handleCopyClick = (userId) => {
  navigator.clipboard.writeText(userId)
    .then(() => console.log('Copiado'))
    .catch((error) => console.error('Erro ao copiar', error));
};

export const isCPF = (term) => {
  const cpfRegex = /^\d{3}\d{3}\d{3}\d{2}$/;
  return cpfRegex.test(term);
};




export const isEmail = (term) => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(term);
};

export const isData = (term) => {

  return term.includes('/')
};

export const formatarId = (id) => {
  return `+${id.slice(0, 2)} (${id.slice(2, 4)}) ${id.slice(4, 8)}-${id.slice(8)}`;
};

export const generateRandomToken = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  for (let i = 0; i < 50; i++) {
    token += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return token;
};

export function cpfIsValid(cpf) {
  cpf = String(cpf).replace(/\D/g, '');
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  if (remainder !== parseInt(cpf.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  return remainder === parseInt(cpf.charAt(10));
}

export const allSpecialties = [
  "",
  "Angiologia",
  "Cardiologia",
  "Cirurgia Geral",
  "Cirurgia Pediátrica",
  "Cirurgia Plástica",
  "Citologia",
  "Clínica Médica",
  "Dermatologia",
  "Eletrocardiografia",
  "Eletroencefalografia",
  "Endocrinologia",
  "Endoscopia",
  "Fisioterapia",
  "Fonoaudiologia",
  "Gastroenterologia",
  "Genética",
  "Geriatria",
  "Ginecologia",
  "Hematologia",
  "Hemodinâmica",
  "Hemoterapia",
  "Homeopatia",
  "Imaginologia",
  "Imunologia",
  "Medicina Nuclear",
  "Nefrologia",
  "Neurocirurgia",
  "Neurologia",
  "Nutrição",
  "Obstetrícia",
  "Odontologia",
  "Oftalmologia",
  "Oncologia",
  "Ortopedia",
  "Otorrino",
  "Pediatria",
  "Psicologia",
  "Psicoterapia",
  "Pneumologia",
  "Proctologia",
  "Psiquiatria",
  "Quimioterapia",
  "Radiologia",
  "Radioterapia",
  "Reumatologia",
  "Ressonância Magnética",
  "Tomografia",
  "Traumatologia",
  "Ultrassonografia",
  "Urologia",
  "Mamografia",
  "Densitometria Óssea",
  "Ecocardiograma",
  "Ecodoppler",
  "Teste Ergométrico",
  "Colonoscopia",
  "Cistoscopia",
  "Polissonografia",
  "Espectroscopia",
  "Cintilografia",
  "Angiografia",
  "Artroscopia",
  "Broncoscopia",
  "Laparoscopia",
  "Histeroscopia",
  "Videolaringoscopia",
  "Radiografia de Tórax",
  "Radiografia de Abdômen",
  "Radiografia de Coluna",
  "Radiografia de Crânio",
  "Radiografia de Membros",
  "Radiografia de Seios da Face",
  "Radiografia Panorâmica",
  "Tomografia Computadorizada",
  "Ressonância Magnética Cardíaca",
  "Ultrassonografia Obstétrica",
  "Ultrassonografia Abdominal",
  "Ultrassonografia Transvaginal",
  "Ultrassonografia de Mamas",
  "Ultrassonografia Vascular",
  "Ultrassonografia de Tireoide",
  "Ultrassonografia de Próstata",
  "Ultrassonografia Musculoesquelética",
  "Angiotomografia",
];

export const allExames = [
  "",
  "Endoscopia",
  "Colonoscopia",
  "Ecocardiograma",
  "Teste ergométrico",
  "Densitometria óssea",
  "Espirometria",
  "Holter de 24 horas",
  "Mamografia",
  "MAPA",
  "Ressonância Magnética",
  "Tomografia",
  "Ultrassonografia: Abdômen Total",
  "Ultrassonografia: Pélvica",
  "Ultrassonografia: Transvaginal",
  "Ultrassonografia: Próstata",
  "Ultrassonografia: Aparelho Urinário",
  "Ultrassonografia: Mamária",
  "Ultrassonografia: Articulação",
  "Ultrassonografia: Tireoide",
  "Ultrassonografia: Partes moles",
  "Ultrassonografia: Obstétrica",
  "Ultrassonografia: Outros",
  "Raio X: Tórax",
  "Raio X: Joelhos",
  "Raio X: Tornozelos",
  "Raio X: Pés",
  "Raio X: Punho e mão",
  "Raio X: Coluna Lombar",
  "Raio X: Coluna Cervical",
  "Raio X: Coluna Dorsal",
  "Raio X: Coluna Total",
  "Raio X: Quadril/Bacia",
  "Raio X: Ombro",
  "Exames de laboratório (sangue)"
];





export const Atendente = [
  "Todas permissões - Pacientes",
  "Todas permissões - Solicitações",
  "Todas permissões - Confirmados",
  "Todas permissões - Marcações",
  "Todas permissões - Calendário",
  "Todas permissões - Chat"
]

export const Coodernador = [
  "Todas permissões - Pacientes",
  "Todas permissões - Solicitações",
  "Todas permissões - Confirmados",
  "Todas permissões - Marcações",
  "Todas permissões - Calendário",
  "Todas permissões - Chat",
  "Todas permissões - Campanhas",
  "Todas permissões - Profissionais",
  "Todas permissões - Instituições",
  "Todas permissões - Vagas"
]

export const Gestao = [
  "Todas permissões - Pacientes",
  "Todas permissões - Solicitações",
  "Todas permissões - Confirmados",
  "Todas permissões - Avaliações",
  "Todas permissões - Instituições",
  "Todas permissões - Dashboard",
  "Todas permissões - Chat",
  "Todas permissões - Campanhas",
  "Todas permissões - Cargos e Funções",
  "Todas permissões - Marcações",
  "Todas permissões - Profissionais",
  "Todas permissões - Calendário",
  "Todas permissões - Usuários",
  "Todas permissões - Vagas"
]

export const allCargos = [
  "",
  "Todas permissões - Pacientes",
  "Todas permissões - Solicitações",
  "Todas permissões - Confirmados",
  "Todas permissões - Avaliações",
  "Todas permissões - Instituições",
  "Todas permissões - Dashboard",
  "Todas permissões - Chat",
  "Todas permissões - Cargos e Funções",
  "Todas permissões - Marcações",
  "Todas permissões - Profissionais",
  "Todas permissões - Calendário",
  "Todas permissões - Campanhas",
  "Todas permissões - Usuários",
  "Todas permissões - Vagas",

  "Visualizar - Calendário",                  // /${cidade}/
  "Atualizar - Calendário",                   // /${cidade}/

  "Chat - Comunicação",                       // /${cidade}/chat
  "Transmissão - Comunicação",                // /${cidade}/chat
  "Campanhas - Comunicação",                  // /${cidade}/campanhas

  "Atualizar - Confirmados",                  // /${cidade}/confirmados
  "Atualizar - Vagas",
  "Atualizar - Solicitações",                 // /${cidade}/solicitacoes
  "Finalizar - Confirmados",                  // /${cidade}/confirmados

  "Editar - Pacientes",                       // /${cidade}/pacientes/editar
  "Editar - Instituições",                    // /${cidade}/instituicoes/editar
  "Editar - Profissionais",                   // /${cidade}/profissionais/editar
  "Editar - Usuários",

  "Visualizar - Pacientes",                   // /${cidade}/pacientes
  "Visualizar - Solicitações",                // /${cidade}/solicitacoes
  "Visualizar - Confirmados",                 // /${cidade}/confirmados
  "Visualizar - Instituições",                // /${cidade}/instituicoes
  "Visualizar - Profissionais",               // /${cidade}/profissionais
  "Visualizar - Dashboard",                   // /${cidade}/dashboard

  "Excluir - Instituições",                   // /${cidade}/instituicoes
  "Excluir - Profissionais",                  // /${cidade}/profissionais

  "Relatórios - Pacientes",                   // /${cidade}/pacientes
  "Relatórios - Solicitações",                // /${cidade}/solicitacoes
  "Relatórios - Confirmados",                 // /${cidade}/confirmados

  "Criar - Novos Pacientes",                  // /${cidade}/pacientes/novo
  "Criar - Novas Solicitações",               // /${cidade}/solicitacoes/novo
  "Criar - Novas Instituições",               // /${cidade}/instituicoes/novo
  "Criar - Novos Profissionais",              // /${cidade}/profissionais/novo

  "Baixar - Solicitações",                    // /${cidade}/solicitacoes
  "Baixar - Pacientes",                       // /${cidade}/pacientes
  "Baixar - Confirmados",                     // /${cidade}/confirmados

  "Config Tabela - Solicitações",             // /${cidade}/solicitacoes
  "Config Tabela - Confirmados"               // /${cidade}/confirmados
];


export function gerarID() {
  const numeroAleatorio = Math.floor(10000000 + Math.random() * 90000000);
  return numeroAleatorio.toString();
}

export const formatarData = (data) => {
  const dataObj = new Date(data);
  const dia = String(dataObj.getDate()).padStart(2, '0');
  const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
  const ano = dataObj.getFullYear();
  return `${dia}/${mes}/${ano}`;
};






export const handleSendError = async (newMessage, nameUser) => {
  const GZAPPY_URL = `${url_api_gzappy}`;
  if (newMessage.trim() === '') return;



  let msg = newMessage;

  const gzappyData = {
    instance_id: `${KEY_INSTANCE_ID}`,
    instance_token: `${KEY_INSTANCE_TOKEN}`,
    message: [`> ⓘ *IMPORTANTE!*:\n\nTRAVAMENTO NO SERVIDOR\n\n> ⓘ *BOT MESSEGER:*\n\n Usuário *(${nameUser})* travou o sistema, mas conseguimos recuperar rapidamente, recomendo observar os logs gerados no servidor para saber a causa do Problema.\n\n> ⓘ *LOG DO ERRO:*\n ${msg}\n\n> ⓘ *DATA E HORA DA OCORRÊNCIA*:\n\n` + datetime],
    phone: '5583986347332'
  };

  const response = await fetch(GZAPPY_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'user_token_id': `${KEY_USER_TOKEN_ID}`
    },
    body: JSON.stringify(gzappyData)
  });

  if (response.ok) {
    /*   console.log('Mensagem enviada para a API GZAPPY com sucesso!'); */
  } else {
    console.error('Erro ao enviar mensagem para a API GZAPPY:', response.statusText);

  }

};

export const cidadesPB = [
  "Água Branca",
  "Aguiar",
  "Alagoa Grande",
  "Alagoa Nova",
  "Alagoinha",
  "Alcantil",
  "Algodão de Jandaíra",
  "Alhandra",
  "Amparo",
  "Aparecida",
  "Araçagi",
  "Arara",
  "Araruna",
  "Areia",
  "Areia de Baraúnas",
  "Areial",
  "Aroeiras",
  "Assunção",
  "Baía da Traição",
  "Bananeiras",
  "Baraúna",
  "Barra de Santa Rosa",
  "Barra de Santana",
  "Barra de São Miguel",
  "Bayeux",
  "Belém",
  "Belém do Brejo do Cruz",
  "Bernardino Batista",
  "Boa Ventura",
  "Boa Vista",
  "Bom Jesus",
  "Bom Sucesso",
  "Bonito de Santa Fé",
  "Boqueirão",
  "Borborema",
  "Brejo do Cruz",
  "Brejo dos Santos",
  "Caaporã",
  "Cabaceiras",
  "Cabedelo",
  "Cachoeira dos Índios",
  "Cacimba de Areia",
  "Cacimba de Dentro",
  "Cacimbas",
  "Caiçara",
  "Cajazeiras",
  "Cajazeirinhas",
  "Caldas Brandão",
  "Camalaú",
  "Campina Grande",
  "Campo de Santana",
  "Capim",
  "Caraúbas",
  "Carrapateira",
  "Casserengue",
  "Catingueira",
  "Catolé do Rocha",
  "Caturité",
  "Conceição",
  "Condado",
  "Conde",
  "Congo",
  "Coremas",
  "Coxixola",
  "Cruz do Espírito Santo",
  "Cubati",
  "Cuité",
  "Cuité de Mamanguape",
  "Cuitegi",
  "Curral de Cima",
  "Curral Velho",
  "Damião",
  "Desterro",
  "Diamante",
  "Dona Inês",
  "Duas Estradas",
  "Emas",
  "Esperança",
  "Fagundes",
  "Frei Martinho",
  "Gado Bravo",
  "Guarabira",
  "Gurinhém",
  "Gurjão",
  "Ibiara",
  "Igaracy",
  "Imaculada",
  "Ingá",
  "Itabaiana",
  "Itaporanga",
  "Itapororoca",
  "Itatuba",
  "Jacaraú",
  "Jericó",
  "João Pessoa",
  "Juarez Távora",
  "Juazeirinho",
  "Junco do Seridó",
  "Juripiranga",
  "Juru",
  "Lagoa",
  "Lagoa de Dentro",
  "Lagoa Seca",
  "Lastro",
  "Livramento",
  "Logradouro",
  "Lucena",
  "Mãe d'Água",
  "Malta",
  "Mamanguape",
  "Manaíra",
  "Marcação",
  "Mari",
  "Marizópolis",
  "Massaranduba",
  "Mataraca",
  "Matinhas",
  "Mato Grosso",
  "Maturéia",
  "Mogeiro",
  "Montadas",
  "Monte Horebe",
  "Monteiro",
  "Mulungu",
  "Natuba",
  "Nazarezinho",
  "Nova Floresta",
  "Nova Olinda",
  "Nova Palmeira",
  "Olho d'Água",
  "Olivedos",
  "Ouro Velho",
  "Parari",
  "Passagem",
  "Patos",
  "Paulista",
  "Pedra Branca",
  "Pedra Lavrada",
  "Pedras de Fogo",
  "Pedro Régis",
  "Piancó",
  "Picuí",
  "Pilar",
  "Pilões",
  "Pilõezinhos",
  "Pirpirituba",
  "Pitimbu",
  "Pocinhos",
  "Poço Dantas",
  "Poço de José de Moura",
  "Pombal",
  "Prata",
  "Princesa Isabel",
  "Puxinanã",
  "Queimadas",
  "Quixabá",
  "Remígio",
  "Riachão",
  "Riachão do Bacamarte",
  "Riachão do Poço",
  "Riacho de Santo Antônio",
  "Riacho dos Cavalos",
  "Rio Tinto",
  "Salgadinho",
  "Salgado de São Félix",
  "Santa Cecília",
  "Santa Cruz",
  "Santa Helena",
  "Santa Inês",
  "Santa Luzia",
  "Santa Rita",
  "Santa Teresinha",
  "Santana de Mangueira",
  "Santana dos Garrotes",
  "Santarém",
  "Santo André",
  "São Bentinho",
  "São Bento",
  "São Domingos de Pombal",
  "São Domingos do Cariri",
  "São Francisco",
  "São João do Cariri",
  "São João do Rio do Peixe",
  "São João do Tigre",
  "São José da Lagoa Tapada",
  "São José de Caiana",
  "São José de Espinharas",
  "São José de Piranhas",
  "São José de Princesa",
  "São José do Bonfim",
  "São José do Brejo do Cruz",
  "São José do Sabugi",
  "São José dos Cordeiros",
  "São José dos Ramos",
  "São Mamede",
  "São Miguel de Taipu",
  "São Sebastião de Lagoa de Roça",
  "São Sebastião do Umbuzeiro",
  "Sapé",
  "Seridó",
  "Serra Branca",
  "Serra da Raiz",
  "Serra Grande",
  "Serra Redonda",
  "Serraria",
  "Sertãozinho",
  "Sobrado",
  "Solânea",
  "Soledade",
  "Sossêgo",
  "Sousa",
  "Sumé",
  "Taperoá",
  "Tavares",
  "Teixeira",
  "Tenório",
  "Triunfo",
  "Uiraúna",
  "Umbuzeiro",
  "Várzea",
  "Vieirópolis",
  "Vista Serrana",
  "Zabelê"
]

export const PB = [
  "Paraiba"
]