import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Menu
} from "@mui/material";

import { DataGrid } from '@mui/x-data-grid'
import { theme2, tokens } from "../../theme";
import Header from "../../utils/HeaderMarcacao";
import { useEffect, useState } from 'react';
import { getDatabase, ref, update, push, get, remove } from '@firebase/database';
import { IconButton } from "@mui/material";
import firebaseApp, { KEY, KEY_NAME_USER, KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, url_api_gzappy, BASE_PROD, KEY_LICENSE, KEY_CONFIG } from '../../integration/FirebaseConfig';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { formatarTelefone, getPrioridadeLabel, removeAccents } from "../../services/script";
import DownloadingIcon from '@mui/icons-material/Downloading';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import GetAppIcon from '@mui/icons-material/GetApp';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as dataGridPtBR } from '@mui/x-data-grid';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import * as XLSX from 'xlsx';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { ThemeProvider } from "@emotion/react";
import SettingsIcon from '@mui/icons-material/Settings';
import { useFuncoes } from "../../services/funcoes";

const Marcacoes = () => {
  const database = getDatabase(firebaseApp);
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [changeStatusCause, setChangeStatusCause] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [userData, setUserData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cacheSolicitadosFalta, setCacheSolicitadosFalta] = useState([]);
  const [cacheSolicitadosCompareceu, setCacheSolicitadosCompareceu] = useState([]);
  const [cacheSolicitadosEmProcesso, setCacheSolicitadosEmProcesso] = useState([]);
  const [documentType, setDocumentType] = useState('');
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 999));
  const [additionalColumns, setAdditionalColumns] = useState([]);
  const {
    funcRelatoriosConfirmados,
    funcAtualizarConfirmados, funcFinalizarConfirmados, funcBaixarConfirmados,
    funcConfigTabelaConfirmados
  } = useFuncoes();


  const [columns, setColumns] = useState([
    { field: "protocolo", flex: 0.4, headerName: "Protocolo" },
    {
      field: "nome",
      headerName: "Nome Completo",
      flex: 0.65,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row.nome.toUpperCase(),
    },


    {
      field: 'cor',
      headerName: 'Prioridade',
      flex: 0.2,

      renderCell: (params) => {
        const backgroundColor = colorMap[params.row.cor.toLowerCase()] || 'green';
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                backgroundColor: backgroundColor,
                border: '0px solid #ccc',
                marginRight: 8,
              }}
            />

          </div>
        );
      },
    },

    {
      field: "procedimento",
      headerName: "Procedimento",
      flex: 0.4,

    },

    ,
    {
      field: "categoria",
      headerName: "Categoria",
      flex: 0.4,
      valueGetter: (params) => params.row.categoria.toUpperCase(),
    }
    ,
    {
      field: "turno",
      headerName: "Turno",
      flex: 0.4,
      renderCell: (params) => (
        <div>
          {params.value === 'Manha' ? (<Typography> Manhã</Typography>) : (<Typography> Tarde</Typography>)}
        </div>
      )
    },

    {
      field: 'datefinish',
      headerName: 'Data Marcada',
      flex: 0.4,

    },

    {
      field: "status_paciente",
      headerName: "Status do Atendimento",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
    },

  ]);

  useEffect(() => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        if (col.field === 'status_paciente') {
          return {
            ...col,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>



                <IconButton
                  onClick={() => handleAccessClick(row)}
                  sx={{
                    backgroundColor: colors.tabelas.backTable,
                    border: "1px solid #1D1D1D",
                    borderRadius: '4px',
                    padding: '8px',
                    fontSize: 13,
                    fontWeight: 'bold',
                    marginLeft: 1
                  }}
                >

                  <InfoIcon />
                </IconButton>

                {funcBaixarConfirmados && (<IconButton
                  onClick={() => handleDownloadPDF(row)}
                  sx={{

                    backgroundColor: colors.tabelas.fundoIcon,
                    borderRadius: '4px',
                    border: "1px solid #242424",
                    padding: '8px',
                    marginLeft: '8px',
                  }}
                >
                  <GetAppIcon />

                </IconButton>)}



                <Button
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid #FF4B00",
                    borderRadius: '4px',
                    padding: '8px',
                    marginLeft: '8px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: 'white',
                    marginRight: 1
                  }}
                >
                  {row.status_paciente ? row.status_paciente : 'Aguardando'}
                </Button>

              </Box>
            ),
          };
        }
        return col;
      })
    );
  }, [funcBaixarConfirmados]);



  const additionalColumnDetails = [
    { field: 'bairro', headerName: 'Bairro', flex: 0.5 },
    { field: 'cep', headerName: 'CEP', flex: 0.5 },
    { field: 'cidade', headerName: 'Cidade', flex: 0.5 },
    { field: 'cns', headerName: 'CNS', flex: 0.7 },
    { field: 'cpf', headerName: 'CPF', flex: 0.5 },
    { field: 'codigoSisREG', headerName: 'SISREG', flex: 0.5 },
    { field: 'datetime', headerName: 'Solicitado', flex: 0.5 },
    { field: 'datanascimento', headerName: 'Data de Nascimento', flex: 0.5 },
    { field: 'email', headerName: 'Email', flex: 0.5 },
    { field: 'estado', headerName: 'Estado', flex: 0.5 },
    { field: 'logradouro', headerName: 'Logradouro', flex: 0.5 },
    { field: 'numero', headerName: 'Número', flex: 0.5 },
    { field: 'rg', headerName: 'RG', flex: 0.5 },
    { field: 'telefone', headerName: 'Telefone', flex: 0.5 },
    {
      field: 'turno', headerName: 'Turno Preferência', flex: 0.5, renderCell: (params) => (
        <div>
          {params.value === 'Manhã' ? (<Typography>Manhã</Typography>) : (<Typography>Tarde</Typography>)}
        </div>
      )
    },
  ];

  const additionalColumnOptions = [
    'data de solicitação',
    'Código SISREG',
    'cpf',
    'rg',
    'Cartão do SUS',
    'data de nascimento',
    'telefone',
    'email',
    'logradouro',
    'numero',
    'bairro',
    'cidade',
    'cep',
    'estado',
    'turno',
  ];

  const handleAdditionalColumnChange = (event) => {
    let { value } = event.target;

    if (value === 'Código SISREG') {
      value = 'codigoSisREG';
    } else if (value === 'data de nascimento') {
      value = 'datanascimento';
    } else if (value === 'data de solicitação') {
      value = 'datetime'
    } else if (value === 'Cartão do SUS') {
      value = 'cns'
    }

    setAdditionalColumns((prevAdditionalColumns) => {
      const updatedColumns = prevAdditionalColumns.includes(value)
        ? prevAdditionalColumns.filter(col => col !== value)
        : [...prevAdditionalColumns, value];


      const fixedColumns = columns.filter(col => col.field === 'protocolo' || col.field === 'nome');


      const newAdditionalColumns = additionalColumnDetails.filter(col =>
        updatedColumns.includes(col.field)
      );

      const existingColumns = columns.filter(col => col.field !== 'protocolo' && col.field !== 'nome' && !additionalColumnDetails.find(addCol => addCol.field === col.field));

      setColumns([
        ...fixedColumns,
        ...newAdditionalColumns,
        ...existingColumns
      ]);

      return updatedColumns;
    });
  };

  const handleCheck = (option) => {

    let { value } = option;

    if (value === 'Código SISREG') {
      value = 'codigoSisREG';
    } else if (value === 'data de nascimento') {
      value = 'datanascimento';
    } else if (value === 'data de solicitação') {
      value = 'datetime'
    } else if (value === 'Cartão do SUS') {
      value = 'cns'
    }

    additionalColumns.includes(value)
  };





  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
    switch (event.target.value) {
      case 'compareceu':
        setUserData(cacheSolicitadosCompareceu);
        break;
      case 'faltou':
        setUserData(cacheSolicitadosFalta);
        break;
      default:
        setUserData(cacheSolicitadosEmProcesso);
    }
  };




  useEffect(() => {
    const cachedStartDate = localStorage.getItem('cacheStartDateMarcados');
    const cachedEndDate = localStorage.getItem('cacheEndMarcados');

    if (cachedStartDate && cachedEndDate) {
      setStartDate(new Date(parseInt(cachedStartDate)));
      setEndDate(new Date(parseInt(cachedEndDate)));
    } else {
      setStartDate(new Date().setHours(0, 0, 0, 0));
      setEndDate(new Date().setHours(23, 59, 59, 999));
    }
  }, []);


  const handleStartDateChange = (newDate) => {
    const start = new Date(newDate).setHours(0, 0, 0, 0);
    const newEndDate = new Date(start).setHours(23, 59, 59, 999);
    setStartDate(start);
    setEndDate(newEndDate);
    localStorage.setItem('cacheStartDateMarcados', start.toString());
    localStorage.setItem('cacheEndMarcados', newEndDate.toString());
  };

  const handleEndDateChange = (newDate) => {
    const end = new Date(newDate).setHours(23, 59, 59, 999);
    const maxEndDate = new Date(startDate).setDate(new Date(startDate).getDate() + 8);

    if (end <= maxEndDate) {
      setEndDate(end);
      localStorage.setItem('cacheEndMarcados', end.toString());
    } else {

      alert("A data final não pode ser mais do que 8 dias após a data inicial.");
    }
  };



  const fetchEspecialidades = async () => {
    startSyncAnimation();
    /*     fetchAndCreateIndex()
        return; */
    try {

      const indexRef = ref(database, `${BASE_PROD}/${KEY}/marcados_index`);
      const snapshotIndex = await get(indexRef);

      if (snapshotIndex.exists()) {
        const indexData = snapshotIndex.val();
        const indexSizeBytes = JSON.stringify(indexData).length;
        const indexSizeKB = indexSizeBytes / 1024;
        const indexSizeMB = indexSizeKB / 1024;

        console.log(`Update 'marcados_index': ${indexSizeKB.toFixed(2)} KB (${indexSizeMB.toFixed(2)} MB)`);

        const filteredProtocoloIds = Object.keys(indexData).filter(userId => {
          const user = indexData[userId];
          if (user.datefinish) {

            const [datePart, timePart] = user.datefinish.split(', ');
            const [day, month, year] = datePart.split('/');
            const [hour, minute] = timePart.split(':');
            const userDateTime = new Date(year, month - 1, day, hour, minute);

            return userDateTime >= startDate && userDateTime <= endDate;
          }
          return false;
        });

        if (filteredProtocoloIds.length > 0) {

          const promises = filteredProtocoloIds.map(userId => {
            const solicitadosRef = ref(database, `${BASE_PROD}/${KEY}/solicitados/${userId}`);
            return get(solicitadosRef).then(snapshot => ({
              userId,
              data: snapshot.val(),
              size: JSON.stringify(snapshot.val()).length
            }));
          });

          const snapshots = await Promise.all(promises);

          const totalSizeBytes = snapshots.reduce((acc, snapshot) => acc + snapshot.size, 0);
          const totalSizeKB = totalSizeBytes / 1024;
          const totalSizeMB = totalSizeKB / 1024;

          console.log(`Update 'solicitados': ${totalSizeKB.toFixed(2)} KB (${totalSizeMB.toFixed(2)} MB)`);

          const solicitadosData = {};
          snapshots.forEach(snapshot => {
            if (snapshot.data) {
              solicitadosData[snapshot.userId] = snapshot.data;
            }
          });

          const userList = filteredProtocoloIds.map(userId => {
            return { ...solicitadosData[userId], id: userId };
          });

          let falta;
          let compareceu;
          let emProcesso;


          if (KEY_CONFIG.acessoExterno === true) {
            falta = userList.filter(user => user.status === "Marcada" && user.status_paciente === "faltou" && user.unidade === KEY_CONFIG.nomeAcessoExterno);
            compareceu = userList.filter(user => user.status === "Marcada" && user.status_paciente === "compareceu" && user.unidade === KEY_CONFIG.nomeAcessoExterno);
            emProcesso = userList.filter(user => user.status === "Marcada" && !user.status_paciente && user.unidade === KEY_CONFIG.nomeAcessoExterno);
            console.log('EXIBINDO SOMENTE', KEY_CONFIG.nomeAcessoExterno)
          } else {
            falta = userList.filter(user => user.status === "Marcada" && user.status_paciente === "faltou");
            compareceu = userList.filter(user => user.status === "Marcada" && user.status_paciente === "compareceu");
            emProcesso = userList.filter(user => user.status === "Marcada" && !user.status_paciente);
          }


          if (JSON.stringify(emProcesso) === '[]') {
            if (JSON.stringify(falta) === '[]') {
              if (JSON.stringify(compareceu) === '[]') {
                setUserData([])
              } else {
                setUserData(compareceu);
                setDocumentType("compareceu");
              }
            } else {
              setUserData(falta);
              setDocumentType("faltou");
            }
          } else {
            setUserData(emProcesso);
            setDocumentType("Marcada");
          }

          setCacheSolicitadosFalta(falta);
          setCacheSolicitadosCompareceu(compareceu);
          setCacheSolicitadosEmProcesso(emProcesso);

          setTimeout(() => {
            stopSyncAnimation();
            setLoading(false);

            try {
              localStorage.setItem('cacheSolicitadosEmProcesso', JSON.stringify(emProcesso));
              localStorage.setItem('cacheSolicitadosCompareceu', JSON.stringify(compareceu));
              localStorage.setItem('cacheSolicitadosFalta', JSON.stringify(falta));
              localStorage.setItem('cacheTimestamp', Date.now().toString());

            } catch (error) {
              console.error('Erro ao armazenar dados no localStorage:', error);
            }
          }, 600);
        } else {

          setUserData([]);
          setLoading(false);
          stopSyncAnimation();
        }
      } else {

        setLoading(false);
        stopSyncAnimation();
      }
    } catch (error) {
      console.error("Erro ao obter dados do Firebase:", error);
    }
  };



  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setLoading(true);
    const cachedSolicitados = localStorage.getItem('cacheSolicitadosEmProcesso');
    const cachedCompareceu = localStorage.getItem('cacheSolicitadosCompareceu');
    const cachedFalta = localStorage.getItem('cacheSolicitadosFalta');
    const cachedStartDate = localStorage.getItem('cacheStartDateMarcados');
    const cachedEndDate = localStorage.getItem('cacheEndMarcados');

    if (cachedSolicitados && cachedCompareceu && cachedFalta && cachedStartDate && cachedEndDate) {
      setCacheSolicitadosEmProcesso(JSON.parse(cachedSolicitados));
      setCacheSolicitadosCompareceu(JSON.parse(cachedCompareceu));
      setCacheSolicitadosFalta(JSON.parse(cachedFalta));

      setUserData([
        ...JSON.parse(cachedSolicitados),
      ])


      setLoading(false);
    } else {
      console.log('Nenhum dado em cache encontrado. Buscando dados atualizados...');
      fetchEspecialidades()

    }
  }, []);



  const startSyncAnimation = () => {
    setIsSyncing(true);
  };

  const stopSyncAnimation = () => {
    setIsSyncing(false);
  };


  const handleFilterApply = (newFilters) => {
    const filteredData = userData.filter((user) => {
      return Object.keys(newFilters).every((key) => {
        if (key === 'data') {

          const formattedFilterDate = newFilters[key] ? new Date(newFilters[key]).toISOString().substr(0, 10) : '';
          const formattedUserData = user[key] ? new Date(user[key]).toISOString().substr(0, 10) : '';
          return !formattedFilterDate || formattedUserData.includes(formattedFilterDate);
        }

        const filterValue = key !== 'data' ? removeAccents(newFilters[key].toLowerCase()) : newFilters[key].toLowerCase();
        const userValue = key !== 'data' && user[key] ? removeAccents(user[key].toLowerCase()) : user[key];
        return !newFilters[key] || (typeof user[key] === 'string' && userValue.includes(filterValue));
      });
    });

    if (filteredData.length <= 0) {

      setShowNotification(true);
    }
    setFilteredTableData(filteredData);
  };



  const handleAccessClick = (row) => {
    setSelectedUser(row);
    setDialogOpen(true);
  };


  const handleabrir = () => {
    setOpenModal(true)
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setDialogOpen(false);
    setSelectedStatus("");
  };

  const handleSendMessage = async (userData) => {

    const phoneNumber = userData.telefone;
    let message = `Olá, ${userData.nome}.\n\nPassando para informar que seu protocolo de N° ${userData.protocolo} foi cancelado pela Instituição, por motivos de: *${changeStatusCause}*\n\nPor favor entre em contato para realizar uma nova solicitação.`;


    await fetch(url_api_gzappy, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user_token_id': `${KEY_USER_TOKEN_ID}`
      },
      body: JSON.stringify({
        instance_id: `${KEY_INSTANCE_ID}`,
        instance_token: `${KEY_INSTANCE_TOKEN}`,
        message: [`${message}`],
        phone: [`${phoneNumber}`]
      })
    })

    const messageData = {
      hora: new Date().toLocaleTimeString(),
      data: new Date().toLocaleDateString(),
      enviado: 'Atendente',
      msg: `${message}`,
      atendente: `${KEY_NAME_USER}`

    };


    const chatRef = ref(database, `/${BASE_PROD}/${KEY}/chats/${phoneNumber}/conversas`);
    push(chatRef, messageData)
      .then(() => {
      })
      .catch((error) => {
        console.error('Erro ao enviar mensagem:', error);
      });

  }


  const handleSaveStatus = async () => {
    if (selectedUser && selectedStatus) {
      const userId = selectedUser.protocolo;
      const userRef = ref(database, `${BASE_PROD}/${KEY}/solicitados/${userId}`);
      const userRefDb = ref(database, `${BASE_PROD}/${KEY}/users/${selectedUser.telefone}/solicitacoes/${userId}`);
      const indexRef = ref(database, `${BASE_PROD}/${KEY}/marcados_index/${userId}`);

      const updates = {
        status: selectedStatus,
        causa: changeStatusCause,
        motivo: changeStatusCause
      };



      if (selectedStatus === "Cancelado pela Instituição") {
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + currentDate.getDate()).slice(-2);
        updates.data = formattedDate;
        handleSendMessage(selectedUser);
        setChangeStatusCause("");
        handleCloseDialog();
        setFilteredTableData('');

        try {

          update(userRef, updates);
          update(userRefDb, updates);
          await remove(indexRef);
          navigate(`/confirmados`);
        } catch (error) {
          console.error("Erro ao atualizar status ou remover índice:", error);
        }

      } else if (selectedStatus === "Cancelado pelo usuário") {
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + currentDate.getDate()).slice(-2);
        updates.data = formattedDate;
        handleSendMessage(selectedUser);
        setChangeStatusCause("");
        handleCloseDialog();
        setFilteredTableData('');

        try {

          update(userRef, updates);
          await remove(indexRef);
          navigate(`/confirmados`);
        } catch (error) {
          console.error("Erro ao atualizar status ou remover índice:", error);
        }

      }



    }
  };


  const colorMap = {
    verde: 'green',
    amarelo: 'yellow',
    laranja: 'orange',
    azul: 'blue',
    vermelho: 'red',

  };




  const handleDownloadXLSX = () => {
    setIsDownload(true);
    let xlsxData;

    if (filteredTableData.length > 0) {
      xlsxData = filteredTableData.map(row => {
        const data = {};
        columns.forEach(column => {
          data[column.headerName] = row[column.field];
        });
        return data;
      });
    } else {
      xlsxData = userData.map(row => {
        const data = {};
        columns.forEach(column => {
          data[column.headerName] = row[column.field];
        });
        return data;
      });
    }

    setTimeout(() => {
      const ws = XLSX.utils.json_to_sheet(xlsxData);


      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = ws[XLSX.utils.encode_cell({ r: 0, c: C })];
        if (cell) {
          cell.s = {
            fill: { fgColor: { rgb: "FF4B00" } },
            font: { bold: true, color: { rgb: "FFFFFF" } }
          };
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');

      const xlsxBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([xlsxBuffer], { type: 'application/octet-stream' }), 'RELATORIO_MARCADAS.xlsx');
      setIsDownload(false);
    }, 600);
  };


  const handleDownloadPDF = (selectedUser) => {
    const doc = new jsPDF({
      unit: 'mm',
      format: [210, 297]
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const canvasWidth = 1240;
    const canvasHeight = 1748;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const backgroundImagePath = '/images/status.png';
    const backgroundImage = new Image();
    backgroundImage.src = backgroundImagePath;

    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);


      const backgroundImageDataURL = canvas.toDataURL('image/jpeg', 1);
      doc.addImage(backgroundImageDataURL, 'JPEG', 0, 0, pageWidth, pageHeight);

      const currentDate = new Date();
      const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
      const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
      const LICENSE = KEY_LICENSE.toUpperCase();

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(`Status: Em análise`, 85, 62);

      doc.setFontSize(13);
      doc.setFont("helvetica", "normal");
      doc.text(`Nº ${selectedUser.protocolo} | ${selectedUser.categoria} | ${selectedUser.nome}`, 50, 72);

      if (selectedUser.status === "Marcada") {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`Status: Marcada`, 85, 97);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Data: ${selectedUser.datefinish} | Turno: ${selectedUser.turno} | Unidade: ${selectedUser.unidade}`, 36, 107);
      }

      if (selectedUser.status_paciente != null) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`Status: Finalizado`, 85, 133);

        doc.setFontSize(13);
        doc.setFont("helvetica", "normal");
        doc.text(`O paciente ${selectedUser.status_paciente.toUpperCase()} a solicitação Nº ${selectedUser.protocolo}`, 50, 144);
      }

      doc.setFontSize(11);
      doc.setFont("helvetica", "bold");
      doc.text(`Este documento serve como comprovação oficial do status atual da solicitação em aberto com `, 18, 170);
      doc.text(`o protocolo de número Nº ${selectedUser.protocolo}. Em conformidade com os procedimentos estabelecidos e `, 18, 175);
      doc.text(`como prova de autoria, assinamos abaixo para atestar a veracidade e a integridade das informa-`, 18, 180);
      doc.text(`ções aqui apresentadas.`, 18, 185);


      doc.setDrawColor(0, 0, 0);
      doc.line(35, 240, doc.internal.pageSize.getWidth() - 35, 240);


      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Assinatura do responsável", (pageWidth - doc.getTextWidth("Assinatura do responsável")) / 2, 250);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(`Este documento foi gerado dia ${formattedDate} às ${formattedTime}, certificação: ${LICENSE}`, pageWidth - 30, 285, { align: 'right' });




      doc.save(`protocolo_${selectedUser.protocolo}.pdf`);
    };

    backgroundImage.onerror = (error) => {
      console.error('Erro ao carregar a imagem de fundo:', error);
    };

  };




  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadPDFPro = (selectedUser) => {
    setIsDownload(true);
    const doc = new jsPDF({
      unit: 'mm',
      format: [210, 297]
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const canvasWidth = 1240;
    const canvasHeight = 1748;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const backgroundImagePath = '/images/background.png';
    const backgroundImage = new Image();
    backgroundImage.src = backgroundImagePath;

    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);


      const backgroundImageDataURL = canvas.toDataURL('image/jpeg', 1);
      doc.addImage(backgroundImageDataURL, 'JPEG', 0, 0, pageWidth, pageHeight);


      doc.setFontSize(12);
      doc.text(`Nº ${selectedUser.protocolo}  |  ${selectedUser.categoria}  `, 15, 50);


      const tableStartY = 60;
      const tableWidth = pageWidth - 30;
      const tableHeight = doc.autoTableHtmlToJson(document.querySelector('table')).rows.length * 10 + 30;

      doc.setFillColor(255, 255, 255);
      doc.rect(15, tableStartY - 10, tableWidth, tableHeight, 'F');


      doc.autoTable({
        startY: tableStartY,
        head: [['Campo', 'Dados']],
        body: [
          ['Procedimento ', selectedUser.procedimento || ''],
          ['Turno', selectedUser.turno || ''],
          ['Data e hora', selectedUser.datetime || ''],
          ['Unidade', selectedUser.unidade || ''],
          ['Profissional', selectedUser.profissional || ''],
          ['Status Protocolo', selectedUser.status || ''],
          ['', ''],
          ['Nome', selectedUser.nome || ''],
          ['Data Nasc.', selectedUser.datanascimento || ''],
          ['CPF/CNS', selectedUser.cpf || ''],
          ['Telefone', selectedUser.telefone || ''],
          ['Endereço:', `${selectedUser.logradouro}, ${selectedUser.numero}, ${selectedUser.bairro}, ${selectedUser.cidade} `],
          ['', ''],
          ['Status final', selectedUser.status_paciente || ''],
          ['Finalizado às', selectedUser.datecheck || ''],
        ],
        headStyles: {
          fillColor: [255, 75, 0],
          textColor: [255, 255, 255],
        },
        didDrawCell: (data) => {
          if (data.section === 'head') {
            doc.setTextColor(255, 255, 255);
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            const textWidth = doc.getTextWidth(data.cell.text[0]);
            const textHeight = doc.internal.getLineHeight();
            const x = data.cell.x + (data.cell.width - textWidth) / 2;
            const y = data.cell.y + (data.cell.height / 2) + (textHeight / 2);
            doc.text(data.cell.text[0], x, y);
          }
        },
      });


      const lastY = doc.autoTable.previous.finalY;
      doc.setDrawColor(0, 0, 0);
      doc.line(35, lastY + 55, doc.internal.pageSize.getWidth() - 35, lastY + 55);


      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Assinatura do responsável", (pageWidth - doc.getTextWidth("Assinatura do responsável")) / 2, lastY + 60);

      const currentDate = new Date();
      const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
      const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
      const LICENSE = KEY_LICENSE.toUpperCase();

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(`Este documento foi gerado dia ${formattedDate} às ${formattedTime}, certificação: ${LICENSE}`, pageWidth - 30, lastY + 95, { align: 'right' });


      doc.save(`solicitacao_${selectedUser.protocolo}.pdf`);
      setIsDownload(false);
    };

    backgroundImage.onerror = (error) => {
      console.error('Erro ao carregar a imagem de fundo:', error);
    };

  };

  return (
    <>
      <HelmetProvider><Helmet>
        <title>Painel DHF - Confirmados</title>
      </Helmet></HelmetProvider>


      <Box m="10px">

        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src="/images/dhf-transparente2.gif" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

          </Box>
        </Backdrop>

        <Header title="CONFIRMADOS" onFilterApply={handleFilterApply}></Header>



        <div className="calendar-page">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsPtBR}>

            <Box display="flex" alignItems="center" backgroundColor="#1d1d1d">
              <FormControl variant="filled" fullWidth sx={{ maxWidth: "80%" }}>
                <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Tipo de Marcação</InputLabel>
                <Select
                  value={documentType}
                  onChange={handleDocumentTypeChange}
                  inputProps={{
                    id: 'document-type-select',
                  }}
                  style={{ color: '#d3d3d3' }}
                >
                  <MenuItem value="compareceu">Exibir somente COMPARECIDAS</MenuItem>
                  <MenuItem value="faltou">Exibir somente FALTAS</MenuItem>
                  <MenuItem value="Marcada">Exibir somente EM PROCESSO</MenuItem>
                </Select>
              </FormControl>
              <ThemeProvider theme={theme2}>
                <DatePicker
                  label="Data Início"
                  value={startDate}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} style={{ marginLeft: 30, marginRight: 10 }} />}
                  format="dd/MM/yyyy"
                />


                <DatePicker
                  label="Data Fim"
                  value={endDate}
                  onChange={handleEndDateChange}
                  minDate={startDate}
                  maxDate={new Date(startDate).setDate(new Date(startDate).getDate() + 7)}
                  renderInput={(params) => <TextField {...params} style={{ marginLeft: 0, marginRight: 10 }} />}
                  format="dd/MM/yyyy"
                />
              </ThemeProvider>
              <IconButton onClick={fetchEspecialidades} style={{ color: 'white', fontSize: 15, marginLeft: "-10px", borderRadius: 10 }}>
                {isSyncing ? (
                  <>
                    <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                    Buscando...
                  </>
                ) : (
                  <>
                    <ManageSearchIcon sx={{ fontSize: 25, marginRight: 0.5 }} />
                    Buscar
                  </>

                )}
              </IconButton>

              {funcRelatoriosConfirmados && (
                <IconButton onClick={handleDownloadXLSX} style={{ color: 'white', fontSize: 15, marginLeft: 5, borderRadius: 10 }}>
                  {isDownload ? (
                    <>
                      <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                      Gerando...
                    </>

                  ) : (
                    <>
                      <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                      Baixar
                    </>

                  )}
                </IconButton>

              )}



              {funcConfigTabelaConfirmados && (
                <IconButton
                  onClick={handleMenuOpen}
                  style={{ color: 'white', fontSize: 15, marginLeft: 5, borderRadius: 10 }}>

                  <SettingsIcon sx={{ fontSize: 25 }} />
                </IconButton>

              )}


              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <Box p={2}>


                  <Grid container spacing={2} width={"450px"}>
                    {additionalColumnOptions.map((option, index) => (
                      <Grid item xs={12} sm={6} md={6} key={option}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={index}
                              checked={handleCheck(option)}
                              onChange={handleAdditionalColumnChange}
                              value={option}
                              style={{ color: additionalColumns.includes(option) ? '#ff4b00' : '#ff4b00' }}
                            />
                          }
                          label={option.toUpperCase()}
                          style={{ fontSize: '1rem' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Menu>

              <IconButton onClick={handleabrir} style={{ color: 'white', fontSize: 14, marginLeft: 1, marginRight: 15, borderRadius: 10 }}>

                <>
                  <HelpOutlinedIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                </>


              </IconButton>


            </Box>
          </LocalizationProvider>
        </div>

        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Legenda de Prioridades</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green' }} />
              <span>Verde: Sem Prioridade</span>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'yellow' }} />
              <span>Amarelo: Prioridade (Idoso)</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'orange' }} />
              <span>Laranja: Prioridade (Gestante)</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'blue' }} />
              <span>Azul: Prioridade (Portador de Dificiência)</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red' }} />
              <span>Vermelho: Prioridade (Outras Emergência)</span>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button style={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00", color: 'white', fontWeight: 'bold'
            }} onClick={handleCloseModal} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              width: "80%",
              margin: "auto",
              border: '3px',
              borderRadius: '1%',
              padding: "20px"
            },
          }}
        >



          <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold", color: "white" }}>
            {selectedUser && (!selectedUser.status_paciente ? (<> CONFIRMADO PARA {(selectedUser.categoria).toUpperCase() + ' |  Nº ' + selectedUser.protocolo + ' | ' + (selectedUser.status).toUpperCase()}</>) :
              (<>FINALIZADO PARA {(selectedUser.categoria).toUpperCase() + ' |  Nº ' + selectedUser.protocolo}</>))}

            {funcBaixarConfirmados && (<>
              <IconButton onClick={() => handleDownloadPDFPro(selectedUser)} style={{ color: 'white', fontSize: 15, marginLeft: 5, borderRadius: 10 }}>
                {isDownload ? (
                  <>
                    <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                    BAIXANDO...
                  </>

                ) : (
                  <>
                    <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                    BAIXAR PDF
                  </>

                )}
              </IconButton>
            </>)

            }

          </DialogTitle>

          <DialogContent dividers sx={{ pb: 4 }}>
            {selectedUser && (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ marginRight: '25px' }}>
                    {selectedUser.foto ? (
                      <img src={selectedUser.foto} alt="Foto do usuário" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                    ) : (
                      <img src="/images/perfil.jpg" alt="Imagem padrão" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      Código SISREG:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedUser.codigoSisREG}
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      Nº Protocolo:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedUser.protocolo}
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      N° Cartão do SUS
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedUser.cns}
                    </Typography>
                  </Box>


                  <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Procedimento:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.procedimento || ""}
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Data solicitação:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.datetime}
                    </Typography>
                    <Box>
                      <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                        Data Marcada:
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                        {selectedUser.datefinish}
                      </Typography>
                    </Box>

                  </Box>

                  <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Unidade:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.unidade}
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Profissional:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.profissional}
                    </Typography>
                    <Box>


                      <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                        Turno:
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                        {selectedUser.turno}
                      </Typography>
                    </Box>

                  </Box>
                </Box>


                <Grid container spacing={2} >

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Nome"
                      fullWidth
                      value={selectedUser.nome}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Telefone"
                      fullWidth
                      value={formatarTelefone(selectedUser.telefone)}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CPF"
                      fullWidth
                      value={selectedUser.cpf}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}

                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Data de Nascimento"
                      fullWidth
                      value={selectedUser.datanascimento}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={selectedUser.email}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CEP"
                      fullWidth
                      value={selectedUser.cep || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Cidade"
                      fullWidth
                      value={selectedUser.cidade || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Bairro"
                      fullWidth
                      value={selectedUser.bairro || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Logradouro"
                      fullWidth
                      value={selectedUser.logradouro}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Número"
                      fullWidth
                      value={selectedUser.numero}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>



                  <Grid item xs={12} sm={5}>
                    <TextField
                      label="Prioridade"
                      fullWidth
                      value={getPrioridadeLabel(selectedUser.cor || 'verde')}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Observações"
                      fullWidth
                      value={selectedUser.observacoes || "Não"}
                      multiline
                      rows={3}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',

                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },
                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold' },
                      }}
                    />
                  </Grid>

                </Grid>





                {selectedUser && !selectedUser.status_paciente && (<>
                  <Typography variant="h6" sx={{ mb: 1, color: 'white', fontFamily: "Arial, sans-serif", fontSize: "1rem", fontWeight: 'bold', marginTop: 3 }}>
                    Atualizar status do protocolo:
                  </Typography>

                  <FormControl variant="filled" fullWidth sx={{ mb: 2 }}
                    disabled={!funcAtualizarConfirmados}>
                    <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Status</InputLabel>
                    <Select
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      inputProps={{
                        id: 'document-type-select',
                      }}
                      style={{ color: 'white' }}
                    >
                      <MenuItem value="Cancelado pelo usuário">Cancelado pelo Paciente</MenuItem>
                      <MenuItem value="Cancelado pela Instituição">Cancelado pela Instituição</MenuItem>
                    </Select>
                  </FormControl>




                </>)}



                {selectedStatus && (
                  <>


                    <Typography variant="h6" sx={{ mb: 1, color: 'white', fontFamily: "Arial, sans-serif", fontSize: "1rem", fontWeight: 'bold', marginTop: 1 }}>
                      Motivo do Cancelamento:
                    </Typography>

                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={changeStatusCause}
                      onChange={(e) => setChangeStatusCause(e.target.value)}
                    />
                  </>
                )}


              </Box>
            )}
          </DialogContent>



          <DialogActions>


            <Button onClick={handleCloseDialog} style={{ backgroundColor: "white", fontWeight: "bold" }}>Voltar</Button>
            {selectedUser && (selectedStatus && (selectedStatus !== selectedUser.status && (<><Button onClick={handleSaveStatus} style={{ backgroundColor: "#ff4b00", fontWeight: "bold", marginRight: "10px", color: 'white' }} >Salvar alterações </Button></>)))}

          </DialogActions>
        </Dialog>



        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.tabelas.textWhite
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.tabelas.blackCinza

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.tabelas.backTable
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid
                rows={filteredTableData}
                columns={columns}
                rowHeight={80}

                pagination={true}
                localeText={dataGridPtBR.components.MuiDataGrid.defaultProps.localeText}
              />
            ) : (
              <>
                <DataGrid
                  rows={filteredTableData.length > 0 ? filteredTableData : userData}
                  columns={columns}
                  rowHeight={80}

                  pagination={true}
                  localeText={dataGridPtBR.components.MuiDataGrid.defaultProps.localeText}
                />
              </>


            )}


            {/* <div className="watermark-table">
              <img src="/images/logoc.png" alt="Watermark" className="watermark-img" />
            </div> */}
          </Box>
        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>

    </>
  );
};

export default Marcacoes;
