import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../theme";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { KEY_DATA_LOGIN, KEY, KEY_CATEGORIA, KEY_LICENSE, KEY_MASTER, KEY_NAME_CITY, KEY_VALIDATE, KEY_NAME_USER, UPDATE } from "../integration/FirebaseConfig";
import LogoDevIcon from '@mui/icons-material/LogoDev';
import {
  Box, Typography, IconButton, useTheme, Avatar, Skeleton, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Button, Select, InputLabel, FormControl, ListItem, RadioGroup,
  FormControlLabel,
  Radio, List, ListItemText, ListItemAvatar, ListItemIcon, Collapse,
  ListItemButton
} from "@mui/material";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import '../index.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import WorkIcon from '@mui/icons-material/Work';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuRouter from "./MenuRouter";
import Deslogar from "../authentication/Logout/Deslogar";
import { reloadPage, useLastVersion } from "../services/script";


const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      icon={icon}
      onClick={() => {
        setSelected(title);
        setIsCollapsed(true);
      }}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ onToggle }) => {

  let version = useLastVersion();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [showLicense, setShowLicense] = useState(true);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleExit = () => {
    Deslogar(navigate);
  };

  const handleToggleLicense = () => {
    setShowLicense(prevShowLicense => !prevShowLicense);
  };

  const handleToggleMaster = () => {
    navigate(`/stats/dev`)
  };

  const handleToggle = () => {
    setIsCollapsed(prev => !prev);
  };

  useEffect(() => {
    onToggle(isCollapsed);
  }, [isCollapsed, onToggle]);


  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        zIndex="999"
        height="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: isCollapsed ? "80px" : "250px",
          maxWidth: isCollapsed ? "80px" : "250px",
          transition: "min-width 3s ease, max-width 3s ease",
        }}
      >
        <ProSidebar collapsed={isCollapsed} className="custom-scroll">
          <Menu iconShape="square">
            <MenuItem
              onClick={handleToggle}
              icon={isCollapsed ? <img src="/images/brasao.webp" style={{ maxWidth: 40 }} />
                : undefined}
              style={{
                margin: "10px 0 10px",
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <img
                    src="/images/logo.webp"
                    alt="logo"
                    style={{
                      width: "140px",
                    }}
                  />
                  <IconButton>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>



            <Box sx={{ bgcolor: 'transparent' }}>
              <MenuRouter selected={selected} isCollapseds={isCollapsed} collapseSidebar={setIsCollapsed} />

            </Box>

            {!isCollapsed ? (
              <>
                <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'transparent' }}>



                  <ListItem button onClick={handleClick}>
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: 'white' }}>
                        <DeveloperModeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Sistema" secondary='' sx={{ color: 'white' }} />
                    {open ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />}
                  </ListItem>


                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                      {KEY_MASTER === true ? (
                        <>

                          <ListItem button onClick={handleToggleMaster} >
                            <ListItemAvatar>
                              <Avatar sx={{ backgroundColor: 'white' }}>
                                <LogoDevIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="CONFIGURAÇÕES" secondary={'MASTER'} />
                          </ListItem>


                        </>


                      ) : (<></>)}

                      <ListItem button onClick={handleToggleLicense}>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: 'white' }}>
                            <CodeOffIcon />
                          </Avatar>
                        </ListItemAvatar>


                        {showLicense ? (
                          <>

                            <ListItemText primary={`Licença: ${KEY_VALIDATE}`} secondary={`${KEY_LICENSE.toLocaleUpperCase()}`} />

                            <IconButton>
                              <VisibilityOffIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>

                            <img
                              src="/images/license.webp"
                              alt={`${KEY_LICENSE.toLocaleUpperCase()}`}
                              style={{
                                width: "115px",
                              }}
                            />


                          </>
                        )}
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: 'white' }}>
                            <SupervisedUserCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${KEY_NAME_USER.toLocaleUpperCase()}`} secondary={`${KEY_DATA_LOGIN.toLocaleUpperCase()}`} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: 'white' }}>
                            <WorkIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary='CIDADE DE ACESSO' secondary={`${KEY_NAME_CITY.toLocaleUpperCase()}`} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: 'white' }}>
                            <VerifiedUserIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="TIPO DE ACESSO" secondary={`${KEY_CATEGORIA.toLocaleUpperCase()}`} />
                      </ListItem>

                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: 'white' }}>
                            <DisplaySettingsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        {version === UPDATE ? (<> <ListItemText primary="VERSÃO" secondary={`${UPDATE}`} /></>) : (<IconButton
                          sx={{
                            backgroundColor: "transparent",
                            border: "1px solid #FF4B00",
                            borderRadius: "4px",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                          }}
                          onClick={reloadPage}
                        >
                          <SystemUpdateAltIcon />
                          <Typography variant="button" ml={1}>
                            ATUALIZAÇÃO
                          </Typography>
                        </IconButton>)}



                      </ListItem>



                    </List>

                  </Collapse>

                  <ListItem button onClick={handleExit}>
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: 'white' }}>
                        <ExitToAppIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Deslogar" secondary='' sx={{ color: 'white' }} />

                  </ListItem>
                </List>

              </>
            ) : (
              <>

                <List sx={{ width: '100%', maxWidth: '100%', height: '100%', bgcolor: 'transparent' }}  >

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: 'white' }}>
                        <DeveloperModeIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItem>
                  {open && (<>

                    {KEY_MASTER === true ? (
                      <>

                        <ListItem button onClick={handleToggleMaster} >
                          <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: 'white' }}>
                              <LogoDevIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </ListItem>


                      </>


                    ) : (<></>)}

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'white' }}>
                          <SupervisedUserCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'white' }}>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'white' }}>
                          <VerifiedUserIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'white' }}>
                          <DisplaySettingsIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>



                  </>
                  )}

                  <ListItem button onClick={handleExit}>
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: 'white' }}>
                        <ExitToAppIcon />
                      </Avatar>
                    </ListItemAvatar>

                  </ListItem>
                </List></>
            )}

          </Menu>

        </ProSidebar>


      </Box>

    </>
  );
};

export default Sidebar;
