import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { API_DEV, KEY, auth } from '../../../integration/FirebaseConfig';
import ReactFrappeChart from "react-frappe-charts";
import { tokenCrypt } from "../../../services/script";
import axios from 'axios';

const DiasMaisSolicitados = ({ onUpdate, integridade }) => {

  const [mesAtualData, setMesAtualData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [mesPassadoData, setMesPassadoData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const fetchDataFromFirebase = async () => {

    try {
      const userId = auth.currentUser.uid
      const email = auth.currentUser.email
      const token = await auth.currentUser.getIdToken();
      const result = tokenCrypt(userId, email, token)

      const response = await axios.get(`${API_DEV}/dashboard/protocolos/dias`, {
        headers: {
          Authorization: `Bearer ${result}`
        },
        params: { cidade: KEY }
      });

      if (response.status === 200) {

        const { mesAtualData, mesPassadoData } = response.data;
        setMesAtualData(mesAtualData);
        setMesPassadoData(mesPassadoData);

        localStorage.setItem("cacheDiasMaisSolicitados", JSON.stringify({
          mesAtualData: mesAtualData,
          mesPassadoData: mesPassadoData
        }));
      } else {
        integridade(10)
      }

    } catch (error) {
      integridade(0)
      if (error.response) {
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error);
      }
    }


  }
  useEffect(() => {
    const cachedData = localStorage.getItem("cacheDiasMaisSolicitados");
    if (onUpdate === false) {
      if (cachedData) {
        const cached = JSON.parse(cachedData);
        setMesAtualData(cached.mesAtualData);
        setMesPassadoData(cached.mesPassadoData);
      } else {
        console.log('sem cache, utilize o update');
      }
    } else if (onUpdate === true) {
      fetchDataFromFirebase();
    }
  }, [onUpdate]);

  return (
    <Box display="inline-block" gridColumn={"span 6"} gridRow="span 2" backgroundColor={'#1d1d1d'} borderRadius={5} padding={0} height="300px">
      <Typography
        variant="h5"
        fontWeight="600"
        style={{ textAlign: 'center', marginBottom: '-30px', marginTop: 20 }}
      >
        Comparativo de Dias Mais Solicitados - Mês Atual, Mês Passado e Média Anual
      </Typography>

      <ReactFrappeChart
        type="line"
        colors={['#FF4B00', '#4ED083', '#762E10']}
        axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
        height={300}

        lineOptions={{
          dotSize: 5,
          /*     hideLine: 1,
              hideDots: 1, */
          /*      heatline: 1, */
          regionFill: 20,
          areaFill: 20,
          /* spline: 1, */
        }}
        data={{
          labels: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          datasets: [
            {
              name: "Mês Passado",
              values: mesPassadoData,
              chartType: "line",

            },
            {
              name: "Mês Atual",
              values: mesAtualData,
              chartType: "line",
            },
            // {
            //   name: "Média Anual",
            //   values: mediaAnualData,
            //   chartType: "line",
            // },
          ],
        }}
      />
    </Box>
  );
};

export default DiasMaisSolicitados;
