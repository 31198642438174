import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemText,
  Alert, Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Select,
  MenuItem,
  FormControl, LinearProgress,
  InputLabel, CircularProgress, Skeleton, Slide
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterIcon from "@mui/icons-material/FilterList";
import { KEY, KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID } from "../../integration/UserDate";
import { API_DEV, auth, database, url_api_gzappy } from "../../integration/FirebaseConfig";
import { datetime, tokenCrypt } from "../../services/script";
import axios from 'axios';
import * as XLSX from 'xlsx';
import DownloadingIcon from '@mui/icons-material/Downloading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { ref, push } from "firebase/database";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const Campanha = () => {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [search, setSearch] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSke, setLoadingSke] = useState(true);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [sending, setSending] = useState(false);
  const [sendingAgendado, setSendingAgendado] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sentStatus, setSentStatus] = useState({});
  const [message, setMessage] = useState('');
  const [nome, setNome] = useState('');
  const [isSyncing, setIsSyncing] = useState(false);
  const [selectedCampaignContacts, setSelectedCampaignContacts] = useState([]);
  const [selectedCampaignPessoas, setSelectedCampaignPessoas] = useState([]);
  const [loadingCampaignId, setLoadingCampaignId] = useState(null);
  const [numeros, setNumeros] = useState(0);
  const itemsPerPage = 10;
  const paginatedData = Object.entries(filteredData).slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const [filterValues, setFilterValues] = useState({
    idade: [0, 120],
    zona: '',
    genero: ''
  });



  const itemsPerPageContacts = 10;
  const [currentPageContacts, setCurrentPageContacts] = useState(1);


  // const paginatedCampaignContacts = Object.values(selectedCampaignContacts).slice(
  //   (currentPageContacts - 1) * itemsPerPageContacts,
  //   currentPageContacts * itemsPerPageContacts
  // );

  const cardStyle = {
    backgroundColor: "#282828",
    borderRadius: "10px",
    padding: "15px",
    marginBottom: "15px",
  };

  const formStyle = {
    backgroundColor: "#1d1d1d",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    height: "100%",
  };



  const fetchCampaigns = async () => {
    try {
      setIsSyncing(true)
      const userId = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const token = await auth.currentUser.getIdToken();
      const result = tokenCrypt(userId, email, token);

      const params = {
        cidade: KEY
      };

      const response = await axios.get(`${API_DEV}/campanhas/listar`, {
        headers: {
          Authorization: `Bearer ${result}`,
        },
        params: params,
      });

      if (response.status === 200) {

        if (response.data) {

          const sortedCampaigns = response.data.sort((a, b) => {
            const dateA = new Date(a.timeSend.split(', ')[0].split('/').reverse().join('-') + 'T' + a.timeSend.split(', ')[1]);
            const dateB = new Date(b.timeSend.split(', ')[0].split('/').reverse().join('-') + 'T' + b.timeSend.split(', ')[1]);
            return dateB - dateA;
          });

          setCampaigns(sortedCampaigns);
          setAllCampaigns(sortedCampaigns);
          setLoadingSke(false);
          setIsSyncing(false)
        }
      }

    } catch (error) {
      setIsSyncing(false)
      setCampaigns([]);
      setLoadingSke(false);
      setAllCampaigns([]);
      console.error('Erro ao buscar campanhas:', error);
    }
  };

  const handleFetchCampaignContacts = async (campaignId) => {
    try {
      setLoadingCampaignId(campaignId);
      setSelectedCampaignContacts([]);
      setSelectedCampaignPessoas([]);
      const userId = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const token = await auth.currentUser.getIdToken();
      const result = tokenCrypt(userId, email, token);

      const params = {
        cidade: KEY,
      };

      const response = await axios.get(`${API_DEV}/campanhas/listar/${campaignId}`, {
        headers: {
          Authorization: `Bearer ${result}`,
        },
        params: params,
      });

      if (response.status === 200) {
        setSelectedCampaignContacts(response.data.contatos);
        setFilteredData([])
        setSelectedCampaignPessoas(response.data.quantidadePessoasAtingidas);

      } else {
        setFilteredData([])

        console.error("Erro ao buscar os contatos da campanha:", response);
      }
    } catch (error) {
      console.error('Erro ao buscar os contatos da campanha:', error);
    } finally {

      setLoadingCampaignId(null);
    }
  };



  useEffect(() => {
    fetchCampaigns();

  }, []);

  const handleConfirmClick = () => {
    setIsConfirmed(!isConfirmed);
  };

  const handleSend = () => {

    handleSaveMessage()
  };

  const handleClear = () => {

    setSending(false);
    setSendingAgendado(false)
    setNome('')
    setMessage('')
    fetchCampaigns();
    setFilteredData([])
    setNumeros([])
    setSelectedTime("")
    setSelectedDate(null)
    setFilterValues({
      idade: [0, 120],
      zona: '',
      genero: ''
    })
  };

  const handleTimeChange = (newValue) => {
    const formattedTime = format(newValue, 'HH:mm');
    setSelectedTime(formattedTime);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };

  const handleAgeSliderChange = (event, newValue) => {
    setFilterValues({ ...filterValues, idade: newValue });
  };

  const handleZonaChange = (event) => {
    setFilterValues({ ...filterValues, zona: event.target.value });
  };

  const handleGeneroChange = (event) => {
    setFilterValues({ ...filterValues, genero: event.target.value });
  };

  const handleDownloadXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      Object.entries(filteredData).map(([telefone, pessoa]) => ({
        Nome: pessoa.nome,
        Idade: `${pessoa.idade} anos`,
        "Data de Nascimento": pessoa.datanascimento,
        Gênero: pessoa.genero || "Não informado",
        Zona: pessoa.zona || "Não informado",
        Status: pessoa.status || "Aguardando",

      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Pessoas");
    XLSX.writeFile(workbook, "pessoas_a_ser_alcancadas.xlsx");
  };

  const handleDownloadCampaignContactsXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      Object.values(selectedCampaignContacts).map(contato => ({
        Nome: contato.nome || "Desconhecido",
        Idade: contato.idade ? `${contato.idade} anos` : "Não informado",
        "Data de Nascimento": contato.datanascimento || "Não informado",
        Gênero: contato.genero || "Não informado",
        Zona: contato.zona || "Não informado",
        "Data e Hora": contato.status === "Erro" ? "---" : contato.datetime || "Não informado",
        Status: contato.status || "Desconhecido",
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contatos da Campanha");
    XLSX.writeFile(workbook, "contatos_da_campanha.xlsx");
  };

  const handleFilterApply = async () => {
    setProgress(0);
    setFilteredData([]);
    setNumeros([])
    setSentStatus({})
    setLoading(true);

    const params = {
      idadeInicial: filterValues.idade[0],
      idadeFinal: filterValues.idade[1],
      zonaRural: filterValues.zona === 'rural',
      zonaUrbana: filterValues.zona === 'urbana',
      genero: filterValues.genero,
      cidade: KEY
    };

    try {
      const userId = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const token = await auth.currentUser.getIdToken();

      const result = tokenCrypt(userId, email, token);

      const response = await axios.get(`${API_DEV}/campanhas/filtro`, {
        headers: {
          Authorization: `Bearer ${result}`,
        },
        params: params,
      });


      if (response.status === 200) {
        setFilteredData(response.data.nomes);
        setSelectedCampaignContacts([]);
        setNumeros(response.data.numeros)
        setLoading(false);
        setOpenFilterDialog(false);
        setSending(false)

        setIsConfirmed(false)
      } else {
        setSelectedCampaignContacts([]);
        setFilteredData([]);
        setNumeros(response.data.numeros)
        setLoading(false);
        setOpenFilterDialog(false);
        setSending(false)

        setIsConfirmed(false)
      }


    } catch (error) {
      console.error('Erro ao aplicar filtros:', error);
      setLoading(false);
    }
  };



  const handleSaveMessage = async () => {
    if (message.trim() === '' || numeros.length === 0) {
      alert('Erro: Mensagem vazia ou nenhum contato selecionado');
      return;
    }

    if (nome.trim() === '') {
      alert('Erro: Mensagem sem título');
      return;
    }

    if ((selectedDate && !selectedTime) || (!selectedDate && selectedTime)) {
      alert('Erro: Se você selecionar a data, deve selecionar também o horário e vice-versa.');
      return;
    }

    const foiAgendado = selectedDate && selectedTime;

    if (foiAgendado) {
      const userId = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const token = await auth.currentUser.getIdToken();
      const result = tokenCrypt(userId, email, token);

      const scheduleDate = new Date(`${format(selectedDate, 'yyyy-MM-dd')}T${selectedTime}:00+00:00`);
      scheduleDate.setHours(scheduleDate.getHours() + 3);
      const scheduleUtcDate = scheduleDate.toISOString();


      try {
        setSendingAgendado(true);
        let timeSendFormatted = `${format(selectedDate, 'dd/MM/yyyy')}, ${selectedTime}:00`;
        const requestData = {
          KEY,
          nome,
          message,
          numeros,
          filteredData,
          datetime: timeSendFormatted,
          scheduleUtcDate,
          KEY_USER_TOKEN_ID,
          KEY_INSTANCE_ID,
          KEY_INSTANCE_TOKEN
        };


        const response = await axios.post(`${API_DEV}/campanhas/enviar`, requestData, {
          headers: {
            Authorization: `Bearer ${result}`,
          },
        });

        if (response.status === 200) {
          setSendingAgendado(false);

          setNome('')
          setMessage('')
          fetchCampaigns();
          setNumeros([]);
          setFilteredData([])
          setIsConfirmed(false)
          setFilterValues({
            idade: [0, 120],
            zona: '',
            genero: ''
          })
          // const { scheduledStatus } = response.data;
        }



      } catch (error) {
        console.error('Erro ao enviar a campanha agendada:', error);
      } finally {
        setSendingAgendado(false);
        setTimeout(() => {
          setProgress(0)
        }, 2500);
      }

    } else {

      try {
        setSending(true);
        let progressStep = 100 / numeros.length;
        const contatosResponse = {};
        const currentTime = datetime();

        for (const chatId of numeros) {
          const gzappyData = {
            instance_id: `${KEY_INSTANCE_ID}`,
            instance_token: `${KEY_INSTANCE_TOKEN}`,
            message: `> ⓘ *TRANSMISSÃO*\n\n*${nome}*\n\n${message}`,
            phone: chatId
          };

          const response = await fetch(`${url_api_gzappy}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'user_token_id': `${KEY_USER_TOKEN_ID}`
            },
            body: JSON.stringify(gzappyData)
          });

          const pessoa = filteredData[chatId] || {};
          let statusMessage = '';

          if (response.ok) {
            statusMessage = "Enviado";
          } else {
            switch (response.status) {
              case 400:
                statusMessage = "Erro: Parâmetros inválidos";
                break;
              case 401:
                statusMessage = "Erro: Chave da API ausente";
                break;
              case 403:
                statusMessage = "Erro: Chave da API inválida";
                break;
              case 404:
                statusMessage = "Erro: Número inválido ou ausente";
                break;
              case 429:
                statusMessage = "Erro: Limite de taxa excedido";
                break;
              case 500:
              case 501:
              case 502:
              case 503:
              case 504:
                statusMessage = "Erro: Problema no servidor";
                break;
              default:
                statusMessage = `Erro desconhecido (${response.status})`;
                break;
            }
          }

          setSentStatus(prevState => ({
            ...prevState,
            [chatId]: statusMessage
          }));

          contatosResponse[chatId] = {
            ...pessoa,
            status: statusMessage,
            datetime: datetime()
          };

          setProgress(prevProgress => prevProgress + progressStep);

          setFilteredData(prevData => ({
            ...prevData,
            [chatId]: { ...pessoa, entregue: response.ok ? datetime() : '---' }
          }));

          await sleep(500);
        }

        const campanhaData = {
          nomeCampanha: nome,
          mensagem: message,
          quantidadePessoasAtingidas: numeros.length,
          agendado: false,
          timeSend: currentTime,
          status: "Enviado",
          contatos: contatosResponse
        };

        const db = database;
        const campanhaRef = ref(db, `db/cidades/${KEY}/campanhas`);
        push(campanhaRef, campanhaData)


      } catch (error) {
        console.error('Erro ao enviar a campanha:', error);
      } finally {
        setSending(false);
        setSendingAgendado(false)
        setNome('')
        setMessage('')
        fetchCampaigns();
        setFilteredData([])
        setNumeros([])
        setSelectedTime("")
        setSelectedDate(null)
        setFilterValues({
          idade: [0, 120],
          zona: '',
          genero: ''
        })

        setTimeout(() => {
          setProgress(0)
        }, 2500);
      }
    }
  };


  const handleSearch = () => {
    if (search.trim() === "") {
      setCampaigns(allCampaigns);
    } else {
      const filtered = allCampaigns.filter(campaign =>
        campaign.nomeCampanha.toLowerCase().includes(search.toLowerCase())
      );
      setCampaigns(filtered);
    }
  };








  return (
    <>

      <HelmetProvider><Helmet>
        <title>Painel DHF - Campanhas</title>
      </Helmet></HelmetProvider>

      <Box m="10px" sx={{ minHeight: "80vh" }}>
        <Box
          mb="30px"
          display="flex"
          alignItems="center"
          padding={2}
          sx={{ backgroundColor: "#1d1d1d", borderRadius: "10px" }}
        >
          <img
            src="/images/brasao2.webp"
            style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }}
            alt="logo"
          />
          <Typography
            variant="h2"
            sx={{ color: "#fff", fontWeight: "bold", flexGrow: 1 }}
          >
            CAMPANHAS
          </Typography>

        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ backgroundColor: "#1d1d1d", borderRadius: "10px", padding: "20px" }}>
              <Box display="flex" mb={2}>
                <TextField
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Nome da campanha"
                  InputProps={{
                    sx: {
                      backgroundColor: "#262626",
                      color: "#fff",
                      borderRadius: "10px",
                      '& input': {
                        color: "#fff",
                      },
                    },
                  }}
                  variant="outlined"
                />
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid #FF4B00",
                    borderRadius: "7px",
                    padding: "7px",
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    marginLeft: 2
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon sx={{ color: "#fe4b00" }} />
                  <Typography variant="button" ml={1}>
                    PESQUISAR
                  </Typography>
                </IconButton>

                <IconButton onClick={fetchCampaigns} sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #FF4B00",
                  borderRadius: "7px",
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  marginLeft: 1
                }}>
                  {isSyncing ? (
                    <>
                      <CircularProgress size={24} color="inherit" sx={{ marginRight: 0.5 }} />

                    </>

                  ) : (
                    <>
                      <SyncIcon sx={{ fontSize: 24, marginRight: 0.5 }} />

                    </>

                  )}
                </IconButton>

              </Box>

              <List sx={{ maxHeight: "440px", overflow: "auto", minHeight: "440px", paddingRight: 2 }}>
                {loadingSke ? (

                  Array.from(new Array(5)).map((_, index) => (
                    <Box sx={cardStyle} key={index}>
                      <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 20px', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                        <ListItemText
                          primary={<Skeleton width="80%" />}
                          primaryTypographyProps={{
                            sx: {
                              color: "#fff",
                              fontWeight: 'bold',
                              fontSize: '1.1em',
                            }
                          }}
                          sx={{ flex: 1 }}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, textAlign: 'center' }}>
                          <Typography sx={{ color: "#fff", fontSize: '0.9em', marginRight: '10px' }}>
                            Pessoas atingidas
                          </Typography>
                          <Skeleton variant="rectangular" width={60} height={30} sx={{ borderRadius: 1.5 }} />
                        </Box>

                        {/*    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, textAlign: 'center' }}>
                          <Typography sx={{ color: "#fff", fontSize: '0.9em', marginRight: '10px' }}>
                            Data:
                          </Typography>
                          <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 1.5 }} />
                        </Box> */}

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
                          <Typography sx={{ color: "white", fontSize: '0.9em', marginRight: '10px' }}>
                            Status:
                          </Typography>
                          <Skeleton variant="rectangular" width={120} height={30} sx={{ borderRadius: 1.5 }} />
                        </Box>
                      </ListItem>
                    </Box>
                  ))
                ) : (
                  campaigns.length > 0 ? (
                    campaigns.map((campaign, index) => (
                      <React.Fragment key={index}>
                        <Slide
                          key={index}
                          direction="up"
                          in={true}
                          mountOnEnter
                          unmountOnExit
                          timeout={300}
                        >
                          <Box sx={cardStyle}>
                            <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 20px', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                              <ListItemText
                                primary={
                                  campaign.nomeCampanha.length > 20
                                    ? `${campaign.nomeCampanha.substring(0, 20)}...`
                                    : campaign.nomeCampanha
                                }
                                primaryTypographyProps={{
                                  sx: {
                                    color: "#fff",
                                    fontWeight: 'bold',
                                    fontSize: '1.1em',
                                  }
                                }}
                                sx={{ flex: 1 }}
                              />

                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, textAlign: 'center' }}>
                                <Typography sx={{ color: "#fff", fontSize: '0.9em', marginRight: '10px' }}>
                                  Pessoas atingidas
                                </Typography>

                                {loadingCampaignId === campaign.id ? (
                                  <CircularProgress size={24} sx={{ color: "#fff" }} />
                                ) : (
                                  <Chip
                                    label={campaign.quantidadePessoasAtingidas}
                                    sx={{
                                      backgroundColor: "#FF4B00",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      borderRadius: '5px',
                                      padding: '0 10px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleFetchCampaignContacts(campaign.id)}
                                  />
                                )}
                              </Box>



                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
                                <Typography sx={{ color: "#fff", fontSize: '0.9em', marginRight: '10px' }}>
                                  Status
                                </Typography>
                                <Tooltip
                                  title={campaign.status === "Agendado"
                                    ? `Agendado para: ${campaign.timeSend}`
                                    : `Enviado em: ${campaign.timeSend}`}
                                  arrow
                                >
                                  <Chip
                                    label={campaign.status}
                                    sx={{
                                      backgroundColor: campaign.status === "Agendado" ? "#1D1D1D" : "#FF4B00",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      borderRadius: '5px',
                                      padding: '0 10px',
                                      cursor: 'pointer',
                                      width: "120px"
                                    }}
                                  />
                                </Tooltip>
                              </Box>

                            </ListItem>
                          </Box>
                        </Slide>
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography sx={{ color: "#fff", textAlign: "center" }}>Nenhuma campanha encontrada</Typography>
                  )
                )}
              </List>


            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={formStyle}>
              <Typography variant="h6" sx={{ color: "#fff", marginBottom: "15px" }}>
                Criar Campanha
              </Typography>
              <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item xs={12} md={9}>
                  <TextField
                    disabled={sending}
                    fullWidth
                    label="Nome"
                    variant="outlined"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    sx={{
                      backgroundColor: "#262626",
                      color: "#fff",
                      borderRadius: "10px",
                      '& label': { color: "#fff" },
                      '& input': { color: "#fff" },
                    }}
                  />

                </Grid>
                <Grid item xs={12} md={3} >
                  <Button
                    fullWidth
                    disabled={sending}
                    variant="outlined"
                    onClick={handleFilterDialogOpen}

                    sx={{
                      borderColor: '#fe4b00',
                      color: '#fff',
                      whiteSpace: 'nowrap',
                      padding: 1.8,
                      borderRadius: "7px",
                    }}
                  >
                    <FilterIcon sx={{ marginRight: 1, color: "#fe4b00" }} />
                    Filtrar Público
                  </Button>
                </Grid>
              </Grid>

              <Box display="flex" gap={1} mb={2} flexWrap="wrap">

                <Chip
                  label={`Zona: ${filterValues.zona === 'rural' ? 'Rural' : filterValues.zona === 'urbana' ? 'Urbana' : 'Todas'}`}
                  sx={{ backgroundColor: "#fe4b00", color: "#fff", borderRadius: '8px', fontSize: '15px' }}
                />


                <Chip
                  label={`Idade: ${filterValues.idade[0] === 0 && filterValues.idade[1] === 120 ? 'Todas' : `${filterValues.idade[0]} ~ ${filterValues.idade[1]} anos`}`}
                  sx={{ backgroundColor: "#fe4b00", color: "#fff", borderRadius: '8px', fontSize: '15px' }}
                />


                <Chip
                  label={`Gênero: ${filterValues.genero ? filterValues.genero : 'Todos'}`}
                  sx={{ backgroundColor: "#fe4b00", color: "#fff", borderRadius: '8px', fontSize: '15px' }}
                />
              </Box>




              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    disabled={sending}
                    label="Mensagem"
                    variant="outlined"
                    multiline
                    value={message}
                    rows={4.5}
                    sx={{
                      backgroundColor: "#262626",
                      color: "#fff",
                      borderRadius: "10px",
                      '& label': { color: "#fff" },
                      '& textarea': { color: "#fff" },
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                  />

                </Grid>

                <Grid item xs={12} md={3}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsPtBR}>
                      <TimePicker
                        label="Horário"
                        disabled={sending}
                        value={selectedTime ? new Date(`1970-01-01T${selectedTime}:00`) : null}
                        onChange={handleTimeChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              backgroundColor: "#262626",
                              borderRadius: "10px",
                              '& input': { color: "#fff" },
                              '& label': { color: "#fff" },
                            }}
                          />
                        )}
                        ampm={false}
                      />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsPtBR}>
                      <DatePicker
                        label="Data"
                        disabled={sending}
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              backgroundColor: "#262626",
                              borderRadius: "10px",
                              '& input': { color: "#fff" },
                              '& label': { color: "#fff" },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>




              <Box display="flex" justifyContent="flex-end" mt={2}>
                {filteredData && Object.keys(filteredData).length > 0 && (
                  <>

                    <Button
                      variant="contained"
                      disabled={sending}
                      sx={{
                        backgroundColor: "transparent",
                        border: "1px solid #FF4B00",
                        borderRadius: "4px",
                        padding: "8px",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        marginRight: 1,
                      }}
                      onClick={handleClear}
                    >
                      LIMPAR FILTROS E DADOS
                    </Button>

                  </>
                )}

                {filteredData && Object.keys(filteredData).length > 0 && (
                  <>

                    {isConfirmed ? (<>

                      <Button
                        variant="contained"
                        disabled={sending}
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          marginRight: 1,
                        }}
                        onClick={handleSend}
                      >
                        ENVIAR CAMPANHA
                      </Button>
                    </>) : (<>

                      <Button
                        variant="contained"
                        disabled={true}
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          marginRight: 1,
                        }}
                      >
                        ENVIAR CAMPANHA
                      </Button>

                    </>)}
                  </>
                )}

              </Box>

              {sending && (
                <Box sx={{ backgroundColor: "#1d1d1d", borderRadius: "10px", padding: "20px", width: '100%' }}>
                  <Typography variant="h6" sx={{ color: '#fff', mb: 2, textAlign: 'center' }}>
                    Enviando mensagens...
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ height: 10, borderRadius: 5, backgroundColor: '#444' }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#ff4b00' }}>{`${progress.toFixed(0)}%`}</Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" sx={{ color: '#ff4b00', textAlign: 'center' }}>
                    {`Enviando ${Math.round((progress / 100) * numeros.length)} de ${numeros.length} mensagens...`}
                  </Typography>
                  {progress === 100 ? (<>
                    <Typography variant="subtitle2" sx={{ color: 'white', mt: 2, textAlign: 'center' }}>
                      Mensagens enviada com sucesso!
                    </Typography>
                  </>) : (<>
                    <Typography variant="subtitle2" sx={{ color: 'white', mt: 2, textAlign: 'center' }}>
                      Aguarde enquanto enviamos as mensagens. Por favor, não feche esta janela.
                    </Typography></>)}

                </Box>

              )}

              {sendingAgendado && (
                <Box sx={{ backgroundColor: "#1d1d1d", borderRadius: "10px", padding: "20px", width: '100%' }}>
                  <Typography variant="h6" sx={{ color: '#fff', mb: 2, textAlign: 'center' }}>
                    Realizando o agendamento das mensagens...
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ height: 10, borderRadius: 5, backgroundColor: '#444' }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#ff4b00' }}>{`${progress.toFixed(0)}%`}</Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" sx={{ color: '#ff4b00', textAlign: 'center' }}>
                    {`Agendando ${Math.round((progress / 100) * numeros.length)} de ${numeros.length} mensagens...`}
                  </Typography>
                  {progress === 100 ? (<>
                    <Typography variant="subtitle2" sx={{ color: 'white', mt: 2, textAlign: 'center' }}>
                      Mensagens enviada com sucesso!
                    </Typography>
                  </>) : (<>
                    <Typography variant="subtitle2" sx={{ color: 'white', mt: 2, textAlign: 'center' }}>
                      Aguarde enquanto agendando as mensagens. Por favor, não feche esta janela.
                    </Typography></>)}

                </Box>

              )}
            </Box>
          </Grid>



          <Grid item xs={12} md={12}>
            {selectedCampaignContacts && Object.keys(selectedCampaignContacts).length > 0 && (
              <Slide
                key={0}
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={300}
              >
                <Box sx={{ backgroundColor: "#1d1d1d", borderRadius: "10px", padding: "20px" }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ color: "#fff", mb: 2, fontWeight: 'bold' }}>
                      QUANTIDADE DE PESSOAS: {selectedCampaignPessoas}
                    </Typography>
                    <IconButton onClick={handleDownloadCampaignContactsXLSX} style={{ color: 'white', fontSize: 15, borderRadius: 10, marginBottom: 2 }}>
                      <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                      Baixar lista
                    </IconButton>
                  </Box>
                  <Box sx={{ mt: 1, backgroundColor: "#262626", borderRadius: "10px", padding: "20px" }}>

                    <Box>
                      <table style={{ width: '100%', color: '#fff' }}>
                        <thead>
                          <tr style={{ backgroundColor: '#333', textAlign: 'left' }}>
                            <th style={{ padding: '10px' }}>Nome</th>
                            <th style={{ padding: '10px' }}>Idade</th>
                            <th style={{ padding: '10px' }}>Gênero</th>
                            <th style={{ padding: '10px' }}>Zona</th>
                            <th style={{ padding: '10px' }}>Entregue</th>
                            <th style={{ padding: '10px' }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(selectedCampaignContacts).map((contato, index) => (
                            <tr key={index} style={{ borderBottom: '1px solid #444' }}>
                              <td style={{ padding: '10px' }}>{contato.nome || "Desconhecido"}</td>
                              <td style={{ padding: '10px' }}>{contato.idade ? `${contato.idade} anos` : "Não informado"}</td>
                              <td style={{ padding: '10px' }}>{contato.genero || "Não informado"}</td>
                              <td style={{ padding: '10px' }}>{contato.zona || "Não informado"}</td>
                              <td style={{ padding: '10px' }}>
                                {contato.status === "Enviado" || contato.status === "Enviado API" ? contato.datetime : "---" || "Não informado"}
                              </td>
                              <td style={{ padding: '10px' }}>
                                {contato.status === "Enviado" ? (
                                  <Tooltip title="Mensagem enviada com sucesso" arrow>
                                    <IconButton>
                                      <CheckCircleIcon sx={{ color: 'green' }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : contato.status === "Enviado API" ? (
                                  <Tooltip title='Pode ter sido enviado no horário agendado' arrow>
                                    <IconButton>
                                      <CheckCircleIcon sx={{ color: '#FE4B00' }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : contato.status === "Erro: Número inválido ou ausente" ? (
                                  <Tooltip title={contato.status} arrow>
                                    <IconButton>
                                      <ErrorIcon sx={{ color: 'red' }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : contato.status === "Agendado" ? (
                                  <Tooltip title="Aguardando envio no horário marcado" arrow>
                                    <IconButton>
                                      <ScheduleSendIcon sx={{ color: '#2cf3f9' }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : contato.status || contato === null ? (
                                  <Tooltip title="Error Desconhecido" arrow>
                                    <IconButton>
                                      <ErrorIcon sx={{ color: '#2cf3f9' }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={contato.status} arrow>
                                    <IconButton>
                                      <ErrorOutlineIcon sx={{ color: 'yellow' }} />
                                    </IconButton>
                                  </Tooltip>
                                )}


                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          marginRight: 1,
                        }}
                        onClick={() => setCurrentPageContacts((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPageContacts === 1}
                      >
                        Anterior
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          marginRight: 1,
                        }}
                        onClick={() => setCurrentPageContacts((prev) => prev + 1)}
                        disabled={currentPageContacts * itemsPerPageContacts >= Object.keys(selectedCampaignContacts).length}
                      >
                        Próximo
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Slide>
            )}
          </Grid>


          <Grid item xs={12} md={12}>
            {filteredData && Object.keys(filteredData).length > 0 && (
              <Slide
                key={0}
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={300}
              >
                <Box sx={{ backgroundColor: "#1d1d1d", borderRadius: "10px", padding: "20px" }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" sx={{ color: "#fff", mb: 2, fontWeight: 'bold' }}>
                      QUANTIDADE DE PESSOAS A SER ALCANÇADAS: {Object.keys(filteredData).length}
                    </Typography>
                    <IconButton onClick={handleDownloadXLSX} style={{ color: 'white', fontSize: 15, borderRadius: 10, marginBottom: 2 }}>


                      <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                      Baixar lista



                    </IconButton>
                  </Box>

                  <Box sx={{ mt: 1, backgroundColor: "#262626", borderRadius: "10px", padding: "20px" }}>


                    <Box>
                      <table style={{ width: '100%', color: '#fff' }}>
                        <thead>
                          <tr style={{ backgroundColor: '#333', textAlign: 'left' }}>
                            <th style={{ padding: '10px' }}>Nome</th>
                            <th style={{ padding: '10px' }}>Idade</th>
                            <th style={{ padding: '10px' }}>Gênero</th>
                            <th style={{ padding: '10px' }}>Zona</th>
                            <th style={{ padding: '10px' }}>Entregue</th>
                            <th style={{ padding: '10px' }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData.map(([telefone, pessoa], index) => (
                            <tr key={index} style={{ borderBottom: '1px solid #444' }}>
                              <td style={{ padding: '10px' }}>{pessoa.nome}</td>
                              <td style={{ padding: '10px' }}>{pessoa.idade} anos</td>
                              <td style={{ padding: '10px' }}>{pessoa.genero || "Não informado"}</td>
                              <td style={{ padding: '10px' }}>{pessoa.zona || "Não informado"}</td>
                              <td style={{ padding: '10px' }}>{pessoa.entregue || '---'}</td>
                              <td style={{ padding: '10px' }}>
                                {sending && !sentStatus[telefone] ? (
                                  <Tooltip title="Enviando..." arrow>
                                    <IconButton>
                                      <CircularProgress size={24} sx={{ color: '#FF4B00' }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : sentStatus[telefone] ? (
                                  <Tooltip title={sentStatus[telefone]} arrow>
                                    {sentStatus[telefone].includes('Erro') ? (
                                      <IconButton>
                                        <ErrorIcon sx={{ color: 'red' }} />
                                      </IconButton>
                                    ) : (
                                      <IconButton>
                                        <CheckCircleIcon sx={{ color: 'green' }} />
                                      </IconButton>
                                    )}
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Aguardando envio" arrow>
                                    <IconButton>
                                      <HourglassBottomIcon sx={{ color: '#FF4B00' }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>

                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          marginRight: 1,
                        }}
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          marginRight: 1,
                        }}
                        onClick={() => setCurrentPage((prev) => prev + 1)}
                        disabled={currentPage * itemsPerPage >= Object.keys(filteredData).length}
                      >
                        Próximo
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "4px",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          border: `1px solid ${isConfirmed ? "#FF0000" : "#00FF00"}`,
                          marginLeft: 1,
                        }}
                        onClick={handleConfirmClick}
                      >
                        {isConfirmed ? "CANCELAR" : "CONFIRMAR"}
                      </Button>
                    </Box>
                  </Box>


                </Box>
              </Slide>
            )}

          </Grid>








        </Grid>





        <Dialog open={openFilterDialog} onClose={handleFilterDialogClose} fullWidth>
          <DialogTitle>Filtrar Público</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Idade: {filterValues.idade[0]} anos até {filterValues.idade[1]} anos
            </Typography>
            <Slider
              sx={{
                mt: 2,
                color: '#FF4B00'
              }}
              value={filterValues.idade}
              onChange={handleAgeSliderChange}
              valueLabelDisplay="auto"
              min={0}
              max={120}
            />


            <Typography variant="body1" sx={{ mt: 3 }}>Zona:</Typography>
            <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel style={{ color: 'white' }}>Selecione a zona</InputLabel>
              <Select
                value={filterValues.zona}
                onChange={handleZonaChange}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="rural">Rural</MenuItem>
                <MenuItem value="urbana">Urbana</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1">Gênero:</Typography>
            <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel style={{ color: 'white' }}>Selecione o gênero</InputLabel>
              <Select
                value={filterValues.genero}
                onChange={handleGeneroChange}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="masculino">Masculino</MenuItem>
                <MenuItem value="feminino">Feminino</MenuItem>
                <MenuItem value="outros">Outros</MenuItem>
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleFilterDialogClose} sx={{ color: '#fff' }}>Cancelar</Button>
            <Button onClick={handleFilterApply} sx={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00",
              borderRadius: "4px",
              padding: "8px",
              display: "flex",
              alignItems: "center",
              color: "white",
            }} disabled={loading}>
              {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Aplicar Filtros'}
            </Button>
          </DialogActions>

        </Dialog>
      </Box >

      <Snackbar
        open={progress === 100}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>
          ENVIO FEITO COM SUCESSO!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Campanha;
