import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { KEY, API_DEV, auth } from '../../../integration/FirebaseConfig';
import ProgressCircle from "./ProgressCircle";
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";



const ComponeneteFaltas = ({ onUpdate, integridade }) => {

    const [totalSolicitados, setTotalSolicitados] = useState(0);
    const [totalFaltou, setTotalFaltou] = useState(0);
    const [totalCompareceu, setTotalCompareceu] = useState(0);
    const [totalOutros, setTotalOutros] = useState(0);

    const fetchData = async () => {
        try {
            const userId = auth.currentUser.uid
            const email = auth.currentUser.email
            const token = await auth.currentUser.getIdToken();
            const result = tokenCrypt(userId, email, token)

            const response = await axios.get(`${API_DEV}/dashboard/protocolos/status-atendimento`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { cidade: KEY }
            });

            if (response.status === 200) {
                const { year, totalSolicitados, totalFaltou, totalCompareceu, totalOutros } = response.data;
                integridade(100)
                setTotalSolicitados(totalSolicitados);
                setTotalFaltou(totalFaltou);
                setTotalCompareceu(totalCompareceu);
                setTotalOutros(totalOutros);

                localStorage.setItem('cacheAbsenteismo', JSON.stringify({
                    totalSolicitados: totalSolicitados,
                    totalFaltou: totalFaltou,
                    totalCompareceu: totalCompareceu,
                    totalOutros: totalOutros,
                }));
            } else {
                integridade(10)
            }

        } catch (error) {
            setTotalSolicitados(0)
            setTotalFaltou(0)
            setTotalCompareceu(0)
            setTotalOutros(0)

            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }


    };


    useEffect(() => {

        const cachedData = localStorage.getItem("cacheAbsenteismo");
        if (onUpdate === false) {
            if (cachedData) {

                const cached = JSON.parse(cachedData);
                setTotalSolicitados(cached.totalSolicitados);
                setTotalFaltou(cached.totalFaltou);
                setTotalCompareceu(cached.totalCompareceu);
                setTotalOutros(cached.totalOutros);
                integridade(100)
            } else {
                integridade(0)
            }

        } else if (onUpdate === true) {
            fetchData();

        }

    }, [onUpdate]);







    return (
        <Box gridColumn="span 3" gridRow="span 2" backgroundColor="#1d1d1d" p="20px" borderRadius={5}>
            <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center' }}>
                Status de atendimento
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
                <ProgressCircle value1={totalFaltou} value2={totalCompareceu} value3={totalOutros} color1="#ff4b00" color2="#762e10" color3="#ea916e" size={150} />
                <Box style={{ marginTop: '-90px', textAlign: 'center' }}>
                    <Typography style={{ fontSize: 20 }}>Total </Typography>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{totalSolicitados}</Typography>
                </Box>
                <Box style={{ marginTop: '40px', textAlign: 'center', }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#ff4b00' }} />
                        <span>Absenteísmo: ({totalFaltou})</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#762e10' }} />
                        <span>Atendimentos: ({totalCompareceu})</span>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#762e10' }} />
                        <span>Em processo: ({totalOutros})</span>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ComponeneteFaltas;
