import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { KEY, API_DEV, auth } from '../../../integration/FirebaseConfig';

import ReactFrappeChart from "react-frappe-charts";
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";


const DiasComMaisFalta = ({ onUpdate, integridade }) => {


    const [currentMonth, setCurrentMonthFaltas] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [previousMonth, setPreviousMonthFaltas] = useState([0, 0, 0, 0, 0, 0, 0]);


    const fetchDataFromFirebase = async () => {

        try {
            const userId = auth.currentUser.uid
            const email = auth.currentUser.email
            const token = await auth.currentUser.getIdToken();
            const result = tokenCrypt(userId, email, token)

            const response = await axios.get(`${API_DEV}/dashboard/protocolos/faltas-por-dia`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { cidade: KEY }
            });

            if (response.status === 200) {
                const { currentMonthFaltas, previousMonthFaltas } = response.data;

                setCurrentMonthFaltas(currentMonthFaltas);
                setPreviousMonthFaltas(previousMonthFaltas);
                integridade(100)

                localStorage.setItem("cacheDiasComFaltas", JSON.stringify({
                    currentMonthFaltas: currentMonthFaltas,
                    previousMonthFaltas: previousMonthFaltas,
                }));
            } else {
                integridade(10)
            }

        } catch (error) {
            setCurrentMonthFaltas([0, 0, 0, 0, 0, 0, 0])
            setPreviousMonthFaltas([0, 0, 0, 0, 0, 0, 0])
            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }


    };

    useEffect(() => {
        const cachedData = localStorage.getItem("cacheDiasComFaltas");

        if (onUpdate === false) {
            if (cachedData) {
                const cached = JSON.parse(cachedData);
                setCurrentMonthFaltas(cached.currentMonthFaltas || [0, 0, 0, 0, 0, 0, 0]);
                setPreviousMonthFaltas(cached.previousMonthFaltas || [0, 0, 0, 0, 0, 0, 0]);
                integridade(100)
            } else {
                integridade(0)
            }

        } else if (onUpdate === true) {
            fetchDataFromFirebase();

        }


    }, [onUpdate]);


    return (
        <Box
            display="inline-block"
            backgroundColor={'#1d1d1d'}
            borderRadius={5}
            gridColumn={"span 4"}
            padding={0}
            height="300px"
            gridRow="span 2"

            stacked={1}

            depth={0}

            position="relative"
        >
            <Typography
                variant="h6"
                fontWeight="600"
                style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-30px', marginTop: 20 }}
            >
                Faltas por Dia da Semana - Mês Atual vs. Mês Passado
            </Typography>
            <ReactFrappeChart
                type="bar"
                colors={['#FF4B00', '#4ED083']}
                animate={0}
                axisOptions={{ xAxisMode: 'tick', yAxisMode: 'tick', xIsSeries: 1 }}
                height={300}
                data={{
                    labels: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                    datasets: [
                        {
                            name: "Mês Atual",
                            values: currentMonth,
                            chartType: "bar",
                        },
                        {
                            name: "Mês Passado",
                            values: previousMonth,
                            chartType: 'bar'
                        }
                    ],
                }}
            />
        </Box>
    );
};

export default DiasComMaisFalta;
