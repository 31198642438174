import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
  Grid,
  TextField,
  Tooltip,
  CircularProgress
} from "@mui/material";
import { BASE_PROD, KEY_FUNCTIONS, KEY_LICENSE } from '../../integration/FirebaseConfig';
import { useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import HeaderPacientes from "../../utils/HeaderPacientes";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDatabase, ref, get } from '@firebase/database';
import ChatIcon from '@mui/icons-material/Chat';
import firebaseApp, { KEY } from '../../integration/FirebaseConfig';
import InfoIcon from '@mui/icons-material/Info';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { formatarTelefone, getPrioridadeLabel, removeAccents } from "../../services/script";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SyncIcon from '@mui/icons-material/Sync';
import * as XLSX from 'xlsx';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { saveAs } from 'file-saver';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useFuncoes } from "../../services/funcoes";

const Pacientes = () => {
  const database = getDatabase(firebaseApp);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [documentType, setDocumentType] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const [dialogInfoOpen, setdialogInfoOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const {
    funcEditarPacientes: canEdit,
    funcRelatoriosPacientes: canDownload,
    funcCriarNovasSolicitacoes: canSolicitacoes,
    funcComunicacaoChat,
    funcBaixarPacientes
  } = useFuncoes();


  useEffect(() => {
    const cachedData = localStorage.getItem('cachePacientes');
    if (cachedData) {

      const parsedData = JSON.parse(cachedData);
      setUserData(parsedData);
      setLoading(false);

    } else {
      fetchUsers();
      console.log('Nenhum dado em cache encontrado. Buscando dados atualizados...');
    }
  }, []);



  const handleFilterApply = (newFilters) => {
    const currentYear = new Date().getFullYear();

    const filteredData = userData.filter((user) => {
      return Object.keys(newFilters).every((key) => {
        if (key === 'data') {
          const formattedFilterDate = newFilters[key] ? new Date(newFilters[key]).toISOString().substr(0, 10) : '';
          const formattedUserData = user[key] ? new Date(user[key]).toISOString().substr(0, 10) : '';
          return !formattedFilterDate || formattedUserData.includes(formattedFilterDate);
        }

        if (key === 'idade') {
          const userYear = user['datanascimento'] ? parseInt(user['datanascimento'].split('/')[2]) : null;
          const userAge = userYear ? currentYear - userYear : null;
          return userAge >= newFilters['idade'][0] && userAge <= newFilters['idade'][1];
        }

        const filterValueWithoutAccents = removeAccents(newFilters[key].toLowerCase());
        const userValueWithoutAccents = user[key] ? removeAccents(user[key].toLowerCase()) : '';
        return !newFilters[key] || (typeof user[key] === 'string' && userValueWithoutAccents.includes(filterValueWithoutAccents));
      });
    });

    if (filteredData.length <= 0) {
      setShowNotification(true);
    }

    setFilteredTableData(filteredData);
  };



  const startSyncAnimation = () => {
    setIsSyncing(true);
  };

  const stopSyncAnimation = () => {
    setIsSyncing(false);
  };


  const handleAdd = (user) => {

    navigate(`/pacientes/editar`, { state: { userData: user } });
  };


  const fetchUsers = async () => {
    startSyncAnimation();

    try {
      const usersRef = ref(database, `${BASE_PROD}/${KEY}/users`);
      const dependenteRef = ref(database, `${BASE_PROD}/${KEY}/dependentes`);
      const [usersSnapshot, dependentesSnapshot] = await Promise.all([
        get(usersRef),
        get(dependenteRef),
      ]);

      const usersData = usersSnapshot.val();
      const dependentesData = dependentesSnapshot.val();

      const userList = [];

      if (usersData) {
        Object.keys(usersData).forEach((userId) => {
          const user = { ...usersData[userId], id: userId };
          user.endereco = `${user.logradouro || 'N/A'}, ${user.numero || 'N/A'}, ${user.bairro || 'N/A'}`.trim();
          user.nomecompleto = user.nomecompleto || 'N/A';

          if (user.cadastrado === true) {
            userList.push(user);
          }
        });
      }

      if (dependentesData) {
        Object.keys(dependentesData).forEach((userId) => {
          const dependente = { ...dependentesData[userId], id: userId };
          dependente.endereco = `${dependente.logradouro || 'N/A'}, ${dependente.numero || 'N/A'}, ${dependente.bairro || 'N/A'}`.trim();

          if (dependente.cadastrado === true) {
            userList.push(dependente);
          }
        });
      }

      setUserData(userList);


      setTimeout(() => {
        stopSyncAnimation();
        setLoading(false);

        try {
          const serializedData = JSON.stringify(userList);
          if (serializedData.length < 5000000) {
            localStorage.setItem('cachePacientes', serializedData);
            localStorage.setItem('cacheTimestamp', Date.now().toString());

          } else {
            console.warn('Os dados são muito grandes para serem armazenados no localStorage');
          }
        } catch (error) {
          console.error('Erro ao armazenar dados no localStorage:', error);
        }
      }, 600);
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  };

  const handleDownloadXLSX = () => {
    setIsDownload(true);
    let xlsxData;

    if (filteredTableData.length > 0) {
      xlsxData = filteredTableData.map(row => {
        const data = {};
        columns.forEach(column => {
          data[column.headerName] = row[column.field];
        });
        return data;
      });
    } else {
      xlsxData = userData.map(row => {
        const data = {};
        columns.forEach(column => {
          data[column.headerName] = row[column.field];
        });
        return data;
      });
    }

    setTimeout(() => {
      const ws = XLSX.utils.json_to_sheet(xlsxData);


      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = ws[XLSX.utils.encode_cell({ r: 0, c: C })];
        if (cell) {
          cell.s = {
            fill: { fgColor: { rgb: "FF4B00" } },
            font: { bold: true, color: { rgb: "FFFFFF" } }
          };
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');

      const xlsxBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([xlsxBuffer], { type: 'application/octet-stream' }), 'RELATORIO_PACIENTES.xlsx');
      setIsDownload(false);
    }, 600);
  };


  const handleAccessInfo = (row) => {
    setSelectedUser(row);
    setdialogInfoOpen(true);
  };

  const handleCloseDialogInfo = () => {
    setSelectedUser(null);
    setdialogInfoOpen(false);
  };


  const handleChatClick = (id) => {
    navigate(`/chat/paciente`, { state: { idClick: id } });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };


  const handleSolicitar = (user) => {
    navigate(`/solicitacoes/novo`, { state: { userData: user } });
  };


  const handleDownloadPDF = (selectedUser) => {
    const doc = new jsPDF({
      unit: 'mm',
      format: [210, 297]
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const canvasWidth = 1240;
    const canvasHeight = 1748;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const backgroundImagePath = '/images/background.webp';
    const backgroundImage = new Image();
    backgroundImage.src = backgroundImagePath;

    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);


      const backgroundImageDataURL = canvas.toDataURL('image/jpeg', 1);
      doc.addImage(backgroundImageDataURL, 'JPEG', 0, 0, pageWidth, pageHeight);


      doc.setFontSize(12);
      doc.text(`Ficha Cadastral    |    ${selectedUser.nomecompleto.toUpperCase()}  `, 15, 50);


      const tableStartY = 60;
      const tableWidth = pageWidth - 30;
      const tableHeight = doc.autoTableHtmlToJson(document.querySelector('table')).rows.length * 10 + 30;

      doc.setFillColor(255, 255, 255);
      doc.rect(15, tableStartY - 10, tableWidth, tableHeight, 'F');


      doc.autoTable({
        startY: tableStartY,
        head: [['Campo', 'Dados']],
        body: [
          ['Nome', (selectedUser.nomecompleto).toUpperCase() || ''],
          ['Data de Nascimento', selectedUser.datanascimento || ''],
          ['CNS', selectedUser.cns || ''],
          ['CPF', selectedUser.cpf || ''],
          ['Estado civil', selectedUser.estadoCivil || 'Não informado'],
          ['Genêro', selectedUser.genero || 'Não informado'],
          ['Telefone', formatarTelefone(selectedUser.telefone) || ''],
          ['Email', (selectedUser.email).toUpperCase() || ''],
          ['Logradouro', (selectedUser.logradouro) || ''],
          ['Número', selectedUser.numero || ''],
          ['Bairro', selectedUser.bairro || ''],
          ['Cidade', selectedUser.cidade || ''],
          ['CEP', selectedUser.cep || ''],
        ],
        headStyles: {
          fillColor: [255, 75, 0],
          textColor: [255, 255, 255],
        },
        didDrawCell: (data) => {
          if (data.section === 'head') {
            doc.setTextColor(255, 255, 255);
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            const textWidth = doc.getTextWidth(data.cell.text[0]);
            const textHeight = doc.internal.getLineHeight();
            const x = data.cell.x + (data.cell.width - textWidth) / 2;
            const y = data.cell.y + (data.cell.height / 2) + (textHeight / 2);
            doc.text(data.cell.text[0], x, y);
          }
        },
      });


      const lastY = doc.autoTable.previous.finalY;
      doc.setDrawColor(0, 0, 0);
      doc.line(35, lastY + 75, doc.internal.pageSize.getWidth() - 35, lastY + 75);


      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Assinatura do responsável", (pageWidth - doc.getTextWidth("Assinatura do responsável")) / 2, lastY + 80);

      const currentDate = new Date();
      const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
      const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
      const LICENSE = KEY_LICENSE.toUpperCase();

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(`Este documento foi gerado dia ${formattedDate} às ${formattedTime}, certificação: ${LICENSE}`, pageWidth - 30, lastY + 115, { align: 'right' });


      doc.save(`paciente_${selectedUser.nomecompleto.toLowerCase()}.pdf`);
    };

    backgroundImage.onerror = (error) => {
      console.error('Erro ao carregar a imagem de fundo:', error);
    };

  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.1,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "dependente",
      headerName: "Dependente",
      flex: 0.2,
      filterable: true,
      renderCell: (params) => (
        params.value === true ? <Typography
          style={{ color: '#FF4B00', fontWeight: 'bold' }}
        >
          <CheckCircleOutlineIcon fontSize="large" />
        </Typography> : null
      ),
    },
    {
      field: "nomecompleto",
      headerName: "Nome Completo",
      flex: 0.8,
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value ? params.value.toUpperCase() : '',
    },

    {
      field: "endereco",
      headerName: "Endereço",
      flex: 0.8,
      filterable: true,
    },

    {
      field: "cpf",
      headerName: "CPF",
      flex: 0.4,
      filterable: true,
      valueFormatter: (params) => params.value ? params.value.toLowerCase() : '',
    },

    {
      field: "datetime",
      headerName: "Modificação",
      flex: 0.4,
      filterable: true,
    },
    {
      field: "edit",
      filterable: false,
      headerName: "Ações",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {canEdit && (
            <IconButton
              onClick={() => handleAdd(row)}
              sx={{
                backgroundColor: colors.tabelas.backTable,
                borderRadius: '4px',
                border: "1px solid #1D1D1D",
                padding: '8px',
                marginLeft: 1
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          )}

          <IconButton
            onClick={() => handleAccessInfo(row)}
            sx={{
              backgroundColor: colors.tabelas.backTable,
              border: "1px solid #1D1D1D",
              borderRadius: '4px',
              padding: '8px',
              fontSize: 13,
              fontWeight: 'bold',
              marginLeft: 1
            }}
          >
            <InfoIcon />


          </IconButton>

          {funcBaixarPacientes && (
            <IconButton
              onClick={() => handleDownloadPDF(row)}
              sx={{

                backgroundColor: colors.tabelas.fundoIcon,
                borderRadius: '4px',
                border: "1px solid #242424",
                padding: '8px',
                marginLeft: '8px',
              }}
            >
              <GetAppIcon />

            </IconButton>
          )}



          {funcComunicacaoChat && (<Tooltip
            title={
              row.dependente ?
                (
                  <>
                    Não é possível enviar mensagem para o dependente direto. Mande mensagem para o proprietário do cadastro.
                    <br />
                    <br />
                    CPF: {row.ownerTaxNumber}, Telefone: {row.telefone}
                    <br />
                    <br />
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<FileCopyIcon />}
                      onClick={() => copyToClipboard(row.ownerTaxNumber)}
                    >
                      Copiar CPF
                    </Button>
                  </>
                ) : ''
            }
            placement="top"
            disableHoverListener={!row.dependente}
            arrow
          >
            <span>
              <IconButton
                onClick={() => handleChatClick(row.telefone)}
                disabled={row.dependente === true}
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #FF4B00",
                  borderRadius: '4px',
                  padding: '8px',
                  marginLeft: '8px',
                  fontSize: 12,
                  fontWeight: 'bold',
                  opacity: row.dependente === true ? 0.5 : 1,
                }}
              >
                <ChatIcon style={{ color: colors.tabelas.textWhite, marginRight: 5 }} />
                CHAT
              </IconButton>
            </span>
          </Tooltip>)}


          {canSolicitacoes && (<IconButton
            onClick={() => handleSolicitar(row)}
            disabled={row.dependente === true}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00",
              borderRadius: '4px',
              padding: '8px',
              marginLeft: '8px',
              fontSize: 12,
              fontWeight: 'bold',
              opacity: row.dependente === true ? 0.5 : 1,
            }}
          >
            <AddCircleOutlineIcon style={{ color: colors.tabelas.textWhite, marginRight: 5 }} />
            SOLICITAÇÃO
          </IconButton>)}







        </Box>
      ),
    },
  ];



  return (
    <>


      <HelmetProvider> <Helmet>
        <title>Painel DHF - Pacientes</title>
      </Helmet></HelmetProvider>


      <Box m="10px">

        <HeaderPacientes title="PACIENTES"
          onFilterApply={handleFilterApply}></HeaderPacientes>

        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

          </Box>
        </Backdrop>

        <Dialog
          open={dialogInfoOpen}
          onClose={handleCloseDialogInfo}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              width: "80%",
              margin: "auto",
              border: '3px',
              borderRadius: '1%',
              padding: "20px"
            },
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold", color: "white" }}>
            Detalhes do paciente:
            {selectedUser && selectedUser.nomecompleto ? (
              selectedUser.nomesocial ? (
                ` ${selectedUser.nomecompleto} (${selectedUser.nomesocial})`
              ) : (
                ` ${selectedUser.nomecompleto}`

              )
            ) : (
              ""
            )}
          </DialogTitle>


          <DialogContent dividers sx={{ pb: 4 }}>
            {selectedUser && (
              <Box>


                <Grid container spacing={2} >

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Nome"
                      fullWidth
                      value={selectedUser.nome}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Telefone"
                      fullWidth
                      value={formatarTelefone(selectedUser.telefone)}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CPF"
                      fullWidth
                      value={selectedUser.cpf}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}

                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Data de Nascimento"
                      fullWidth
                      value={selectedUser.datanascimento}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={selectedUser.email}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CEP"
                      fullWidth
                      value={selectedUser.cep || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Cidade"
                      fullWidth
                      value={selectedUser.cidade || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Bairro"
                      fullWidth
                      value={selectedUser.bairro || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Logradouro"
                      fullWidth
                      value={selectedUser.logradouro}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Número"
                      fullWidth
                      value={selectedUser.numero}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>



                  <Grid item xs={12} sm={5}>
                    <TextField
                      label="Prioridade"
                      fullWidth
                      value={getPrioridadeLabel(selectedUser.cor || 'verde')}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>



                </Grid>



              </Box>
            )}
          </DialogContent>



          <DialogActions>

            <Button onClick={handleCloseDialogInfo} sx={{ borderColor: '#FF4B00', color: '#FF4B00' }}>Fechar</Button>
          </DialogActions>
        </Dialog>



        <Box display="flex" alignItems="center" backgroundColor="#1d1d1d" sx={{ paddingRight: 2 }}>
          <FormControl variant="filled" fullWidth>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Tipo de Usuário</InputLabel>
            <Select
              value={documentType}
              onChange={setDocumentType}
              inputProps={{
                id: 'document-type-select',
              }}
              style={{ color: colors.grey[100] }}
            >
              <MenuItem value="">Exibir todos os tipos</MenuItem>
            </Select>
          </FormControl>

          <IconButton onClick={fetchUsers} style={{ color: 'white', fontSize: 15, marginLeft: "10px", borderRadius: 10 }}>
            {isSyncing ? (
              <>
                <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                Atualizando...
              </>

            ) : (
              <>
                <SyncIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                Atualizar
              </>

            )}
          </IconButton>



          {canDownload && (<IconButton onClick={handleDownloadXLSX} style={{ color: 'white', fontSize: 15, marginLeft: "10px", borderRadius: 10 }}>
            {isDownload ? (
              <>
                <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                Gerando...
              </>

            ) : (
              <>
                <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                Baixar
              </>

            )}
          </IconButton>)}

        </Box>

        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `0.2px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.tabelas.textWhite
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.tabelas.blackCinza

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.tabelas.backTable
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid
                rows={filteredTableData}
                columns={columns}
                rowHeight={80}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            ) : (
              <DataGrid
                rows={filteredTableData.length > 0 ? filteredTableData : userData}
                columns={columns}
                rowHeight={80}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            )}
          </Box>

        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Pacientes;