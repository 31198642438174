import React from "react";
import { Box, Typography } from "@mui/material";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Start = () => {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Painel DHF - Dashboards</title>
        </Helmet>
      </HelmetProvider>

      <Box m="5px">
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows={"140px"}
          gap="10px"
          textAlign="center"
          position="relative"
          height="80vh"
        >
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              zIndex: 1,
            }}
            className={'logo'}
          >
            <img
              src="/images/logo.png"
              alt="Logo"
              style={{
                maxWidth: '100%',
                height: 'auto',
                width: '50vw',
                maxHeight: '280px',
                objectFit: 'contain',
              }}
            />
            <Typography
              sx={{
                marginTop: 4,
                color: '#424242',
                fontWeight: 'lighter',
                letterSpacing: 5,
                fontFamily: "Source Sans 3, sans-serif",
                fontSize: '32px'

              }}
            >
              Tecnologia para melhorar vidas, 
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                color: '#424242',
                fontWeight: 'lighter',
                letterSpacing: 5,
                fontFamily: "Source Sans 3, sans-serif",
                fontSize: '32px'

              }}
            >
              informação para transformar!
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Start;
