import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Slide,
  Divider, Snackbar, Alert,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import InfoIcon from '@mui/icons-material/Info';
import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { KEY } from '../../integration/FirebaseConfig';
import { BASE_PROD } from '../../integration/FirebaseConfig';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { datetime, formatarTelefone, getPrioridadeLabel, handleCopyClick } from "../../services/script";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';



const StatusComponent = ({ selectedUser, handleCopyClick }) => {

  const isFaltou = selectedUser.status_paciente === 'faltou';
  const backgroundColor = isFaltou ? '#F8D7DA' : '#D4EDDA';
  const textColor = isFaltou ? '#721C24' : '#155724';
  const statusText = isFaltou ? 'USUÁRIO FALTOU' : 'USUÁRIO COMPARECEU';
  const Icon = isFaltou ? CancelIcon : CheckCircleIcon;

  return (

    <Grid item xs={12} sm={5}>
      {selectedUser.datecheck != null && (<>
        <TextField
          label=""
          fullWidth
          value={`STATUS: ${statusText}`}
          sx={{
            bgcolor: backgroundColor,
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
            },
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            readOnly: true,
            style: { color: textColor, fontWeight: 'bold', textTransform: 'uppercase' },
            endAdornment: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ color: textColor, fontWeight: 'bold', textTransform: 'uppercase' }}
                >

                </Typography>
                <IconButton
                  onClick={() => handleCopyClick(selectedUser.status_paciente || "Não finalizado")}
                  sx={{
                    color: textColor,
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon sx={{ color: textColor, fontSize: '1.2rem' }} />
                </Box>
              </Box>
            ),
          }}
        />


      </>)}

      {selectedUser.datecheck != null && (<>
        <TextField
          label=""
          fullWidth
          value={`FINALIZADO ÀS ${selectedUser.datecheck}`}
          sx={{
            marginTop: 1,
            bgcolor: backgroundColor,
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
            },
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            readOnly: true,
            style: { color: textColor, fontWeight: 'bold', textTransform: 'uppercase' },
            endAdornment: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ color: textColor, fontWeight: 'bold', textTransform: 'uppercase' }}
                >

                </Typography>
                <IconButton
                  onClick={() => handleCopyClick(`FINALIZADO ÀS ${selectedUser.datecheck}`)}
                  sx={{
                    color: textColor,
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon sx={{ color: textColor, fontSize: '1.2rem' }} />
                </Box>
              </Box>
            ),
          }}
        />


      </>)}

    </Grid>
  );
};


const allSpecialties = [
  "",
  "Angiologia",
  "Cardiologia",
  "Cirurgia Geral",
  "Cirurgia Pediátrica",
  "Cirurgia Plástica",
  "Citologia",
  "Clínica Médica",
  "Dermatologia",
  "Eletrocardiografia",
  "Eletroencefalografia",
  "Endocrinologia",
  "Endoscopia",
  "Fisioterapia",
  "Fonoaudiologia",
  "Gastroenterologia",
  "Genética",
  "Geriatria",
  "Ginecologia",
  "Hematologia",
  "Hemodinâmica",
  "Hemoterapia",
  "Homeopatia",
  "Imaginologia",
  "Imunologia",
  "Medicina Nuclear",
  "Nefrologia",
  "Neurocirurgia",
  "Neurologia",
  "Nutrição",
  "Obstetrícia",
  "Odontologia",
  "Oftalmologia",
  "Oncologia",
  "Ortopedia",
  "Otorrinolaringologia",
  "Pediatria",
  "Psicologia",
  "Psicoterapia",
  "Pneumologia",
  "Proctologia",
  "Psiquiatria",
  "Quimioterapia",
  "Radiologia",
  "Radioterapia",
  "Reumatologia",
  "Ressonância Magnética",
  "Tomografia",
  "Traumatologia",
  "Ultra-sonografia",
  "Urologia",
];

const BuscaSolicitacoes = () => {


  const [error, setError] = useState(null);
  const [buscaCPF, setBuscaCPF] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showForm, setShowForm] = useState(false);



  const handleCPFChange = (event) => {
    let { value } = event.target;
    

    const trimmedValue = value.trim();
  

    if (trimmedValue !== '') {
      value = trimmedValue.replace(/\D/g, '');
      setBuscaCPF(value);
    } else {

      setBuscaCPF('');
    }
  };
  
  

  const handleBuscarPaciente = async () => {
    if (!buscaCPF || buscaCPF.trim() === '' || !/^\d+$/.test(buscaCPF)) {
      setError("Protocolo inválido ou não fornecido.");
      setLoading(false);
      return;
    }

    setLoading(true)
    try {
      const database = getDatabase();
      const userRef = ref(database, `${BASE_PROD}/${KEY}/solicitados/${buscaCPF}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const user = snapshot.val();

        setSelectedUser(user);
        setShowForm(true);


      } else {
        setSelectedUser(null);
        setShowForm(false);
        setError("Protocolo não encontrado.");
      }

      setLoading(false)
    } catch (error) {
      console.error("Erro ao buscar paciente:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleBuscarPaciente();
      console.log('Buscando com CPF:', buscaCPF);
    }
  };





  return (
    <>
      <Box p="20px" style={{ marginLeft: '50px', marginRight: '50px', marginTop: '40px' }}>
        <Grid container spacing={2} style={{ justifyContent: "center", alignContent: 'center', alignItems: 'center' }}>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Digite o N° do protocolo"
              fullWidth
              value={buscaCPF}
              onChange={handleCPFChange}
              onKeyPress={handleKeyPress}
              sx={{ bgcolor: '#3338' }}
              InputLabelProps={{
                style: { color: 'white' },
                focused: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            {loading ? (<>


              <Button style={{
                backgroundColor: "transparent",
                border: "1px solid #FF4B00", fontWeight: 'bold',
                color: 'white', alignContent: 'center',
                alignItems: 'center', padding: 10
              }}>
                <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
              </Button></>



            ) : (<>

              <Button onClick={handleBuscarPaciente} style={{
                backgroundColor: "transparent",
                border: "1px solid #FF4B00", fontWeight: 'bold',
                color: 'white', alignContent: 'center',
                alignItems: 'center', padding: 10
              }}>
                <DoneOutlineIcon />
              </Button></>)}

          </Grid>
        </Grid>



        {showForm && selectedUser && (
          <>


            <Box padding={5} bgcolor="#1D1D1D" borderRadius="20px" marginTop={5} >

              <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17 }}>Resultado</Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ marginRight: '25px' }}>
                  {selectedUser.foto ? (
                    <img src={selectedUser.foto} alt="Foto do usuário" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                  ) : (
                    <img src="/images/perfil.jpg" alt="Imagem padrão" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                  )}
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                    Código SISREG:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                    {selectedUser.codigoSisREG}
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                    Nº Protocolo:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                    {selectedUser.protocolo}
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                    N° Cartão do SUS
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                    {selectedUser.cns}
                  </Typography>
                </Box>


                <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                    Procedimento:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                    {selectedUser.procedimento || ""}
                  </Typography>

                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                    Data solicitação:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                    {selectedUser.datetime}
                  </Typography>
                  <Box>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Data Marcada:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.datefinish}
                    </Typography>
                  </Box>

                </Box>

                <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                    Unidade:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                    {selectedUser.unidade}
                  </Typography>

                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                    Profissional:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                    {selectedUser.profissional}
                  </Typography>
                  <Box>


                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Turno:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.turno}
                    </Typography>
                  </Box>

                </Box>
              </Box>


              <Grid container spacing={2} >

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Nome"
                    fullWidth
                    value={selectedUser.nome}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.nome)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Telefone"
                    fullWidth
                    value={formatarTelefone(selectedUser.telefone)}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(formatarTelefone(selectedUser.telefone))}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="CPF"
                    fullWidth
                    value={selectedUser.cpf}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.cpf)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Data de Nascimento"
                    fullWidth
                    value={selectedUser.datanascimento}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.datanascimento)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={selectedUser.email}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.email)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>


                <Grid item xs={12} sm={3}>
                  <TextField
                    label="CEP"
                    fullWidth
                    value={selectedUser.cep || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.cep)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Cidade"
                    fullWidth
                    value={selectedUser.cidade || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.cidade)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>


                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Bairro"
                    fullWidth
                    value={selectedUser.bairro || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.bairro)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Logradouro"
                    fullWidth
                    value={selectedUser.logradouro}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.logradouro)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Número"
                    fullWidth
                    value={selectedUser.numero}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.numero)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>



                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Prioridade"
                    fullWidth
                    value={getPrioridadeLabel(selectedUser.cor || 'verde')}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.cor)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Status"
                    fullWidth
                    value={selectedUser.status}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.status)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={7}>
                  <TextField
                    label="Observações"
                    fullWidth
                    value={selectedUser.observacoes || "Não"}
                    multiline
                    rows={4}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' },
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopyClick(selectedUser.observacoes)}
                          sx={{
                            color: 'white',
                            cursor: 'default',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}

                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>

                <StatusComponent selectedUser={selectedUser} handleCopyClick={handleCopyClick} />


              </Grid>




            </Box>




          </>
        ) 
        }
      </Box >

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BuscaSolicitacoes;
