import React, { useState } from "react";
import { Box, Typography, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton } from "@mui/material";
import { tokens } from "../theme";
import { Link } from "react-router-dom";

import AddHomeIcon from '@mui/icons-material/AddHome';
import { KEY } from "../integration/FirebaseConfig";
import { useFuncoes } from "../services/funcoes";



const HeaderExames = ({ title, subtitle, onFilterClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
      <img src="/images/brasao2.webp" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        mb="5px"
        flexGrow={1}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>

      <Link to={`/exames/novo`}>
        <IconButton variant="contained" style={{ backgroundColor: "#FF4B00", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }} onClick={handleClickOpen}>
          <AddHomeIcon style={{ marginRight: 4 }} /> NOVO EXAME
        </IconButton>
      </Link>







    </Box>
  );
};

export default HeaderExames;
