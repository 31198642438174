import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tooltip, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import firebaseApp, { KEY, BASE_PROD, API_DEV, secretKey } from "../../../integration/FirebaseConfig";
import { getDatabase, ref, onValue, get } from "firebase/database";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { getAuth } from "firebase/auth";
import { tokenCrypt } from "../../../services/script";

const database = getDatabase(firebaseApp);
const auth = getAuth();

const useStyles = makeStyles({
  orangeDesign: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 160,
    height: "auto",
    backgroundColor: "#FF4B00",
    borderRadius: "45% 10% 10% 45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
    padding: "10px",
    boxSizing: "border-box",
  },
  absenteismoText: {
    position: "absolute",
    top: 1,
    left: 25,
    color: "white",
    fontWeight: "bold",
    fontSize: 20,
  },
  percentNumber: {
    position: "absolute",
    top: 50,
    left: 30,
    color: "#d14f4f",
    fontFamily: "Source Sans 3, sans-serif",
    letterSpacing: 3,
    fontSize: 30,
    fontWeight: "bold",
  },
  monthText: {
    position: "absolute",
    top: 95,
    left: 25,
    fontWeight: "lighter",
    fontSize: 14,
  },
});

const CardComponent = ({ onUpdate, integridade }) => {
  const classes = useStyles();
  const orangeRef = useRef(null);
  const [totalSolicitados, setTotalSolicitados] = useState(0);
  const [totalFaltou, setTotalFaltou] = useState(0);
  const [previousMonthFaltou, setPreviousMonthFaltou] = useState(0);

  const fetchData = async () => {
    const userId = auth.currentUser.uid
    const email = auth.currentUser.email
    const token = await auth.currentUser.getIdToken();
    const result = tokenCrypt(userId, email, token)


    try {
      const response = await axios.get(`${API_DEV}/dashboard/metricas`, {
        headers: {
          Authorization: `Bearer ${result}`
        },
        params: { cidade: KEY }
      });

      if (response.status === 200) {
        const { solicitadosMetricas } = response.data;
        integridade(100)
        const totalSolicitados = solicitadosMetricas.TotalSolicitados;
        const totalFaltou = solicitadosMetricas.FaltasMesAtual;
        const previousMonthFaltou = solicitadosMetricas.FaltasMesPassado;

        setTotalSolicitados(totalSolicitados);
        setTotalFaltou(totalFaltou);
        setPreviousMonthFaltou(previousMonthFaltou);

        const cachedData = {
          totalSolicitados: totalSolicitados,
          totalFaltou: totalFaltou,
          previousMonthFaltou: previousMonthFaltou
        };

        localStorage.setItem("cacheCardComponent", JSON.stringify(cachedData));
      } else {
        integridade(10)
      }
    } catch (error) {
      setTotalSolicitados(0)
      setTotalFaltou(0)
      setPreviousMonthFaltou(0)
      integridade(0)
      if (error.response) {
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error);
      }
    }


  };

  useEffect(() => {

    const cachedData = localStorage.getItem("cacheCardComponent");
    if (onUpdate === false) {

      if (cachedData) {

        const cached = JSON.parse(cachedData);
        setTotalSolicitados(cached.totalSolicitados);
        setTotalFaltou(cached.totalFaltou);
        setPreviousMonthFaltou(cached.previousMonthFaltou);
        integridade(100)
      } else {
        integridade(0)
      }
    } else if (onUpdate === true) {
      fetchData();
    }

  }, [onUpdate]);

  useEffect(() => {
    if (orangeRef.current) {
      const boxHeight = orangeRef.current.parentElement.clientHeight;
      orangeRef.current.style.height = `${boxHeight}px`;
    }
  }, []);



  const calculateAbsenteismoRate = () => {
    if (totalSolicitados > 0) {
      const currentAbsenteismoRate =
        (totalFaltou / totalSolicitados) * 100;
      const previousAbsenteismoRate =
        (previousMonthFaltou / totalSolicitados) * 100;
      return currentAbsenteismoRate - previousAbsenteismoRate;
    }
    return 0;
  };

  const absenteismoRate = calculateAbsenteismoRate();

  return (
    <>

      <Box
        gridColumn="span 3"
        gridRow="span 1"
        backgroundColor="#1d1d1d"
        p="10px"
        borderRadius={5}
        position="relative"
      >
        <h2 className={classes.absenteismoText}>Absenteísmo</h2>
        <Tooltip title={`Faltas mês anterior: ${previousMonthFaltou}`}>
          <Typography className={classes.percentNumber} style={{ color: absenteismoRate >= 0 ? "#d14f4f" : "#4ed08c", fontSize: 30 }}>
            {absenteismoRate >= 0 ? (
              <TrendingUpIcon style={{ marginBottom: "-5px" }} />
            ) : (
              <TrendingDownIcon style={{ marginBottom: "-5px" }} />
            )}
            {absenteismoRate.toFixed(2)}%
          </Typography>
        </Tooltip>
        <h2 className={classes.monthText}>Mês Atual</h2>
        <div ref={orangeRef} className={classes.orangeDesign}>
          <h2 style={{ fontWeight: "bold", fontSize: 35, marginBottom: "-15px", marginTop: "-4px" }}>{totalFaltou}</h2>
          <h2 style={{ fontWeight: "bold", fontSize: 12, color: "#ffc0a8" }}>TOTAL DE FALTAS</h2>
        </div>
      </Box>


    </>

  );
};

export default CardComponent;
