import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Snackbar, Alert, FormControl, InputLabel, Box } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, ref, onValue, update, get } from 'firebase/database';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Login.css';
import { useNavigate } from "react-router-dom";
import firebaseApp, { API_IBGE, BASE_PROD, ROTA_USUARIOS } from '../../integration/FirebaseConfig';
import { PB, cidadesPB, reloadPage } from '../../services/script';

function cpfIsValid(cpf) {
  cpf = String(cpf).replace(/\D/g, '');
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  if (remainder !== parseInt(cpf.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  return remainder === parseInt(cpf.charAt(10));
}



const CadastroAll = () => {

  const auth = getAuth(firebaseApp);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cpf, setCpf] = useState('');
  const [uf, setUf] = useState('');
  const [municipio, setMunicipio] = useState('');
  const [unidade, setUnidade] = useState('');
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [units, setUnits] = useState([]);

  const handleLogout = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {

        navigate("/login");

        window.location.reload();
      })
      .catch((error) => {
        console.error("Erro ao fazer logout:", error);
      });
  };

  useEffect(() => {

    const fetchUfs = async () => {
      try {
        const response = await fetch(API_IBGE);
        const data = await response.json();

        setUfs(data);
      } catch (error) {
        setUfs(PB);
        console.error('Erro ao carregar UFs:', error);
      }
    };

    fetchUfs();
  }, []);

  const handleUfChange = async (event) => {
    try {
      const response = await fetch(`${API_IBGE}/${event.target.value}/municipios`);
      const data = await response.json();
      setMunicipios(data);
    } catch (error) {
      setUfs(cidadesPB);
      console.error('Erro ao carregar municípios:', error);
    }
  };

  useEffect(() => {
    const saveUserCity = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const database = getDatabase();
        const cidadeRef = ref(database, `${ROTA_USUARIOS}/${userId}/cidade`);
        try {
          const snapshot = await get(cidadeRef);
          if (snapshot.exists()) {
            const cidade = snapshot.val();

            const unitsRef = ref(database, `${BASE_PROD}/${cidade}/instituicoes`);
            const unitsSnapshot = await get(unitsRef);
            if (unitsSnapshot.exists()) {
              const data = unitsSnapshot.val();
              const instituicoes = Object.keys(data).map((key) => ({
                id: key,
                ...data[key],
              }));
              setUnits(instituicoes);
            } else {
              setUnits([]);
            }
          } else {
            console.log("Cidade não encontrada para este usuário");
          }
        } catch (error) {
          console.error("Erro ao recuperar a cidade do usuário:", error);
        }
      }
    };

    saveUserCity();
  }, [auth.currentUser]);




  const handleSignUp = async (event) => {
    event.preventDefault();

    if (verificaCamposPreenchidos()) {
      if (cpfIsValid(cpf)) {
        const user = auth.currentUser;
        if (user) {
          const userId = user.uid;
          const database = getDatabase();
          let cidade = 0;
          const cidadeRef = ref(database, `usuarios/${userId}/cidade`);

          try {
            const snapshot = await get(cidadeRef);
            if (snapshot.exists()) {
              cidade = snapshot.val();

            } else {
              console.log("Cidade não encontrada para este usuário");
            }
          } catch (error) {
            console.error("Erro ao recuperar a cidade do usuário:", error);
          }



          const userData = {
            nome: nome,
            cpf: cpf,
            datanascimento: dataNascimento,
            estado: uf,
            municipio: municipio,
            acesso: false,
            updateDash: 30
          };

          const userRef = ref(database, `usuarios/${userId}`);
          update(userRef, userData)
            .then(() => {

              navigate(`/${cidade}`);
              reloadPage()
            })
            .catch((error) => {
              console.error('Erro ao salvar dados do usuário no Firebase Database:', error);
            });
        } else {
          console.log('Usuário não autenticado');
        }
      } else {
        setError("Informe um CPF válido!")
      }
    } else {
      console.log('Preencha todos os campos antes de prosseguir');
    }
  };

  const handleCpfChange = (value) => {

    const formattedCpf = value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    setCpf(formattedCpf);
  };



  const verificaCamposPreenchidos = () => {

    if (!nome) {
      setError("Digite seu nome completo!");
    }

    else if (!dataNascimento) {
      setError("Digite sua data de nascimento!");
    }

    else if (!cpf) {
      setError("Digite seu CPF");
    }

    else if (!uf) {
      setError("Digite seu Estado (UF)");
    }

    else if (!municipio) {
      setError("Digite sua cidade!");
    }

    if (dataNascimento) {
      const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

      if (!datePattern.test(dataNascimento)) {
        setError("Digite uma data de nascimento válida no formato DD/MM/AAAA!");
        return
      } else {
        const [day, month, year] = dataNascimento.split('/');
        const inputDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();

        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        if (inputDate.getTime() >= today.getTime()) {
          setError("A data de nascimento não pode ser igual ou superior à data atual!");
          return
        }
      }
    }

    return nome && dataNascimento && cpf && uf && municipio;
  };

  const handleDataNascimentoChange = (value) => {

    const onlyNumbers = value.replace(/\D/g, '');

    if (onlyNumbers.length <= 8) {
      const formattedDate = onlyNumbers.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
      setDataNascimento(formattedDate);
    }
  };



  return (
    <>
      <HelmetProvider>   <Helmet>
        <title>Painel DHF - Cadastro</title>
      </Helmet></HelmetProvider>




      <div className="login-container">
        <div className="logo-container">
          <img src="/images/logo.webp" alt="Logo" width="220" />
        </div>
        <div className="login-form">
          <h1>CADASTRO - PROGRESSO 3/3</h1>
          <form onSubmit={handleSignUp}>
            <div className="input-group">
              <label>Nome completo: </label>
              <input style={{height: 50, fontSize: 15, fontWeight: 500, letterSpacing: 0.5 , fontVariant: 'normal'}}
                type="text"
                placeholder="Digite seu nome"
                value={nome}
                onChange={(e) => {
                  const newName = e.target.value;
                  const onlyLetters = newName.replace(/[^a-zA-Z\s]/g, '');
                  setNome(onlyLetters);
                }}
              />
            </div>

            <div className="input-group">
              <label>Data de Nascimento:</label>
              <input style={{height: 50, fontSize: 15, fontWeight: 500, letterSpacing: 0.5 , fontVariant: 'normal'}}
                type="text"
                placeholder="DD/MM/AAAA"
                value={dataNascimento}
                onChange={(e) => handleDataNascimentoChange(e.target.value)}
                maxLength={10}
              />
            </div>

            <div className="input-group">
              <label>CPF:</label>
              <input style={{height: 50, fontSize: 15, fontWeight: 500, letterSpacing: 0.5 , fontVariant: 'normal'}} type="text" maxLength={11} placeholder="CPF" value={cpf} onChange={(e) => handleCpfChange(e.target.value)} />
            </div>

            <Box sx={{ maxWidth: '350px' }}>
              <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione seu Estado</InputLabel>
                <Select
                  value={uf}

                  onChange={(e) => {
                    setUf(e.target.value);
                    handleUfChange(e);

                  }}>



                  {ufs.map((uf) => (
                    <MenuItem key={uf.id} value={uf.sigla} >
                      {uf.nome}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>


              <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione seu Município</InputLabel>
                <Select
                  value={municipio}

                  onChange={(e) => setMunicipio(e.target.value)}>



                  {municipios.map((municipio) => (
                    <MenuItem key={municipio.id} value={municipio.nome}>
                      {municipio.nome}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>



            <button className="login-button" type="submit" style={{height: 45, fontWeight: 'bold', letterSpacing: 1, fontSize: 14}}>
              CONCLUIR CADASTRO
            </button>

            <button
              style={{
                marginTop: 15,
                backgroundColor: 'transparent',
                border: '1px solid red',
              }}

              onClick={handleLogout}
              className="login-button"
            >
              Deslogar
            </button>


          </form>
        </div>
      </div>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CadastroAll;
