import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  CardMedia
} from "@mui/material";
import firebaseApp, { API_DEV, KEY, KEY_NAME_CITY, KEY_UF, ROTA_USUARIOS, secretKey } from '../../integration/FirebaseConfig.js';
import { getDatabase, ref, update, get } from '@firebase/database';
import ComponeneteFaltas from "./Absenteismo/index";
import ComponeneteTotalConsultas from "./TotalConsultas";
import CardComponent from "./Absenteismo/CardComponente";
import CategoriesBox from "./Categorias/CategoriesBox.jsx";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TotalMarcadas from "./TotalMarcadas/index.jsx";
import SyncIcon from '@mui/icons-material/Sync';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DeveloperBoardRoundedIcon from '@mui/icons-material/DeveloperBoardRounded';
import DiasMaisSolicitados from "./DiasMaisSolicitadoa/index.jsx";
import DiasComMaisFalta from "./DiasComFaltas/index.jsx";
import IdadePacientes from "./IdadePacientes/index.jsx";
import Fake from "./FakeInit/Fake.jsx";
import { tokens } from "../../theme.js";
import { getAuth } from "firebase/auth";
import IdadePacientesFaltantes from "./IdadePacientesFaltantes/index.jsx";
import Avaliacoes from "./Avaliaoes/index.jsx";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UpdateIcon from '@mui/icons-material/Update';
import { datetime } from "../../services/script.jsx";
import CryptoJS from 'crypto-js';
import { useFuncoes } from "../../services/funcoes.jsx";

const database = getDatabase(firebaseApp);
const auth = getAuth();

const Dashboard = () => {
  const [updateDataFlag, setUpdateDataFlag] = useState(false);
  const [integridade, setIntegridade] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateFull, setUpdate] = useState(null);
  const [updateDashValue, setUpdateDashValue] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [updateDate, setUpdateDate] = useState('00/00/0000, 00:00:00');
  const [integridadeValues, setIntegridadeValues] = useState({});
  const [integridadeValue, setIntegridadeValue] = useState(0);

  const {
    funcDashboard,
    funcAdminDashboard
  } = useFuncoes();

  const handleIntegridadeUpdate = (componentName, value) => {
    setIntegridadeValues(prevValues => {

      const newValues = { ...prevValues, [componentName]: value };


      const sum = Object.values(newValues).reduce((acc, val) => acc + val, 0);
      const average = sum / Object.keys(newValues).length;


      setIntegridadeValue(average);


      return newValues;
    });
  };


  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmUpdate = async () => {
    setOpenConfirmDialog(false);
    await handleSyncCloud();
  };


  const handleCancelUpdate = () => {
    setOpenConfirmDialog(false);
  };


  const fetchUpdateDashValue = async () => {
    const user = auth.currentUser;
    if (user) {
      const dbRef = ref(database, `${ROTA_USUARIOS}/${user.uid}/updateDash`);
      const snapshot = await get(dbRef);
      const currentValue = snapshot.val();
      setUpdateDashValue(currentValue);
    }
  };


  const handleSyncCloud = async () => {
    setLoading(true);
    setIsDownload(true);
    setIsSyncing(true);
    const user = auth.currentUser;
    if (user && updateDashValue > 0) {
      const dbRef = ref(database, `${ROTA_USUARIOS}/${user.uid}/updateDash`);
      const dbRefdash = ref(database, `${ROTA_USUARIOS}/${user.uid}/`);
      const snapshot = await get(dbRef);
      const currentValue = snapshot.val();
      let valor = currentValue - 1;

      await update(dbRefdash, { updateDash: valor });

      setUpdate(true);
      localStorage.removeItem('cacheTotalConsultas');
      localStorage.removeItem('cacheCardComponent');
      localStorage.removeItem('cacheAvaliacoes');
      localStorage.removeItem('cacheDiasMaisSolicitados');
      localStorage.removeItem('cacheCategories');
      localStorage.removeItem('cacheAbsenteismo');
      localStorage.removeItem('cacheDiasComFaltas');
      localStorage.removeItem('cacheIdades');
      localStorage.removeItem('cacheTotalMarcadas');
      localStorage.removeItem('cacheIdadesFaltantes');
      localStorage.setItem('cacheUpdateDate', datetime());
      setUpdateDate(datetime());


      setTimeout(() => {
        setIsSyncing(false);
        setIsGenerating(true);

        setTimeout(() => {
          setUpdate(null);
          setIsDownload(false);
          setLoading(false);
          setIsSyncing(false);

          setIsGenerating(false);
          setUpdateDashValue(valor);
        }, 1500);
      }, 1000);
    } else {
      alert("A atualização não é permitida. updateDash é 0 ou menor.");
    }
  };

  const handleDocumentTypeChange = (event) => {

    setDocumentType(event.target.value);

    if (event.target.value === '2024') {
      setIsSyncing(true);
    } else {
      setDocumentType('');
      setIsSyncing(true);
    }

    setTimeout(() => {
      setIsSyncing(false);

    }, 1500);

  };


  const updateData = async () => {
    setLoading(true);
    setIsSyncing(true);
    setUpdate(false);
    setIntegridade(false)


    const cachedDate = localStorage.getItem('cacheUpdateDate');
    if (!cachedDate) {
      setTimeout(() => {
        setIntegridade(true)
        setLoading(false);
        setIsGenerating(false);
        setUpdateDataFlag(true);
        setIsSyncing(false);
        fetchUpdateDashValue()
      }, 800);
      return;
    }
    setUpdateDate(cachedDate || '00/00/0000, 00:00:00');
    fetchUpdateDashValue()

    setTimeout(() => {
      setIsSyncing(false);
      setIsGenerating(true);

      setUpdateDataFlag(true);

      setTimeout(() => {
        setIntegridade(true)
        setLoading(false);
        setIsGenerating(false);
        setUpdateDataFlag(true);

      }, 400);
    }, 300);

  };

  /*   useEffect(() => {
      const cachedAnalise = localStorage.getItem('cacheUpdateDate');
  
      if (cachedAnalise) {
        setLoading(true);
        setIsGenerating(true);
        setUpdate(false);
        setUpdateDataFlag(true);
        fetchUpdateDashValue()
        setUpdateDate(cachedAnalise || '00/00/0000, 00:00:00');
  
        setTimeout(() => {
          setLoading(false);
          setIntegridade(true)
          setLoading(false);
          setIsGenerating(false);
          fetchUpdateDashValue()
        }, 1000);
  
      }
  
    }, []); */

  const getOpacity = (componentName) => {
    return integridadeValues[componentName] < 100 ? 0.5 : 1;
  };

  return (
    <>
      <HelmetProvider>   <Helmet>
        <title>Painel DHF - Dashboards</title>
      </Helmet></HelmetProvider>


      <Box m="10px">
        <Box mb="20px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3}>
          <img src="/images/brasao2.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          <Typography
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
            marginRight={10}
            fontSize={19}
          >
            DASHBOARDS
          </Typography>

          <Button disabled style={{ color: 'white', fontSize: 15, marginRight: 1 }}>

            <>
              {integridade ? (<>
                <DeveloperBoardRoundedIcon />
                <Typography
                  fontSize={14}
                  color={colors.grey[100]}
                  fontWeight="bold"
                  marginLeft={'6px'}

                >
                  INTEGRIDADE:
                </Typography>
                <Typography sx={{ color: 'green', fontWeight: 'bold', marginLeft: '3px', fontSize: 16 }}>
                  {integridadeValue.toFixed(2)}%
                </Typography>
              </>) : (<>
                <DeveloperBoardRoundedIcon />
                <Typography
                  fontSize={14}
                  color={colors.grey[100]}
                  fontWeight="bold"
                  marginLeft={'6px'}

                >
                  INTEGRIDADE:
                </Typography>
                <Typography sx={{ color: 'red', fontWeight: 'bold', marginLeft: '4px', fontSize: 15 }}> 0.00%</Typography>

              </>)}

            </>

          </Button>



          <Button disabled style={{ color: 'white', fontSize: 15, opacity: 0.5 }}>

            <>
              <DownloadingIcon />
              <Typography
                fontSize={14}
                color={colors.grey[100]}
                fontWeight="bold"
                marginLeft={'6px'}

              >
                BAIXAR INFORMAÇÕES
              </Typography>
            </>

          </Button>

          <Button onClick={updateData} style={{ color: 'white', fontSize: 15 }}>
            {isSyncing ? (
              <>
                <CircularProgress size={15} color="inherit" />
                <Typography
                  fontSize={14}
                  color={colors.grey[100]}
                  fontWeight="bold"
                  marginLeft={'6px'}
                >
                  BUSCANDO...
                </Typography>
              </>
            ) : isGenerating ? (
              <>
                <CircularProgress size={15} color="inherit" />
                <Typography
                  fontSize={14}
                  color={colors.grey[100]}
                  fontWeight="bold"
                  marginLeft={'6px'}
                >
                  GERANDO GRAFICOS...
                </Typography>
              </>
            ) : (
              <>
                <SyncIcon sx={{ transition: 'transform 0.5s', transform: isSyncing ? 'rotate(360deg)' : 'none' }} />
                <Typography
                  fontSize={14}
                  color={colors.grey[100]}
                  fontWeight="bold"
                  marginLeft={'4px'}
                >
                  GERAR INFORMAÇÕES
                </Typography>
              </>
            )}
          </Button>




          <Button
            style={{ color: 'white', fontSize: 15, opacity: 1 }}
            disabled
          >
            <>


              <UpdateIcon sx={{ transition: 'transform 0.5s', transform: isDownload ? 'rotate(360deg)' : 'none' }} />
              <Typography
                fontSize={14}
                color={colors.grey[100]}
                fontWeight="bold"
                marginLeft={'4px'}
              >
                {updateDate}
              </Typography>
            </>

          </Button>

          {funcAdminDashboard && (<>
            <Button
              onClick={handleConfirmDialogOpen}
              style={{ color: 'white', fontSize: 15, opacity: updateDashValue > 0 ? 1 : 0.5 }}
              disabled={updateDashValue <= 0}
            >
              {isDownload ? (
                <>
                  <CircularProgress size={15} color="inherit" />
                  <Typography
                    fontSize={14}
                    color={colors.grey[100]}
                    fontWeight="bold"
                    marginLeft={'4px'}
                  >
                    ATUALIZANDO DADOS...
                  </Typography>
                </>
              ) : (
                <>
                  <CloudDownloadIcon sx={{ transition: 'transform 0.5s', transform: isDownload ? 'rotate(360deg)' : 'none' }} />
                  <Typography
                    fontSize={14}
                    color={colors.grey[100]}
                    fontWeight="bold"
                    marginLeft={'4px'}
                  >
                    UPDATE ({updateDashValue || 0})
                  </Typography>
                </>
              )}
            </Button>
          </>)}




          <FormControl variant="filled" sx={{ width: 160, marginLeft: 5 }}>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}> DADOS DE EXIBIÇÃO </InputLabel>
            <Select
              value={documentType}
              onChange={handleDocumentTypeChange}
              inputProps={{
                id: 'document-type-select',
              }}
              style={{ color: colors.grey[100] }}
            >
              <MenuItem value="2024">2024</MenuItem>

            </Select>
          </FormControl>
        </Box>

        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows={"140px"}
          gap="20px"
          textAlign="center"
          position="relative"
          height="80vh"
        >
          {updateDataFlag ? (
            <>
              <CardComponent onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('CardComponent', value)} />
              <ComponeneteTotalConsultas onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('ComponeneteTotalConsultas', value)} />
              <Avaliacoes onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('Avaliacoes', value)} />
              <ComponeneteFaltas onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('ComponeneteFaltas', value)} />
              <CategoriesBox onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('CategoriesBox', value)} />
              <DiasMaisSolicitados onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('DiasMaisSolicitados', value)} />
              <DiasComMaisFalta onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('DiasComMaisFalta', value)} />
              <TotalMarcadas onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('TotalMarcadas', value)} />
              <IdadePacientes onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('IdadePacientes', value)} />
              <IdadePacientesFaltantes onUpdate={updateFull} integridade={(value) => handleIntegridadeUpdate('IdadePacientesFaltantes', value)} />



            </>
          ) : (
            <>
              <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1 }} className={'logo'}>
                <img
                  src="/images/logo.png"
                  alt="Logo"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    width: '50vw'
                   
                  }}
                />
                <Typography
                  style={{
                    marginTop: 40,
                    color: '#424242',
                    fontWeight: 'lighter',
                    letterSpacing: KEY_NAME_CITY.length >= 13 ? 5 : 10,
                    fontSize: KEY_NAME_CITY.length >= 13 ? 14 : 30,
                    fontFamily: "Source Sans 3, sans-serif",
                  }}
                >
                  DASHBOARDS {(KEY_NAME_CITY).toUpperCase()} - {KEY_UF}
                </Typography>
              </Box>
            </>

          )}
        </Box>
        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />
          </Box>
        </Backdrop>
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        PaperProps={{
          style: { borderRadius: 12 }
        }}
      >
        <DialogTitle id="confirm-dialog-title">
          <Typography color="textPrimary" fontWeight="bold">
            Confirmação Necessária
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={2}>
            <img
              src="/images/cloud2.webp"
              alt="Download na Nuvem"
              style={{ width: 130, height: 'auto' }}
            />
          </Box>
          <Typography variant="body1" color="textSecondary" sx={{ fontSize: 15 }}>
            Você deseja atualizar os dados do Dashboard com a nuvem? Seu plano inclui apenas 30 atualizações mensais. Atualmente você tem {updateDashValue} atualizações disponíveis.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelUpdate}
            style={{ backgroundColor: '#f44336', color: '#fff', fontWeight: 'bold' }}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmUpdate}
            style={{ backgroundColor: '#4caf50', color: '#fff', fontWeight: 'bold' }}
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>



    </>
  );
};

export default Dashboard;
