import { useEffect, useState } from 'react';
import { KEY_FUNCTIONS } from '../integration/FirebaseConfig';

export function useFuncoes() {
  const [funcAvaliacoes, setFuncAvaliacoes] = useState(false);
  const [funcDashboard, setFuncDashboard] = useState(false);
  const [funcCalendarioVisualizar, setFuncCalendarioVisualizar] = useState(false);
  const [funcCalendarioAtualizar, setFuncCalendarioAtualizar] = useState(false);

  const [funcCargos, setFuncCargos] = useState(false);
  const [funcUsuarios, setFuncUsuarios] = useState(false);
  const [funcEditarUsuarios, setFuncEditarUsuarios] = useState(false);
  const [funcAdminDashboard, setFuncAdminDashboard] = useState(false);
  const [funcMarcacao, setFuncMarcacao] = useState(false);
  const [funcComunicacaoChat, setFuncComunicacaoChat] = useState(false);
  const [funcComunicacaoTickets, setFuncComunicacaoTickets] = useState(false);
  const [funcComunicacaoTransmissao, setFuncComunicacaoTransmissao] = useState(false);
  const [funcComunicacaoCampanhas, setFuncComunicacaoCampanhas] = useState(false);
  const [funcAtualizarConfirmados, setFuncAtualizarConfirmados] = useState(false);
  const [funcFinalizarConfirmados, setFuncFinalizarConfirmados] = useState(false);
  const [funcEditarPacientes, setFuncEditarPacientes] = useState(false);
  const [funcEditarInstituicoes, setFuncEditarInstituicoes] = useState(false);
  const [funcEditarProfissionais, setFuncEditarProfissionais] = useState(false);
  const [funcVisualizarPacientes, setFuncVisualizarPacientes] = useState(false);
  const [funcVisualizarSolicitacoes, setFuncVisualizarSolicitacoes] = useState(false);
  const [funcVisualizarConfirmados, setFuncVisualizarConfirmados] = useState(false);
  const [funcVisualizarInstituicoes, setFuncVisualizarInstituicoes] = useState(false);
  const [funcVisualizarProfissionais, setFuncVisualizarProfissionais] = useState(false);
  const [funcExcluirInstituicoes, setFuncExcluirInstituicoes] = useState(false);
  const [funcExcluirProfissionais, setFuncExcluirProfissionais] = useState(false);
  const [funcRelatoriosPacientes, setFuncRelatoriosPacientes] = useState(false);
  const [funcRelatoriosSolicitacoes, setFuncRelatoriosSolicitacoes] = useState(false);
  const [funcRelatoriosConfirmados, setFuncRelatoriosConfirmados] = useState(false);
  const [funcCriarNovosPacientes, setFuncCriarNovosPacientes] = useState(false);
  const [funcCriarNovasSolicitacoes, setFuncCriarNovasSolicitacoes] = useState(false);
  const [funcCriarNovasInstituicoes, setFuncCriarNovasInstituicoes] = useState(false);
  const [funcCriarNovosProfissionais, setFuncCriarNovosProfissionais] = useState(false);
  const [funcBaixarSolicitacoes, setFuncBaixarSolicitacoes] = useState(false);
  const [funcBaixarPacientes, setFuncBaixarPacientes] = useState(false);
  const [funcBaixarConfirmados, setFuncBaixarConfirmados] = useState(false);
  const [funcAtualizarSolicitacoes, setFuncAtualizarSolicitacoes] = useState(false);
  const [funcConfigTabelaSolicitacoes, setFuncConfigTabelaSolicitacoes] = useState(false);
  const [funcConfigTabelaConfirmados, setFuncConfigTabelaConfirmados] = useState(false);
  const [funcDeveloper, setFuncDeveloper] = useState(false);
  const [funcAtualizarVagas, setFuncAtualizarVagas] = useState(false);
  const [funcVisualizarVagas, setVisualizarVagas] = useState(false);

  useEffect(() => {
    const userPermissions = KEY_FUNCTIONS || [];

    if (userPermissions.includes("Developer")) {
      setFuncDeveloper(true);
    }

    if (userPermissions.includes("Atualizar - Vagas")) {
      setFuncAtualizarVagas(true);
    }

    if (userPermissions.includes("Visualizar - Vagas")) {
      setVisualizarVagas(true);
    }

    if (userPermissions.includes("Todas permissões - Vagas")) {
      setVisualizarVagas(true);
      setFuncAtualizarVagas(true);
    }


    if (userPermissions.includes("Todas permissões - Pacientes")) {
      setFuncEditarPacientes(true);
      setFuncRelatoriosPacientes(true);
      setFuncCriarNovosPacientes(true);
      setFuncVisualizarPacientes(true);
      setFuncBaixarPacientes(true)
    }


    if (userPermissions.includes("Todas permissões - Solicitações")) {
      setFuncVisualizarSolicitacoes(true);
      setFuncAtualizarSolicitacoes(true);
      setFuncRelatoriosSolicitacoes(true);
      setFuncCriarNovasSolicitacoes(true);
      setFuncBaixarSolicitacoes(true)
      setFuncConfigTabelaSolicitacoes(true)
    }

    if (userPermissions.includes("Todas permissões - Confirmados")) {
      setFuncVisualizarConfirmados(true);
      setFuncRelatoriosConfirmados(true);
      setFuncAtualizarConfirmados(true);
      setFuncFinalizarConfirmados(true);
      setFuncConfigTabelaConfirmados(true)
      setFuncBaixarConfirmados(true)
    }

    if (userPermissions.includes("Todas permissões - Avaliações")) {
      setFuncAvaliacoes(true);
    }

    if (userPermissions.includes("Todas permissões - Instituições")) {
      setFuncEditarInstituicoes(true);
      setFuncExcluirInstituicoes(true);
      setFuncCriarNovasInstituicoes(true);
      setFuncVisualizarInstituicoes(true);
    }

    if (userPermissions.includes("Todas permissões - Dashboard")) {
      setFuncDashboard(true);
      setFuncAdminDashboard(true);
    }

    if (userPermissions.includes("Todas permissões - Chat")) {
      setFuncComunicacaoTransmissao(true);
      setFuncComunicacaoChat(true);
      setFuncComunicacaoTickets(true)
    }

    if (userPermissions.includes("Todas permissões - Campanhas")) {
      setFuncComunicacaoCampanhas(true);
    }

    if (userPermissions.includes("Todas permissões - Cargos e Funções")) {
      setFuncCargos(true)
    }

    if (userPermissions.includes("Todas permissões - Usuários")) {
      setFuncUsuarios(true);
      setFuncEditarUsuarios(true);
    }

    if (userPermissions.includes("Todas permissões - Marcações")) {
      setFuncMarcacao(true);
    }

    if (userPermissions.includes("Todas permissões - Profissionais")) {
      setFuncVisualizarProfissionais(true);
      setFuncEditarProfissionais(true);
      setFuncExcluirProfissionais(true);
      setFuncCriarNovosProfissionais(true)
    }

    if (userPermissions.includes("Todas permissões - Calendário")) {
      setFuncCalendarioVisualizar(true);
      setFuncCalendarioAtualizar(true);
    }

    if (userPermissions.includes("Visualizar - Calendário")) {
      setFuncCalendarioVisualizar(true);
    }

    if (userPermissions.includes("Visualizar - Tickets")) {
      setFuncComunicacaoTickets(true)
    }

    if (userPermissions.includes("Atualizar - Calendário")) {
      setFuncCalendarioAtualizar(true);
    }

    if (userPermissions.includes("Chat - Comunicação")) {
      setFuncComunicacaoChat(true);
    }

    if (userPermissions.includes("Transmissão - Comunicação")) {
      setFuncComunicacaoTransmissao(true);
    }

    if (userPermissions.includes("Campanhas - Comunicação")) {
      setFuncComunicacaoCampanhas(true);
    }


    if (userPermissions.includes("Atualizar - Confirmados")) {
      setFuncAtualizarConfirmados(true);
    }

    if (userPermissions.includes("Atualizar - Solicitações")) {
      setFuncAtualizarSolicitacoes(true);
    }


    if (userPermissions.includes("Finalizar - Confirmados")) {
      setFuncFinalizarConfirmados(true);
    }

    if (userPermissions.includes("Editar - Pacientes")) {
      setFuncEditarPacientes(true);
    }

    if (userPermissions.includes("Editar - Instituições")) {
      setFuncEditarInstituicoes(true);
    }

    if (userPermissions.includes("Editar - Profissionais")) {
      setFuncEditarProfissionais(true);
    }

    if (userPermissions.includes("Visualizar - Pacientes")) {
      setFuncVisualizarPacientes(true);
    }

    if (userPermissions.includes("Visualizar - Usuários")) {
      setFuncUsuarios(true);
    }

    if (userPermissions.includes("Editar - Usuários")) {
      setFuncEditarUsuarios(true);
    }

    if (userPermissions.includes("Visualizar - Solicitações")) {
      setFuncVisualizarSolicitacoes(true);
    }

    if (userPermissions.includes("Visualizar - Confirmados")) {
      setFuncVisualizarConfirmados(true);
    }

    if (userPermissions.includes("Visualizar - Instituições")) {
      setFuncVisualizarInstituicoes(true);
    }

    if (userPermissions.includes("Visualizar - Profissionais")) {
      setFuncVisualizarProfissionais(true);
    }

    if (userPermissions.includes("Visualizar - Dashboard")) {
      setFuncDashboard(true);
    }

    if (userPermissions.includes("Excluir - Instituições")) {
      setFuncExcluirInstituicoes(true);
    }

    if (userPermissions.includes("Excluir - Profissionais")) {
      setFuncExcluirProfissionais(true);
    }

    if (userPermissions.includes("Relatórios - Pacientes")) {
      setFuncRelatoriosPacientes(true);
    }

    if (userPermissions.includes("Relatórios - Solicitações")) {
      setFuncRelatoriosSolicitacoes(true);
    }

    if (userPermissions.includes("Relatórios - Confirmados")) {
      setFuncRelatoriosConfirmados(true);
    }

    if (userPermissions.includes("Criar - Novos Pacientes")) {
      setFuncCriarNovosPacientes(true);
    }

    if (userPermissions.includes("Criar - Novas Solicitações")) {
      setFuncCriarNovasSolicitacoes(true);
    }

    if (userPermissions.includes("Criar - Novas Instituições")) {
      setFuncCriarNovasInstituicoes(true);
    }

    if (userPermissions.includes("Criar - Novos Profissionais")) {
      setFuncCriarNovosProfissionais(true);
    }

    if (userPermissions.includes("Baixar - Solicitações")) {
      setFuncBaixarSolicitacoes(true)
    }

    if (userPermissions.includes("Baixar - Pacientes")) {
      setFuncBaixarPacientes(true)
    }

    if (userPermissions.includes("Baixar - Confirmados")) {
      setFuncBaixarConfirmados(true)
    }

    if (userPermissions.includes("Config Tabela - Solicitações")) {
      setFuncConfigTabelaSolicitacoes(true)
    }

    if (userPermissions.includes("Config Tabela - Confirmados")) {
      setFuncConfigTabelaConfirmados(true)
    }




  }, [KEY_FUNCTIONS]);


  return {
    funcDeveloper,
    funcEditarUsuarios,
    funcAtualizarVagas,
    funcVisualizarVagas,
    funcAvaliacoes,
    funcDashboard,
    funcCalendarioVisualizar,
    funcCalendarioAtualizar,
    funcCargos,
    funcUsuarios,
    funcAdminDashboard,
    funcMarcacao,
    funcBaixarConfirmados,
    funcBaixarPacientes,
    funcComunicacaoChat,
    funcConfigTabelaConfirmados,
    funcComunicacaoTransmissao,
    funcComunicacaoCampanhas,
    funcAtualizarConfirmados,
    funcFinalizarConfirmados,
    funcEditarPacientes,
    funcEditarInstituicoes,
    funcEditarProfissionais,
    funcVisualizarProfissionais,
    funcExcluirProfissionais,
    funcCriarNovosProfissionais,
    funcVisualizarPacientes,
    funcVisualizarSolicitacoes,
    funcVisualizarConfirmados,
    funcVisualizarInstituicoes,
    funcExcluirInstituicoes,
    funcRelatoriosPacientes,
    funcRelatoriosSolicitacoes,
    funcRelatoriosConfirmados,
    funcCriarNovosPacientes,
    funcCriarNovasSolicitacoes,
    funcCriarNovasInstituicoes,
    funcAtualizarSolicitacoes,
    funcBaixarSolicitacoes,
    funcConfigTabelaSolicitacoes,
    funcComunicacaoTickets
  };


}


