import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Divider,

} from "@mui/material";

import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import firebaseApp, { KEY, KEY_LICENSE, KEY_NAME_CITY, KEY_NAME_USER } from '../../../integration/FirebaseConfig';
import { BASE_PROD } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datetime, getPrioridadeLabel, handleSendError } from "../../../services/script";
import { useLocation } from 'react-router-dom';
const database = getDatabase(firebaseApp);



const NovaSolicitacao = () => {
  const location = useLocation();
  const { userData } = location.state;

  const navigate = useNavigate();
  const [buscaCNS, setBuscaCNS] = useState('');
  const [isDateFocused, setDateFocused] = useState(false);
  const [nome, setNome] = useState(userData.nome || '');
  const [nomecompleto, setNomeCompleto] = useState(userData.nomecompleto || '');
  const [cpf, setCpf] = useState(userData.cpf || '');
  const [rg, setRg] = useState(userData.rg || '');
  const [cns, setCns] = useState(userData.cns || '');
  const [telefone, setTelefone] = useState(userData.telefone || '');
  const [email, setEmail] = useState(userData.email || '');
  const [estadoCivil, setEstadoCivil] = useState(userData.estadoCivil || '');
  const [datanascimento, setDataNascimento] = useState(userData.datanascimento || '');
  const [genero, setGenero] = useState(userData.genero || '');
  const [prioridade, setPrioridade] = useState('');
  const [Especialidade, setEspecialidade] = useState('');
  const [Turno, setTurno] = useState('');
  const [portador, setPortador] = useState(userData.portador || 'não');
  const [cep, setCep] = useState(userData.cep || '');
  const [uf, setUf] = useState(userData.estado || '');
  const [cidade, setCidade] = useState(userData.cidade || '');
  const [logradouro, setLogradouro] = useState(userData.logradouro || '');
  const [numero, setNumero] = useState(userData.numero || '');
  const [protocolo, setProtocolo] = useState('');
  const [complemento, setComplemento] = useState(userData.complemento || '');
  const [localidade, setLocalidade] = useState('');
  const [bairro, setBairro] = useState(userData.bairro || '');
  const [showForm, setShowForm] = useState(false);
  const [vagas, setVagas] = useState([]);
  const [notfound, setNotfound] = useState(false);
  const [procedimentos, setProcedimentos] = useState('');
  const [quantidadeVagasDisponiveis, setQuantidadeVagasDisponiveis] = useState(0);
  const [especialidadesDisponiveis, setEspecialidadesDisponiveis] = useState([]);




  function gerarChamado() {
    const numeroAleatorio = Math.floor(1000000000 + Math.random() * 9000000000);
    return numeroAleatorio.toString();
  }


  useEffect(() => {
    const fetchEspecialidades = async () => {
      const database = getDatabase(firebaseApp);
      const instituicoesRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes`);
      const instituicoesSnapshot = await get(instituicoesRef);

      const especialidades = [];

      instituicoesSnapshot.forEach((instituicao) => {
        const especialidadesInstituicao = instituicao.val().especialidade;
        if (especialidadesInstituicao) {
          Object.keys(especialidadesInstituicao).forEach((especialidadeKey) => {
            const especialidadeData = especialidadesInstituicao[especialidadeKey];
            if (especialidadeData.vagas > 0) {
              especialidades.push({
                id: especialidadeKey,
                nome: especialidadeData.nome
              });
            }
          });
        }
      });

      setEspecialidadesDisponiveis(especialidades);
    };


    fetchEspecialidades();
  }, []);




  const handleEspecialidadeChange = (event) => {
    const especialidadeSelecionada = event.target.value;
    setEspecialidade(especialidadeSelecionada);
  };


  const handleSalvar = async () => {
    const chamado = gerarChamado();
    const db = ref(database, `${BASE_PROD}/${KEY}/solicitados/` + chamado);
    const missingFields = [];

    try {
      if (!Especialidade) {
        missingFields.push("Especialidade");
      }
      if (!procedimentos) {
        missingFields.push("Procedimento");
      }
      if (!Turno) {
        missingFields.push("Turno");
      }

      if (missingFields.length > 0) {
        const missingFieldsMessage = missingFields.join(", ");
        alert(`Por favor, preencha os seguintes campos: ${missingFieldsMessage}`);
        return;
      }

      let pri
      if (userData.tipoPrioridade != null) {
        if (userData.tipoPrioridade != '') {
          pri = true
        } else {
          pri = false
        }
      } else {
        pri = false
      }

      const novaSolicitacao = {
        id: chamado,
        nome: nomecompleto,
        cpf,
        rg,
        datetime: datetime(),
        cns,
        categoria: Especialidade,
        telefone,
        email,
        estadoCivil,
        datanascimento,
        protocolo: chamado,
        prioridade: pri,
        genero,
        portador: userData.portador || 'não',
        cep: userData.cep || '',
        estado: userData.estado || '',
        cidade: userData.cidade || '',
        logradouro: userData.logradouro || '',
        bairro: userData.bairro || '',
        numero: userData.numero || '',
        complemento: userData.complemento || '',
        localidade: userData.localidade || '',
        procedimento: procedimentos,
        tipoPrioridade: userData.tipoPrioridade || '',
        cor: userData.cor || 'green',
        horario: "00:00",
        turno: Turno,
        status: "Em Análise"
      };

      const cachedSolicitados = JSON.parse(localStorage.getItem('cacheSolicitadosAnalise')) || [];
      const updatedCache = [...cachedSolicitados, novaSolicitacao];
      localStorage.setItem('cacheSolicitadosAnalise', JSON.stringify(updatedCache));

      await set(db,
        novaSolicitacao
      );

      navigate(`/solicitacoes`);

    } catch (error) {
      console.error("Erro ao salvar a solicitação:", error);

      const errorObject = {
        datetime: new Date(),
        errorLineTrace: error.stack,
        errorMessage: error.message,
        errorType: error.constructor.name,
        responseStatus: error.response?.status || 'none',
        responseMsg: error.response?.data?.message || 'none',
        page: 'Nova Solicitação',
        KEY,
        KEY_LICENSE,
        KEY_NAME_CITY,
        KEY_NAME_USER
      };

      console.log("Tentando enviar objeto de erro para o Firebase:", errorObject);

      const firebaseErrorsRef = ref(getDatabase(), 'panel/erros');
      const newErrorRef = push(firebaseErrorsRef);

      const errorString = JSON.stringify(errorObject);
      handleSendError(errorString, KEY_NAME_USER);

      try {
        await set(newErrorRef, errorObject);
        console.log("Objeto de erro enviado com sucesso para o Firebase.");
      } catch (firebaseError) {
        console.error("Erro ao enviar objeto de erro para o Firebase:", firebaseError);
      }
    }
  };





  return (
    <>
      <HelmetProvider>   <Helmet>
        <title>Painel DHF - Nova Solicitação</title>
      </Helmet>
      </HelmetProvider>




      <Box p="10px" bgcolor="#1D1D1D" borderRadius="10px" style={{ marginLeft: '5%', marginRight: '5%', marginTop: '40px' }}>

        <Typography variant="h4" style={{ color: "white", borderRadius: "10px", marginLeft: 20, marginRight: 20, backgroundColor: "#FF4B00", padding: "10px", marginBottom: 25, marginTop: "15px", fontWeight: 'bold' }}>NOVA SOLICITAÇÃO</Typography>



        {userData ? (


          <form>
            <Grid container spacing={2} sx={{ paddingLeft: 3, paddingRight: 3 }}>
              <Grid item xs={12} sm={12} >
                <TextField
                  label="Nome"
                  fullWidth
                  value={nome}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Nome Completo"
                  fullWidth
                  value={nomecompleto}
                  onChange={(e) => setNomeCompleto(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="CPF"
                  fullWidth
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  sx={{ bgcolor: '#3338' }}

                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Registro Geral (RG)"
                  fullWidth
                  value={rg}
                  onChange={(e) => setRg(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="CNS"
                  fullWidth
                  value={cns}
                  onChange={(e) => setCns(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Telefone"
                  fullWidth
                  sx={{ bgcolor: '#3338' }}
                  value={telefone}

                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="E-mail"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data de Nascimento"
                  fullWidth
                  sx={{ bgcolor: '#3338' }}
                  type="text"
                  value={datanascimento}

                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>


              {userData.tipoPrioridade != '' ? (<>              <Grid item xs={12} sm={4}>

                <TextField
                  label="Prioridade"
                  fullWidth
                  value={getPrioridadeLabel(userData.cor)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />


              </Grid></>) : (<> </>)}


              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel style={{ color: "white" }}>Especialidades</InputLabel>
                  <Select
                    value={Especialidade}
                    sx={{ bgcolor: '#3338' }}
                    onChange={handleEspecialidadeChange}
                    label="Especialidade"
                    fullWidth
                  >
                    {especialidadesDisponiveis.map((especialidade) => (
                      <MenuItem key={especialidade.id} value={especialidade.nome}>
                        {especialidade.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>




              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel style={{ color: "white" }}>Procedimento</InputLabel>
                  <Select
                    value={procedimentos}
                    sx={{ bgcolor: '#3338' }}
                    onChange={(event) => setProcedimentos(event.target.value)}
                    label="Procedimento"
                    fullWidth
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    <MenuItem value="Consulta">Consulta</MenuItem>
                    <MenuItem value="Cirugia">Cirugia</MenuItem>
                    <MenuItem value="Exames">Exames</MenuItem>
                    <MenuItem value="Retorno">Retorno</MenuItem>
                    <MenuItem value="Outros">Outros</MenuItem>

                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={12} sm={4}>
                <TextField
                  label="Turno de Preferência"
                  select
                  fullWidth
                  sx={{ bgcolor: '#3338' }}
                  value={Turno}
                  onChange={(event) => setTurno(event.target.value)}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                >

                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="Manha">Manhã</MenuItem>
                  <MenuItem value="Tarde">Tarde</MenuItem>

                </TextField>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography>Gênero</Typography>
                <RadioGroup
                  aria-label="gender"
                  name="gender"

                  value={genero}
                  onChange={(event) => setGenero(event.target.value)}
                >
                  <FormGroup row>
                    <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                    <FormControlLabel value="feminino" control={<Radio />} label="Feminino" />
                    <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                  </FormGroup>
                </RadioGroup>
              </Grid>



              <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
                <Button
                  style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                  onClick={handleSalvar}
                >
                  <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                  CONCLUIR
                </Button>
              </Grid>



            </Grid>
          </form>
        ) : (
          <>

            <Typography style={{ textAlign: 'center', justifyContent: 'center', marginTop: 20, fontWeight: 'bold' }}>Paciente não cadastrado</Typography>

          </>

        )
        }

      </Box >

    </>
  );
};

export default NovaSolicitacao;
