import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Grid, Slide, Divider,
  Tooltip
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import { IconButton } from "@mui/material";
import { getDatabase, ref, get, update, remove } from '@firebase/database';
import firebaseApp, { BASE_PROD, KEY } from '../../integration/FirebaseConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import NovoCargo from "./NovoCargo";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WindowIcon from '@mui/icons-material/Window';
import EditarCargo from "./EditCargo";
import LanguageIcon from '@mui/icons-material/Language';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CargosList = () => {
  const database = getDatabase(firebaseApp);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [dados, setDados] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [showNotification, setShowNotification] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState(null);
  const [openSolicitacoes, setOpenSolicitacoes] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);


  const fetchUsers = async () => {
    const database = getDatabase();
    const usersRef = ref(database, `${BASE_PROD}/${KEY}/cargos`);
    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const filteredUsers = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }));

        setUserData(filteredUsers);

        setLoading(false);



      } else {
        setTimeout(() => {
          setLoading(false);
          setUserData([]);
        }, 600);
      }
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchUsers();
  }, [KEY]);





  const CustomRenderCell = ({ value }) => {
    const [openDialog, setOpenDialog] = useState(false);

    if (!value || value.length === 0) {
      return (
        <Typography style={{ color: 'white', fontWeight: 'bold' }}>
          -
        </Typography>
      );
    }

    if (Array.isArray(value)) {
      const limiteVisivel = 2;

      if (value.length > limiteVisivel) {
        return (
          <>
            <Box>
              {value.slice(0, limiteVisivel).map((funcao, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  {funcao.includes("Visualizar") && <VisibilityIcon style={{ color: '#FF4B00' }} />}
                  {funcao.includes("Relatórios") && <AssessmentIcon style={{ color: '#FF4B00' }} />}
                  {funcao.includes("Excluir") && <DeleteIcon style={{ color: '#FF4B00' }} />}
                  {funcao.includes("Todas") && <LanguageIcon style={{ color: '#FF4B00' }} />}
                  {funcao.includes("Editar") && <EditOutlinedIcon style={{ color: '#FF4B00' }} />}
                  {!["Visualizar", "Relatórios", "Excluir", "Editar"].some((term) => funcao.includes(term)) && (
                    <WindowIcon style={{ color: '#FF4B00' }} />
                  )}
                  <Typography variant="body2" color="white" style={{ marginLeft: 8 }}>
                    {funcao}
                  </Typography>
                </Box>
              ))}
              <Button variant="text" style={{ color: '#FF4B00', fontWeight: 'bold' }} onClick={() => setOpenDialog(true)}>
                Visualizar todas permissões
              </Button>
            </Box>

            <Dialog TransitionComponent={Transition} open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
              <DialogTitle style={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: '#1D1D1D' }}>
                Permissões do Usuário
              </DialogTitle>
              <DialogContent style={{ backgroundColor: '#1D1D1D' }}>
                {value.map((funcao, index) => (
                  <Box key={index} display="flex" alignItems="center" mb={1}>
                    {funcao.includes("Visualizar") && <VisibilityIcon style={{ color: '#FF4B00' }} />}
                    {funcao.includes("Relatórios") && <AssessmentIcon style={{ color: '#FF4B00' }} />}
                    {funcao.includes("Excluir") && <DeleteIcon style={{ color: '#FF4B00' }} />}
                    {funcao.includes("Todas") && <LanguageIcon style={{ color: '#FF4B00' }} />}
                    {funcao.includes("Editar") && <EditOutlinedIcon style={{ color: '#FF4B00' }} />}
                    {!["Visualizar", "Relatórios", "Excluir", "Editar"].some((term) => funcao.includes(term)) && (
                      <WindowIcon style={{ color: '#FF4B00' }} />
                    )}
                    <Typography variant="body1" color="white" style={{ marginLeft: 8, fontWeight: 'bold' }}>
                      {funcao}
                    </Typography>
                  </Box>
                ))}
              </DialogContent>
              <DialogActions style={{ backgroundColor: '#1D1D1D' }}>
                <Button
                  onClick={() => setOpenDialog(false)}
                  style={{
                    backgroundColor: '#FF4B00',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '8px 16px',
                    borderRadius: '4px',
                  }}
                >
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }

      return (
        <Box>
          {value.map((funcao, index) => (
            <Box key={index} display="flex" alignItems="center" mb={1}>
              {funcao.includes("Visualizar") && <VisibilityIcon style={{ color: '#FF4B00' }} />}
              {funcao.includes("Relatórios") && <AssessmentIcon style={{ color: '#FF4B00' }} />}
              {funcao.includes("Excluir") && <DeleteIcon style={{ color: '#FF4B00' }} />}
              {funcao.includes("Editar") && <EditOutlinedIcon style={{ color: '#FF4B00' }} />}
              {!["Visualizar", "Relatórios", "Excluir", "Editar"].some((term) => funcao.includes(term)) && (
                <WindowIcon style={{ color: '#FF4B00' }} />
              )}
              <Typography variant="body2" color="white" style={{ marginLeft: 8 }}>
                {funcao}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Typography style={{ color: 'white' }}>
        {value}
      </Typography>
    );
  };





  const handleDeleteConfirmation = (id) => {
    setInstituicaoSelecionada(id);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteInstituicao(instituicaoSelecionada);
    setDeleteConfirmationDialogOpen(false);
  };

  const deleteInstituicao = (id) => {
    const instituicaoRef = ref(database, `${BASE_PROD}/${KEY}/cargos/${id}`);
    try {
      remove(instituicaoRef);
      fetchUsers();
    } catch (error) {
      console.error('Erro ao excluir a instituição:', error);
    }
  };




  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',

      flex: 1,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'funcoes',
      headerName: 'Funções',
      flex: 1,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'datetime',
      headerName: 'Atualização',
      flex: 0.5,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'acoes',
      headerName: 'Tipo de acesso',
      flex: 0.5,
      filterable: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {row.acessoExterno === true && (
            <Tooltip title="Acesso Externo">
              <CheckCircleOutlineIcon fontSize="large" sx={{ marginRight: 2, color: '#ff4b00' }} />
            </Tooltip>
          )}
          {row.acessoTemporario === true && (
            <Tooltip title="Acesso Temporário">
              <CheckCircleOutlineIcon fontSize="large" sx={{ color: 'yellow' }} />
            </Tooltip>
          )}
          {row.acessoExterno !== true && row.acessoTemporario !== true && (
            <Tooltip title="Acesso Normal">
              <CheckCircleOutlineIcon fontSize="large" sx={{ color: 'green' }} />
            </Tooltip>
          )}

        </Box>
      ),
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleDeleteConfirmation(row.id)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>

          <IconButton
            onClick={() => handleOpenEditar(row)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
            disabled={row.acessoExterno === true}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Box>
      ),
    }


  ];


  const handleOpenSolicitacoes = () => {
    setOpenSolicitacoes(true);
  };

  const handleCloseSolicitacoes = () => {
    setOpenSolicitacoes(false);
  };

  const handleOpenEditar = (row) => {
    setDados(row)
    setOpenEditar(true);
  };

  const handleCloseEditar = () => {
    setOpenEditar(false);
  };



  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Painel DHF - Cargos</title>
        </Helmet>
      </HelmetProvider>

      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/dhf-transparente2.gif" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <Box m="10px">

        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/brasao2.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            GERENCIAMENTO DE CARGOS E FUNÇÕES
          </Typography>

          <IconButton variant="contained" style={{ backgroundColor: "#FF4B00", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }} onClick={handleOpenSolicitacoes}>
            <ContactEmergencyIcon style={{ marginRight: 4 }} /> NOVO CARGO
          </IconButton>




        </Box>

        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid rows={filteredTableData} columns={columns} rowHeight={105} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            ) : (
              <DataGrid rows={filteredTableData.length > 0 ? filteredTableData : userData} columns={columns} rowHeight={105} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            )}
          </Box>
        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>

      <Dialog open={deleteConfirmationDialogOpen} onClose={() => setDeleteConfirmationDialogOpen(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir este Profissional?</Typography>
        </DialogContent>
        <DialogActions>
          <Button style={{
            backgroundColor: "transparent",
            border: "1px solid #FF4B00", color: 'white',
          }} onClick={() => setDeleteConfirmationDialogOpen(false)}>Cancelar</Button>
          <Button style={{
            backgroundColor: "#FF4B00", fontWeight: 'bold', color: 'white',
          }} onClick={handleDeleteConfirmed} color="error">Excluir</Button>
        </DialogActions>
      </Dialog>

      <Dialog TransitionComponent={Transition} open={openSolicitacoes} onClose={handleCloseSolicitacoes} fullWidth maxWidth="xl">
        <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: 'white', backgroundColor: '#1D1D1D', padding: '16px 24px' }}>
          <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">CADASTRO DE CARGOS/FUNÇÕES</Divider>

          <IconButton
            edge="end"
            onClick={handleCloseSolicitacoes}
            aria-label="close"
            style={{ color: '#FF4B00', position: 'absolute', right: 16, top: 16 }}
          >
            <CloseIcon fontSize="20" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#1D1D1D', color: 'white', padding: '24px' }}>
          <NovoCargo handleClose={handleCloseSolicitacoes} fetch={fetchUsers} />
        </DialogContent>

      </Dialog>

      <Dialog TransitionComponent={Transition} open={openEditar} onClose={handleCloseEditar} fullWidth maxWidth="xl">
        <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: 'white', backgroundColor: '#1D1D1D', padding: '16px 24px' }}>
          <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">EDIÇÃO DE CARGOS/FUNÇÕES</Divider>

          <IconButton
            edge="end"
            onClick={handleCloseEditar}
            aria-label="close"
            style={{ color: '#FF4B00', position: 'absolute', right: 16, top: 16 }}
          >
            <CloseIcon fontSize="20" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#1D1D1D', color: 'white', padding: '24px' }}>
          <EditarCargo handleClose={handleCloseEditar} fetch={fetchUsers} userData={dados} />
        </DialogContent>

      </Dialog>

    </>
  );
};

export default CargosList;
