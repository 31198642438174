import React from "react";
import { Navigate, Route } from 'react-router-dom';
import ChatWhatsApp from "../components/ChatWhatsapp";
import NovoPaciente from "../components/pacientes/NovoPaciente";
import EditPaciente from "../components/pacientes/editpaciente";
import NovaSolicitacao from "../components/solicitados/NovaSolicitacao/index";
import Pacientes from "../components/pacientes";
import Marcacao from "../components/marcacoes/telamarcacao/index";
import Marcacoes from "../components/marcacoes";
import Solicitados from "../components/solicitados";
import FAQ from "../components/Faq/index";
import Deslogar from "../authentication/Logout/Deslogar";
import Calendar from "../components/Calendar";
import Cargos from "../components/Cargos";
import Avaliacoes from "../components/Avaliacoes";
import TerminalJsx from "../services/Terminal";
import Dashboard from "../components/Dashboard";
import Instituicoes from "../components/instituicoes/index";
import EditarInstituicao from "../components/instituicoes/editarInstituicao/index";
import NovaInstituicao from "../components/instituicoes/NovaInstituicao/index";
import Profissionais from "../components/profissionais";
import EditarProfi from "../components/profissionais/editprofissionais/index";
import NovoProfissional from "../components/profissionais/NovoProfissional/index";
import Manutencao from "../authentication/Manutencao";
import Acesso from "../authentication/Acesso/index";
import CadastroCategoria from "../authentication/Cadastro/CadastroCategoria";
import CadastroDados from "../authentication/Cadastro/CadastroDados";
import Login from "../authentication/Login/Login";
import FirstCreen from "../authentication/Cadastro/FirstScreen";
import BuscaSolicitacoes from "../components/busca_solicitacoes";
import NovoCargo from "../components/Cargos/NovoCargo";
import CargosList from "../components/Cargos/cargos";
import Start from "../components/start";
import Campanha from "../components/campanhas";
import Exames from "../components/exames";
import NovoExame from "../components/exames/novoExame";
import Tickets from "../components/tickets";

const commonRoutes = (cidade, funcoes) => (
  <>
    {funcoes.funcComunicacaoChat && <Route path={`/chat`} element={<ChatWhatsApp />} />}
    {funcoes.funcComunicacaoTickets && <Route path={`/tickets`} element={<Tickets />} />}
    {funcoes.funcVisualizarPacientes && <Route path={`/pacientes`} element={<Pacientes />} />}
    {funcoes.funcEditarPacientes && <Route path={`/pacientes/editar`} element={<EditPaciente />} />}
    {funcoes.funcCriarNovosPacientes && <Route path={`/pacientes/novo`} element={<NovoPaciente />} />}
    {funcoes.funcVisualizarSolicitacoes && <Route path={`/solicitacoes`} element={<Solicitados />} />}
    {funcoes.funcVisualizarSolicitacoes && <Route path={`/buscar`} element={<BuscaSolicitacoes />} />}
    {funcoes.funcCriarNovasSolicitacoes && <Route path={`/solicitacoes/novo`} element={<NovaSolicitacao />} />}
    {funcoes.funcVisualizarConfirmados && <Route path={`/confirmados`} element={<Marcacoes />} />}
    {funcoes.funcVisualizarInstituicoes && <Route path={`/instituicoes`} element={<Instituicoes />} />}
    {funcoes.funcVisualizarInstituicoes && <Route path={`/exames`} element={<Exames />} />}
    {funcoes.funcVisualizarInstituicoes && <Route path={`/exames/novo`} element={<NovoExame />} />}
    {funcoes.funcEditarInstituicoes && <Route path={`/instituicoes/editar`} element={<EditarInstituicao />} />}
    {funcoes.funcCriarNovasInstituicoes && <Route path={`/instituicoes/novo`} element={<NovaInstituicao />} />}
    {funcoes.funcVisualizarProfissionais && <Route path={`/profissionais`} element={<Profissionais />} />}
    {funcoes.funcEditarProfissionais && <Route path={`/profissionais/editar`} element={<EditarProfi />} />}
    {funcoes.funcCriarNovosProfissionais && <Route path={`/profissionais/novo`} element={<NovoProfissional />} />}
    {funcoes.funcAvaliacoes && <Route path={`/avaliacoes`} element={<Avaliacoes />} />}
    {funcoes.funcDashboard && <Route path={`/dashboard`} element={<Dashboard />} />}
    {funcoes.funcAdminDashboard && <Route path={`/dashboard`} element={<Dashboard />} />}
    {funcoes.funcCargos && <Route path={`/cargos`} element={<CargosList />} />}
    {funcoes.funcUsuarios && <Route path={`/usuarios`} element={<Cargos />} />}
    {funcoes.funcCargos && <Route path={`/cargos/novo`} element={<NovoCargo />} />}
    {funcoes.funcComunicacaoChat && <Route path={`/chat/paciente`} element={<ChatWhatsApp />} />}
    {funcoes.funcComunicacaoCampanhas && <Route path={`/campanhas`} element={<Campanha />} />}
    {funcoes.funcCalendarioVisualizar && <Route path={`/calendario`} element={<Calendar />} />}
    {funcoes.funcCalendarioVisualizar && <Route path={`/${cidade}/`} element={<Calendar />} />}
    {funcoes.funcMarcacao && <Route path={`/marcar`} element={<Marcacao />} />}
    {funcoes.funcDeveloper && <Route path={`/stats/dev`} element={<TerminalJsx />} />}
    <Route path={`/logout`} element={<Deslogar />} />
    <Route path={`/`} element={<Start />} />
    <Route path={`/faq`} element={<FAQ />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
);



export default commonRoutes;


export const commonRoutesManutencao = (cidade) => (
  <>
    <Route path={`/`} element={<Manutencao />} />
    <Route path={`/${cidade}/`} element={<Manutencao />} />
    <Route path={`/logout`} element={<Deslogar />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
);

export const commonRoutesAcesso = (cidade) => (
  <>
    <Route path={`/`} element={<Acesso />} />
    <Route path={`/${cidade}/`} element={<Acesso />} />
    <Route path={`/logout`} element={<Deslogar />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
);

export const commonRoutesCategory = () => (
  <>
    <Route path="/" element={<CadastroCategoria />} />
    <Route path="/cadastro/categoria/" element={<CadastroCategoria />} />
    <Route path={`/logout`} element={<Deslogar />} />
    <Route path="/*" element={<CadastroCategoria />} />
  </>
);

export const commonRoutesCPF = () => (
  <>
    <Route path="/" element={<CadastroDados />} />
    <Route path="/cadastro/dados/" element={<CadastroDados />} />
    <Route path={`/logout`} element={<Deslogar />} />
    <Route path="/*" element={<CadastroDados />} />
  </>
);

export const commonRoutesCadastro = () => (
  <>
    <Route path="/*" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/cadastro/categoria" element={<CadastroCategoria />} />
    <Route path="/cadastro/dados" element={<CadastroDados />} />
    <Route path="/cadastro" element={<FirstCreen />} />
    <Route path={`/logout`} element={<Deslogar />} />
  </>
);

export const commonRoutesStart = () => (
  <>
    <Route path="/*" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/cadastro" element={<FirstCreen />} />
  </>
);