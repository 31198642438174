import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Typography, Button, Slide, Divider, TextField, Grid, Skeleton, Dialog, DialogTitle, DialogContent, ListItemText, ListItem, List, FormControl, InputLabel, Select, MenuItem, Alert, Snackbar, Collapse, IconButton, Menu, InputAdornment, useTheme, useMediaQuery } from '@mui/material';
import { getDatabase, ref, onValue, off, update, remove, get } from '@firebase/database';
import firebaseApp, { KEY, KEY_LICENSE, KEY_MASTER, KEY_NAME_USER } from '../../integration/FirebaseConfig';
import '../../styles/ChatWhatsApp.css';
import SearchIcon from '@mui/icons-material/Search';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { datetime, formatarId, formatarTelefone, handleCopyClick, isCPF, isData, isEmail } from '../../services/script';
import { ColorModeContext, tokens } from '../../theme';
import { Slider, Tooltip } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { Chat as ChatIcon } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';


const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#1d1d1d',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
}));

const OptionItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#333',
    },
}));

const database = getDatabase(firebaseApp);


const Chats = ({ onChatClick }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [sliderOpen, setSliderOpen] = useState(null);
    const [sliderAtendente, setSliderAtendente] = useState(null);
    const [sliderExcluido, setSliderExcluido] = useState(null);
    const [openOptions, setOpenOptions] = useState(false);
    const [chats, setChats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('Todos');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isCPF(searchTerm)) {
            setSearchType('CPF:');
        } else if (isEmail(searchTerm)) {
            setSearchType('E-mail:');
        } else if (isData(searchTerm)) {
            setSearchType('Data:');
        } else {
            setSearchType('Todos');
        }
    }, [searchTerm]);

    const handleToggleOptions = () => {
        setOpenOptions(!openOptions);
    };

    const handleOptionClick = (type) => {
        setSearchType(type);
        setOpenOptions(false);
    };



    const handleArchive = async (chatId) => {
        const chatRef = ref(database, `/db/cidades/${KEY}/chats/${chatId}/arquivado`);
        const chat = ref(database, `/db/cidades/${KEY}/chats/${chatId}`);
        try {
            const snapshot = await get(chatRef);
            const currentData = snapshot.val();

            const novoEstado = currentData === true ? false : true;

            await update(chat, { arquivado: novoEstado });

            setSliderOpen(null);
        } catch (error) {
            console.error('Erro ao atualizar o estado de mutado:', error);
        }

    };

    const handleMuted = async (chatId) => {
        const chatRef = ref(database, `/db/cidades/${KEY}/chats/${chatId}/mutado`);
        const chat = ref(database, `/db/cidades/${KEY}/chats/${chatId}`);
        try {
            const snapshot = await get(chatRef);
            const currentData = snapshot.val();

            const novoEstado = currentData === true ? false : true;

            await update(chat, { mutado: novoEstado });

            setSliderOpen(null);
        } catch (error) {
            console.error('Erro ao atualizar o estado de mutado:', error);
        }
    };



    const handleDelete = async (chatId) => {
        const chatRef = ref(database, `/db/cidades/${KEY}/chats/${chatId}/`);
        const chatDelete = ref(database, `/db/cidades/${KEY}/chats/${chatId}/conversas`);
        await update(ref(database, `/db/cidades/${KEY}/users/${chatId}`), { bloqueado: false, init: false });
        await update(chatRef, { excluir: true, datefinish: datetime(), atendentefinish: KEY_NAME_USER });
        await remove(chatDelete);
        setSliderOpen(null);
        onChatClick(null)
    };

    useEffect(() => {
        const chatRef = ref(database, `/db/cidades/${KEY}/chats`);

        const fetchChats = () => {
            onValue(chatRef, (snapshot) => {
                const chatsData = snapshot.val();
                if (chatsData) {
                    const chatsArray = Object.keys(chatsData)
                        .map((key) => ({
                            id: key,
                            excluir: chatsData[key]?.excluir || false,
                            arquivado: chatsData[key]?.arquivado || false,
                            nome: chatsData[key]?.nome || 'DESCONHECIDO',
                            msg: chatsData[key]?.msg || '',
                            cpf: chatsData[key]?.cpf || '',
                            cns: chatsData[key]?.cns || '',
                            email: chatsData[key]?.email || '',
                            nomecompleto: chatsData[key]?.nomecompleto || '',
                            visualizado: chatsData[key]?.visualizado,
                            hora: chatsData[key]?.hora,
                            data: chatsData[key]?.data,
                            ticket: String(chatsData[key]?.ticket || ''),
                            notificou: chatsData[key]?.notificou,
                            mutado: chatsData[key]?.mutado || false,
                            arquivado: chatsData[key]?.arquivado || false,
                            KEY_ATENDENTE: chatsData[key]?.KEY_ATENDENTE || null,
                            atendente: chatsData[key]?.atendente || null,
                            datecheck: chatsData[key]?.datecheck || '01/01/2000, 00:00:00',
                            datefinish: chatsData[key]?.datefinish || '01/01/2000, 00:00:00',
                            atendentefinish: chatsData[key]?.atendentefinish || 'Não coletado',
                        }));

                    setTimeout(() => {
                        setChats(chatsArray);
                        setLoading(false);
                    }, 500);

                    const hasUnviewedMessages = chatsArray.some(chat => !chat.visualizado && !chat.notificou);

                    if (hasUnviewedMessages) {
                        if (Notification.permission !== "granted") {
                            Notification.requestPermission();
                        } else {
                            chatsArray.forEach(chat => {
                                if (!chat.visualizado && !chat.notificou && !chat.mutado && !chat.arquivado) {
                                    const senderName = chat.nome || 'Novo usuário';

                                    const notification = new Notification("Nova Mensagem - CHAT DHF", {
                                        body: `${senderName}: ${chat.msg}`,
                                        icon: '/images/brasao.webp',
                                    });

                                    const chatToUpdateRef = ref(database, `/db/cidades/${KEY}/chats/${chat.id}`);
                                    update(chatToUpdateRef, {
                                        notificou: true,
                                    });
                                }
                            });
                        }
                    }
                } else {
                    setTimeout(() => {
                        setChats([]);
                        setLoading(false);
                    }, 1000);
                }
            });
        };

        fetchChats();

        return () => {
            off(chatRef);
        };
    }, []);


    const parseDateTime = (date, time) => {
        if (!date || !time) return null;
        const [day, month, year] = date.split('/');
        const [hours, minutes, seconds] = time.split(':');
        return new Date(year, month - 1, day, hours, minutes, seconds);
    };

    const filteredChats = chats
        .filter(chat =>
            chat.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.msg.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.cpf.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.cns.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.nomecompleto.toLowerCase().includes(searchTerm.toLowerCase()) ||
            chat.ticket.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter(chat => {
            if (searchType === 'Todos') {
                return chat.arquivado === false && chat.excluir === false;
            } else if (searchType === 'Excluídos') {
                return chat.excluir;
            } else if (searchType === 'Arquivados') {
                return chat.arquivado;
            }
            return true;
        })
        .sort((a, b) => {
            const dateTimeA = parseDateTime(a.data, a.hora);
            const dateTimeB = parseDateTime(b.data, b.hora);
            return dateTimeB - dateTimeA;
        });





    return (
        <>

            <Box sx={{
                marginLeft: 2,
                marginRight: 2,
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 1,
                        position: 'relative',
                        marginRight: 2,
                    }}
                >
                    <TextField
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        fullWidth
                        sx={{
                            maxWidth: '70vh',
                            backgroundColor: '#1d1d1d',
                            borderRadius: 1,
                            '& .MuiOutlinedInput-root': {
                                color: 'white',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'transparent',
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'white' }} />
                                </InputAdornment>
                            ),
                            placeholder: 'Pesquisar uma conversa',
                        }}
                    />

                    <IconButton
                        onClick={handleToggleOptions}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                        }}
                    >
                        {openOptions ? (
                            <KeyboardDoubleArrowUpIcon sx={{ color: 'white' }} />
                        ) : (
                            <KeyboardDoubleArrowDownIcon sx={{ color: 'white' }} />
                        )}
                    </IconButton>



                </Box>
                <Box
                    sx={{
                        marginRight: 2,
                        borderRadius: 1,
                        minWidth: '30vh',
                        width: '54vh',
                        maxWidth: '100%',
                        height: '60vh',
                        maxHeight: '60vh',
                        overflowY: 'auto',
                        backgroundColor: 'transparent',
                        paddingRight: 1,
                        // '@media (max-width: 768px)': {
                        //     height: '50vh',
                        //     paddingRight: 1,
                        // },
                    }}
                >



                    {searchTerm && (
                        <Typography sx={{ color: '#8c8c8c', textAlign: 'center', mb: 1 }}>
                            Resultados da Pesquisa para {searchType} "{searchTerm}"
                        </Typography>
                    )}

                    <Collapse in={openOptions} sx={{ width: '100%', maxWidth: '70vh', marginTop: 0.5, marginBottom: 1.2 }}>
                        <Slide direction="down" in={openOptions} mountOnEnter unmountOnExit>
                            <StyledBox
                                sx={{
                                    padding: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <OptionItem onClick={() => handleOptionClick('Todos')}>
                                    <ChatIcon sx={{ color: 'white', mr: 1 }} />
                                    <Typography sx={{ fontSize: 14, color: 'white' }}>Todas as Conversas</Typography>
                                </OptionItem>
                                <Divider sx={{ backgroundColor: '#333', my: 0.5 }} />
                                <OptionItem onClick={() => handleOptionClick('Excluídos')}>
                                    <DeleteIcon sx={{ color: 'white', mr: 1 }} />
                                    <Typography sx={{ fontSize: 14, color: 'white' }}>Conversas excluídas</Typography>
                                </OptionItem>
                                <Divider sx={{ backgroundColor: '#333', my: 0.5 }} />
                                <OptionItem onClick={() => handleOptionClick('Arquivados')}>
                                    <ArchiveIcon sx={{ color: 'white', mr: 1 }} />
                                    <Typography sx={{ fontSize: 14, color: 'white' }}>Conversas arquivados</Typography>
                                </OptionItem>
                            </StyledBox>
                        </Slide>
                    </Collapse>

                    <Box >
                        {loading ? (
                            <>
                                {[...Array(15)].map((_, index) => (
                                    <Grid container spacing={2} sx={{ mb: 0.8 }} key={index}>
                                        <Grid item xs={2}>
                                            <Skeleton variant="circular" width={45} height={45} />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Skeleton variant="rounded" animation="wave" width="98%" height={45} />
                                        </Grid>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {filteredChats.map((chat) => (

                                    <Slide
                                        key={chat.id}
                                        direction="up"
                                        in={true}
                                        mountOnEnter
                                        unmountOnExit
                                        timeout={300}
                                    >

                                        <Box
                                            key={chat.id}
                                            sx={{
                                                position: 'relative',
                                                cursor: chat.KEY_ATENDENTE && chat.KEY_ATENDENTE !== KEY_LICENSE ? 'not-allowed' : (chat.excluir ? 'default' : 'pointer'),
                                                backgroundColor: chat.KEY_ATENDENTE && chat.KEY_ATENDENTE !== KEY_LICENSE ? '#00529B' : (chat.excluir ? '#8e0505' : '#1D1D1D'),
                                                borderRadius: 2,
                                                padding: 0.8,
                                                marginBottom: 0.7,
                                                overflow: 'hidden',
                                                opacity: chat.excluir ? 0.5 : 1,
                                                '&:hover': {
                                                    backgroundColor: chat.KEY_ATENDENTE && chat.KEY_ATENDENTE !== KEY_LICENSE ? '#054a87' : '#444444',
                                                },
                                                // '@media (max-width: 768px)': {
                                                //     padding: 0.5,
                                                //     marginBottom: 0.5,
                                                // },
                                            }}
                                            onMouseEnter={() => {
                                                if (chat.excluir) {
                                                    setSliderExcluido(chat.id);
                                                } else if (chat.KEY_ATENDENTE && chat.KEY_ATENDENTE !== KEY_LICENSE) {
                                                    setSliderAtendente(chat.id);
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                if (chat.excluir) {
                                                    setSliderExcluido(null);
                                                } else if (chat.KEY_ATENDENTE && chat.KEY_ATENDENTE !== KEY_LICENSE) {
                                                    setSliderAtendente(null);
                                                }
                                            }}
                                            onClick={() => {
                                                if (!chat.excluir && (!chat.KEY_ATENDENTE || chat.KEY_ATENDENTE === KEY_LICENSE || KEY_MASTER)) {
                                                    onChatClick(chat.id);
                                                }
                                            }}
                                        >
                                            <Box

                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    transition: 'transform 0.3s ease',
                                                    transform: sliderOpen === chat.id ? 'translateX(-150px)' : 'translateX(0)',
                                                    // '@media (max-width: 768px)': {
                                                    //     flexDirection: 'column',
                                                    //     alignItems: 'flex-start',
                                                    // },
                                                }}
                                            >
                                                <img
                                                    src="/images/user.webp"
                                                    style={{
                                                        maxWidth: 35,
                                                        opacity: 1,
                                                        marginRight: 10,
                                                        // '@media (max-width: 768px)': {
                                                        //     maxWidth: 25,
                                                        //     marginRight: 5,
                                                        // },
                                                    }}
                                                    alt="User Avatar"
                                                />

                                                {sliderAtendente === chat.id && (
                                                    <Slide direction="left" in={sliderAtendente === chat.id} mountOnEnter unmountOnExit>
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                width: '200px',
                                                                height: '100%',
                                                                backgroundColor: '#1D1D1D',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                borderRadius: '0 4px 4px 0',
                                                                boxShadow: 3,
                                                                padding: 0.5,
                                                                zIndex: 1,
                                                            }}
                                                        >

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 10,
                                                                        fontWeight: 'bold',
                                                                        color: 'white',
                                                                        textTransform: 'uppercase',
                                                                        marginBottom: 1,
                                                                    }}
                                                                >
                                                                    {chat.atendente}
                                                                </Typography>

                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 10,
                                                                        color: 'white',
                                                                        marginTop: '-10px'
                                                                    }}
                                                                >
                                                                    Iniciado às {chat.datecheck}
                                                                </Typography>
                                                            </Box>
                                                            <PersonPinIcon sx={{ marginRight: 0, fontSize: 25 }} />

                                                        </Box>
                                                    </Slide>

                                                )}

                                                {sliderExcluido === chat.id && (
                                                    <Slide direction="left" in={sliderExcluido === chat.id} mountOnEnter unmountOnExit>
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                width: '230px',
                                                                height: '100%',
                                                                backgroundColor: '#1D1D1D',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                borderRadius: '0 4px 4px 0',
                                                                boxShadow: 3,
                                                                padding: 1,
                                                                zIndex: 1,
                                                            }}
                                                        >
                                                            <PersonPinIcon sx={{ marginRight: 2, fontSize: 25 }} />

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 11,
                                                                        fontWeight: 'bold',
                                                                        color: 'white',
                                                                        textTransform: 'uppercase',
                                                                        marginBottom: 1,
                                                                    }}
                                                                >
                                                                    {chat.atendentefinish}
                                                                </Typography>

                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 10,
                                                                        color: 'white',
                                                                        marginTop: '-10px'
                                                                    }}
                                                                >
                                                                    Excluido às {chat.datefinish}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Slide>

                                                )}

                                                {sliderOpen !== chat.id && (
                                                    <>
                                                        <Box sx={{ flexGrow: 1 }}>

                                                            <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'white' }}>
                                                                {chat.nome === 'DESCONHECIDO' ? formatarId(chat.id) : chat.nome}{' '}
                                                                {!chat.visualizado && (
                                                                    <AnnouncementIcon sx={{ color: '#00d11b', fontSize: 16 }} />
                                                                )}
                                                            </Typography>


                                                            <Typography
                                                                sx={{
                                                                    fontSize: 12,
                                                                    color: '#8c8c8c',

                                                                }}
                                                            >
                                                                {chat.KEY_ATENDENTE && chat.KEY_ATENDENTE !== KEY_LICENSE
                                                                    ? 'Usuário em atendimento'
                                                                    : chat.msg.length > 25
                                                                        ? chat.msg.substring(0, 25) + '...'
                                                                        : chat.msg}
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                )}


                                                {sliderOpen === chat.id ? (<>
                                                    <IconButton
                                                        onClick={() => handleCopyClick(formatarTelefone(chat.id))}
                                                        sx={{
                                                            marginLeft: 13,
                                                            color: 'white',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}

                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>

                                                    <Typography sx={{ fontSize: 11, fontWeight: 'bold', color: 'white' }}>

                                                        {formatarTelefone(chat.id)}
                                                    </Typography>


                                                </>) : (<>
                                                    {chat.mutado && (<NotificationsOffIcon sx={{ fontSize: 16, marginRight: '1px' }} />)}
                                                    <Typography
                                                        sx={{
                                                            fontSize: 10,
                                                            color: '#6d6d6d',
                                                            fontWeight: 'bold',
                                                            textAlign: 'right',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {chat.data && (() => {
                                                            const now = new Date();
                                                            const [dia, mes, ano] = chat.data.split('/');
                                                            const [horas, minutos, segundos] = chat.hora.split(':');
                                                            const chatDateTime = new Date(ano, mes - 1, dia, horas, minutos, segundos);
                                                            return chatDateTime.toDateString() === now.toDateString() ? chat.hora : chat.data;
                                                        })()}


                                                    </Typography>

                                                </>)}

                                                {sliderOpen !== chat.id && (
                                                    <Tooltip title="Abrir opções" arrow>
                                                        <IconButton
                                                            sx={{
                                                                marginLeft: 1,
                                                                marginRight: '-4px',
                                                                width: 25,
                                                                height: 38,
                                                                borderRadius: 1,
                                                                transition: 'transform 0.3s ease, box-shadow 0.4s ease',
                                                                '&:hover': {
                                                                    transform: 'scale(1.3)',
                                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                                                                    backgroundColor: '#1D1D1D'
                                                                }
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSliderOpen(sliderOpen === chat.id ? null : chat.id);
                                                            }}
                                                        >
                                                            <KeyboardDoubleArrowLeftIcon sx={{ color: 'white' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>

                                            {sliderOpen === chat.id && chat.excluir === false && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        width: '155px',
                                                        height: '100%',
                                                        backgroundColor: '#2c2c2c',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderRadius: '0 4px 4px 0',
                                                        boxShadow: 3,
                                                        padding: 0.8,
                                                        zIndex: 1,
                                                        transform: 'translateX(0)',
                                                    }}
                                                >
                                                    <Tooltip title={chat.mutado ? (<>Ativar Notificações</>) : (<>Desativar Notificações</>)} arrow>
                                                        <span>
                                                            <IconButton onClick={() => handleMuted(chat.id)} sx={{ color: 'white' }}>
                                                                {chat.mutado ? (<><NotificationsActiveIcon /></>) : (<> <NotificationsPausedIcon /></>)}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>

                                                    <Tooltip title={chat.arquivado ? (<>Desarquivar conversa</>) : (<>Arquivar conversa</>)} arrow>
                                                        <IconButton onClick={() => handleArchive(chat.id)} sx={{ color: 'white' }}>
                                                            {chat.arquivado ? (<><UnarchiveIcon /></>) : (<> <ArchiveIcon /></>)}

                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Excluir conversa" arrow>
                                                        <IconButton onClick={() => handleDelete(chat.id)} sx={{ color: 'white' }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Fechar" arrow>
                                                        <IconButton
                                                            sx={{
                                                                marginLeft: 1,
                                                                marginRight: '-4px',
                                                                width: 25,
                                                                height: 40,
                                                                borderRadius: 1,
                                                                transition: 'transform 0.3s ease, box-shadow 0.4s ease',
                                                                '&:hover': {
                                                                    transform: 'scale(1.3)',
                                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                                                                    backgroundColor: '#1D1D1D'
                                                                }
                                                            }}
                                                            onClick={() => setSliderOpen(null)}
                                                        >
                                                            <KeyboardDoubleArrowRightIcon sx={{ color: 'white' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Box>
                                    </Slide>
                                ))}
                            </>
                        )}
                    </Box>

                </Box>
            </Box >

        </>
    );
};

export default Chats;
