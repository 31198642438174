import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,

  MenuItem,

  TextField,

  Autocomplete,
  Divider,
} from "@mui/material";
import { IconButton } from "@mui/material";

import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";

import 'react-calendar/dist/Calendar.css';

import firebaseApp, { BASE_PROD, KEY } from '../../../integration/FirebaseConfig';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ClearIcon from '@mui/icons-material/Clear';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { allSpecialties, datetime } from "../../../services/script";

const database = getDatabase(firebaseApp);

function gerarID() {
  const numeroAleatorio = Math.floor(1000000000000 + Math.random() * 9000000000000);
  return numeroAleatorio.toString();
}




const NovaInstituicao = () => {

  const navigate = useNavigate();

  const [buscaCPF, setBuscaCPF] = useState('');
  const [isDateFocused, setDateFocused] = useState(false);
  const [nome, setNome] = useState('');
  const [nomeInstituicao, setNomeInstituicao] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [procedimentos, setProcedimentos] = useState('');
  const [cep, setCep] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [cargo, setCargo] = useState('');
  const [categoria, setCategoria] = useState(allSpecialties[0]);
  const [userData, setUserData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState([]);
  const [procedimentosSelecionados, setProcedimentosSelecionados] = useState([]);


  const checkCEP = async (event) => {
    const novoCEP = event.target.value.replace(/\D/g, '').slice(0, 8);
    console.log(novoCEP);
    try {
      const response = await fetch(`https://viacep.com.br/ws/${novoCEP}/json/`);
      const data = await response.json();
      console.log(data);
      if (!data.erro) {
        setEstado(data.uf);
        setCidade(data.localidade);
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
      } else {
        setEstado("");
        setCidade("");
        setLogradouro("");
        setBairro("");
        console.error("CEP não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };


  const handleCEPChange = (event) => {
    let novoCEP = event.target.value;
    novoCEP = novoCEP.replace(/\D/g, '').slice(0, 8);
    setCep(novoCEP);
    if (novoCEP.length === 8) {
      checkCEP(event);
    }
  };



  const handleCategoriaChange = (event, value) => {
    if (value && !especialidadesSelecionadas.includes(value)) {
      setEspecialidadesSelecionadas([...especialidadesSelecionadas, value]);
    }
    setCategoria('');
  };
  const handleRemoveEspecialidade = (especialidade) => {
    const updatedEspecialidades = especialidadesSelecionadas.filter(item => item !== especialidade);
    setEspecialidadesSelecionadas(updatedEspecialidades);
  };

  const handleProcedimentosChange = (event) => {
    const selectedCategory = event.target.value;
    if (!procedimentosSelecionados.includes(selectedCategory)) {
      setProcedimentosSelecionados([...procedimentosSelecionados, selectedCategory]);
    }
    setProcedimentos('');
  };

  const handleRemoveProcedimentos = (procedimento) => {
    const updatedEspecialidades = procedimentosSelecionados.filter(item => item !== procedimento);
    setProcedimentosSelecionados(updatedEspecialidades);
  };

  const handleTelefoneChange = (event) => {
    let { value } = event.target;


    value = value.slice(0, 14);

    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
    setTelefone(formattedValue);
  };

  const handleCPFChange = (event) => {
    let { value } = event.target;


    value = value.replace(/\D/g, '');

    const formattedValue = value
      .slice(0, 11)
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    setBuscaCPF(formattedValue);
  };

  const handleBuscarPaciente = async () => {
    try {
      const database = getDatabase();
      const snapshot = await get(child(ref(database), `usuarios`));
      let userFound = false;
      snapshot.forEach((childSnapshot) => {
        const user = childSnapshot.val();
        if (user.cpf === buscaCPF) {
          setUserData(user);
          setShowForm(true);
          userFound = true;
          console.log("Paciente encontrado");

          setNome(user.nome);

          setCpf(user.cpf);

          setEmail(user.email);

          setCargo(user.categoria);


        }
      });

      if (!userFound) {
        setUserData(null);
        setShowForm(false);
        console.log("Paciente não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar paciente:", error);
    }
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleBuscarPaciente()
      console.log('Buscando paciente com CPF:', buscaCPF);
    }
  };



  const handleSalvar = async () => {
    const uid = gerarID();
    const db = ref(database, `${BASE_PROD}/${KEY}/instituicoes/` + uid);

    const especialidadesFormatadas = especialidadesSelecionadas.reduce((acc, especialidade) => {
      const especialidadeId = gerarID(); 
      acc[especialidadeId] = {
        nome: especialidade,
        vagas: 0,
        preenchido: 0,
        id: especialidadeId
      };
      return acc;
    }, {});

    await set(db, {
      nome: nomeInstituicao,
      telefone: telefone,
      localidade,
      cep,
      estado,
      datetime: datetime(),
      cidade,
      logradouro,
      numero,
      complemento,
      bairro,
      id: uid,
      especialidade: especialidadesFormatadas,
      procedimentos: procedimentosSelecionados,
    });

    navigate(`/instituicoes`);
  };






  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
      <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left"> CADASTRO DE NOVA INSTITUIÇÃO</Divider>



      {showForm && userData ? (
        <>

          <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17 }}> Cadastrante</Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Nome"
                  fullWidth
                  value={nome}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>


              <Grid item xs={12} sm={3}>
                <TextField
                  label="CPF"
                  fullWidth
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  sx={{ bgcolor: '#3338' }}

                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>



              <Grid item xs={12} sm={3}>
                <TextField
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Cargo"
                  fullWidth
                  value={cargo}
                  onChange={(e) => setCargo(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },

                  }}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#686868', fontWeight: 'bold' }
                  }}
                />
              </Grid>


            </Grid>
          </form>

          <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}> Dados da Instituição</Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome da Instituição"
                  fullWidth
                  value={nomeInstituicao}
                  onChange={(e) => setNomeInstituicao(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Telefone"
                  fullWidth
                  sx={{ bgcolor: '#3338' }}
                  value={telefone}
                  onChange={handleTelefoneChange}
                  onFocus={() => setDateFocused(true)}
                  onBlur={() => setDateFocused(false)}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: isDateFocused,
                  }}
                />
              </Grid>


              <Grid item xs={12} sm={4}>
                <TextField
                  label="Localidade"
                  select
                  fullWidth
                  sx={{ bgcolor: '#3338' }}
                  value={localidade}
                  onChange={(event) => setLocalidade(event.target.value)}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="Zona Urbana">Zona Urbana</MenuItem>
                  <MenuItem value="Zona Rural">Zona Rural</MenuItem>
                </TextField>
              </Grid>




              <Grid item xs={12} sm={1}>
                <TextField
                  label="CEP"
                  fullWidth
                  value={cep}
                  onChange={handleCEPChange}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <TextField
                  label="UF"
                  fullWidth
                  value={estado}
                  onChange={(e) => setEstado(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  label="Município"
                  fullWidth
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Endereço"
                  fullWidth
                  value={logradouro}
                  onChange={(e) => setLogradouro(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <TextField
                  label="Complemento"
                  fullWidth
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  label="Número"
                  fullWidth
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  label="Bairro"
                  fullWidth
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>


            </Grid>
          </form>

          <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}>Especialidades e Procedimentos</Typography>

          <form>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={allSpecialties}
                  value={categoria}
                  onChange={(event, value) => handleCategoriaChange(event, value)}
                  inputValue={categoria}
                  onInputChange={(event, newInputValue) => {
                    setCategoria(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Especialidades"
                      sx={{ bgcolor: '#3338' }}
                      InputLabelProps={{
                        style: { color: 'white' },
                        focused: false,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Procedimentos"
                  select
                  fullWidth
                  sx={{ bgcolor: '#3338' }}
                  value={procedimentos}
                  onChange={handleProcedimentosChange}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="Consulta">Consulta</MenuItem>
                  <MenuItem value="Cirugia">Cirugia</MenuItem>
                  <MenuItem value="Exames">Exames</MenuItem>
                  <MenuItem value="Retorno">Retorno</MenuItem>
                  <MenuItem value="Outros">Outros</MenuItem>


                </TextField>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  {especialidadesSelecionadas.map((especialidade, index) => (
                    <Grid item xs={12} sm={4} key={index} >

                      <Typography style={{
                        margin: '0.5em',
                        fontWeight: 'bold',
                        backgroundColor: "transparent",
                        border: "1px solid #FF4B00",
                        display: 'flex',
                        maxWidth: 220,
                        padding: 5,
                        borderRadius: 10,
                        alignItems: 'center', textAlign: 'center', justifyContent: 'center'
                      }}>



                        <IconButton style={{ fontSize: 13, borderRadius: 4, fontWeight: 'bold' }} onClick={() => handleRemoveEspecialidade(especialidade)}>
                          {especialidade}
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                <Grid container spacing={1} style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                  {procedimentosSelecionados.map((procedimento, index) => (
                    <Grid item xs={12} sm={4} key={index} style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>

                      <Typography style={{
                        margin: '0.5em',
                        fontWeight: 'bold',
                        backgroundColor: "transparent",
                        border: "1px solid #FF4B00",
                        display: 'flex',
                        maxWidth: 220,
                        padding: 5,
                        borderRadius: 10,
                        alignItems: 'center', textAlign: 'center', justifyContent: 'center'
                      }}>



                        <IconButton style={{ fontSize: 13, borderRadius: 4, fontWeight: 'bold' }} onClick={() => handleRemoveProcedimentos(procedimento)}>
                          {procedimento}
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              

              <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
                <Button
                  style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                  onClick={handleSalvar}
                >
                  <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                  CONCLUIR
                </Button>
              </Grid>


            </Grid>
          </form>


        </>
      ) : (
        <Grid container spacing={2} style={{ justifyContent: "center", alignContent: 'center', alignItems: 'center' }}>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Digite seu CPF"
              fullWidth
              value={buscaCPF}
              onChange={handleCPFChange}
              onKeyPress={handleKeyPress}
              sx={{ bgcolor: '#3338' }}
              InputLabelProps={{
                style: { color: 'white' },
                focused: false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button onClick={handleBuscarPaciente} style={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00", fontWeight: 'bold',
              color: 'white', alignContent: 'center',
              alignItems: 'center', padding: 10
            }}>
              <DoneOutlineIcon />
            </Button>
          </Grid>
        </Grid>
      )
      }
    </Box >
  );
};

export default NovaInstituicao;
