import { getAuth, signOut } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/storage"
import axios from 'axios';
import { getUserDate } from './UserDate';
import { tokenCrypt } from '../services/script';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage();
export const analytics = getAnalytics(firebaseApp);
export const secretKey = process.env.REACT_APP_SECRET_KEY;
export const UPDATE = process.env.REACT_APP_VERSION;
export const url_api_gzappy = process.env.REACT_APP_URL_API_GZAPPY;
export const url_api_gzappy_media = process.env.REACT_APP_URL_API_GZAPPY_SEND_MEDIA;
export const API_IBGE = process.env.REACT_APP_API_IBGE;
export const ROTA_MANUTENCAO = process.env.REACT_APP_ROTA_MANUTENCAO;
export const ROTA_USUARIOS = process.env.REACT_APP_ROTA_USUARIOS;
export const ROTA_CIDADES = process.env.REACT_APP_ROTA_CIDADES;
export const BASE_PROD = process.env.REACT_APP_BASE_PROD;
export const API_DEV = process.env.REACT_APP_API_DEV;
export const YEAR = new Date().getFullYear();

auth.onAuthStateChanged(async user => {
    if (user) {
        try {
            const userId = user.uid;
            const email = user.email;
            const token = await user.getIdToken();
            const result = tokenCrypt(userId, email, token)

            const response = await axios.get(`${API_DEV}/user-data`, {
                headers: {
                    Authorization: `Bearer ${result}`
                }
            });

            if (response.status === 200) {
                getUserDate(response.data);
            } else {
                signOut(auth);
            }
        } catch (error) {

            if (error.response) {
                if (error.response.status === 401) {
                    signOut(auth);
                }
                console.error('Erro no status:', error.response.status);
            } else if (error.request) {
                console.error('Erro na requisição:', error.request);

            } else {
                console.error('Erro na mensagem:', error);
            }

            signOut(auth);
        }
    }
});



export * from './UserDate'
export default firebaseApp;
