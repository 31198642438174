import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, ref, update } from 'firebase/database'; 
import firebaseApp from '../../integration/FirebaseConfig';
import { generateRandomToken, reloadPage } from '../../services/script';

const Deslogar = async (navigate) => {
    const auth = getAuth(firebaseApp);
    const database = getDatabase(firebaseApp); 

    const user = auth.currentUser;

    if (user) {
        const userId = user.uid;
        const randomToken = generateRandomToken();
        const userRef = ref(database, `usuarios/${userId}`);

        const cacheKeys = [
            'cacheEspecialidadesTimestamp',
            'cacheCalendar',
            'cachePacientes',
            'cacheSolicitadosAnalise',
            'cacheSolicitadosAguardandoVagas',
            'cacheSolicitadosCanceladas',
            'cacheTimestamp',
            'cacheSolicitadosEmProcesso',
            'cacheSolicitadosCompareceu',
            'cacheSolicitadosFalta',
            'cacheUpdateDate', 
            'cacheCardComponent',
            'cacheTotalConsultas',
            'cacheAvaliacoes',
            'cacheAbsenteismo',
            'cacheDiasMaisSolicitados',
            'cacheTotalMarcadas',
            'cacheIdadesFaltantes',
            'cacheCategories',
            'cacheDiasComFaltas',
            'cacheIdades',
            'cacheEspecialidades',
            'cacheCalendarTimestamp',
            'cacheEndDate',
            'cacheStartDate',
            'cacheSolicitadosCanceladasUser',
            'cacheStartDateMarcados',
            'cacheSolicitadosDadosDivergente',
            'cacheSolicitados',
            'cacheEndMarcados',
            'cachePrioridadeCalendar'
        ];

        await cacheKeys.forEach(key => localStorage.removeItem(key));

        console.log('Cache limpo');

        update(userRef, {
            token: randomToken
        }).then(() => {
            signOut(auth).then(() => {
                console.log('Logout bem-sucedido');

                
                reloadPage();

            }).catch((error) => {
                console.error('Erro ao fazer logout:', error);
            });

        }).catch((error) => {
            console.error('Erro ao atualizar o token:', error);
        });
    }
};

export default Deslogar;
