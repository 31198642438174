import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { KEY, API_DEV, auth } from '../../../integration/FirebaseConfig';
import ReactFrappeChart from "react-frappe-charts";
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";


const Avaliacoes = ({ onUpdate, integridade }) => {
    const [avaliacoesData, setAvaliacoes] = useState([0, 0, 0, 0, 0, 0]);

    const fetchData = async () => {


        try {
            const userId = auth.currentUser.uid
            const email = auth.currentUser.email
            const token = await auth.currentUser.getIdToken();
            const result = tokenCrypt(userId, email, token)

            const response = await axios.get(`${API_DEV}/dashboard/avaliacoes`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { cidade: KEY }
            });

            if (response.status === 200) {
                const { avaliacoes } = response.data;
                integridade(100)
                localStorage.setItem("cacheAvaliacoes", JSON.stringify({ avaliacoes: avaliacoes }));
                setAvaliacoes(avaliacoes);
            } else {
                integridade(10)
            }

        } catch (error) {
            setAvaliacoes([0, 0, 0, 0, 0, 0])
            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }


    };

    useEffect(() => {
        const cachedData = localStorage.getItem("cacheAvaliacoes");
        if (onUpdate === false) {
            if (cachedData) {
                const cached = JSON.parse(cachedData);
                setAvaliacoes(cached.avaliacoes);
                integridade(100)
            } else {
                integridade(0)
            }
        } else if (onUpdate === true) {
            fetchData();
        }
    }, [onUpdate]);

    return (
        <Box display="inline-block" gridColumn="span 3" gridRow="span 2" backgroundColor={'#1d1d1d'} borderRadius={5} height="300px">




            <Typography
                variant="h6"
                fontWeight="600"
                style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-25px', marginTop: 10 }}
            >
                Avaliações
            </Typography>
            <ReactFrappeChart
                type="bar"
                colors={['#FFA00A']}
                animate={0}

                axisOptions={{ xAxisMode: 'tick', yAxisMode: 'tick', xIsSeries: 1 }}
                height={300}
                data={{

                    labels: ['5 ☆', '4 ☆', '3 ☆', '2 ☆', '1 ☆', '0 ☆'],
                    datasets: [
                        {
                            name: " Qntd. Estrelas",
                            values: avaliacoesData,
                            chartType: "bar",
                        },

                    ],
                }}
            />


        </Box>
    );
};

export default Avaliacoes;
