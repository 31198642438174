import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Backdrop,
  Grid,
  CircularProgress,
  Menu
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid'
import { theme2, tokens } from "../../theme";
import Header from "../../utils/HeaderSolicitados";
import { useEffect } from 'react';
import { getDatabase, ref, get, update, push } from '@firebase/database';
import { IconButton } from "@mui/material";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import firebaseApp, { KEY, BASE_PROD, KEY_LICENSE, url_api_gzappy, KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, KEY_NAME_USER } from '../../integration/FirebaseConfig';
import ChatIcon from '@mui/icons-material/Chat';
import InfoIcon from '@mui/icons-material/Info';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as XLSX from 'xlsx';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import GetAppIcon from '@mui/icons-material/GetApp';
import { formatarTelefone, getPrioridadeLabel } from "../../services/script";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR as dataGridPtBR } from '@mui/x-data-grid';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsIcon from '@mui/icons-material/Settings';
import { ThemeProvider } from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';
import { useFuncoes } from "../../services/funcoes";

const database = getDatabase(firebaseApp);


const Solicitados = () => {

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showAdditionalCheckboxes, setShowAdditionalCheckboxes] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const [cacheSolicitadosAnalise, setCacheSolicitadosAnalise] = useState([]);
  const [cacheSolicitadosAguardandoVagas, setCacheSolicitadosAguardandoVagas] = useState([]);
  const [cacheSolicitadosCanceladas, setCacheSolicitadosCanceladas] = useState([]);
  const [cacheSolicitadosCanceladasUser, setCacheSolicitadosCanceladasUser] = useState([]);
  const [cacheSolicitadosDadosDivergente, setCacheSolicitadosDadosDivergente] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 999));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    logradouro: false,
    nome: false,
    email: false,
    cpf: false,
    cns: false,
    numero: false,
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [additionalColumns, setAdditionalColumns] = useState([]);
  const [columns, setColumns] = useState([
    { field: "protocolo", headerName: "Protocolo", flex: 0.5 },
    {
      field: "nome",
      headerName: "Nome Completo",
      flex: 0.8,
      cellClassName: "name-column--cell",
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "categoria",
      headerName: "Categoria",
      flex: 0.4,
      valueGetter: (params) => params.row.categoria.toUpperCase(),
    },
    {
      field: "datetime",
      headerName: "Data Solicitação",
      flex: 0.4,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
    },

    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",

    }
  ]);


  const {
    funcRelatoriosSolicitacoes,
    funcComunicacaoChat, funcMarcacao,
    funcBaixarSolicitacoes,
    funcAtualizarSolicitacoes,
    funcConfigTabelaSolicitacoes
  } = useFuncoes();


  useEffect(() => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        if (col.field === 'edit') {
          return {
            ...col,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => handleAccessClick(row)}
                  sx={{
                    backgroundColor: colors.tabelas.backTable,
                    border: "1px solid #1D1D1D",
                    borderRadius: '4px',
                    padding: '8px',
                    fontSize: 13,
                    fontWeight: 'bold',
                    marginLeft: 1
                  }}
                >
                  <InfoIcon />
                </IconButton>
                {funcBaixarSolicitacoes && (
                  <IconButton
                    onClick={() => handleDownloadPDF(row)}
                    sx={{
                      backgroundColor: colors.tabelas.fundoIcon,
                      borderRadius: '4px',
                      border: "1px solid #242424",
                      padding: '8px',
                      marginLeft: '8px',
                    }}
                  >
                    <GetAppIcon />
                  </IconButton>
                )}


                {funcComunicacaoChat && (
                  <IconButton
                    onClick={() => handleChatClick(row.telefone)}
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid #FF4B00",
                      borderRadius: '4px',
                      padding: '8px',
                      marginLeft: '8px',
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}
                  >
                    <ChatIcon style={{ color: colors.tabelas.textWhite, marginRight: 5 }} />
                    CHAT
                  </IconButton>
                )}

                {funcMarcacao && (<IconButton
                  onClick={() => handleAdd(row)}
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid #FF4B00",
                    borderRadius: '4px',
                    padding: '8px',
                    marginLeft: '8px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    opacity: row.status === 'Cancelado pela Instituição' || row.status === 'Cancelado pelo usuário' || row.status === 'Dados divergentes' ? 0.5 : 1,
                    pointerEvents: row.status === 'Cancelado pela Instituição' || row.status === 'Cancelado pelo usuário' || row.status === 'Dados divergentes' ? 'none' : 'auto',
                  }}
                >
                  <NoteAddIcon style={{ color: colors.tabelas.textWhite }} />
                  MARCAR
                </IconButton>)}

              </Box>
            ),
          };
        }
        return col;
      })
    );
  }, [funcComunicacaoChat, funcMarcacao, funcBaixarSolicitacoes]);


  const additionalColumnDetails = [
    { field: 'bairro', headerName: 'Bairro', flex: 0.5 },
    { field: 'cep', headerName: 'CEP', flex: 0.5 },
    { field: 'cidade', headerName: 'Cidade', flex: 0.5 },
    { field: 'cns', headerName: 'CNS', flex: 0.5 },
    { field: 'cpf', headerName: 'CPF', flex: 0.5 },
    { field: 'datanascimento', headerName: 'Data de Nascimento', flex: 0.5 },
    { field: 'email', headerName: 'Email', flex: 0.5 },
    { field: 'estado', headerName: 'Estado', flex: 0.5 },
    { field: 'logradouro', headerName: 'Logradouro', flex: 0.5 },
    { field: 'numero', headerName: 'Número', flex: 0.5 },
    { field: 'rg', headerName: 'RG', flex: 0.5 },
    { field: 'telefone', headerName: 'Telefone', flex: 0.5 },
    {
      field: 'turno', headerName: 'Turno Preferência', flex: 0.5, renderCell: (params) => (
        <div>
          {params.value === 'Manhã' ? (<Typography>Manhã</Typography>) : (<Typography>Tarde</Typography>)}
        </div>
      )
    },
  ];

  const additionalColumnOptions = [
    'cpf',
    'rg',
    'Cartão do SUS',
    'data de nascimento',
    'telefone',
    'email',
    'logradouro',
    'numero',
    'bairro',
    'cidade',
    'cep',
    'estado',
    'turno',
  ];

  const handleAdditionalColumnChange = (event) => {
    let { value } = event.target;

    if (value === 'Código SISREG') {
      value = 'codigoSisREG';
    } else if (value === 'data de nascimento') {
      value = 'datanascimento';
    } else if (value === 'data de solicitação') {
      value = 'datetime'
    } else if (value === 'Cartão do SUS') {
      value = 'cns'
    }

    setAdditionalColumns((prevAdditionalColumns) => {
      const updatedColumns = prevAdditionalColumns.includes(value)
        ? prevAdditionalColumns.filter(col => col !== value)
        : [...prevAdditionalColumns, value];


      const fixedColumns = columns.filter(col => col.field === 'protocolo' || col.field === 'nome');


      const newAdditionalColumns = additionalColumnDetails.filter(col =>
        updatedColumns.includes(col.field)
      );

      const existingColumns = columns.filter(col => col.field !== 'protocolo' && col.field !== 'nome' && !additionalColumnDetails.find(addCol => addCol.field === col.field));

      setColumns([
        ...fixedColumns,
        ...newAdditionalColumns,
        ...existingColumns
      ]);

      return updatedColumns;
    });
  };

  const handleCheck = (option) => {

    let { value } = option;

    if (value === 'Código SISREG') {
      value = 'codigoSisREG';
    } else if (value === 'data de nascimento') {
      value = 'datanascimento';
    } else if (value === 'data de solicitação') {
      value = 'datetime'
    } else if (value === 'Cartão do SUS') {
      value = 'cns'
    }

    additionalColumns.includes(value)
  };


  const handleSendMessage = async (userData, status) => {

    const phoneNumber = userData.telefone;
    let message = `Olá, ${userData.nome}.\n\nPassando para informar que seu protocolo de N° ${userData.protocolo} foi atualizado pela Instituição, o seu status atual é ${(status).toUpperCase()}.`;


    await fetch(url_api_gzappy, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user_token_id': `${KEY_USER_TOKEN_ID}`
      },
      body: JSON.stringify({
        instance_id: `${KEY_INSTANCE_ID}`,
        instance_token: `${KEY_INSTANCE_TOKEN}`,
        message: [`${message}`],
        phone: [`${phoneNumber}`]
      })
    })

    const messageData = {
      hora: new Date().toLocaleTimeString(),
      data: new Date().toLocaleDateString(),
      enviado: 'Atendente',
      msg: `${message}`,
      atendente: `${KEY_NAME_USER}`

    };


    const chatRef = ref(database, `/${BASE_PROD}/${KEY}/chats/${phoneNumber}/conversas`);
    push(chatRef, messageData)
      .then(() => {
        console.log('menssagem enviada')
      })
      .catch((error) => {
        console.error('Erro ao enviar mensagem:', error);
      });

  }

  const handleDownloadPDF = (selectedUser) => {
    const doc = new jsPDF({
      unit: 'mm',
      format: [210, 297]
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const canvasWidth = 1240;
    const canvasHeight = 1748;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const backgroundImagePath = '/images/background.webp';
    const backgroundImage = new Image();
    backgroundImage.src = backgroundImagePath;

    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);


      const backgroundImageDataURL = canvas.toDataURL('image/jpeg', 1);
      doc.addImage(backgroundImageDataURL, 'JPEG', 0, 0, pageWidth, pageHeight);


      doc.setFontSize(12);
      doc.text(`Solicitação  |  Nº ${selectedUser.protocolo}  |  ${selectedUser.categoria}  `, 15, 50);


      const tableStartY = 60;
      const tableWidth = pageWidth - 30;
      const tableHeight = doc.autoTableHtmlToJson(document.querySelector('table')).rows.length * 10 + 30;

      doc.setFillColor(255, 255, 255);
      doc.rect(15, tableStartY - 10, tableWidth, tableHeight, 'F');


      doc.autoTable({
        startY: tableStartY,
        head: [['Campo', 'Dados']],
        body: [
          ['Procedimento ', selectedUser.procedimento || ''],
          ['Turno', selectedUser.turno || ''],
          ['Protocolo', 'Nº ' + selectedUser.protocolo || ''],
          ['Status', selectedUser.status || ''],
          ['', ''],
          ['Nome', selectedUser.nome || ''],
          ['Data Nasc.', selectedUser.datanascimento || ''],
          ['CNS', selectedUser.cns || ''],
          ['CPF', selectedUser.cpf || ''],
          ['Telefone', selectedUser.telefone || ''],
          ['Email', selectedUser.email || ''],
          ['Logradouro', selectedUser.logradouro || ''],
          ['Número', selectedUser.numero || ''],
          ['Bairro', selectedUser.bairro || ''],
          ['Cidade', selectedUser.cidade || ''],
          ['CEP', selectedUser.cep || ''],
        ],
        headStyles: {
          fillColor: [255, 75, 0],
          textColor: [255, 255, 255],
        },
        didDrawCell: (data) => {
          if (data.section === 'head') {
            doc.setTextColor(255, 255, 255);
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            const textWidth = doc.getTextWidth(data.cell.text[0]);
            const textHeight = doc.internal.getLineHeight();
            const x = data.cell.x + (data.cell.width - textWidth) / 2;
            const y = data.cell.y + (data.cell.height / 2) + (textHeight / 2);
            doc.text(data.cell.text[0], x, y);
          }
        },
      });


      const lastY = doc.autoTable.previous.finalY;
      doc.setDrawColor(0, 0, 0);
      doc.line(35, lastY + 65, doc.internal.pageSize.getWidth() - 35, lastY + 65);


      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Assinatura do responsável", (pageWidth - doc.getTextWidth("Assinatura do responsável")) / 2, lastY + 70);

      const currentDate = new Date();
      const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
      const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
      const LICENSE = KEY_LICENSE.toUpperCase();

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(`Este documento foi gerado dia ${formattedDate} às ${formattedTime}, certificação: ${LICENSE}`, pageWidth - 30, lastY + 101, { align: 'right' });


      doc.save(`solicitacao_${selectedUser.protocolo}.pdf`);
    };

    backgroundImage.onerror = (error) => {
      console.error('Erro ao carregar a imagem de fundo:', error);
    };

  };


  const handleChatClick = (id) => {
    navigate(`/chat/paciente`, { state: { idClick: id } });
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleFilterApply = (newFilters) => {
    const filteredData = userData.filter((user) => {
      return Object.keys(newFilters).every((key) => {
        if (key === 'data') {
          const formattedFilterDate = newFilters[key] ? new Date(newFilters[key]).toISOString().substr(0, 10) : '';
          const formattedUserData = user[key] ? new Date(user[key]).toISOString().substr(0, 10) : '';
          return !formattedFilterDate || formattedUserData.includes(formattedFilterDate);
        }
        const filterValue = typeof newFilters[key] === 'string' ? removeAccents(newFilters[key].toLowerCase()) : newFilters[key];
        const userValue = typeof user[key] === 'string' ? removeAccents(user[key].toLowerCase()) : user[key];
        return !newFilters[key] || userValue.includes(filterValue);
      });
    });

    if (filteredData.length <= 0) {
      setShowNotification(true);
    }
    setFilteredTableData(filteredData);
  };

  useEffect(() => {
    const cachedStartDate = localStorage.getItem('cacheStartDate');
    const cachedEndDate = localStorage.getItem('cacheEndDate');

    if (cachedStartDate && cachedEndDate) {
      setStartDate(new Date(parseInt(cachedStartDate)));
      setEndDate(new Date(parseInt(cachedEndDate)));
    } else {
      setStartDate(new Date().setHours(0, 0, 0, 0));
      setEndDate(new Date().setHours(23, 59, 59, 999));
    }
  }, []);


  const handleStartDateChange = (newDate) => {
    const start = new Date(newDate).setHours(0, 0, 0, 0);
    const newEndDate = new Date(start).setHours(23, 59, 59, 999);
    setStartDate(start);
    setEndDate(newEndDate);
    localStorage.setItem('cacheStartDate', start.toString());
    localStorage.setItem('cacheEndDate', newEndDate.toString());
  };


  const handleEndDateChange = (newDate) => {
    const end = new Date(newDate).setHours(23, 59, 59, 999);
    const maxEndDate = new Date(startDate).setDate(new Date(startDate).getDate() + 8);

    if (end <= maxEndDate) {
      setEndDate(end);
      localStorage.setItem('cacheEndDate', end.toString());
    } else {

      alert("A data final não pode ser mais do que 8 dias após a data inicial.");
    }
  };


  const fetchEspecialidades = async () => {


    setIsSyncing(true);
    try {
      const usersRef = ref(database, `${BASE_PROD}/${KEY}/solicitados`);
      const snapshot = await get(usersRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const userList = Object.keys(data).map((userId) => {
          return { ...data[userId], id: userId };
        });

        const filteredUsers = userList.filter((user) => {
          const userDateTimeParts = user.datetime.split(', ');
          const userDateParts = userDateTimeParts[0].split('/');
          const userDate = new Date(userDateParts[2], userDateParts[1] - 1, userDateParts[0], 0, 0, 0);
          const userDateTime = new Date(userDate.setHours(userDateTimeParts[1].split(':')[0], userDateTimeParts[1].split(':')[1]));

          return userDateTime >= startDate && userDateTime <= endDate;
        });

        const falta = filteredUsers.filter(user => user.status === "Em Análise");
        const compareceu = filteredUsers.filter(user => user.status === "Aguardando vagas");
        const emProcesso = filteredUsers.filter(user => user.status === "Cancelado pela Instituição");
        const canceladoUser = filteredUsers.filter(user => user.status === "Cancelado pelo usuário");
        const DadosDivergente = filteredUsers.filter(user => user.status === "Dados divergentes");


        if (JSON.stringify(falta) === '[]') {
          if (JSON.stringify(compareceu) === '[]') {
            if (JSON.stringify(emProcesso) === '[]') {
              if (JSON.stringify(canceladoUser) === '[]') {
                if (JSON.stringify(DadosDivergente) === '[]') {
                  setUserData([]);
                } else {
                  setUserData(DadosDivergente);
                  setDocumentType("Dados divergentes");
                }
              } else {
                setUserData(canceladoUser);
                setDocumentType("Cancelado pelo usuário");
              }
            } else {
              setUserData(emProcesso);
              setDocumentType("Cancelado pela Instituição");
            }
          } else {
            setUserData(compareceu);
            setDocumentType("Aguardando vagas");
          }
        } else {
          setUserData(falta);
          setDocumentType("Em Análise");
        }



        setCacheSolicitadosAnalise(falta);
        setCacheSolicitadosAguardandoVagas(compareceu);
        setCacheSolicitadosCanceladas(emProcesso);
        setCacheSolicitadosCanceladasUser(canceladoUser);
        setCacheSolicitadosDadosDivergente(DadosDivergente)

        setTimeout(() => {
          setIsSyncing(false);
          setLoading(false);
          try {
            localStorage.setItem('cacheSolicitadosAnalise', JSON.stringify(falta));
            localStorage.setItem('cacheSolicitadosAguardandoVagas', JSON.stringify(compareceu));
            localStorage.setItem('cacheSolicitadosCanceladas', JSON.stringify(emProcesso));
            localStorage.setItem('cacheSolicitadosCanceladasUser', JSON.stringify(canceladoUser));
            localStorage.setItem('cacheSolicitadosDadosDivergente', JSON.stringify(DadosDivergente));

          } catch (error) {
            console.error("Erro ao armazenar dados no localStorage:", error);
          }
        }, 600);
      } else {
        setIsSyncing(false);
        setLoading(false);
        setCacheSolicitadosAnalise([]);
        setCacheSolicitadosAguardandoVagas([]);
        setCacheSolicitadosCanceladas([]);
        setCacheSolicitadosCanceladasUser([]);
        setCacheSolicitadosDadosDivergente([])
        localStorage.setItem('cacheSolicitadosAnalise', JSON.stringify([]));
        localStorage.setItem('cacheSolicitadosAguardandoVagas', JSON.stringify([]));
        localStorage.setItem('cacheSolicitadosCanceladas', JSON.stringify([]));
        localStorage.setItem('cacheSolicitadosCanceladasUser', JSON.stringify([]));
        localStorage.setItem('cacheSolicitadosDadosDivergente', JSON.stringify([]));

      }
    } catch (error) {
      console.error("Erro ao obter dados do Firebase:", error);
    }
  };


  useEffect(() => {
    setLoading(true);
    const cachedAnalise = localStorage.getItem('cacheSolicitadosAnalise');
    const cacheAguardandoVagas = localStorage.getItem('cacheSolicitadosAguardandoVagas');
    const cachedCanceladas = localStorage.getItem('cacheSolicitadosCanceladas');
    const cachedCanceladasUser = localStorage.getItem('cacheSolicitadosCanceladasUser');
    const cacheSolicitadosDadosDivergente = localStorage.getItem('cacheSolicitadosDadosDivergente');

    const cachedStartDate = localStorage.getItem('cacheStartDate');
    const cachedEndDate = localStorage.getItem('cacheEndDate');


    if (cachedAnalise && cacheAguardandoVagas && cacheSolicitadosDadosDivergente && cachedCanceladasUser && cachedCanceladas) {
      setCacheSolicitadosAnalise(JSON.parse(cachedAnalise));
      setCacheSolicitadosAguardandoVagas(JSON.parse(cacheAguardandoVagas));
      setCacheSolicitadosCanceladas(JSON.parse(cachedCanceladas));
      setCacheSolicitadosCanceladasUser(JSON.parse(cachedCanceladasUser))
      setCacheSolicitadosDadosDivergente(JSON.parse(cacheSolicitadosDadosDivergente))
      setUserData([
        ...JSON.parse(cachedAnalise),
        /*         ...JSON.parse(cacheAguardandoVagas),
                ...JSON.parse(cachedCanceladas),
                ...JSON.parse(cachedCanceladasUser),
                ...JSON.parse(cacheSolicitadosDadosDivergente), */
      ]);
      if (cachedStartDate && cachedEndDate) {
        setStartDate(new Date(parseInt(cachedStartDate)));
        setEndDate(new Date(parseInt(cachedEndDate)));
      }

      setLoading(false);
    } else {
      console.log('Buscando dados...');
      fetchEspecialidades();
    }
  }, []);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    if (e.target.value === 'Dados divergentes') {
      setShowAdditionalCheckboxes(true);
    } else {
      setShowAdditionalCheckboxes(false);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };



  const handleAdd = (user) => {
    navigate(`/marcar`, { state: { userData: user } });
  };


  const handleAccessClick = (row) => {
    setSelectedUser(row);
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setSelectedUser(null);
    setDialogOpen(false);
    setSelectedStatus("");
    setShowAdditionalCheckboxes(false);
    setSelectedCheckboxes({
      logradouro: false,
      nome: false,
      email: false,
      cpf: false,
      cns: false,
      numero: false,
    });


  };


  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
    switch (event.target.value) {
      case 'Em Análise':
        setUserData(cacheSolicitadosAnalise);
        break;
      case 'Aguardando vagas':
        setUserData(cacheSolicitadosAguardandoVagas);
        break;
      case 'Cancelado pela Instituição':
        setUserData(cacheSolicitadosCanceladas);
        break;
      case 'Cancelado pelo usuário':
        setUserData(cacheSolicitadosCanceladasUser);
        break;
      case 'Dados divergentes':
        setUserData(cacheSolicitadosDadosDivergente);
        break;
      default:
        setUserData(cacheSolicitadosAnalise);
        break;
    }
  };

  const handleDownloadXLSX = () => {
    setIsDownload(true);
    let xlsxData;

    if (filteredTableData.length > 0) {
      xlsxData = filteredTableData.map(row => {
        const data = {};
        columns.forEach(column => {
          data[column.headerName] = row[column.field];
        });
        return data;
      });
    } else {
      xlsxData = userData.map(row => {
        const data = {};
        columns.forEach(column => {
          data[column.headerName] = row[column.field];
        });
        return data;
      });
    }

    setTimeout(() => {
      const ws = XLSX.utils.json_to_sheet(xlsxData);


      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = ws[XLSX.utils.encode_cell({ r: 0, c: C })];
        if (cell) {
          cell.s = {
            fill: { fgColor: { rgb: "FF4B00" } },
            font: { bold: true, color: { rgb: "FFFFFF" } }
          };
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');

      const xlsxBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([xlsxBuffer], { type: 'application/octet-stream' }), 'RELATORIO_SOLICITADOS.xlsx');
      setIsDownload(false);
    }, 600);
  };

  const handleSaveStatus = async () => {
    if (selectedUser && selectedStatus) {
      const userId = selectedUser.protocolo;
      const userRef = ref(database, `${BASE_PROD}/${KEY}/solicitados/${userId}`);
      const updates = { status: selectedStatus };


      let dadosDivergentes = {};
      if (selectedStatus === 'Dados divergentes') {
        for (const [key, value] of Object.entries(selectedCheckboxes)) {
          if (value) {
            dadosDivergentes[key] = true;
          }
        }
        updates.dadosDivergentes = dadosDivergentes;
      }

      try {
        await update(userRef, updates)
          .then(() => {
            handleSendMessage(selectedUser, selectedStatus);
          })
          .catch(error => {
            console.error("Erro ao atualizar:", error);
          });


        if (selectedStatus === 'Dados divergentes') {
          const userPhone = selectedUser.telefone.replace(/[^\d]/g, '');
          const userRefDivergente = ref(database, `${BASE_PROD}/${KEY}/users/${userPhone}/`);
          const userRefDados = ref(database, `${BASE_PROD}/${KEY}/users/${userPhone}/dadosdivergentes/`);
          const userRefDb = ref(database, `${BASE_PROD}/${KEY}/users/${selectedUser.telefone}/solicitacoes/${userId}`);


          await update(userRefDivergente, { divergente: true, divergenteSend: true });
          await update(userRefDb, { status: 'Dados divergentes' });
          await update(userRefDados, dadosDivergentes);

        }


        handleCloseDialog();
      } catch (error) {
        console.error('Erro ao salvar status:', error);
      }
    }
  };





  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <HelmetProvider>    <Helmet>
        <title>Painel DHF - Solicitações</title>
      </Helmet></HelmetProvider>

      <Box m="10px">


        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

          </Box>
        </Backdrop>

        <Header title="SOLICITAÇÕES"
          onFilterApply={handleFilterApply}></Header>


        <div className="calendar-page">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsPtBR}>

            <Box display="flex" alignItems="center" backgroundColor="#1d1d1d" sx={{ paddingRight: 3 }}>


              <FormControl variant="filled" fullWidth style={{ backgroundColor: '#2c2c2c', color: '#d3d3d3', maxWidth: "80%" }}>
                <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Tipo de Solicitação</InputLabel>
                <Select
                  value={documentType}
                  onChange={handleDocumentTypeChange}
                  inputProps={{
                    id: 'document-type-select',
                  }}
                  style={{ backgroundColor: '#2c2c2c', color: '#d3d3d3', padding: 0 }}
                >
                  <MenuItem value="Aguardando vagas">Exibir todas aguardando vagas</MenuItem>
                  <MenuItem value="Cancelado pela Instituição">Exibir canceladas pela Instituição</MenuItem>
                  <MenuItem value="Cancelado pelo usuário">Exibir Canceladas pelo usuário</MenuItem>
                  <MenuItem value="Dados divergentes">Dados divergentes</MenuItem>
                  <MenuItem value="Em Análise">Exibir todas em Análise</MenuItem>
                </Select>
              </FormControl>

              <ThemeProvider theme={theme2}>
                <DatePicker
                  label="Data de Início"
                  value={startDate}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} style={{ marginLeft: 30, marginRight: 10 }} />}
                  format="dd/MM/yyyy"
                />


                <DatePicker
                  label="Data do Fim"
                  value={endDate}
                  onChange={handleEndDateChange}
                  minDate={startDate}
                  maxDate={new Date(startDate).setDate(new Date(startDate).getDate() + 7)}
                  renderInput={(params) => <TextField {...params} style={{ marginLeft: 0, marginRight: 10 }} />}
                  format="dd/MM/yyyy"
                />
              </ThemeProvider>
              <IconButton onClick={fetchEspecialidades} style={{ color: 'white', fontSize: 15, marginLeft: "-10px", borderRadius: 10 }}>
                {isSyncing ? (
                  <>
                    <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                    Buscando...
                  </>
                ) : (
                  <>
                    <ManageSearchIcon sx={{ fontSize: 25, marginRight: 0.5 }} />
                    Buscar
                  </>

                )}
              </IconButton>


              {funcRelatoriosSolicitacoes && (<IconButton onClick={handleDownloadXLSX} style={{ color: 'white', fontSize: 15, marginLeft: "10px", borderRadius: 10 }}>
                {isDownload ? (
                  <>
                    <CircularProgress size={15} color="inherit" sx={{ marginRight: 0.5 }} />
                    Gerando...
                  </>

                ) : (
                  <>
                    <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                    Baixar
                  </>

                )}
              </IconButton>)}


              {funcConfigTabelaSolicitacoes && (<IconButton
                onClick={handleMenuOpen}
                style={{ color: 'white', fontSize: 14, marginLeft: "7px", borderRadius: 10 }}
              >
                <SettingsIcon sx={{ fontSize: 25 }} />
              </IconButton>)}


              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <Box p={2}>


                  <Grid container spacing={2} width={"450px"}>
                    {additionalColumnOptions.map((option, index) => (
                      <Grid item xs={12} sm={6} md={6} key={option}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={index}
                              checked={handleCheck(option)}
                              onChange={handleAdditionalColumnChange}
                              value={option}
                              style={{ color: additionalColumns.includes(option) ? '#ff4b00' : '#ff4b00' }}
                            />
                          }
                          label={option.toUpperCase()}
                          style={{ fontSize: '1rem' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Menu>

            </Box>
          </LocalizationProvider>
        </div>


        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              width: "80%",
              margin: "auto",
              border: '3px',
              borderRadius: '1%',
              padding: "20px"
            },
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold", color: "white" }}>
            Solicitação {selectedUser && (`${selectedUser.categoria + ' |  Nº' + selectedUser.protocolo + ' | ' + selectedUser.status}`)}
          </DialogTitle>

          <DialogContent dividers sx={{ pb: 4 }}>
            {selectedUser && (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ marginRight: '25px' }}>
                    {selectedUser.foto ? (
                      <img src={selectedUser.foto} alt="Foto do usuário" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                    ) : (
                      <img src="/images/perfil.jpg" alt="Imagem padrão" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      Código SISREG:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      - - -
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      Nº Protocolo:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedUser.protocolo}
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      N° Cartão do SUS
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedUser.cns}
                    </Typography>
                  </Box>


                  <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Procedimento:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.procedimento || "- - -"}
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Data solicitação:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedUser.datetime}
                    </Typography>
                    <Box>
                      <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                        Data Marcada:
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                        - - -
                      </Typography>
                    </Box>

                  </Box>

                  <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Unidade:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                      - - -
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Profissional:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      - - -
                    </Typography>
                    <Box>


                      <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                        Turno de preferência:
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                        {selectedUser.turno}
                      </Typography>
                    </Box>

                  </Box>
                </Box>


                <Grid container spacing={2} >

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Nome"
                      fullWidth
                      value={selectedUser.nome}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Telefone"
                      fullWidth
                      value={formatarTelefone(selectedUser.telefone)}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CPF"
                      fullWidth
                      value={selectedUser.cpf}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}

                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Data de Nascimento"
                      fullWidth
                      value={selectedUser.datanascimento}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={selectedUser.email}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CEP"
                      fullWidth
                      value={selectedUser.cep || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Cidade"
                      fullWidth
                      value={selectedUser.cidade || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Bairro"
                      fullWidth
                      value={selectedUser.bairro || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Logradouro"
                      fullWidth
                      value={selectedUser.logradouro}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Número"
                      fullWidth
                      value={selectedUser.numero}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>



                  <Grid item xs={12} sm={5}>
                    <TextField
                      label="Prioridade"
                      fullWidth
                      value={getPrioridadeLabel(selectedUser.cor || 'verde')}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>
                  {selectedUser.status.includes("Cancelado") && (
                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Motivo do cancelamento"
                          fullWidth
                          value={selectedUser.motivo}
                          sx={{
                            bgcolor: '#3338',
                            '& .MuiOutlinedInput-root': {
                              pointerEvents: 'none',
                            },
                          }}
                          InputLabelProps={{
                            style: { color: 'white' },
                          }}
                          InputProps={{
                            readOnly: true,
                            style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                          }}
                        />
                      </Grid>
                    </>
                  )}






                </Grid>
                {selectedUser.prescricaoUrl && (
                  <IconButton
                    style={{ color: 'white', fontSize: 15, marginTop: 10, borderRadius: 10 }}
                    onClick={() => {
                      window.open(selectedUser.prescricaoUrl, '_blank');
                    }}
                  >
                    <DownloadingIcon sx={{ fontSize: 23, marginRight: 0.5 }} />
                    {selectedUser.prescricaoType === 'Foto' && 'Baixar Prescrição médica (Foto)'}
                    {selectedUser.prescricaoType === 'Documento PDF' && 'Baixar Prescrição médica (PDF)'}
                    {!['Foto', 'Documento PDF'].includes(selectedUser.prescricaoType) && 'Baixar Prescrição médica (não identificado)'}
                  </IconButton>
                )}





                <Typography variant="h6" sx={{ mb: 1, color: 'white', fontFamily: "Arial, sans-serif", fontSize: "1rem", fontWeight: 'bold', marginTop: 3 }}>
                  Atualizar status do protocolo:
                </Typography>


                <FormControl
                  variant="filled"
                  fullWidth
                  sx={{
                    mb: 2,
                    opacity: (selectedUser.status === "Cancelado pelo usuário") ? 0.5 : 1
                  }}
                  disabled={selectedUser.status === "Cancelado pelo usuário" || !funcAtualizarSolicitacoes}
                >
                  <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Status</InputLabel>
                  <Select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    inputProps={{
                      id: 'document-type-select',
                    }}
                    style={{ color: 'white' }}
                  >
                    <MenuItem value="Dados divergentes">Dados divergentes</MenuItem>
                    <MenuItem value="Em Análise">Em Análise</MenuItem>
                    <MenuItem value="Aguardando vagas">Aguardando Vagas</MenuItem>
                  </Select>
                </FormControl>



                {showAdditionalCheckboxes && (
                  <Box>
                    <Typography variant="h6" sx={{ mb: 1, color: 'white', fontFamily: 'Arial, sans-serif', fontSize: '1rem', fontWeight: 'bold' }}>
                      Selecione os dados divergentes:
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.logradouro}
                            onChange={handleCheckboxChange}
                            name="logradouro"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#FF4B00' } }}
                          />
                        }
                        label={<Typography variant="body1" sx={{ fontSize: '1rem', color: 'white' }}>Endereço</Typography>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.nome}
                            onChange={handleCheckboxChange}
                            name="nome"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#FF4B00' } }}
                          />
                        }
                        label={<Typography variant="body1" sx={{ fontSize: '1rem', color: 'white ' }}>Nome</Typography>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.cpf}
                            onChange={handleCheckboxChange}
                            name="cpf"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#FF4B00' } }}
                          />
                        }
                        label={<Typography variant="body1" sx={{ fontSize: '1rem', color: 'white' }}>CPF</Typography>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.cns}
                            onChange={handleCheckboxChange}
                            name="cns"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#FF4B00' } }}
                          />
                        }
                        label={<Typography variant="body1" sx={{ fontSize: '1rem', color: 'white' }}>CNS</Typography>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.email}
                            onChange={handleCheckboxChange}
                            name="email"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#FF4B00' } }}
                          />
                        }
                        label={<Typography variant="body1" sx={{ fontSize: '1rem', color: 'white' }}>E-mail</Typography>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.numero}
                            onChange={handleCheckboxChange}
                            name="numero"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#FF4B00' } }}
                          />
                        }
                        label={<Typography variant="body1" sx={{ fontSize: '1rem', color: 'white' }}>Nº Imovel</Typography>}
                      />
                    </Box>
                  </Box>
                )}


              </Box>
            )}
          </DialogContent>

          <DialogActions>

            <Button onClick={handleCloseDialog} style={{ backgroundColor: "white", fontWeight: "bold" }}>Voltar</Button>
            {selectedUser && (selectedStatus && (selectedStatus !== selectedUser.status && (<><Button onClick={handleSaveStatus} style={{ backgroundColor: "#ff4b00", fontWeight: "bold", marginRight: "10px", color: 'white' }} >Salvar alterações </Button></>)))}


          </DialogActions>
        </Dialog>



        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.tabelas.textWhite
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.tabelas.blackCinza

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.tabelas.backTable
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >

            {showNotification ? (
              <DataGrid
                rows={filteredTableData}
                columns={columns}
                rowHeight={80}

                pagination={true}
                localeText={dataGridPtBR.components.MuiDataGrid.defaultProps.localeText}
              />
            ) : (
              <DataGrid
                rows={filteredTableData.length > 0 ? filteredTableData : userData}
                columns={columns}
                rowHeight={80}

                pagination={true}

                localeText={dataGridPtBR.components.MuiDataGrid.defaultProps.localeText}
              />
            )}


          </Box>
        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>


    </>
  );
};

export default Solicitados;
