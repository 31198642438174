import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import { useState, useEffect } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import '../../styles/style.css'
import '../../index.css'
import {
  CircularProgress,
  Box, Grid, Typography, useTheme, Button, Dialog, Backdrop, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemText, TextField, IconButton,
  InputAdornment, Snackbar, Alert, FormControl, FormControlLabel, Radio, RadioGroup
} from "@mui/material";
import { Lock, LockOpen } from '@mui/icons-material';
import { getDatabase, ref, update } from '@firebase/database';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import firebaseApp, { KEY, BASE_PROD, KEY_WPP_BOT } from '../../integration/FirebaseConfig';
import { tokens } from "../../theme";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { categoryColors, datetime, formatarTelefone, getPrioridadeLabel } from "../../services/script";
import SyncIcon from '@mui/icons-material/Sync';
import { get } from "firebase/database";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import QRCode from 'qrcode.react';
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useFuncoes } from "../../services/funcoes";


const formatDate2 = (date) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(date).toLocaleDateString('pt-BR', options);
};

const DisplayRequests = () => {
  const today = new Date();
  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 7);
  const formattedDate = formatDate2(threeDaysAgo);

  return (
    <Typography
      fontSize={14}
      color={"white"}
      fontWeight="bold"
      marginTop={'4px'}
    >
      Exibindo marcações partir de {formattedDate} ou posteriores:
    </Typography>
  );
};

const Calendar = () => {
  const database = getDatabase(firebaseApp);
  const [userData, setUserData] = useState([]);
  const [sucesso, setSucesso] = useState(false);
  const [qntdCategory, setQntdCategory] = useState(0);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [selectedEventInfo, setSelectedEventInfo] = useState(null);
  const [categoria, setCategoria] = useState('');
  const [radioValues, setRadioValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [openModal, setOpenModal] = useState(false);
  const [openModalQR, setOpenModalQR] = useState(false);
  const [lockValue, setLockValue] = useState(true);
  const [especialidades, setEspecialidades] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [height, setHeight] = useState(250);
  const [prioridadeCounts, setPrioridadeCounts] = useState([0, 0, 0, 0, 0]);
  const {
    funcCalendarioVisualizar,
    funcCalendarioAtualizar
  } = useFuncoes();



  useEffect(() => {
    const cachedCalendarData = localStorage.getItem('cacheCalendar');
    const cachedEspecialidadesData = localStorage.getItem('cacheEspecialidades');
    const cachedPrioridadeData = localStorage.getItem('cachePrioridadeCalendar');

    if (cachedCalendarData) {
      const parsedCalendarData = JSON.parse(cachedCalendarData);

      const events = [];

      if (parsedCalendarData) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);


        const threeDaysAgo = new Date(today);
        threeDaysAgo.setDate(today.getDate() - 7);

        Object.keys(parsedCalendarData).forEach((solicitadoId) => {
          const solicitado = parsedCalendarData[solicitadoId];
          if (solicitado.status === 'Marcada') {


            const dateFinish = solicitado.datefinish.trim();
            const [datePart, timePart] = dateFinish.split(', ');
            const [day, month, year] = datePart.split('/');
            const [hour, minute] = timePart.split(':');

            const finishDate = new Date(year, month - 1, day, hour, minute);


            if (finishDate >= threeDaysAgo) {


              const formattedHour = hour.padStart(2, '0');
              const formattedMinute = minute.padStart(2, '0');

              let title = solicitado.nome.toUpperCase();
              const nameParts = title.split(' ');
              if (nameParts.length > 3) {
                title = nameParts.slice(0, 3).join(' ');
              }

              const dateTime = `${year}-${month}-${day}T${formattedHour}:${formattedMinute}`;
              const formattedDateTime = formatDate(dateTime, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              });

              const startDateTime = new Date(dateTime);

              if (solicitado.status_paciente == null) {
                events.push({
                  id: solicitado.protocolo,
                  title: title,
                  start: startDateTime,
                  allDay: false,
                  formatDat: formattedDateTime,
                  backgroundColor: categoryColors[solicitado.cor] || 'green',
                  info: solicitado,
                });
              }
            }
          }
        });

        setCurrentEvents(events);

      }

      const parsedEspecialidadesData = JSON.parse(cachedEspecialidadesData);
      setEspecialidades(parsedEspecialidadesData);
      setSolicitacoes(parsedCalendarData)


      if (cachedPrioridadeData) {
        const parsedPrioridadeData = JSON.parse(cachedPrioridadeData);
        setPrioridadeCounts(parsedPrioridadeData)
      }

      setTimeout(() => {
        setLoading(false);
        setTimeout(() => {
          stopSyncAnimation();
        }, 800);
      }, 200);

    } else {
      console.log('Buscando dados...')
      stopSyncAnimation();
      fetchData();
    }

  }, []);

  useEffect(() => {

    const handleResize = () => {

      if (window.innerHeight > 1400) {
        setHeight(700);
      } else if (window.innerHeight > 1200) {
        setHeight(580);
      } else if (window.innerHeight > 1100) {
        setHeight(540);
      } else if (window.innerHeight > 1000) {
        setHeight(480);
      } else if (window.innerHeight > 900) {
        setHeight(390);
      } else if (window.innerHeight > 800) {
        setHeight(340);
      } else if (window.innerHeight > 600) {
        setHeight(220);
      } else {
        setHeight(150);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEspecialidades = especialidades.filter((especialidade) =>
    especialidade.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startSyncAnimation = () => {
    setIsSyncing(true);
  };

  const stopSyncAnimation = () => {
    setIsSyncing(false);
  };




  const handleEventMount = (info) => {
    const dayEl = info.el.closest('.fc-day');
    if (dayEl) {
      dayEl.classList.add('has-event');
    }
    const dotEl = document.createElement("div");
    dotEl.classList.add("dot");
    dotEl.style.backgroundColor = info.event.backgroundColor || "blue";
    info.el.appendChild(dotEl);
  };

  const handleCustomButtonClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleQRCODEClick = () => {
    setOpenModalQR(true);
  };

  const handleAjustes = () => {
    setSucesso(false);
  };

  const handleSobre = () => {
    navigate(`/faq`);
  };

  const handleCloseAjustes = () => {
    setSucesso(false);
  };

  const handleClosehandleQRCODE = () => {
    setOpenModalQR(false);
  };




  const fetchData = async () => {

    startSyncAnimation();
    try {
      const calendarRef = ref(database, `${BASE_PROD}/${KEY}/solicitados`);
      const snapshot = await get(calendarRef);
      const data = snapshot.val();
      const events = [];
      const dataSolicitados = [];
      const prioridadeMap = {
        verde: 0,
        amarelo: 1,
        laranja: 2,
        azul: 3,
        ciano: 4,
        vermelho: 5
      };

      const newPrioridadeCounts = [0, 0, 0, 0, 0];

      if (data) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);


        const threeDaysAgo = new Date(today);
        threeDaysAgo.setDate(today.getDate() - 7);

        Object.values(data).forEach((solicitado) => {
          if (solicitado.status === 'Marcada') {
            const [datePart, timePart] = solicitado.datefinish.trim().split(', ');
            const [day, month, year] = datePart.split('/');
            const [hour, minute] = timePart.split(':');

            const finishDate = new Date(year, month - 1, day, hour, minute);

            if (finishDate >= threeDaysAgo) {
              dataSolicitados.push(solicitado);

              solicitado.id = solicitado.protocolo;

              const formattedHour = hour.padStart(2, '0');
              const formattedMinute = minute.padStart(2, '0');

              let title = solicitado.nome.toUpperCase().split(' ').slice(0, 3).join(' ');

              const dateTime = `${year}-${month}-${day}T${formattedHour}:${formattedMinute}`;
              const formattedDateTime = formatDate(dateTime, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              });



              const startDateTime = new Date(dateTime);
              if (solicitado.status_paciente == null) {
                events.push({
                  id: solicitado.protocolo,
                  title: title,
                  start: startDateTime,
                  allDay: false,
                  formatDat: formattedDateTime,
                  backgroundColor: categoryColors[solicitado.cor] || 'green',
                  info: solicitado,
                });

                const prioridadeIndex = prioridadeMap[solicitado.cor] || 0;
                newPrioridadeCounts[prioridadeIndex] += 1;


              }

            }
          }


        });

        setCurrentEvents(events);
        setSolicitacoes(dataSolicitados);

        localStorage.setItem('cacheCalendar', JSON.stringify(dataSolicitados));
        setPrioridadeCounts(newPrioridadeCounts);
        localStorage.setItem('cachePrioridadeCalendar', JSON.stringify(newPrioridadeCounts));
      }
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    }


    try {


      const instituicoesRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes`);
      const instituicoesSnapshot = await get(instituicoesRef);

      const especialidadesMap = {};

      instituicoesSnapshot.forEach((instituicaoSnapshot) => {
        const especialidadesInstituicao = instituicaoSnapshot.child('especialidade');

        especialidadesInstituicao.forEach((especialidadeSnapshot) => {
          const especialidadeData = especialidadeSnapshot.val();

          if (especialidadeData && especialidadeData.vagas > 0) {
            const nomeEspecialidade = especialidadeData.nome;

            if (especialidadesMap[nomeEspecialidade]) {
              especialidadesMap[nomeEspecialidade].vagas += especialidadeData.vagas;
            } else {

              especialidadesMap[nomeEspecialidade] = {
                ...especialidadeData
              };
            }
          }
        });
      });


      const especialidadesArray = Object.values(especialidadesMap);

      setEspecialidades(especialidadesArray);

      localStorage.setItem('cacheEspecialidades', JSON.stringify(especialidadesArray));
    } catch (error) {
      console.error("Erro ao buscar especialidades:", error);
    } finally {
      stopSyncAnimation();
      setLoading(false)
    }
  };




  const handleEventDrop = (dropInfo) => {
    const { event } = dropInfo;

  };


  const WhatsAppQRCode = () => {
    const message = 'Olá';
    const url = `https://wa.me/${KEY_WPP_BOT}?text=${encodeURIComponent(message)}`;
    const logoUrl = '/images/brasao.webp';

    return (
      <>
        <QRCode
          value={url}
          size={400}
          renderAs="canvas"
          fgColor="black"
          bgColor="white"
          level="H"
          style={{ padding: 8 }}
          includeMargin={false}
        />
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100px',
          height: '100px',
          borderRadius: '0%',
          overflow: 'hidden',
          backgroundColor: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img
            src={logoUrl}
            alt="Logo"
            style={{ width: '80%', height: '80%' }}
          />
        </div>
      </>
    );
  };



  const handleEventClick = (clickInfo) => {
    setSelectedEventInfo(clickInfo.event.extendedProps.info);
    setOpenEventDialog(true);
  };

  const handleCloseEventDialog = () => {
    setOpenEventDialog(false);
  };



  const handleCategoryClick = (category) => {
    setOpenCategoryDialog(true);


    setLoadingCategory(true)

    if (category === 'clinica') {
      category = 'clínica geral';
    }

    const data = solicitacoes;
    if (data) {
      const filteredData = Object.values(data).filter(item =>
        item && item.categoria && item.categoria.toLowerCase() === category.toLowerCase() && item.status === 'Marcada'
      );

      setCategoria(category);

      const sortedData = [...filteredData].sort((a, b) => {
        if ((!a.cadeado || a.cadeado === false) && b.cadeado === true) {
          return -1;
        }
        if ((!b.cadeado || b.cadeado === false) && a.cadeado === true) {
          return 1;
        }
        return 0;
      });

      const newRadioValues = {};
      const newLockValues = {};
      sortedData.forEach(item => {
        newRadioValues[item.protocolo] = item.status_paciente || null;
        newLockValues[item.protocolo] = item.cadeado || false;
      });

      setRadioValues(newRadioValues);
      setLockValue(newLockValues);

      const userDataWithId = sortedData.map((item, index) => ({
        ...item,
        id: index + 1
      }));

      const qntd = userDataWithId.length;

      setQntdCategory(qntd);

      setUserData(userDataWithId);

      setTimeout(() => {
        setLoadingCategory(false);
      }, 1000);

      setLoading(false);
    } else {
      setUserData([]);
      setLoading(false);
    }



  };


  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };


  const handleStatusChange = async (event, row) => {
    const statusValue = event.target.value;
    const newRadioValues = { ...radioValues, [row.protocolo]: statusValue };
    const userRef = ref(database, `${BASE_PROD}/${KEY}/solicitados/${row.protocolo}`);
    setRadioValues(newRadioValues);


    const solicitacoesRef = ref(database, `/${BASE_PROD}/${KEY}/users/${row.telefone}/solicitacoes/${row.protocolo}`);

    await update(solicitacoesRef, {
      status: "Marcada",
      status_paciente: statusValue,
      cadeado: true,
      datecheck: datetime()
    })

    const newCacheSolicitados = solicitacoes.map(item => {
      if (item.protocolo === row.protocolo) {
        return { ...item, status_paciente: statusValue, cadeado: true, datecheck: datetime() };
      }
      return item;
    });

    setSolicitacoes(newCacheSolicitados);


    localStorage.setItem('cacheSolicitados', JSON.stringify(newCacheSolicitados));

    setTimeout(() => {
      update(userRef, {
        status: "Marcada",
        status_paciente: statusValue,
        cadeado: true,
        datecheck: datetime()
      })
        .then(() => {
          console.log('Status atualizado com sucesso.');


        })
        .catch((error) => {
          console.error('Erro ao atualizar o status:', error);
        });
    }, 2000);
  };


  const toggleLock = (protocolo) => {
    const newValue = !lockValue[protocolo];
    setLockValue({ ...lockValue, [protocolo]: newValue });

    const newCacheSolicitados = solicitacoes.map(item => {
      if (item.protocolo === protocolo) {
        return { ...item, cadeado: newValue };
      }
      return item;
    });

    setSolicitacoes(newCacheSolicitados);
    localStorage.setItem('cacheSolicitados', JSON.stringify(newCacheSolicitados));

    const updates = {};
    updates[`${BASE_PROD}/${KEY}/solicitados/${protocolo}/cadeado`] = newValue;

    setTimeout(() => {
      update(ref(database), updates)
        .then(() => {
          console.log('Cadeado atualizado com sucesso.');

        })
        .catch((error) => {
          console.error('Erro ao atualizar o cadeado:', error);
        });


    }, 1000)

  };


  const columns = [

    {
      field: "nome",
      headerName: "Nome Completo",
      flex: 1.3,
      fontWeight: "bold",
      cellClassName: (params) => lockValue[params.row.protocolo] ? 'opaque-cell' : '',
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "cadeado",
      headerName: "🔒",
      flex: 0.1,
      headerAlign: 'center',
      align: "center",
      fontWeight: "bold",
      renderCell: (params) => (
        <div>
          {lockValue[params.row.protocolo] ? (
            <Lock onClick={() => toggleLock(params.row.protocolo)} />
          ) : (
            <LockOpen onClick={() => toggleLock(params.row.protocolo)} style={{ color: '#ff4b00' }} />
          )}
        </div>
      )
    },

    { field: "cpf", headerName: "CPF", flex: 0.6, align: "center", fontWeight: "bold", headerAlign: 'center' },
    {
      field: "status_paciente",
      headerName: "Status Paciente", fontWeight: "bold",
      flex: 0.8,
      cellClassName: (params) => lockValue[params.row.protocolo] ? 'opaque-cell' : '',
      renderCell: (params) => (
        <>
          {funcCalendarioAtualizar && (
            <FormControl component="fieldset" >
              <RadioGroup
                row
                aria-label={`status-paciente-${params.row.protocolo}`}
                name={`status-paciente-${params.row.protocolo}`}
                value={radioValues[params.row.protocolo] || ""}
                onChange={(e) => handleStatusChange(e, params.row)}
                disabled={lockValue[params.row.protocolo]}
              >
                <FormControlLabel
                  value="faltou"
                  control={<Radio sx={{ '&.Mui-checked': { color: '#ff4b00' } }} />}
                  label="Faltou"
                  disabled={lockValue[params.row.protocolo]}
                />
                <FormControlLabel
                  value="compareceu"
                  control={<Radio sx={{ '&.Mui-checked': { color: '#ff4b00' } }} />}
                  label="Compareceu"
                  disabled={lockValue[params.row.protocolo]}
                />
              </RadioGroup>
            </FormControl>
          )}

        </>


      ),

    },
    {
      field: 'cor',
      headerName: 'Prioridade',
      flex: 0.4,

      renderCell: (params) => {
        const backgroundColor = categoryColors[params.row.cor.toLowerCase()] || 'green';
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                backgroundColor: backgroundColor,
                border: '0px solid #ccc',
                marginRight: 8,
              }}
            />

          </div>
        );
      },
    },
    {
      field: "codigoSisREG", headerName: "SISREG", flex: 0.5, align: "center", headerAlign: "center",
      fontWeight: "bold", cellClassName: (params) => lockValue[params.row.protocolo] ? 'opaque-cell' : ''
    },
    { field: "datefinish", headerName: "Data", flex: 1, align: "center", fontWeight: "bold", headerAlign: 'center', cellClassName: (params) => lockValue[params.row.protocolo] ? 'opaque-cell' : '' },

  ];


  const renderEventContent = (eventInfo) => {
    if (
      eventInfo.view.type === 'timeGridWeek' ||
      eventInfo.view.type === 'dayGridWeek'
    ) {
      const currentDateTime = eventInfo.event.start;
      let sameDateTimeEventCount = 0;

      for (const event of currentEvents) {
        if (event.start === currentDateTime) {
          sameDateTimeEventCount++;
          if (sameDateTimeEventCount > 1) {
            return <div className="dot" style={{ backgroundColor: eventInfo.event.backgroundColor || "blue" }}></div>;
          }
        }
      }

      return (
        <div>
          <div className="dot" style={{ backgroundColor: eventInfo.event.backgroundColor || "blue" }}></div>
          <div className="fc-event-title" style={{ marginLeft: 15, fontWeight: 'bold', fontSize: 10 }}>{eventInfo.event.title}</div>
        </div>
      );
    } else if (
      eventInfo.view.type === 'timeGridDay' ||
      eventInfo.view.type === 'dayGridDay'
    ) {
      const currentDateTime = eventInfo.event.start;
      let sameDateTimeEventCount = 0;

      for (const event of currentEvents) {
        if (event.start === currentDateTime) {
          sameDateTimeEventCount++;
          if (sameDateTimeEventCount > 7) {
            return <div className="dot" style={{ backgroundColor: eventInfo.event.backgroundColor || "blue" }}></div>;
          }
        }
      }


      return (
        <div>
          <div className="dot" style={{ backgroundColor: eventInfo.event.backgroundColor || "blue" }}></div>
          <div className="fc-event-title" style={{ marginLeft: 15, fontWeight: 'bold', fontSize: 12 }}>{eventInfo.event.title}</div>
        </div>
      );
    } else {

      return (
        <div>
          <div className="dot" style={{ backgroundColor: eventInfo.event.backgroundColor || "blue" }}></div>
          <div className="fc-event-title" style={{ marginLeft: 15, fontSize: 10 }}>{eventInfo.event.title}</div>
        </div>
      );
    }
  };


  return (
    <>
      <HelmetProvider>   <Helmet>
        <title>Painel DHF - Página Inicial</title>
      </Helmet></HelmetProvider>



      <Box m="20px">

        {/* <FloatingWhatsApp
          phoneNumber="+55 (83)986347332"
          accountName="Suporte Data Health Flow"
          avatar="/images/data.webp"
          statusMessage="responde em 30 minutos"
          allowEsc="true"
          allowClickAway="true"
          darkMode="true"
          notification="true"
          notificationSound="true"
          messageDelay="0.5"
          placeholder="Escreva uma mensagem..."
          initialMessageByServer="Olá"
          chatMessage="Estamos aqui para te ajudar! precisa de alguma coisa?"
          notificationDelay="5"
        /> */}

        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src="/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

          </Box>
        </Backdrop>

        <Box display="flex" justifyContent="center">

          <Box flex="1 1 0%" p="15px" borderRadius="4px">
            <Box className='calendar-container' backgroundColor={'#1d1d1d'} borderRadius={5} height="210px" style={{ marginLeft: '10px', marginRight: '10px', marginTop: "-15px" }}>
              {/*      <CalendarREAC locale="pt-BR" /> 
 */}



              <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center', padding: '15px' }}>
                Prioridades
              </Typography>

              <Typography fontSize={11} fontWeight="600" style={{ textAlign: 'center', marginTop: '-12px' }}>
                (Protocolos em Aberto)
              </Typography>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="5px"
              >

                <Box style={{ justifyContent: 'flex-start', marginTop: '0px' }} >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: 0.5 }}>
                    <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: 'green' }} />
                    <span>Sem Prioridade: {prioridadeCounts[0]}</span>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: 'yellow' }} />
                    <span>Idoso: {prioridadeCounts[1]}</span>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: 'orange' }} />
                    <span>Gestante: {prioridadeCounts[2]}</span>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: 'blue' }} />
                    <span>Portador de Deficiência: {prioridadeCounts[3]}</span>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#00ffc3' }} />
                    <span>Oncologia : {prioridadeCounts[4]}</span>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: 'red' }} />
                    <span>Outras Emergências: {prioridadeCounts[5]}</span>
                  </Box>
                </Box>

              </Box>



            </Box>



            <Box style={{ marginLeft: 18, marginTop: 25, marginBottom: 20 }} display={"flex"} >
              <Typography color={"#878787"} >Especialidade</Typography><KeyboardDoubleArrowDownIcon style={{ color: "#878787" }} />

              <Typography marginLeft={12} color={"#878787"} >Vagas Totais </Typography><KeyboardDoubleArrowDownIcon style={{ color: "#878787" }} />
            </Box>

            <Box
              marginTop={1}
              borderRadius="15px"
              backgroundColor="#363636"
              p="14px"
              marginX={1}
              width={300}
            >
              <Box display="flex" alignItems="center" justifyContent="center" gap={1} style={{ marginTop: 2, marginBottom: 5, marginRight: 20 }}>
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    width: 255,
                    borderColor: '#1d1d1d',
                    border: 'none',
                    backgroundColor: '#1d1d1d',
                    color: 'white',
                    borderRadius: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: 'white' }} />
                      </InputAdornment>
                    ),
                    placeholder: 'Buscar Especialidade',
                    style: {
                      color: 'white',
                      borderRadius: 10,
                      borderColor: '#1d1d1d',
                      border: 'none',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={fetchData} style={{ color: 'white' }}>
                          {isSyncing ? (
                            <>
                              <CircularProgress size={15} color="inherit" />

                            </>
                          ) : (
                            <>
                              <SyncIcon sx={{ transition: 'transform 0.5s', transform: isSyncing ? 'rotate(360deg)' : 'none' }} />

                            </>
                          )}

                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                style={{
                  maxHeight: height,
                  maxWidth: 300,
                  overflowY: "scroll",
                  paddingRight: 10,
                  marginTop: 10
                }}
                className="custom-scroll-especialidades">
                <List>
                  {filteredEspecialidades.map((especialidade, index) => (
                    <ListItem key={index} button onClick={() => handleCategoryClick(especialidade.nome)} sx={{ backgroundColor: "#1A1A1A", marginBottom: "10px", borderRadius: "8px" }}>
                      <ListItemText>
                        <Typography variant="body1" style={{ fontSize: "11px", fontWeight: 'bold' }}>
                          {especialidade.nome.toUpperCase()}
                        </Typography>
                      </ListItemText>
                      <Typography variant="body1" style={{ fontSize: "13px" }}>
                        {especialidade.preenchido}/{especialidade.vagas}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Box>

          <Box flex="1 1 80%" ml="5px" className="watermark-container">



            <FullCalendar
              height="95vh"

              eventDidMount={handleEventMount}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,next today,dayGridMonth,timeGridWeek,timeGridDay',
                center: 'title',
                right: 'myCustomButton,myCustomButton2,myCustomButton4',
              }}
              editable={true}
              selectMirror={true}
              dayMaxEvents={true}
              locale={ptLocale}
              eventClick={handleEventClick}
              events={currentEvents}

              eventContent={renderEventContent}
              eventDrop={handleEventDrop}
              customButtons={{
                myCustomButton: {
                  text: 'Legenda',
                  click: handleCustomButtonClick,
                },
                myCustomButton2: {
                  text: 'WhatsApp',
                  click: handleQRCODEClick,
                },
                myCustomButton3: {
                  text: 'Ajustes',
                  click: handleAjustes,
                },
                myCustomButton4: {
                  text: 'Sobre',
                  click: handleSobre,
                },
              }}
            />




            <div className="watermark">
              <img src="/images/logo.webp" alt="Watermark" className="watermark-img" />
            </div>
          </Box>


        </Box>


        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Legenda de Prioridades</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green' }} />
              <span>Verde: Sem Prioridade</span>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'yellow' }} />
              <span>Amarelo: Prioridade (Idoso)</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'orange' }} />
              <span>Laranja: Prioridade (Gestante)</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'blue' }} />
              <span>Azul: Prioridade (Portador de Dificiência)</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red' }} />
              <span>Vermelho: Prioridade (Outras Emergência)</span>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button style={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00", color: 'white', fontWeight: 'bold'
            }} onClick={handleCloseModal} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModalQR}
          onClose={handleClosehandleQRCODE}
        >
          <DialogContent sx={{
            textAlign: 'center',
            background: 'none',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <WhatsAppQRCode />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openCategoryDialog}
          onClose={handleCloseCategoryDialog}
          fullWidth
          maxWidth="md"

          PaperProps={{
            style: {
              margin: 0,
              borderRadius: '1%',
            },
          }}
        >
          <DialogContent>
            <Typography variant="h4" style={{ color: "white", marginBottom: "-5px", borderRadius: "10px", backgroundColor: "#141414", padding: "10px", fontWeight: "bold", fontSize: 22 }}>




              {loadingCategory ? (
                <>
                  {categoria.toLocaleUpperCase()}
                  <Typography
                    fontSize={14}
                    color={colors.grey[100]}
                    fontWeight="bold"
                    marginTop={'4px'}
                  >
                    <CircularProgress size={15} sx={{ marginLeft: 0, marginRight: 1 }} color="inherit" />
                    BUSCANDO...
                  </Typography>

                </>
              ) : (<>

                {categoria.toLocaleUpperCase()} ({qntdCategory})
                <Button style={{ color: 'white', fontSize: 15 }} onClick={() => handleCategoryClick(categoria)}>        <SyncIcon sx={{ transition: 'transform 0.5s', transform: isSyncing ? 'rotate(360deg)' : 'none' }} /> </Button>

                <DisplayRequests />


              </>
              )}



            </Typography>


            <Box
              className="watermark-container"
              m="10px 0 0"
              marginRight={0}

              height="60vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none"
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: `1px solid ${colors.primary[500]}`,
                },
                "& .name-column--cell": {
                  color: colors.grey[100],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#141414",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#212121",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: '#1D1D1D',
                },
                ".MuiDataGrid-columnSeparator": {
                  color: '#707070 !important'
                }
              }}
            >
              <DataGrid
                rows={userData}
                columns={columns}
                rowHeight={80}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pagination={true}
                filterMode="server"
              /* sortModel={[
                {
                  field: 'nome',
                  sort: 'asc',
                },
              ]} */
              />

            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openEventDialog}
          onClose={handleCloseEventDialog}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              width: "80%",
              margin: "auto",
              border: '3px',
              borderRadius: '1%',
              padding: "20px"
            },
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold", color: "white" }}>
            {selectedEventInfo && (!selectedEventInfo.status_paciente ? (<> CONFIRMADO PARA {(selectedEventInfo.categoria).toUpperCase() + ' |  Nº ' + selectedEventInfo.protocolo + ' | ' + (selectedEventInfo.status).toUpperCase()}</>) :
              (<>FINALIZADO PARA {(selectedEventInfo.categoria).toUpperCase() + ' |  Nº ' + selectedEventInfo.protocolo}</>))}

          </DialogTitle>

          <DialogContent dividers sx={{ pb: 4 }}>
            {selectedEventInfo && (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ marginRight: '25px' }}>
                    {selectedEventInfo.foto ? (
                      <img src={selectedEventInfo.foto} alt="Foto do usuário" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                    ) : (
                      <img src="/images/perfil.jpg" alt="Imagem padrão" style={{ width: '175px', height: '175px', borderRadius: '5%' }} />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      Código SISREG:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedEventInfo.codigoSisREG}
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      Nº do Protocolo:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedEventInfo.protocolo}
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", }}>
                      N° Cartão do SUS
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                      {selectedEventInfo.cns}
                    </Typography>
                  </Box>


                  <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Procedimento:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                      {selectedEventInfo.procedimento || ""}
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Data/hora da Solicitação:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedEventInfo.datetime}
                    </Typography>
                    <Box>
                      <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                        Data/hora da Marcação:
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                        {selectedEventInfo.datefinish}
                      </Typography>
                    </Box>

                  </Box>

                  <Box id="box-cns-dados" sx={{ marginLeft: '30px' }}>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Unidade:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                      {selectedEventInfo.unidade}
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                      Profissional:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {selectedEventInfo.profissional}
                    </Typography>
                    <Box>


                      <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", ml: '20px', mr: '20px' }}>
                        Turno:
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                        {selectedEventInfo.turno}
                      </Typography>
                    </Box>

                  </Box>
                </Box>

                <Grid container spacing={2} >

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Nome"
                      fullWidth
                      value={selectedEventInfo.nome}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Telefone"
                      fullWidth
                      value={formatarTelefone(selectedEventInfo.telefone)}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CPF"
                      fullWidth
                      value={selectedEventInfo.cpf}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}

                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Data de Nascimento"
                      fullWidth
                      value={selectedEventInfo.datanascimento}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={selectedEventInfo.email}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="CEP"
                      fullWidth
                      value={selectedEventInfo.cep || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Cidade"
                      fullWidth
                      value={selectedEventInfo.cidade || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Bairro"
                      fullWidth
                      value={selectedEventInfo.bairro || "SEM INFORMAÇÃO"}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Logradouro"
                      fullWidth
                      value={selectedEventInfo.logradouro}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Número"
                      fullWidth
                      value={selectedEventInfo.numero}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>



                  <Grid item xs={12} sm={5}>
                    <TextField
                      label="Prioridade"
                      fullWidth
                      value={getPrioridadeLabel(selectedEventInfo.cor || 'verde')}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },

                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Observações"
                      fullWidth
                      value={selectedEventInfo.observacoes || "Não"}
                      multiline
                      rows={3}
                      sx={{
                        bgcolor: '#3338',
                        '& .MuiOutlinedInput-root': {
                          pointerEvents: 'none',

                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'white' },
                      }}
                      InputProps={{
                        readOnly: true,
                        style: { color: '#efefef', fontWeight: 'bold' },
                      }}
                    />
                  </Grid>

                </Grid>



              </Box>
            )}
          </DialogContent>



          <DialogActions>

            <Button onClick={handleCloseEventDialog} sx={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00", borderColor: '#FF4B00', color: 'white', fontWeight: 'bold'
            }}>Fechar</Button>
          </DialogActions>
        </Dialog>


      </Box >

      <Snackbar
        open={sucesso}
        autoHideDuration={2000}
        onClose={handleCloseAjustes}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>
          Essa funcionalidade está sendo desenvolvida, e em breve estará disponivel para todos, aguarde.
        </Alert>
      </Snackbar>
    </>
  );
};

export default Calendar;
