import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import { getDatabase, ref, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import firebaseApp, { ROTA_USUARIOS } from '../../integration/FirebaseConfig';
import { Atendente, Coodernador, Gestao } from '../../services/script';
import { Box, Button, Grid, Typography, Slide, useMediaQuery } from '@mui/material';

const database = getDatabase(firebaseApp);

const Cadastro = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [showSelected, setShowSelected] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth(firebaseApp);


  const handleImageSelect = (imageNumber) => {
    setSelectedImage(imageNumber);

    switch (imageNumber) {
      case 1:
        setCategoria('Gestão');
        setShowSelected(true);
        break;
      case 2:
        setCategoria('Coordenação');
        setShowSelected(true);
        break;
      case 3:
        setCategoria('Atendimento');
        setShowSelected(true);
        break;
      default:
        setCategoria('Outros');
        setShowSelected(true);
    }
  };


  const handleNext = () => {


    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      console.log(userId);
      const userRef = ref(database, `${ROTA_USUARIOS}/${userId}`);

      if (categoria) {
        let funcoesCargo = [];

        if (categoria == "Gestão") {
          funcoesCargo = Gestao;
        } else if (categoria == "Coordenação") {
          funcoesCargo = Coodernador;
        } else if (categoria == "Atendimento") {
          funcoesCargo = Atendente;
        }

        const userData = {
          categoria: categoria,
          funcoes: funcoesCargo
        };

        update(userRef, userData)
          .then(() => {
            navigate(`/cadastro/dados`);
          })
          .catch((error) => {
            console.error('Erro ao salvar dados do usuário no Firebase Database:', error);
          });
      }
    } else {
      navigate('/login');
    }
  };


  return (
    <>
      <HelmetProvider> <Helmet>
        <title>Painel DHF - Cadastro</title>
      </Helmet></HelmetProvider>

      <Box className="login-container" sx={{ padding: '20px', textAlign: 'center' }}>
        <Box className="logo-container" sx={{ marginBottom: '20px' }}>
          <img src="/images/logo.webp" alt="Logo" width="220" />
        </Box>
        <Box className="login-form">
          <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            CADASTRO - PROGRESSO 2/3
          </Typography>
          <Grid container justifyContent="center" className="image-container">
            {[{ src: "/images/grupo1.webp", label: "Gestão" }, { src: "/images/grupo2.webp", label: "Coordenação" }, { src: "/images/grupo3.webp", label: "Atendimento" }, { src: "/images/outros.png", label: "Outros" }]
              .map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={3}>
                  <Box
                    component="img"
                    src={item.src}
                    alt={item.label}
                    sx={{
                      borderRadius: '40px',
                      width: '95%',
                      maxWidth: '280px',

                      boxShadow: selectedImage === index + 1 ? '0 0 8px 4px rgba(255, 255, 255, 0.7)' : 'none',
                      transform: selectedImage === index + 1 ? 'translateY(-5px)' : 'none',
                      transition: 'all 0.3s ease-in-out',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                      },
                    }}
                    onClick={() => handleImageSelect(index + 1)}
                  />
                </Grid>
              ))}
          </Grid>
          <Slide direction="left" in={showSelected} mountOnEnter unmountOnExit>
            <Box className="selected-image" sx={{ marginTop: '20px' }}>
              <Typography variant="h6">
                Selecionado: {selectedImage === 1 ? 'Gestão' : selectedImage === 2 ? 'Coordenação' : selectedImage === 3 ? 'Atendimento' : 'Outros'}
              </Typography>
            </Box>
          </Slide>

          {selectedImage && (
            <>
              <Box className="button-container" sx={{ marginTop: '15px' }}>
                <Button variant="contained" color="primary" onClick={handleNext} sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #FF4B00",
                  color: "white",
                  borderRadius: "4px",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 14,
                  fontWeight: 'bold'
                }}>
                  Continuar cadastro
                </Button>
              </Box>
              <Typography sx={{ marginTop: '20px' }}>
                Deseja deslogar do sistema?{' '}
                <Link to="/logout" style={{ color: '#FF4B00', textDecoration: 'none' }}>
                  Deslogar
                </Link>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Cadastro;
