import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Divider,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../../theme";
import InfoIcon from '@mui/icons-material/Info';
import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { KEY } from '../../../integration/FirebaseConfig';
import { BASE_PROD } from '../../../integration/FirebaseConfig';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputMask from 'react-input-mask';
import ClearIcon from '@mui/icons-material/Clear';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { allCargos, datetime } from "../../../services/script";

const database = getDatabase(firebaseApp);

function gerarID() {
  const numeroAleatorio = Math.floor(10000000 + Math.random() * 90000000);
  return numeroAleatorio.toString();
}


const EditarCargo = ({ handleClose, fetch, userData }) => {

  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [nome, setNome] = useState(userData.nome || '');
  const [categoria, setCategoria] = useState('');
  const [acessoTemporario, setacessoTemporario] = useState(userData.acessoTemporario || false);
  const [timeAcessoTemporario, settimeAcessoTemporario] = useState(userData.timeAcessoTemporario || "");
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState(userData.funcoes || []);

  const handleCategoriaChange = (event, value) => {
    if (value && !especialidadesSelecionadas.includes(value)) {
      setEspecialidadesSelecionadas([...especialidadesSelecionadas, value]);
    }
    setCategoria(value ? value : '');
  };



  const handleRemoveEspecialidade = (especialidade) => {
    const updatedEspecialidades = especialidadesSelecionadas.filter(item => item !== especialidade);
    setEspecialidadesSelecionadas(updatedEspecialidades);
  };



  const handleSalvar = async () => {
    const db = ref(database, `${BASE_PROD}/${KEY}/cargos/` + userData.id);

    await update(db, {
      nome,
      datetime: datetime(),
      funcoes: especialidadesSelecionadas,
    });
    handleClose();
    fetch();
  };




  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>


      <>

        {acessoTemporario && (<>

          <Grid item xs={12}>
            <Typography
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginBottom: '0.6em',
                marginTop: 15
              }}
            >
              Cargo temporario, expira dia: ({timeAcessoTemporario})
            </Typography>
          </Grid>
        </>)}


        <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}> Novo cargo</Typography>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome do cargo"
                fullWidth
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>



            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={allCargos}
                value={categoria}
                onChange={(event, value) => handleCategoriaChange(event, value)}
                inputValue={categoria}
                onInputChange={(event, newInputValue) => {
                  setCategoria(newInputValue);
                }}
                isOptionEqualToValue={(option, value) => {
                  if (!value) return false;
                  return option.nome === value || option === value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Funções"
                    sx={{ bgcolor: '#3338' }}
                    InputLabelProps={{
                      style: { color: 'white' },
                      focused: false,
                    }}
                  />
                )}
              />






            </Grid>


            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: '0.6em',
                    marginTop: 15
                  }}
                >
                  Funções permitidas
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  {especialidadesSelecionadas.map((especialidade, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: "transparent",
                          border: "1px solid #FF4B00",
                          padding: '0.2em',
                          borderRadius: 10,
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minHeight: '50px',
                        }}
                      >
                        <IconButton
                          style={{ fontSize: 13, borderRadius: 4, fontWeight: 'bold' }}
                          onClick={() => handleRemoveEspecialidade(especialidade)}
                        >
                          {especialidade}
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                onClick={handleSalvar}
                disabled={isDisabled}
              >
                <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                CONCLUIR EDIÇÃO
              </Button>
            </Grid>

          </Grid>
        </form>




      </>

    </Box >
  );
};

export default EditarCargo;
