import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Backdrop,
} from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import { getDatabase, ref, get } from '@firebase/database';
import HeaderCargos from "../../utils/HeaderCargos";
import firebaseApp, { BASE_PROD, KEY } from '../../integration/FirebaseConfig';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { removeAccents } from "../../services/script";


const Avaliacoes = () => {
  const database = getDatabase(firebaseApp);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [showNotification, setShowNotification] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const handleFilterApply = (newFilters) => {

    const filteredData = userData.filter((user) => {
      return Object.keys(newFilters).every((key) => {
        if (key === 'data') {
          const formattedFilterDate = newFilters[key] ? new Date(newFilters[key]).toISOString().substr(0, 10) : '';
          const formattedUserData = user[key] ? new Date(user[key]).toISOString().substr(0, 10) : '';
          return !formattedFilterDate || formattedUserData.includes(formattedFilterDate);
        }
        const filterValue = key !== 'data' ? removeAccents(newFilters[key].toLowerCase()) : newFilters[key].toLowerCase();
        const userValue = key !== 'data' && user[key] ? removeAccents(user[key].toLowerCase()) : user[key];
        return !newFilters[key] || (typeof user[key] === 'string' && userValue.includes(filterValue));
      });
    });

    if (filteredData.length <= 0) {
      setShowNotification(true);
    }
    setFilteredTableData(filteredData);
  };

  useEffect(() => {
    const fetchAvaliacaoData = async () => {
      const usersRef = ref(database, `${BASE_PROD}/${KEY}/avaliacoes`);
      try {
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const filteredUsers = Object.keys(data)
            .map((key) => ({
              id: key,
              ...data[key],
            }));

          setUserData(filteredUsers);
        } else {
          setUserData([]);
        }
      } catch (error) {
        console.error('Erro ao obter dados do Firebase:', error);
        setUserData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAvaliacaoData();
  }, [KEY]);




  const columns = [
    {
      field: "cpf",
      headerName: "Usuário",
      flex: 0.5,
      filterable: true,
    },
    // {
    //   field: "telefone",
    //   headerName: "Telefone",
    //   flex: 0.5,
    //   filterable: true,
    // },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 0.5,
      cellClassName: 'name-column--cell',
      filterable: true,
    },
    {
      field: 'nota_dhf',
      headerName: 'Nota sistema',
      flex: 0.5,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: (params) => {
        const value = parseInt(params.value, 10);
        if (isNaN(value) || value < 0) {
          return null;
        }
        return (
          <div>
            {value === 6 ? (
              <span>Não avaliado</span>
            ) : (
              <>
                {Array.from(Array(value > 5 ? 5 : value), (_, index) => (
                  <StarIcon key={index} style={{ color: '#FFD700' }} />
                ))}
                {Array.from(Array(5 - (value > 5 ? 5 : value)), (_, index) => (
                  <StarBorderIcon key={index} style={{ color: '#FFD700' }} />
                ))}
              </>
            )}
          </div>
        );
      }
    },
    {
      field: 'nota_profissional',
      headerName: 'Nota Profissionais',
      flex: 0.5,
      filterable: true,
      renderCell: (params) => {
        const value = parseInt(params.value, 10);
        if (isNaN(value) || value < 0) {
          return null;
        }
        return (
          <div>
            {value === 6 ? (
              <span>Não avaliado</span>
            ) : (
              <>
                {Array.from(Array(value > 5 ? 5 : value), (_, index) => (
                  <StarIcon key={index} style={{ color: '#FFD700' }} />
                ))}
                {Array.from(Array(5 - (value > 5 ? 5 : value)), (_, index) => (
                  <StarBorderIcon key={index} style={{ color: '#FFD700' }} />
                ))}
              </>
            )}
          </div>
        );
      }
    },
    {
      field: 'nota_unidade',
      headerName: 'Nota UBS',
      flex: 0.5,
      filterable: true,
      renderCell: (params) => {
        const value = parseInt(params.value, 10);
        if (isNaN(value) || value < 0) {
          return null;
        }
        return (
          <div>
            {value === 6 ? (
              <span>Não avaliado</span>
            ) : (
              <>
                {Array.from(Array(value > 5 ? 5 : value), (_, index) => (
                  <StarIcon key={index} style={{ color: '#FFD700' }} />
                ))}
                {Array.from(Array(5 - (value > 5 ? 5 : value)), (_, index) => (
                  <StarBorderIcon key={index} style={{ color: '#FFD700' }} />
                ))}
              </>
            )}
          </div>
        );
      }
    },
    {
      field: 'datetime',
      headerName: 'Enviado',
      flex: 0.5,
      filterable: true,
    },
    {
      field: 'comentario_profissional',
      headerName: 'Comentário Profissional',
      flex: 0.5,
      filterable: true,
    },
    {
      field: 'comentario_dhf',
      headerName: 'Comentário Sistema',
      flex: 0.5,
      filterable: true,
    },
  ];




  return (
    <>
      <HelmetProvider><Helmet>
        <title>Painel DHF - Cargos</title>
      </Helmet></HelmetProvider>


      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images//brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <Box m="10px">

        <HeaderCargos title={`AVALIAÇÕES DOS USUÁRIOS`} onFilterApply={handleFilterApply}></HeaderCargos>
        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid rows={filteredTableData} columns={columns} rowHeight={80} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            ) : (
              <DataGrid rows={filteredTableData.length > 0 ? filteredTableData : userData} columns={columns} rowHeight={80} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            )}


          </Box>
        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Avaliacoes;
