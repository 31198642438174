import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Button,
  MenuItem,
  TextField,
  Popover,
  Slider,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import FilterIcon from "@mui/icons-material/FilterList";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { KEY } from "../integration/FirebaseConfig";
import { useFuncoes } from "../services/funcoes";

const HeaderPacientes = ({ title, subtitle, onFilterApply }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({
    cpf: "",
    nomecompleto: "",
    email: "",
    idade: [0, 100], // Faixa etária inicial
  });

  const {
    funcCriarNovosPacientes
  } = useFuncoes();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event, filterType) => {
    const value = event.target.value;
    setFilterValues((prevValues) => ({
      ...prevValues,
      [filterType]: value,
    }));
  };

  const handleAgeSliderChange = (event, newValue) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      idade: newValue,
    }));
  };

  const handleFilterApply = () => {
    onFilterApply(filterValues);
    setFiltersApplied(true);
    handleMenuClose();
  };

  const handleFilterReset = () => {
    setFilterValues({
      cpf: "",
      nomecompleto: "",
      email: "",
      idade: [0, 100],
    });
    onFilterApply({
      cpf: "",
      nomecompleto: "",
      email: "",
      idade: [0, 100],
    });
    setFiltersApplied(false);
    handleMenuClose();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleFilterApply();
    }
  };

  return (
    <Box mb="30px" display="flex" alignItems="center" backgroundColor={colors.tabelas.blackCinza} padding={2} borderRadius={3}>
      <img src="/images/brasao2.webp" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        mb="5px"
        flexGrow={1}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>

      {filtersApplied && (
        <Link to={"#"}>
          <Button
            variant="contained"
            onClick={handleFilterReset}
            style={{ backgroundColor: "#FF4B00", marginRight: "10px", fontWeight: 'bold' }}
          >
            <RestartAltIcon style={{ marginBottom: 3 }}></RestartAltIcon>
            LIMPAR FILTROS
          </Button>
        </Link>
      )}

      {funcCriarNovosPacientes && (
        <Link to={`/pacientes/novo`}>

          <IconButton
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00",
              borderRadius: "4px",
              padding: "8px",
              display: "flex",
              alignItems: "center",
              color: "white", marginRight: 1
            }}

          >
            <PersonAddIcon />
            <Typography variant="button" ml={1}>
              NOVO PACIENTE
            </Typography>
          </IconButton>
        </Link>
      )}


      <IconButton
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #FF4B00",
          borderRadius: "4px",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          color: "white",
        }}
        onClick={handleMenuOpen}
      >
        <FilterIcon />
        <Typography variant="button" ml={1}>
          FILTRAR
        </Typography>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MenuItem>
            <TextField sx={{ minWidth: 300 }}
              label="CPF"
              type="text"
              value={filterValues.cpf}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "cpf")}
              autoComplete="off"
            />
          </MenuItem>
          <MenuItem>
            <TextField
              sx={{ minWidth: 300 }}
              label="Nome"
              type="text"
              value={filterValues.nomecompleto}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "nomecompleto")}
              autoComplete="off"
            />
          </MenuItem>
          <MenuItem sx={{ marginBottom: 2 }}>
            <TextField sx={{ minWidth: 300 }}
              label="E-mail"
              type="text"
              value={filterValues.email}
              onKeyPress={handleKeyPress}
              onChange={(event) => handleFilterChange(event, "email")}
              autoComplete="off"
            />
          </MenuItem>
          <Typography variant="subtitle1">Filtro de idade</Typography>
          <MenuItem sx={{ marginTop: '-25px', marginBottom: '30px' }}>

            <Slider
              sx={{
                width: 250,
                mt: 2,
                color: '#FF4B00'
              }}
              value={filterValues.idade}
              onChange={handleAgeSliderChange}
              valueLabelDisplay="auto"
              min={0}
              max={120}
            />

          </MenuItem>

          <MenuItem>
            <Button
              style={{ backgroundColor: "#ff4b00", fontWeight: "bold", marginRight: "10px", color: 'white' }}
              onClick={handleFilterApply}
            >
              Filtrar
            </Button>
            <Button
              style={{ backgroundColor: "white", fontWeight: "bold" }}
              onClick={handleFilterReset}
            >
              Voltar
            </Button>
          </MenuItem>
        </Box>
      </Popover>
    </Box>
  );
};

export default HeaderPacientes;
