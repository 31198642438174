import { reloadPage } from "../services/script";

export let KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, KEY, KEY_NAME_USER, KEY_UF, KEY_CATEGORIA, KEY_NAME_CITY, VERSION, KEY_DATA_LOGIN, KEY_LICENSE, KEY_VALIDATE, KEY_WPP_BOT, KEY_CONFIG;
export let KEY_ACESSO, KEY_MASTER = false;
export let KEY_FUNCTIONS = [];
export function getUserDate(userData) {
    try {
        const { KEY_USER, KEY_FUNCTIONS: functionsFromBackend, KEY_CONFIG: CONFIG } = userData;
        KEY_INSTANCE_ID = KEY_USER.KEY_INSTANCE_ID;
        KEY_INSTANCE_TOKEN = KEY_USER.KEY_INSTANCE_TOKEN;
        KEY_USER_TOKEN_ID = KEY_USER.KEY_USER_TOKEN_ID;
        KEY = KEY_USER.KEY;
        KEY_NAME_USER = KEY_USER.KEY_NAME_USER;
        KEY_UF = KEY_USER.KEY_UF;
        KEY_CATEGORIA = KEY_USER.KEY_CATEGORIA;
        KEY_NAME_CITY = KEY_USER.KEY_NAME_CITY;
        KEY_DATA_LOGIN = KEY_USER.KEY_DATA_LOGIN;
        KEY_LICENSE = KEY_USER.KEY_LICENSE;
        KEY_VALIDATE = KEY_USER.KEY_VALIDATE;
        KEY_WPP_BOT = KEY_USER.KEY_WPP_BOT;
        KEY_ACESSO = KEY_USER.KEY_ACESSO;
        KEY_MASTER = KEY_USER.KEY_MASTER;
        KEY_FUNCTIONS = functionsFromBackend;
        KEY_CONFIG = CONFIG


    // console.log('keys:',KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID)

    } catch (error) {
        console.error("Failed to process user data", error);
    }
}
