import { Box, Button, Divider, Grid, IconButton, TextField, Typography, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ExpandCircleDown } from "@mui/icons-material";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { reloadPage, useLastVersion } from "../../services/script";
import { UPDATE } from "../../integration/FirebaseConfig";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SendIcon from '@mui/icons-material/Send';
const FAQ = () => {

  let version = useLastVersion();

  return (
    <>
      <HelmetProvider> <Helmet>
        <title>Painel DHF - FAQ</title>
      </Helmet>
      </HelmetProvider>

      <Box p="10px" bgcolor="#1D1D1D" borderRadius="10px" style={{ marginLeft: '20px', marginRight: '20px', marginTop: '20px' }}>
        {/* <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              {version === UPDATE ? (<>SEU SISTEMA ESTA ATUALIZADO</>) : (<>
                <Box sx={{ alignContent: 'center', display: 'flex', justifySelf: 'center' }} >

                  <IconButton
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid #FF4B00",
                      borderRadius: "4px",
                      padding: "8px",
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                    onClick={reloadPage}
                  >
                    <SystemUpdateAltIcon />
                    <Typography variant="button" ml={1}>
                      ATUALIZAR PARA A VERSÃO {version}
                    </Typography>
                  </IconButton>
                </Box>
              </>)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" sx={{ color: "#9e9e9e", fontFamily: "Arial, sans-serif", fontSize: "0.7rem", fontWeight: 'bold' }}>
              Copyright © 2024 | Data Health Flow | Version: {`${version}`} | CodeVersion: {`${UPDATE}`}
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">DÚVIDAS FREQUENTES</Divider>

        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              Como acessar o sistema?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="#e0e0e0">
              Para acessar o sistema é necessário que o usuário se cadastre e que o gestor autorize o seu acesso e poderá usar o sistema conforme sua autorização
            </Typography >
          </AccordionDetails>
        </Accordion>

        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              Como visualizo as consultas marcadas para o dia?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="#e0e0e0">
              Para consultar as consultas do dia, basta o usuário acessar a home e selecionar o dia desejado e poderá ver as especialidades e a quantidade de pessoas marcadas

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              Como marco uma consulta para um paciente?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="#e0e0e0">
              Para realizar uma marcação o usuário deve ir no cadastro do paciente, clicar em +, e depois o sistema redireciona para a página de marcação onde é possível selecionar a especialidade e ver as datas e turnos disponíveis para marcação

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              Como vejo as solicitações de marcações enviadas pelo whatsApp?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="#e0e0e0">
              Para visualizar deve ir a aba de consultas a serem marcadas, onde o usuário visualiza a lista de solicitações e vai realizando a análise dessas solicitações
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              Como confirmo a presença de um paciente?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" color="#e0e0e0">
              Para confirmar a presença de um paciente, o usuário deve ir na página home e selecionar o dia atual e ir na lista de paciente, ele visualiza o nome do paciente, solicita do mesmo o código SISReg e adiciona ao sistema, confirmando assim sua presença

            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >

          <AccordionDetails>
            <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">MANUAL DO SISTEMA</Divider>

            <Typography variant="h5" color="#e0e0e0">
              Ao baixar o manual de um sistema, você terá acesso a um guia completo para utilizar o sistema de forma eficaz e segura. Este documento inclui instruções de operação, uma visão geral das funcionalidades, detalhes sobre como usar cada recurso, dicas úteis, informações sobre segurança e privacidade, solução de problemas comuns, e orientações sobre atualizações e manutenção. É o seu recurso indispensável para maximizar a utilidade e a eficiência do sistema.

              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #FF4B00",
                  borderRadius: "4px",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  marginTop: 2
                }}

              >
                <SystemUpdateAltIcon />
                <Typography variant="button" ml={1}>
                  BAIXAR MANUAL
                </Typography>
              </IconButton>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">FALE CONOSCO</Divider>

        <Accordion slotProps={{ transition: { unmountOnExit: true } }} >
          <AccordionSummary expandIcon={<ExpandCircleDown />}>
            <Typography variant="h5" color="white">
              Envie sua Mensagem
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Título"
                  fullWidth
                  required

                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} rows={4}>
                <TextField
                  label="Descrição"
                  fullWidth
                  margin="normal"
                  rows={4}
                  multiline
                  required
                  sx={{ bgcolor: '#3338' }}
                  InputLabelProps={{
                    style: { color: 'white' },
                    focused: false,
                  }}
                />
              </Grid>



            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"

                />



              </Grid>

              <Grid item xs={12} sm={4}>
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid #FF4B00",
                    borderRadius: "4px",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    marginTop: 0.9
                  }}

                >
                  <SendIcon />
                  <Typography variant="button" ml={1}>
                    ENVIAR
                  </Typography>
                </IconButton>
              </Grid>

            </Grid>


          </AccordionDetails>
        </Accordion>




      </Box>


    </>

  );
};

export default FAQ;
