import React, { useEffect, useState } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes } from 'react-router-dom';
import Sidebar from "./utils/SideBar";
import AuthProvider from "./security/AuthProvider";
import commonRoutes, { commonRoutesAcesso, commonRoutesCadastro, commonRoutesCategory, commonRoutesCPF, commonRoutesManutencao, commonRoutesStart } from "./services/CommonRoutes";
import { useFuncoes } from "./services/funcoes";

const App = () => {
  const [theme, colorMode] = useMode();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const {
    funcDeveloper,
    funcEditarUsuarios,
    funcAtualizarVagas,
    funcVisualizarVagas,
    funcAvaliacoes,
    funcDashboard,
    funcCalendarioVisualizar,
    funcCalendarioAtualizar,
    funcCargos,
    funcUsuarios,
    funcAdminDashboard,
    funcMarcacao,
    funcBaixarConfirmados,
    funcBaixarPacientes,
    funcComunicacaoChat,
    funcConfigTabelaConfirmados,
    funcComunicacaoTransmissao,
    funcComunicacaoCampanhas,
    funcAtualizarConfirmados,
    funcFinalizarConfirmados,
    funcEditarPacientes,
    funcEditarInstituicoes,
    funcEditarProfissionais,
    funcVisualizarProfissionais,
    funcExcluirProfissionais,
    funcCriarNovosProfissionais,
    funcVisualizarPacientes,
    funcVisualizarSolicitacoes,
    funcVisualizarConfirmados,
    funcVisualizarInstituicoes,
    funcExcluirInstituicoes,
    funcRelatoriosPacientes,
    funcRelatoriosSolicitacoes,
    funcRelatoriosConfirmados,
    funcCriarNovosPacientes,
    funcCriarNovasSolicitacoes,
    funcCriarNovasInstituicoes,
    funcAtualizarSolicitacoes,
    funcBaixarSolicitacoes,
    funcConfigTabelaSolicitacoes,
    funcComunicacaoTickets
  } = useFuncoes();

  const funcoes = {
    funcDeveloper,
    funcEditarUsuarios,
    funcAtualizarVagas,
    funcVisualizarVagas,
    funcAvaliacoes,
    funcDashboard,
    funcCalendarioVisualizar,
    funcCalendarioAtualizar,
    funcCargos,
    funcUsuarios,
    funcAdminDashboard,
    funcMarcacao,
    funcBaixarConfirmados,
    funcBaixarPacientes,
    funcComunicacaoChat,
    funcConfigTabelaConfirmados,
    funcComunicacaoTransmissao,
    funcComunicacaoCampanhas,
    funcAtualizarConfirmados,
    funcFinalizarConfirmados,
    funcEditarPacientes,
    funcEditarInstituicoes,
    funcEditarProfissionais,
    funcVisualizarProfissionais,
    funcExcluirProfissionais,
    funcCriarNovosProfissionais,
    funcVisualizarPacientes,
    funcVisualizarSolicitacoes,
    funcVisualizarConfirmados,
    funcVisualizarInstituicoes,
    funcExcluirInstituicoes,
    funcRelatoriosPacientes,
    funcRelatoriosSolicitacoes,
    funcRelatoriosConfirmados,
    funcCriarNovosPacientes,
    funcCriarNovasSolicitacoes,
    funcCriarNovasInstituicoes,
    funcAtualizarSolicitacoes,
    funcBaixarSolicitacoes,
    funcConfigTabelaSolicitacoes,
    funcComunicacaoTickets
  };

  const { isAuthenticated, acesso, cpf, category, master, manutencaoAtiva, cidade, loading } = AuthProvider();

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarExpanded(!isCollapsed);
  };

  useEffect(() => {

    const script = document.createElement('script');
    script.src = '//code.jivosite.com/widget/IHL4MmXQpg';
    script.async = true;
    document.body.appendChild(script);


    return () => {
      document.body.removeChild(script);
    };
  }, []);



  if (!loading) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ display: 'flex', backgroundColor: "black", justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <img src="/images/brasao_loading.webp" alt="Logo DHF" style={{ maxWidth: '40%', maxHeight: '100%', borderRadius: 20, padding: 50 }} />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );

  } else {
    
    if (isAuthenticated) {
      if (cidade) {
        if (!manutencaoAtiva) {
          if (!category || !cpf) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    <main className="content">
                      <Routes>
                        {!category && (commonRoutesCategory())}
                        {!cpf && (commonRoutesCPF())}
                      </Routes>
                    </main>
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else {
            if (acesso) {
              return (

                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                      <Sidebar onToggle={handleSidebarToggle} />
                      <main className="content" style={{ marginLeft: isSidebarExpanded ? "270px" : "80px" }}>
                        <Routes>
                          {commonRoutes(cidade, funcoes)}
                        </Routes>
                      </main>
                    </div>
                  </ThemeProvider>
                </ColorModeContext.Provider>
              );

            } else {
              return (
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <div className="app">
                      <main className="content">
                        <Routes>
                          {commonRoutesAcesso(cidade)}
                        </Routes>
                      </main>
                    </div>
                  </ThemeProvider>
                </ColorModeContext.Provider>
              );
            }
          }

        } else {
          return (
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                  <main className="content">
                    <Routes>

                      {(commonRoutesManutencao(cidade))}

                    </Routes>
                  </main>
                </div>
              </ThemeProvider>
            </ColorModeContext.Provider>
          );
        }
      } else {
        return (
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className="app">
                <main className="content">
                  <Routes>
                    {commonRoutesCadastro()}
                  </Routes>
                </main>
              </div>
            </ThemeProvider>
          </ColorModeContext.Provider>
        );
      }
    } else {
      return (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <main className="content">
                <Routes>
                  {commonRoutesStart()}
                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      );

    }
  }
};

export default App;