import React, { useState } from "react";
import { MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../theme";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from 'react-router-dom';
import { KEY } from "../integration/FirebaseConfig";
import { useTheme, Typography, ListItemButton, ListItemIcon, ListItemText, List, Collapse, ListItem, ListItemAvatar, Avatar } from "@mui/material";
import '../index.css'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Deslogar from "../authentication/Logout/Deslogar";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommentIcon from '@mui/icons-material/Comment';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import HomeIcon from '@mui/icons-material/Home';
import { useFuncoes } from "../services/funcoes";

const Item = ({ title, to, icon, selected, setSelected, collapseSidebar, disabled }) => {
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: 'colors.grey[100]',
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.4 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
            }}
            icon={React.cloneElement(icon, {
                sx: { color: selected === title ? '#FF4B00' : 'inherit' },
            })}
            onClick={() => {
                if (!disabled) {
                    setSelected(title);
                    collapseSidebar(true);
                }
            }}
        >
            <Typography sx={{ fontWeight: selected === title ? 'bold' : 'none', color: selected === title ? '#FF4B00' : 'inherit' }}>{collapseSidebar && title}</Typography>
            <Link to={to} />
        </MenuItem>

    );
};

const ItemLogout = ({ title, icon, selected, setSelected, collapseSidebar, disabled }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.4 : 1,
                pointerEvents: disabled ? 'none' : 'auto',

            }}
            icon={icon}
            onClick={() => {
                if (!disabled) {
                    console.log('ItemLogout clicado');
                    Deslogar(navigate);
                    setSelected(title);
                    collapseSidebar(true);
                }
            }}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

const MenuRouter = ({ selecteds, isCollapseds, collapseSidebar }) => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(selecteds);
    const [openPrincipal, setOpenPrincipal] = useState(true);
    const [openComunicacao, setOpenComunicacao] = useState(false);
    const [openCoordenacao, setOpenCoordenacao] = useState(false);
    const [openGestao, setOpenGestao] = useState(false);

    const {
        funcCalendarioVisualizar,
        funcVisualizarPacientes,
        funcVisualizarSolicitacoes,
        funcVisualizarConfirmados,
        funcVisualizarInstituicoes,
        funcVisualizarProfissionais,
        funcComunicacaoTickets,
        funcComunicacaoChat,
        funcComunicacaoCampanhas,
        funcCargos,
        funcUsuarios,
        funcAvaliacoes,
        funcDashboard
    } = useFuncoes();


    const handleNavigate = () => {
        navigate(`/`);
    };


    return (
        <>

            <ListItem button onClick={() => handleNavigate()}>

                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'white' }}>
                        < HomeIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={!isCollapseds && ('Inicio')} sx={{ color: 'white' }} />

            </ListItem>

            {(funcCalendarioVisualizar || funcVisualizarPacientes || funcVisualizarSolicitacoes || funcVisualizarConfirmados) && (
                <>
                    <ListItem button onClick={() => setOpenPrincipal(!openPrincipal)}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: 'white' }}>
                                < EventNoteIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={!isCollapseds && ('Principal')} sx={{ color: 'white' }} />
                        {(!isCollapseds && (openPrincipal ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />))}
                    </ListItem>

                    <Collapse in={openPrincipal} timeout="auto" unmountOnExit>
                        {funcCalendarioVisualizar && (
                            <Item
                                title="Calendário"
                                to={`/calendario`}
                                icon={<CalendarTodayOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}

                        {funcVisualizarPacientes && (
                            <Item
                                title="Pacientes"
                                to={`/pacientes`}
                                icon={<PeopleOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}

                        {funcVisualizarSolicitacoes && (
                            <Item
                                title="Solicitações"
                                to={`/solicitacoes`}
                                icon={<AssignmentIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}

                        {funcVisualizarConfirmados && (
                            <Item
                                title="Confirmados"
                                to={`/confirmados`}
                                icon={<CheckCircleOutlineIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}
                        {funcVisualizarSolicitacoes && (
                            <Item
                                title="Busca Avançada"
                                to={`/buscar`}
                                icon={<ContentPasteSearchIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}
                    </Collapse>
                </>


            )}


            {(funcComunicacaoChat || funcComunicacaoCampanhas) && (<>


                <ListItem button onClick={() => setOpenComunicacao(!openComunicacao)}>
                    <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'white' }}>
                            <CommentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={!isCollapseds && ('Comunicação')} sx={{ color: 'white' }} />
                    {(!isCollapseds && (openPrincipal ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />))}
                </ListItem>

                <Collapse in={openComunicacao} timeout="auto" unmountOnExit>
                    {funcComunicacaoChat && (
                        <Item
                            title="Chat"
                            to={`/chat`}
                            icon={<ChatBubbleOutlineIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            collapseSidebar={collapseSidebar}
                        />
                    )}

                    {funcComunicacaoCampanhas && (<>
                        <Item
                            title="Campanhas"
                            to={`/campanhas`}
                            icon={<CampaignIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            collapseSidebar={collapseSidebar}
                        />
                    </>)}

                    {/* {funcComunicacaoTickets && (<>
                        <Item
                            title="Tickets de Atendimento"
                            to={`/tickets`}
                            icon={<ConfirmationNumberIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            collapseSidebar={collapseSidebar}
                        />
                    </>)} */}


                </Collapse>
            </>)}


            {(funcVisualizarInstituicoes || funcVisualizarProfissionais) && (<>

                <ListItem button onClick={() => setOpenCoordenacao(!openCoordenacao)}>
                    <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'white' }}>
                            <BusinessCenterIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={!isCollapseds && ('Coordenação')} sx={{ color: 'white' }} />
                    {(!isCollapseds && (openPrincipal ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />))}     </ListItem>

                <Collapse in={openCoordenacao} timeout="auto" unmountOnExit>
                    {funcVisualizarInstituicoes && (
                        <Item
                            title="Instituições"
                            to={`/instituicoes`}
                            icon={<BusinessIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            collapseSidebar={collapseSidebar}
                        />
                    )}


                    {funcVisualizarProfissionais && (
                        <Item
                            title="Profissionais"
                            to={`/profissionais`}
                            icon={<GroupIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            collapseSidebar={collapseSidebar}
                        />
                    )}

                    {funcVisualizarInstituicoes && (
                        <Item
                            title="Exames"
                            to={`/exames`}
                            icon={<MonitorHeartIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            collapseSidebar={collapseSidebar}
                        />
                    )}
                </Collapse>
            </>)}



            {(funcCargos || funcUsuarios || funcDashboard || funcAvaliacoes) && (
                <>
                    <ListItem button onClick={() => setOpenGestao(!openGestao)}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: 'white' }}>
                                <AutoAwesomeMosaicIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={!isCollapseds && ('Gestão')} sx={{ color: 'white' }} />
                        {(!isCollapseds && (openPrincipal ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />))}     </ListItem>

                    <Collapse in={openGestao} timeout="auto" unmountOnExit>
                        {funcUsuarios && (

                            <Item
                                title="Usuários"
                                to={`/usuarios`}
                                icon={<SupervisedUserCircleIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />

                        )}

                        {funcCargos && (
                            <Item
                                title="Cargos"
                                to={`/cargos`}
                                icon={<WorkOutlineIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}

                        {funcDashboard && (
                            <Item
                                title="Dashboard"
                                to={`/dashboard`}
                                icon={<LeaderboardIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}

                        {funcAvaliacoes && (
                            <Item
                                title="Avaliações"
                                to={`/avaliacoes`}
                                icon={<StarHalfIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                collapseSidebar={collapseSidebar}
                            />
                        )}
                    </Collapse>
                </>

            )}



        </>
    );
};

export default MenuRouter;
