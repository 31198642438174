import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, TextField, Switch, Skeleton, Tooltip, CircularProgress, Slide, Dialog, Slider, DialogContent, ListItemText, List, FormControl, Select, MenuItem, IconButton, Grid, Modal, InputLabel, DialogTitle } from '@mui/material';
import { Popover } from '@mui/material';
import { getDatabase, ref, onValue, push, update, off, child, get } from '@firebase/database';
import firebaseApp, { KEY, KEY_LICENSE, KEY_NAME_USER, KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, url_api_gzappy, url_api_gzappy_media } from '../../integration/FirebaseConfig'
import '../../styles/ChatWhatsApp.css';
import SendIcon from '@mui/icons-material/Send';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useLocation } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import LogoutIcon from '@mui/icons-material/Logout';
import imageCompression from 'browser-image-compression';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { useSpring, animated } from 'react-spring';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Solicitados from '../solicitados';
import Marcacoes from '../marcacoes';
import CloseIcon from '@mui/icons-material/Close';
import { getStorage, uploadBytes, ref as storageRefs, getDownloadURL } from 'firebase/storage';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import RewindIcon from '@mui/icons-material/Replay';
import { datetime } from '../../services/script';
import { useFuncoes } from '../../services/funcoes';

const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);
const GZAPPY_URL = `${url_api_gzappy}`;
const GZAPPY_URL_MEDIA = `${url_api_gzappy_media}`;

const Chat = ({ id }) => {
    const [hoveredPdf, setHoveredPdf] = useState(null);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedProtocolo, setSelectedProtocolo] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [visibleMessagesCount, setVisibleMessagesCount] = useState(14);
    const [conversationStarted, setConversationStarted] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);
    const messagesEndRef = useRef(null);
    const [user, setUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [bloqueado, setbloqueado] = useState(false);
    const [banUser, setBanUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageLoading, setImageLoading] = useState(true);
    const [showPreview, setShowPreview] = useState(false);
    const [fininshLoading, setFininshLoading] = useState(false);
    const [loadingFoto, setLoadingFoto] = useState(true);
    const [loadingFigurinha, setLoadingFigurinha] = useState(true);
    const [showDropZone, setShowDropZone] = useState(false);
    const audioRef = React.useRef(null);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);

    const loadMoreMessages = () => {
        setLoadingMoreMessages(true);
        setTimeout(() => {
            setVisibleMessagesCount(prevCount => prevCount + 14);
            setLoadingMoreMessages(false);
        }, 300);
    };


    const {
        funcVisualizarSolicitacoes,
        funcVisualizarConfirmados
    } = useFuncoes();

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleSliderChange = (event, newValue) => {
        audioRef.current.currentTime = newValue;
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };


    useEffect(() => {
        const userRef = ref(database, `/db/cidades/${KEY}/users/${id}`);
        const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}/conversas`);

        const updateVisualizado = () => {
            update(ref(database, `/db/cidades/${KEY}/chats/${id}/`), { visualizado: true });
        };

        if (id === null && location.state?.id) {
            id = location.state.id;
            updateVisualizado();
            OpenScrollToBottom();
        } else if (id) {
            updateVisualizado();
            OpenScrollToBottom();
        }

        const checkConversationStatus = () => {
            onValue(userRef, (snapshot) => {
                const userData = snapshot.val();
                if (userData) {
                    setUser(userData);
                    setSelectedProtocolo('');
                    setConversationStarted(userData.init);
                    setbloqueado(userData.bloqueado);
                    setBanUser(userData.banido);
                } else {
                    setUser([]);
                    setConversationStarted(false);
                    setbloqueado(false);
                    setBanUser(false);
                }
            });
        };

        const fetchMessages = () => {
            onValue(chatRef, (snapshot) => {
                const messagesData = snapshot.val();
                if (messagesData) {
                    const messagesArray = Object.values(messagesData);
                    setMessages(messagesArray);

                    updateVisualizado();
                    scrollToBottomTST();

                    setTimeout(() => {
                        messagesEndRef.current?.scrollIntoView();
                        setLoading(false);
                    }, 1000);
                } else {
                    setMessages([]);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        };



        checkConversationStatus();
        fetchMessages();

        return () => {
            off(userRef);
            off(chatRef);
        };
    }, [id]);




    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {

            console.log('Texto copiado: ', text);
        }).catch(err => {
            console.error('Erro ao copiar texto: ', err);
        });
    };

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setOpen(true);
    };

    const handleFloatButtonClick = () => {
        scrollToBottom();
    };


    const imageAnimation = useSpring({
        from: { opacity: 0, transform: 'translateX(100%)' },
        to: { opacity: 1, transform: 'translateX(0%)' },
    });





    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView(/* { behavior: "smooth" } */);
    };

    const scrollToBottomTST = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    const OpenScrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    const handleProtocoloChange = (event) => {
        setSelectedProtocolo(event.target.value);
    };


    const iniciarConversa = () => {
        setFininshLoading(true);

        const gzappyData = {
            instance_id: `${KEY_INSTANCE_ID}`,
            instance_token: `${KEY_INSTANCE_TOKEN}`,
            message: `> ⓘ *ATENDIMENTO*\n\nOlá! Meu nome é (${KEY_NAME_USER}), a partir de agora irei continuar seu atendimento. Se precisar encerrar a conversa, é só digitar "encerrar". Estou aqui para ajudar!`,
            phone: id
        };

        fetch(GZAPPY_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user_token_id': `${KEY_USER_TOKEN_ID}`
            },
            body: JSON.stringify(gzappyData)
        })
            .then(async response => {
                if (response.ok) {
                    const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}`);
                    const messageData = {
                        hora: new Date().toLocaleTimeString(),
                        data: new Date().toLocaleDateString(),
                        enviado: 'Atendente',
                        msg: `Olá! A partir de agora irei continuar seu atendimento. Se precisar encerrar a conversa, é só digitar "encerrar". Estou aqui para ajudar!`,
                    };

                    const conversasRef = ref(database, `/db/cidades/${KEY}/chats/${id}/conversas`);

                    await push(conversasRef, messageData);
                    scrollToBottom();
                    setNewMessage('');

                    await update(ref(database, `/db/cidades/${KEY}/users/${id}`), { bloqueado: true });
                    await update(chatRef, {
                        datecheck: datetime(),
                        atendente: KEY_NAME_USER,
                        KEY_ATENDENTE: KEY_LICENSE,
                        excluir: false,
                        msg: 'Olá! A partir de agora irei continuar seu atendimento. Se precisar encerrar a conversa, é só digitar "encerrar". Estou aqui para ajudar!'
                    });
                    const chatSnapshot = await get(chatRef);
                    if (chatSnapshot.exists()) {
                        const chatData = chatSnapshot.val();
                        const ticket = chatData?.ticket;

                        if (ticket) {
                            const ticketRef = ref(database, `/db/cidades/${KEY}/tickets/${ticket}`);
                            return update(ticketRef, {
                                status: 'Atendimento',
                                responsavel: {
                                    datetime: datetime(),
                                    user: KEY_NAME_USER,
                                    atendente: KEY_LICENSE
                                }
                            });
                        } else {
                            console.error('Erro: Ticket inválido ou não existente.');
                        }
                    } else {
                        console.error('Erro: Dados do chat não encontrados.');
                    }
                } else {
                    throw new Error('Erro ao enviar mensagem para a API GZAPPY: ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('Erro durante o processo de início de conversa:', error);
                setSendingMessage(false);
                setFininshLoading(false);
            })
            .finally(() => {
                setFininshLoading(false);
                setSendingMessage(false);
            });
    };


    const finalizarConversa = () => {
        setFininshLoading(true);

        const gzappyData = {
            instance_id: `${KEY_INSTANCE_ID}`,
            instance_token: `${KEY_INSTANCE_TOKEN}`,
            message: `> ⓘ *ATENDIMENTO FINALIZADO PELO ATENDENTE*`,
            phone: id
        };

        fetch(GZAPPY_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user_token_id': `${KEY_USER_TOKEN_ID}`
            },
            body: JSON.stringify(gzappyData)
        })
            .then(async response => {
                if (response.ok) {
                    const userRef = ref(database, `/db/cidades/${KEY}/users/${id}`);
                    const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}/`);

                    return update(userRef, { bloqueado: false })
                        .then(() => get(chatRef))
                        .then((chatSnapshot) => {
                            if (chatSnapshot.exists()) {
                                const chatData = chatSnapshot.val();
                                const ticket = chatData?.ticket;

                                if (ticket) {
                                    const ticketRef = ref(database, `/db/cidades/${KEY}/tickets/${ticket}`);
                                    return update(ticketRef, {
                                        status: 'Fechado',
                                        datefinish: datetime()
                                    });
                                } else {
                                    console.error('Erro: Ticket inválido ou não existente.');
                                }
                            }
                        })
                        .then(() => {
                            return update(chatRef, { atendente: null, KEY_ATENDENTE: null, ticket: null });
                        });
                } else {
                    throw new Error("Falha no envio da mensagem pelo GZAPPY");
                }
            })
            .catch(error => {
                console.error("Erro durante o processo de finalização:", error);
            })
            .finally(() => {
                setFininshLoading(false);
                setSendingMessage(false);
            });
    };





    const toggleConversation = () => {

        if (conversationStarted) {
            setSendingMessage(true);
            update(ref(database, `/db/cidades/${KEY}/users/${id}`), { init: false })
                .then(async () => {
                    setConversationStarted(false);
                    finalizarConversa()
                })
                .catch((error) => {
                    console.error('Erro ao finalizar conversa:', error);
                });
        } else {
            setSendingMessage(true);
            update(ref(database, `/db/cidades/${KEY}/users/${id}`), { init: true })
                .then(() => {
                    setConversationStarted(true);
                    iniciarConversa();
                })
                .catch((error) => {
                    console.error('Erro ao iniciar conversa:', error);

                });
        }
    };




    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;
        if (conversationStarted === false) {
            alert("Você não iniciou a conversa.");
            return;
        }

        let messageId = '';
        let msg = newMessage;
        setNewMessage('');
        setSendingMessage(true);

        const messageData = {
            hora: new Date().toLocaleTimeString(),
            data: new Date().toLocaleDateString(),
            enviado: 'Atendente',
            msg: msg,
            atendente: `${KEY_NAME_USER}`
        };

        const chatInfoRef = ref(database, `/db/cidades/${KEY}/chats/${id}`);
        const chatInfoData = {
            hora: new Date().toLocaleTimeString(),
            data: new Date().toLocaleDateString(),
            msg: msg
        };
        try {
            await update(chatInfoRef, chatInfoData);
            console.log('Hora e data enviadas com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar hora e data:', error);
        }

        const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}/conversas`);
        try {
            const newMessageRef = await push(chatRef, messageData);
            scrollToBottom();
            /*    console.log('Mensagem enviada com sucesso!'); */
            messageId = newMessageRef.key;
            /*   console.log(messageId); */
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
        }



        const gzappyData = {
            instance_id: `${KEY_INSTANCE_ID}`,
            instance_token: `${KEY_INSTANCE_TOKEN}`,
            message: msg,
            phone: id
        };

        const response = await fetch(GZAPPY_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'user_token_id': `${KEY_USER_TOKEN_ID}`
            },
            body: JSON.stringify(gzappyData)
        });

        if (response.ok) {
            /*   console.log('Mensagem enviada para a API GZAPPY com sucesso!'); */
        } else {
            console.error('Erro ao enviar mensagem para a API GZAPPY:', response.statusText);
            setSendingMessage(false);
            removeMessageFromFirebase(chatRef, messageId, 'msg');
        }

        setSendingMessage(false);
    };


    const removeMessageFromFirebase = async (chatRef, messageId, type) => {
        const messageRef = child(chatRef, messageId);

        try {
            let errorMessage = '';
            let errorCode = '';

            switch (type) {
                case 'pdf':
                    errorMessage = 'A mensagem com o PDF não foi enviada devido a uma falha na API. Por favor, contate o suporte técnico para resolver esse problema.';
                    errorCode = '#PDF_ERR_001';
                    break;
                case 'doc':
                    errorMessage = 'O envio de documentos no formato .doc e .docx ainda não está disponível em nossa API DHF. Por favor, entre em contato com o suporte para mais informações.';
                    errorCode = '#DOC_ERR_002';
                    break;
                case 'foto':
                    errorMessage = 'A mensagem com a foto não foi enviada devido a um problema na API. Por favor, contate o suporte para resolver essa questão.';
                    errorCode = '#PHOTO_ERR_003';
                    break;
                case 'msg':
                    errorMessage = 'A mensagem não foi enviada devido a um erro na API. Entre em contato com o suporte técnico para mais informações.';
                    errorCode = '#MSG_ERR_004';
                    break;
                default:
                    errorMessage = 'Ocorreu um erro desconhecido e a mensagem não foi enviada. Por favor, contate o suporte técnico para assistência.';
                    errorCode = '#UNK_ERR_000';
                    break;
            }

            await update(messageRef, { error: errorMessage, code: errorCode });

        } catch (error) {
            console.error('Erro ao remover mensagem do Firebase:', error);
        }
    };



    const handleChangebloqueado = async () => {
        const newbloqueado = !bloqueado;
        await update(ref(database, `/db/cidades/${KEY}/users/${id}`), { bloqueado: newbloqueado });
        setbloqueado(newbloqueado);
    };

    const handleChangeBanUser = async () => {
        const newBanUser = !banUser;
        await update(ref(database, `/db/cidades/${KEY}/users/${id}`), { banido: newBanUser });
        setBanUser(newBanUser);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = async () => {
        setDialogOpen(true);

    };


    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        e.target.value = '';

        const allowedExtensions = ['pdf', 'docx', 'doc', 'png', 'jpg', 'jpeg'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            alert('Tipo de arquivo não permitido! Por favor, selecione arquivos nos formatos: .pdf, .docx, .doc, .png, .jpg, .jpeg.');
            setSelectedFile(null);
            setPreviewImage(null);
            return;
        }

        setSelectedFile(file);

        const reader = new FileReader();
        reader.onload = () => {
            let previewImage = '';
            if (fileExtension === 'pdf') {
                previewImage = "/images/pdf.webp";
            } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                previewImage = "/images/doc.webp";
            } else {
                previewImage = reader.result;
            }
            setPreviewImage(previewImage);
            setShowPreview(true);
        };
        reader.readAsDataURL(file);
    };


    const sendToGZAPPy = async (mediaUrl, chatRef, messageId, type) => {

        let msg = '';
        if (newMessage.trim() === '') {
            msg = ''
        } else {
            msg = newMessage;
        }

        console.log(mediaUrl)

        const gzappyData = {
            instance_id: `${KEY_INSTANCE_ID}`,
            instance_token: `${KEY_INSTANCE_TOKEN}`,
            mediaUrl: mediaUrl,
            message: msg,
            phone: id
        };

        try {
            const response = await fetch(GZAPPY_URL_MEDIA, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'user_token_id': `${KEY_USER_TOKEN_ID}`
                },
                body: JSON.stringify(gzappyData)
            });

            if (response.ok) {
                setSelectedFile(null);
                setPreviewImage(null);

            } else {

                removeMessageFromFirebase(chatRef, messageId, type);
                console.error('Erro ao enviar mensagem para a API GZAPPY:', response.statusText);


            }
        } catch (error) {
            removeMessageFromFirebase(chatRef, messageId, type);

            console.error('Erro ao enviar mensagem para a API GZAPPY:', error);

        }


    };


    const handleFileUpload = async () => {
        let msg = newMessage;
        let messageId = '';
        let downloadURL = '';

        if (!selectedFile) return;

        const allowedExtensions = ['pdf', 'docx', 'doc', 'png', 'jpg', 'jpeg'];
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            console.error('Tipo de arquivo não permitido!');
            alert('Tipo de arquivo não permitido! Por favor, envie arquivos nos formatos: .pdf, .docx, .doc, .png, .jpg, .jpeg.');
            setSelectedFile(null);
            return;
        }

        setSendingMessage(true);
        const fileName = `${Date.now()}_${selectedFile.name}`;
        const storageRef = firebaseApp.storage().ref();


        try {


            setNewMessage('');

            async function handleFileUpload() {
                console.log('selectedFile instanceof Blob', selectedFile instanceof Blob);
                console.log(`selectedFile size ${selectedFile.size / 1024 / 1024} MB`);

                if (fileExtension === 'pdf') {
                    if (newMessage.trim() === '') {
                        msg = 'Documento PDF';
                    }



                    const imageRef = storageRefs(storage, `whatsapp/${id}/chatDHF/${fileName}`);

                    try {
                        const uploadTaskSnapshot = await uploadBytes(imageRef, selectedFile);

                        console.log('Arquivo PDF enviado para o Firebase Storage com sucesso!');

                        downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
                    } catch (error) {
                        console.error('Erro ao fazer upload:', error);
                    }

                    console.log("PDF enviado com sucesso");

                    const messageData = {
                        hora: new Date().toLocaleTimeString(),
                        data: new Date().toLocaleDateString(),
                        enviado: 'Atendente',
                        pdf: downloadURL,
                        msg: msg,
                        atendente: `${KEY_NAME_USER}`
                    };

                    const chatInfoRef = ref(database, `/db/cidades/${KEY}/chats/${id}`);
                    const chatInfoData = {
                        hora: new Date().toLocaleTimeString(),
                        data: new Date().toLocaleDateString(),
                        msg: msg
                    };

                    try {
                        await update(chatInfoRef, chatInfoData);
                        setSendingMessage(false);
                        setSelectedFile(null);
                        setPreviewImage(null);
                        setShowPreview(false);
                        scrollToBottom();
                    } catch (error) {
                        console.error('Erro ao enviar hora e data:', error);
                    }

                    const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}/conversas`);
                    try {
                        const newMessageRef = await push(chatRef, messageData);
                        scrollToBottom();
                        messageId = newMessageRef.key;
                        sendToGZAPPy(downloadURL, chatRef, messageId, 'pdf');
                    } catch (error) {
                        console.error('Erro ao enviar mensagem:', error);
                    }
                } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                    if (newMessage.trim() === '') {
                        msg = 'Documento Word';
                    }



                    const imageRef = storageRefs(storage, `whatsapp/${id}/chatDHF/${fileName}`);

                    try {
                        const uploadTaskSnapshot = await uploadBytes(imageRef, selectedFile);
                        console.log('Arquivo Word enviado para o Firebase Storage com sucesso!');
                        downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

                    } catch (error) {
                        console.error('Erro ao fazer upload:', error);
                    }

                    const messageData = {
                        hora: new Date().toLocaleTimeString(),
                        data: new Date().toLocaleDateString(),
                        enviado: 'Atendente',
                        doc: downloadURL,
                        msg: msg,
                        atendente: `${KEY_NAME_USER}`
                    };

                    const chatInfoRef = ref(database, `/db/cidades/${KEY}/chats/${id}`);
                    const chatInfoData = {
                        hora: new Date().toLocaleTimeString(),
                        data: new Date().toLocaleDateString(),
                        msg: msg
                    };

                    try {
                        await update(chatInfoRef, chatInfoData);
                        setSendingMessage(false);
                        setSelectedFile(null);
                        setPreviewImage(null);
                        setShowPreview(false);
                        scrollToBottom();
                    } catch (error) {
                        console.error('Erro ao enviar hora e data:', error);
                    }

                    const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}/conversas`);
                    try {
                        const newMessageRef = await push(chatRef, messageData);
                        scrollToBottom();

                        messageId = newMessageRef.key;

                        sendToGZAPPy(downloadURL, chatRef, messageId, 'doc');
                    } catch (error) {
                        console.error('Erro ao enviar mensagem:', error);
                    }
                } else {

                    if (newMessage.trim() === '') {
                        msg = 'Imagem em anexo';
                    }

                    const options = {
                        maxSizeMB: 0.70,
                        maxWidthOrHeight: 1440,
                        useWebWorker: true,
                    };

                    try {
                        const compressedFile = await imageCompression(selectedFile, options);
                        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
                        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

                        const imageRef = storageRefs(storage, `whatsapp/${id}/chatDHF/${fileName}`);

                        try {
                            const uploadTaskSnapshot = await uploadBytes(imageRef, compressedFile);
                            console.log('Arquivo Word enviado para o Firebase Storage com sucesso!');
                            downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

                        } catch (error) {
                            console.error('Erro ao fazer upload:', error);
                        }

                        const messageData = {
                            hora: new Date().toLocaleTimeString(),
                            data: new Date().toLocaleDateString(),
                            enviado: 'Atendente',
                            foto: downloadURL,
                            msg: msg,
                            atendente: `${KEY_NAME_USER}`
                        };

                        const chatInfoRef = ref(database, `/db/cidades/${KEY}/chats/${id}`);
                        const chatInfoData = {
                            hora: new Date().toLocaleTimeString(),
                            data: new Date().toLocaleDateString(),
                            msg: msg
                        };

                        try {
                            await update(chatInfoRef, chatInfoData);
                            setSendingMessage(false);
                            setSelectedFile(null);
                            setPreviewImage(null);
                            setShowPreview(false);
                            scrollToBottom();
                        } catch (error) {
                            console.error('Erro ao enviar hora e data:', error);
                        }

                        const chatRef = ref(database, `/db/cidades/${KEY}/chats/${id}/conversas`);
                        try {
                            const newMessageRef = await push(chatRef, messageData);
                            scrollToBottom();

                            messageId = newMessageRef.key;

                            sendToGZAPPy(downloadURL, chatRef, messageId, 'foto');
                        } catch (error) {
                            console.error('Erro ao enviar mensagem:', error);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            }

            handleFileUpload();
        } catch (error) {
            console.error('Erro ao enviar arquivo:', error);
        }
    };


    const handleCancelPreview = () => {
        setSelectedFile(null);
        setPreviewImage(null);
        setShowPreview(false);
    };



    return (
        <>
            <HelmetProvider><Helmet>
                <title>Painel DHF - Chat</title>
            </Helmet></HelmetProvider>


            <Popover
                id="opcoes"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >

                <List>
                    <MenuItem>
                        <ListItemText primary="Desativar MSG Automaticas:" />
                        <Switch
                            checked={bloqueado}
                            onChange={handleChangebloqueado}
                            sx={{
                                '& .MuiSwitch-track': {
                                    backgroundColor: bloqueado ? '#ff4b00' : '#757575',
                                },
                                '& .MuiSwitch-thumb': {
                                    backgroundColor: bloqueado ? '#ff4b00' : '#f0f0f0',
                                },
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <ListItemText primary="Bloquear Usuario:" />
                        <Switch
                            checked={banUser}
                            onChange={handleChangeBanUser}
                            sx={{
                                '& .MuiSwitch-track': {
                                    backgroundColor: banUser ? '#ff4b00' : '#757575',
                                },
                                '& .MuiSwitch-thumb': {
                                    backgroundColor: banUser ? '#ff4b00' : '#f0f0f0',
                                },
                            }}
                        />
                    </MenuItem>
                </List>
            </Popover>

            <Box onDragOver={(e) => {
                e.preventDefault();
                setShowDropZone(true);
            }}
            >
                <Box style={{ top: 0, right: 0, padding: 7.5, display: 'flex', backgroundColor: '#1D1D1D' }}>

                    <Box
                        variant="contained"
                        style={{
                            backgroundColor: '#1D1D1D',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            marginLeft: 20
                        }}
                        onClick={handleOpenDialog}


                    >
                        <img src="/images/protocolos.webp" alt='Protocolos' style={{ maxWidth: 22, opacity: 1, marginRight: 10 }} />
                    </Box>

                    <Box style={{ marginLeft: 10, marginRight: 10, display: 'flex', alignItems: 'center' }}>
                        <Box borderRight="1px solid gray" height='30px'></Box>
                    </Box>

                    <Box
                        variant="contained"
                        style={{
                            backgroundColor: '#1D1D1D',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 10
                        }}
                    >
                        <img src="/images/reportar.webp" alt='Reportar' style={{ maxWidth: 25, opacity: 1, marginRight: 10 }} />
                    </Box>

                    <Box style={{ marginLeft: 10, marginRight: 10, display: 'flex', alignItems: 'center' }}>
                        <Box borderRight="1px solid gray" height='30px'></Box>
                    </Box>

                    <Box
                        variant="contained"
                        style={{
                            backgroundColor: "#1D1D1D",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            marginLeft: 10

                        }}
                        onClick={handleClick}
                    >
                        <img src="/images/opcoes.webp" alt='Opções' style={{ maxWidth: 25, opacity: 1, marginRight: 10 }} />
                    </Box>



                    <Box flex="1" style={{
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 25
                    }}>


                        <Box style={{
                            position: 'relative',
                            justifyContent: 'center'
                            , display: 'flex', alignItems: 'center',
                        }}>
                            <img src="/images/user.webp" style={{
                                maxWidth: 30, opacity: 1, marginRight: 10, justifyContent: 'center', display: 'flex',

                            }} />

                        </Box>
                        <Box style={{
                            position: 'relative',
                            justifyContent: 'center'
                            , display: 'flex', alignItems: 'center',
                        }}>
                            {user && user.nome ? (
                                <Box>
                                    <Typography style={{ fontSize: 13, fontWeight: 'bold', marginTop: 5 }}>Você está conversando com {user.nome}</Typography>
                                </Box>
                            ) : (<> <Box>
                                <Typography style={{ fontSize: 13, fontWeight: 'bold', marginTop: 5 }}>Você está conversando com {id}</Typography>
                            </Box></>)}



                        </Box>


                    </Box>



                    {conversationStarted && (
                        <IconButton
                            variant="contained"
                            disabled={fininshLoading}
                            style={{ fontWeight: 'bold', borderRadius: 3, fontSize: 12 }}
                            onClick={toggleConversation}
                        >

                            <LogoutIcon fontSize="large" style={{ marginRight: 5 }} />
                            FINALIZAR CONVERSA
                        </IconButton>
                    )}

                    {!conversationStarted && (
                        <IconButton
                            disabled={fininshLoading}
                            variant="contained"
                            style={{ fontWeight: 'bold', borderRadius: 3, fontSize: 12 }}
                            onClick={toggleConversation}
                        >

                            <PlayCircleFilledWhiteIcon fontSize="large" style={{ marginRight: 5 }} />
                            INICIAR CONVERSA
                        </IconButton>
                    )}




                </Box>

                <Box
                    className="chat__container"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '92vh',
                        minWidth: '50vh',
                        overflow: 'hidden',
                    }}

                >
                    <Box
                        className="chat__body"
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            padding: '0 5px',
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            paddingBottom: 1
                        }}
                    >

                        {loadingMoreMessages && (
                            <>
                                {[...Array(10)].map((_, index) => (
                                    <Skeleton
                                        key={index}
                                        variant="rectangular"
                                        animation="wave"
                                        height={45}
                                        sx={{
                                            marginBottom: '10px',
                                            borderRadius: '8px',
                                        }}
                                    />
                                ))}
                            </>
                        )}


                        <Box
                            className="message-container"
                            sx={{
                                position: 'relative',
                                minHeight: '100%',

                                transform: `translateY(${visibleMessagesCount > 10 ? '20px' : '0px'})`,
                                transition: 'transform 0.5s ease-out',
                            }}
                        >
                            {loading ? (<>


                                <>
                                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '10px' }}>
                                        <Grid item xs={10} style={{ position: 'relative' }}>
                                            <Skeleton variant="rounded" animation="wave" width="100%" height={45} />
                                            <Typography
                                                variant="h6"
                                                color="white"
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    color: 'white',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                Carregando mensagens...
                                            </Typography>
                                        </Grid>
                                    </Grid>




                                </>





                            </>) : (<>
                                <Button onClick={loadMoreMessages} sx={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #FF4B00",
                                    borderRadius: "4px",
                                    padding: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "white",
                                    marginRight: 1,
                                    marginBottom: "10px"
                                }}>
                                    Carregar mais mensagens
                                </Button>
                                {messages
                                    .slice(messages.length - visibleMessagesCount, messages.length)
                                    .map((message, index) => (

                                        <Slide
                                            key={index}
                                            direction="down"
                                            in={true}
                                            mountOnEnter
                                            unmountOnExit
                                            timeout={500}
                                        >
                                            <div
                                                key={index}
                                                className={`message ${message.enviado === 'Atendente' ? 'chat__receiver' : 'chat__message'}`}
                                            >


                                                <Typography style={{ color: 'white', fontSize: 15 }}>
                                                    {message.msg || 'Não identificado'}
                                                </Typography>


                                                {message.foto && (
                                                    <Tooltip title="Visualizar Imagem">
                                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                                            {loadingFoto && (
                                                                <img
                                                                    src="/images/brasao_loading.webp"
                                                                    alt="Carregando..."
                                                                    style={{
                                                                        maxWidth: '200px',
                                                                        marginTop: 5,
                                                                        borderRadius: 5,
                                                                    }}
                                                                />
                                                            )}
                                                            <img
                                                                src={message.foto}
                                                                alt="Foto"
                                                                style={{
                                                                    maxWidth: '200px',
                                                                    marginTop: 5,
                                                                    borderRadius: 5,
                                                                    cursor: 'pointer',
                                                                    display: loadingFoto ? 'none' : 'block',
                                                                }}
                                                                onLoad={() => setLoadingFoto(false)}
                                                                onClick={() => handleImageClick(message.foto)}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                )}

                                                {message.figurinha && (
                                                    <Tooltip title="Visualizar Figurinha">
                                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                                            {loadingFigurinha && (
                                                                <img
                                                                    src="/images/brasao_loading.webp"
                                                                    alt="Carregando..."
                                                                    style={{
                                                                        maxWidth: '165px',
                                                                        marginTop: 5,
                                                                        borderRadius: 5,
                                                                    }}
                                                                />
                                                            )}
                                                            <img
                                                                src={message.figurinha}
                                                                alt="Figurinha"
                                                                style={{
                                                                    maxWidth: '165px',
                                                                    marginTop: 5,
                                                                    borderRadius: 5,
                                                                    cursor: 'pointer',
                                                                    display: loadingFigurinha ? 'none' : 'block',
                                                                }}
                                                                onLoad={() => setLoadingFigurinha(false)}
                                                                onClick={() => handleImageClick(message.figurinha)}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                )}


                                                {message.mp3 && (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                bgcolor: '#1d1d1d',
                                                                borderRadius: 1,
                                                                padding: 1,
                                                                width: '20vh',
                                                                marginLeft: 1,
                                                                marginRight: 1,
                                                                marginTop: 0.5,
                                                                marginBottom: 1,
                                                                borderRadius: 6,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                boxShadow: 3,
                                                                border: '1px solid #ff4800',
                                                            }}
                                                        >
                                                            {message.mp3 && (
                                                                <>
                                                                    <audio
                                                                        ref={audioRef}
                                                                        src={message.mp3}
                                                                        onLoadedMetadata={handleLoadedMetadata}
                                                                        onTimeUpdate={handleTimeUpdate}
                                                                        style={{ display: 'none' }}
                                                                    />
                                                                    <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
                                                                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                                                    </IconButton>
                                                                    <Box sx={{ flexGrow: 1, marginLeft: 1.5, marginTop: 1 }}>
                                                                        <Slider
                                                                            value={currentTime}
                                                                            onChange={handleSliderChange}
                                                                            aria-labelledby="position-slider"
                                                                            min={0}
                                                                            max={duration}
                                                                            sx={{ color: 'white' }}
                                                                        />
                                                                    </Box>
                                                                    <Typography variant="caption" color="white" sx={{ marginLeft: 2, marginRight: 1 }}>
                                                                        {`${Math.floor(currentTime / 60)}:${('0' + Math.floor(currentTime % 60)).slice(-2)} / ${Math.floor(duration / 60)}:${('0' + Math.floor(duration % 60)).slice(-2)}`}
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </Box>
                                                    </>
                                                )}

                                                {message.video && (
                                                    <video controls style={{ marginTop: 5, maxWidth: '200px' }}>
                                                        <source src={message.video} type="video/mp4" />
                                                        Seu navegador não suporta o elemento de vídeo.
                                                    </video>
                                                )}

                                                {message.pdf && (
                                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                                        <Tooltip title="Visualizar anexo">
                                                            <IconButton
                                                                onClick={() => setHoveredPdf(prev => prev === message.pdf ? null : message.pdf)}
                                                                sx={{
                                                                    padding: 0,
                                                                    marginLeft: 1,
                                                                }}
                                                            >
                                                                <Box
                                                                    component="img"
                                                                    src="/images/pdf.webp"
                                                                    alt="PDF Preview"
                                                                    sx={{
                                                                        maxWidth: '125px',
                                                                        objectFit: 'cover',
                                                                        borderRadius: 1,
                                                                        transition: 'border 0.3s ease',
                                                                        '&:hover': {
                                                                            border: '0px solid white',
                                                                        },
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Slide
                                                            direction={message.enviado === 'usuario' ? "right" : "left"}
                                                            in={hoveredPdf === message.pdf}
                                                            mountOnEnter
                                                            unmountOnExit
                                                            timeout={300}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    [message.enviado === 'usuario' ? 'right' : 'left']: '-365px',
                                                                    top: 0,
                                                                    width: '350px',
                                                                    height: '100%',
                                                                    backgroundColor: '#262626',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderRadius: 1,
                                                                    padding: 1,
                                                                    zIndex: 1,
                                                                }}
                                                            >
                                                                <iframe
                                                                    src={message.pdf}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '400px',
                                                                        border: 'none',
                                                                    }}
                                                                    title="PDF Preview"
                                                                />
                                                            </Box>
                                                        </Slide>


                                                    </div>
                                                )}

                                                <Typography style={{ color: 'white', fontSize: 11, textAlign: 'right' }}>
                                                    {message.data}, {message.hora.split(':').slice(0, 2).join(':')}
                                                </Typography>


                                                {message.error != null && (
                                                    <>
                                                        <Typography
                                                            style={{
                                                                marginTop: 5,
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            Error: {message.code}
                                                        </Typography>
                                                        <Typography
                                                            className={`chat__receiver'`}
                                                            style={{
                                                                color: 'white',
                                                                fontStyle: 'italic',
                                                                fontSize: 13,
                                                                marginTop: 2,
                                                                backgroundColor: '#474747',
                                                                padding: 5,
                                                                borderRadius: 10,
                                                                width: 200,
                                                                border: '1px solid red',
                                                            }}
                                                        >
                                                            {message.error}
                                                        </Typography>
                                                    </>
                                                )}
                                            </div>
                                        </Slide>
                                    ))}


                                <div style={{ marginBottom: '-50px', height: 0 }} ref={messagesEndRef} />

                            </>)}

                        </Box>

                    </Box>


                    <Box sx={{ width: '100%' }}>
                        <Slide direction="right" in={showPreview} mountOnEnter unmountOnExit>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 70,
                                    left: 10,
                                    p: 1,
                                    backgroundColor: '#1D1D1D',
                                    borderRadius: 2,
                                    border: '0.5px solid #323232',
                                    zIndex: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '250px',
                                    height: '250px',
                                    boxShadow: '0 10px 10px rgba(0,0,0,0.9)',
                                    overflow: 'hidden',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: 'white', flex: 1 }}
                                    >
                                        Pré-visualização do envio
                                    </Typography>
                                    <IconButton
                                        onClick={handleCancelPreview}
                                        sx={{ color: 'white' }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Box
                                    component="img"
                                    src={previewImage}
                                    alt="Preview"
                                    sx={{
                                        maxWidth: '100%',
                                        maxHeight: 'calc(100% - 40px)',
                                        objectFit: 'contain',
                                        borderRadius: 2,
                                    }}
                                />
                                {sendingMessage && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 20,
                                            borderRadius: 2,
                                            p: 2,
                                        }}
                                    >
                                        <CircularProgress sx={{ color: '#ff4b00' }} />
                                        <Typography
                                            variant="body2"
                                            sx={{ color: 'white', mt: 1 }}
                                        >
                                            Enviando Anexo...
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Slide>



                        <Box
                            className="chat__footer"
                            sx={{
                                p: 1,
                                backgroundColor: '#1D1D1D',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                position: 'relative',
                            }}

                        >
                            {showDropZone && (
                                <Slide direction="down" in={showDropZone}>

                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 70,
                                            left: 10,
                                            p: 1,
                                            backgroundColor: '#1D1D1D',
                                            borderRadius: 2,
                                            border: '0.5px solid #323232',
                                            zIndex: 10,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            width: '250px',
                                            height: '250px',
                                            boxShadow: '0 10px 10px rgba(0,0,0,0.9)',
                                            overflow: 'hidden',
                                        }} onDragOver={(e) => {
                                            e.preventDefault();
                                            setShowDropZone(true);
                                        }}
                                        onDragEnter={(e) => {
                                            e.preventDefault();
                                            setShowDropZone(true);
                                        }}

                                        onDrop={(e) => {
                                            e.preventDefault();
                                            setShowDropZone(false);
                                            const file = e.dataTransfer.files[0];
                                            if (file) {
                                                handleFileChange({ target: { files: [file] } });
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: 'white', flex: 1 }}
                                            >
                                                Anexar arquivo
                                            </Typography>
                                            <IconButton
                                                onClick={() => setShowDropZone(false)}
                                                sx={{ color: 'white' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 45,
                                                left: 0,
                                                width: '100%',
                                                height: '85%',

                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                zIndex: 20,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ color: 'white' }}>
                                                Solte aqui para enviar
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Slide>

                            )}


                            {sendingMessage ? (<> <IconButton disabled={true}
                                color="inherit"
                                component="span"
                            >
                                <AttachFileIcon fontSize="large" sx={{ color: 'black' }} />
                            </IconButton></>) : (<> <input
                                id="file-input"
                                type="file"
                                accept=".pdf, .docx, .doc, .png, .jpg, .jpeg"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />

                                <label htmlFor="file-input">
                                    <IconButton

                                        color="inherit"
                                        component="span"
                                    >
                                        <AttachFileIcon fontSize="large" />
                                    </IconButton>
                                </label></>)}


                            <TextField
                                inputRef={inputRef}
                                placeholder="Digite sua mensagem"
                                variant="outlined"
                                fullWidth
                                size="small"
                                sx={{
                                    backgroundColor: '#2e2e2e',
                                    borderRadius: 2,
                                    input: { color: 'white' }
                                }}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        selectedFile ? handleFileUpload() : handleSendMessage();
                                    }
                                }}
                            />

                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#1d1d1d", minWidth: 48 }}
                                disabled={sendingMessage}
                                onClick={selectedFile ? handleFileUpload : handleSendMessage}
                            >
                                {sendingMessage ? (
                                    <CircularProgress size={24} sx={{ color: '#ff4b00' }} />
                                ) : (
                                    <SendIcon fontSize="large" />
                                )}
                            </Button>

                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#727272", color: '#b2b2b2', ml: 1, minWidth: 48 }}
                            >
                                <ScheduleSendIcon />
                            </Button>

                            {/* <IconButton

                                sx={{ position: 'absolute', bottom: 80, left: 10 }}
                                onClick={handleFloatButtonClick}
                            >
                                <ExpandCircleDownIcon sx={{ fontSize: 30 }} />
                            </IconButton> */}

                            <IconButton
                                sx={{ position: 'absolute', bottom: 80, right: 10 }}
                                onClick={handleFloatButtonClick}
                            >
                                <ExpandCircleDownIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box >


            <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="xl">
                <DialogTitle style={{ textAlign: 'center' }}>Busca avançada de protocolos</DialogTitle>
                <DialogContent>



                    <Box display="flex" alignItems="center" >

                        {user && (<> <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Nome"
                                    fullWidth
                                    value={user.nomecompleto || ''}

                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputProps={{

                                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' },
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => handleCopy(user.nomecompleto || '')}
                                                edge="end"
                                                size="small"
                                                aria-label="copy nome"
                                            >
                                                <ContentCopyIcon style={{ color: 'white' }} />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="CPF"
                                    fullWidth
                                    value={user.cpf}

                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputProps={{

                                        style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' },
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => handleCopy(user.cpf)}
                                                edge="end"
                                                size="small"
                                                aria-label="copy cpf"
                                            >
                                                <ContentCopyIcon style={{ color: 'white' }} />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl variant="filled" fullWidth disabled={(!funcVisualizarConfirmados && !funcVisualizarSolicitacoes)}>
                                    <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}> Selecione uma opção:</InputLabel>
                                    <Select
                                        labelId="protocolo-label"
                                        id="protocolo-select"
                                        value={selectedProtocolo}
                                        onChange={handleProtocoloChange}
                                    >
                                        {funcVisualizarSolicitacoes && (
                                            <MenuItem key={1} value={'solicitados'}>
                                                Solicitações
                                            </MenuItem>
                                        )}

                                        {funcVisualizarConfirmados && (
                                            <MenuItem key={2} value={'marcacao'}>
                                                Marcações
                                            </MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        </>)}


                    </Box>

                    {selectedProtocolo === 'solicitados' && (<>     <Solicitados /></>)}
                    {selectedProtocolo === 'marcacao' && (<> <Marcacoes /></>)}

                </DialogContent >
            </Dialog>


            <Modal
                open={open}
                onClose={handleCloseModal}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                    <Box
                        sx={{
                            position: 'relative',
                            backgroundColor: 'transparent',
                            borderRadius: 8,
                            padding: 2,
                            maxWidth: '100vw',
                            maxHeight: '100vh',
                            overflow: 'hidden',
                            justifyContent: 'center',
                            textAlign: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                backgroundColor: '#FF4B00',
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                color: 'white',
                                zIndex: 1,
                            }}
                        >
                            <CloseIcon sx={{ fontSize: '30px' }} />
                        </IconButton>
                        {selectedImage && (
                            <>
                                <CircularProgress
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                    }}
                                    size={50}
                                    style={{ display: imageLoading ? 'block' : 'none' }}
                                />
                                <img
                                    src={selectedImage}
                                    alt="Imagem"
                                    style={{
                                        maxWidth: '600px',
                                        maxHeight: '600px',
                                        width: 'auto',
                                        height: 'auto',
                                        display: imageLoading ? 'none' : 'block',
                                    }}
                                    onLoad={() => setImageLoading(false)}
                                    onError={() => setImageLoading(false)}
                                />

                            </>
                        )}
                    </Box>
                </Slide>
            </Modal>


        </>
    );
};

export default Chat;
