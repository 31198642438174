import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { get, getDatabase, set, onValue, push, ref, update } from "firebase/database";
import 'react-calendar/dist/Calendar.css';
import firebaseApp, { KEY } from '../../../integration/FirebaseConfig';
import { BASE_PROD } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { cpfIsValid, datetime, formatarData, isCPF, maskCPF, priorities } from "../../../services/script";
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';

const database = getDatabase(firebaseApp);



const NovoPaciente = () => {

  const navigate = useNavigate();
  const [sucesso, setSucesso] = useState(false);
  const [isDateFocused, setDateFocused] = useState(false);
  const [nome, setNome] = useState('');
  const [nomesocial, setNomeSocial] = useState('');
  const [nomecompleto, setNomeCompleto] = useState('');
  const [bairro, setBairro] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [cns, setCns] = useState('');
  const [telefone, setTelefone] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [datanascimento, setDataNascimento] = useState('');
  const [genero, setGenero] = useState('');
  const [prioridade, setPrioridade] = useState('nao');
  const [portador, setPortador] = useState('');
  const [cep, setCep] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [tipoPrioridade, setTipoPrioridade] = useState('');


  const handleNavigateBack = () => {

    navigate(`/pacientes`);

  };


  const handleTelefoneChange = (event) => {
    let { value } = event.target;


    value = value.slice(0, 14);

    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
    setTelefone(formattedValue);
  };

  const handleNavigate = () => {
    setTimeout(() => {
      setSucesso(true);
      navigate(`/pacientes`);
    }, 1500);
  };


  const checkCEP = async (event) => {
    const novoCEP = event.target.value.replace(/\D/g, '').slice(0, 8);
    console.log(novoCEP);
    try {
      const response = await fetch(`https://viacep.com.br/ws/${novoCEP}/json/`);
      const data = await response.json();
      console.log(data);
      if (!data.erro) {
        setEstado(data.uf);
        setCidade(data.localidade);
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
      } else {
        setEstado("");
        setCidade("");
        setLogradouro("");
        setBairro("");
        console.error("CEP não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };


  const handleCEPChange = (event) => {
    let novoCEP = event.target.value;
    novoCEP = novoCEP.replace(/\D/g, '').slice(0, 8);
    setCep(novoCEP);
    if (novoCEP.length === 8) {
      checkCEP(event);
    }
  };




  const handleCPF = (event) => {
    let cpf = event.target.value;
    const cpfNumeros = cpf.replace(/\D/g, '');

    if (cpfNumeros.length <= 11) {

      setCpf(maskCPF(cpf));
    }
  };


  const handleSalvar = async () => {

    setIsDisabled(true);
    const telefoneSemMascara = "55" + telefone.replace(/\D/g, '');
    const dataFormatada = formatarData(datanascimento);
    const db = ref(database, `${BASE_PROD}/${KEY}/users/` + telefoneSemMascara);
    const missingFields = [];

    if (!cpf) {
      missingFields.push("CPF");
    } else {
      if (!cpfIsValid(cpf)) {
        alert(`Por favor, preencha um CPF Válido!`);
        return
      }
    }

    const cpfSemMascara = cpf.replace(/\D/g, ''); 

    if (!nome) {
      missingFields.push("Nome");
    }

    if (!logradouro) {
      missingFields.push("Logradouro");
    }
    if (!cep) {
      missingFields.push("CEP");
    }
    if (!rg) {
      missingFields.push("RG (Registro Geral)");
    }

    if (!email) {
      missingFields.push("E-mail");
    }

    if (!telefone) {
      missingFields.push("Telefone");
    }

    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields.join(", ");
      alert(`Por favor, preencha os seguintes campos: ${missingFieldsMessage}`);

      return;
    }

    await set(db, {
      nome,
      nomesocial,
      nomecompleto,
      cadastrado: true,
      cor: tipoPrioridade,
      cpf: cpfSemMascara,
      rg,
      cns,
      telefone: telefoneSemMascara,
      email,
      estadoCivil,
      datanascimento: dataFormatada,
      genero,
      prioridade,
      portador,
      cep,
      datetime: datetime(),
      estado,
      cidade,
      logradouro,
      bairro,
      numero,
      complemento,
      localidade,
      ativo: false,
      menu: 0,
      init: false,
      homeCheck: false,
      nameStart: false,
      lifeBan: 3,
      bloqueado: false,
      banido: false,
      cadastroDependente: false,
    });

    setNome('');
    setBairro('');
    setNomeSocial('');
    setNomeCompleto('');
    setCpf('');
    setRg('');
    setCns('');
    setTelefone('');
    setEmail('');
    setEstadoCivil('');
    setDataNascimento('');
    setGenero('');
    setPrioridade('');
    setPortador('');
    setCep('');
    setEstado('');
    setCidade('');
    setLogradouro('');
    setNumero('');
    setComplemento('');
    setLocalidade('');
    setSucesso(true)
    handleNavigate();

  };

  const handleCloseSnackbar = () => {
    setSucesso(false);
  };


  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Painel DHF - Novo Paciente</title>
        </Helmet>
      </HelmetProvider>

      <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
        <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">CADASTRAR NOVO PACIENTE</Divider>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Primeiro Nome"
                fullWidth
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome Completo"
                fullWidth
                value={nomecompleto}
                onChange={(e) => setNomeCompleto(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Nome Social"
                fullWidth
                value={nomesocial}
                onChange={(e) => setNomeSocial(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="CPF"
                fullWidth
                value={cpf}
                onChange={handleCPF}
                sx={{ bgcolor: '#3338' }}
                inputProps={{
                  maxLength: 14,
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>


            <Grid item xs={12} sm={4}>
              <TextField
                label="Registro Geral (RG)"
                fullWidth
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="CNS"
                fullWidth
                value={cns}
                onChange={(e) => setCns(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Telefone"
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={telefone}
                onChange={handleTelefoneChange}
                onFocus={() => setDateFocused(true)}
                onBlur={() => setDateFocused(false)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: isDateFocused,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="E-mail"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Estado Civil"
                select
                fullWidth

                sx={{ bgcolor: '#3338' }}
                value={estadoCivil}
                onChange={(event) => setEstadoCivil(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >
                <MenuItem value="solteiro">Solteiro (a)</MenuItem>
                <MenuItem value="casado">Casado (a)</MenuItem>
                <MenuItem value="divorciado">Divorciado (a)</MenuItem>
                <MenuItem value="outro">Outro</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label={isDateFocused ? "Data de Nascimento" : null}
                type="date"
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={datanascimento}
                onChange={(event) => setDataNascimento(event.target.value)}
                onFocus={() => setDateFocused(true)}
                onBlur={() => setDateFocused(false)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: isDateFocused,
                }}
              />
            </Grid>



            <Grid item xs={12} sm={2}>
              <TextField
                label="CEP"
                fullWidth
                value={cep}
                onChange={handleCEPChange}
                sx={{ bgcolor: '#3338' }}
                inputProps={{
                  maxLength: 8,
                  inputMode: 'numeric',
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <TextField
                label="UF"
                fullWidth
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Município"
                fullWidth
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Endereço"
                fullWidth
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Número"
                fullWidth
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Bairro"
                fullWidth
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Complemento"
                fullWidth
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>


            <Grid item xs={12} sm={2}>
              <TextField
                label="Pessoa com Deficiência?"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={portador}
                onChange={(event) => setPortador(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >

                <MenuItem value="não">Não</MenuItem>
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="Deficiência Auditiva">Deficiência Auditiva</MenuItem>
                <MenuItem value="Deficiência Visual">Deficiência Visual</MenuItem>
                <MenuItem value="Deficiência Psicossocial">Deficiência Psicossocial</MenuItem>
                <MenuItem value="Deficiência Múltipla">Deficiência Múltipla</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Prioridade"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={prioridade}
                onChange={(event) => setPrioridade(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="nao">Não</MenuItem>
              </TextField>
            </Grid>

            {prioridade === 'sim' ? (
              <>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Tipo de Prioridade"
                    select
                    fullWidth
                    sx={{ bgcolor: '#3338' }}
                    value={tipoPrioridade}
                    onChange={(event) => setTipoPrioridade(event.target.value)}
                    InputLabelProps={{
                      style: { color: 'white' },
                      focused: false,
                    }}
                  >
                    {priorities.map((priority) => (
                      <MenuItem key={priority.value} value={priority.value}>
                        {priority.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography>Gênero</Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"

                    value={genero}
                    onChange={(event) => setGenero(event.target.value)}
                  >
                    <FormGroup row>
                      <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                      <FormControlLabel value="feminino" control={<Radio />} label="Feminino" />
                      <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                    </FormGroup>
                  </RadioGroup>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography>Gênero</Typography>
                <RadioGroup
                  aria-label="gender"
                  name="gender"

                  value={genero}
                  onChange={(event) => setGenero(event.target.value)}
                >
                  <FormGroup row>
                    <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                    <FormControlLabel value="feminino" control={<Radio />} label="Feminino" />
                    <FormControlLabel value="outro" control={<Radio />} label="Outro" />
                  </FormGroup>
                </RadioGroup>
              </Grid>
            )}


            <Grid item xs={12} sm={11} style={{ padding: 15, textAlign: 'left', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#4444", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7, fontVariantCaps: 'normal' }}
                onClick={handleNavigateBack}
              >

                <CancelIcon style={{ marginRight: 2 }} fontSize="large" />
                Cancelar
              </Button>



            </Grid>

            <Grid item xs={12} sm={1} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                onClick={handleSalvar}
                disabled={isDisabled}
              >
                <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                CONCLUIR
              </Button>
            </Grid>



          </Grid>
        </form>
      </Box>

      <Snackbar
        open={sucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>
          NOVO PACIENTE CADASTRADO COM SUCESSO!
        </Alert>
      </Snackbar>


    </>
  );
};

export default NovoPaciente;
