
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import HeaderProfissionais from "../../utils/HeaderProfissionais";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDatabase, ref, get, remove } from '@firebase/database';
import firebaseApp, { KEY } from '../../integration/FirebaseConfig';
import { BASE_PROD } from '../../integration/FirebaseConfig';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useFuncoes } from "../../services/funcoes";


const database = getDatabase(firebaseApp);

const Profissionais = () => {
  const [loading, setLoading] = useState(true);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState(null);
  const [userData, setUserData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();
  const {
    funcEditarProfissionais,
    funcExcluirProfissionais,
  } = useFuncoes();

  const fetchData = async () => {
    try {
      const database = getDatabase();
      const usersRef = ref(database, `${BASE_PROD}/${KEY}/profissionais`);
      const usersSnapshot = await get(usersRef);
      const data = usersSnapshot.val();

      if (data) {
        const instituicoes = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setUserData(instituicoes);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {

    fetchData();

  }, []);


 

  const columns = [

    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },

    {
      field: "matricula",
      headerName: "Matrícula",
      flex: 1,
      filterable: true,
    },

    {
      field: "especialidade",
      headerName: "Especialidade",
      flex: 1,
      filterable: true,
    },
    {
      field: "datetime",
      headerName: "Ultima Atualização",
      flex: 1,
      filterable: true,
    },
    {
      field: "cadastrante",
      headerName: "Cadastrante",
      flex: 1,
      filterable: true,
    },


    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          <IconButton
            onClick={() => handleDeleteConfirmation(row.id)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
            }}
            disabled={!funcExcluirProfissionais}
          >
            <DeleteOutlineIcon />
          </IconButton>


          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
            disabled={!funcEditarProfissionais}
          >
            <EditOutlinedIcon />
          </IconButton>

        </Box>
      ),

    },

  ];


  const handleAdd = (user) => {
    navigate(`/profissionais/editar`, { state: { userData: user } });
  }

  const handleDeleteConfirmation = (id) => {
    setInstituicaoSelecionada(id);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteInstituicao(instituicaoSelecionada);
    setDeleteConfirmationDialogOpen(false);
  };

  const deleteInstituicao = (id) => {
    const instituicaoRef = ref(database, `${BASE_PROD}/${KEY}/profissionais/${id}`);
    try {
      remove(instituicaoRef);
      fetchData();
    } catch (error) {
      console.error('Erro ao excluir a instituição:', error);
    }
  };






  return (
    <>
      <Box m="10px">
        <HeaderProfissionais title="PROFISSIONAIS DE SAÚDE" />

        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

          </Box>
        </Backdrop>

        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columns}
              rowHeight={80}
              filterMode="server"
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}

            />
          </Box>
        ) : (
          <Typography variant="h5" textAlign="center" mt="70px">
            Esta tela é muito pequena para exibir esse elemento.
          </Typography>
        )}
      </Box>


      <Dialog open={deleteConfirmationDialogOpen} onClose={() => setDeleteConfirmationDialogOpen(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir este Profissional?</Typography>
        </DialogContent>
        <DialogActions>
          <Button style={{
            backgroundColor: "transparent",
            border: "1px solid #FF4B00", color: 'white',
          }} onClick={() => setDeleteConfirmationDialogOpen(false)}>Cancelar</Button>
          <Button style={{
            backgroundColor: "#FF4B00", fontWeight: 'bold', color: 'white',
          }} onClick={handleDeleteConfirmed} color="error">Excluir</Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default Profissionais;
