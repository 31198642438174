import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Grid,
  Button,
  TextField,
  Divider,
} from "@mui/material";

import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import 'react-calendar/dist/Calendar.css';
import firebaseApp, { BASE_PROD, KEY } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {datetime } from "../../../services/script";

const database = getDatabase(firebaseApp);

function gerarID() {
  const numeroAleatorio = Math.floor(1000000000000 + Math.random() * 9000000000000);
  return numeroAleatorio.toString();
}



const NovoExame = ({ handleClose, fetch }) => {

  const [nomeInstituicao, setNomeInstituicao] = useState('');


  const handleSalvar = async () => {
    const uid = gerarID();
    const db = ref(database, `${BASE_PROD}/${KEY}/exames/` + uid);

    await set(db, {
      nome: nomeInstituicao,
      datetime: datetime(),
      id: uid
    });

    handleClose();
    fetch();
  };






  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
      <form>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Nome do Exame"
            fullWidth
            value={nomeInstituicao}
            onChange={(e) => setNomeInstituicao(e.target.value)}
            sx={{ bgcolor: '#3338' }}
            InputLabelProps={{
              style: { color: 'white' },
              focused: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
          <Button
            style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
            onClick={handleSalvar}
          >
            <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
            CONCLUIR
          </Button>
        </Grid>



      </form>


    </Box >
  );
};

export default NovoExame;
