import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogContentText,
  Backdrop,
  Divider, Slide
} from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import { IconButton } from "@mui/material";
import { getDatabase, ref, get, update, remove } from '@firebase/database';
import firebaseApp, { KEY, BASE_PROD } from '../../integration/FirebaseConfig';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datetime, reloadPage } from "../../services/script";
import { useFuncoes } from "../../services/funcoes";
const database = getDatabase(firebaseApp);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Tickets = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");

  const {
    funcEditarInstituicoes,
    funcExcluirInstituicoes,
    funcCriarNovasInstituicoes,
    funcVisualizarVagas,
    funcAtualizarVagas
  } = useFuncoes();

  const columns = [
    {
      field: "telefone",
      headerName: "Telefone",
      flex: 1,
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "nome",
      headerName: "Criado por",
      flex: 1,
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "datetime",
      headerName: "Abertura",
      flex: 1,
      filterable: true,
    },
    {
      field: "ticket",
      headerName: "N° Ticket",
      flex: 1,
      filterable: true,
    },
    {
      field: "type",
      headerName: "Motivo",
      flex: 1,
      filterable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      filterable: true,
    },
    {
      field: "datefinish",
      headerName: "Finalizado às",
      flex: 1,
      filterable: true,
    },

  ];


  const fetch = async () => {
    const database = getDatabase();
    const usersRef = ref(database, `${BASE_PROD}/${KEY}/tickets`);

    try {
      const snapshot = await get(usersRef);
      const data = snapshot.val();

      if (data) {
        const instituicoes = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setUserData(instituicoes);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
      setUserData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);




  return (
    <>
      <HelmetProvider><Helmet>
        <title>Painel DHF - Exames</title>
      </Helmet></HelmetProvider>


      <Box m="10px">
        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/brasao2.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            TICKETS DO SISTEMA
          </Typography>



        </Box>
        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
            }}
          >
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={userData}
              columns={columns}
              disableSelectionOnClick
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>


    </>
  );
};

export default Tickets;
