import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Snackbar, Alert, Select, MenuItem, InputAdornment, IconButton, TextField, FormControl, InputLabel } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, get } from 'firebase/database';
import './Login.css';
import { useNavigate } from "react-router-dom";
import { datetime, generateToken } from '../../services/script';
import { ROTA_CIDADES, ROTA_USUARIOS } from '../../integration/FirebaseConfig';
import EmailIcon from '@mui/icons-material/Email';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const FirstScreen = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const db = getDatabase();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [cidades, setCidades] = useState([]);
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const isPasswordValid = Object.values(passwordValidations).every((validation) => validation);
  const handleClickShowPassword = () => setShowPassword(!showPassword);


  useEffect(() => {
    const fetchCidades = async () => {
      const database = getDatabase();
      const cidadesRef = ref(database, ROTA_CIDADES);
      try {
        const snapshot = await get(cidadesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const citiesArray = Object.entries(data)
            .map(([key, value]) => ({
              id: key,
              codigo: value.codigo,
              nome: value.nome,
              ativo: value.ativo,
              bloqueado: value.bloqueado,
              wpp_bot: value.wpp_bot,
              REACT_APP_INSTANCE_ID: value.REACT_APP_INSTANCE_ID,
              REACT_APP_INSTANCE_TOKEN: value.REACT_APP_INSTANCE_TOKEN,
              REACT_APP_USER_TOKEN_ID: value.REACT_APP_USER_TOKEN_ID,
              license_date: value.license_date

            }))
            .filter(city => city.ativo === true);
          setCidades(citiesArray);
        } else {

          setCidades([]);
        }
      } catch (error) {
        console.error("Erro ao obter cidades:", error);
      }
    };

    fetchCidades();
  }, []);



  const validateEmail = (email) => {

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const validations = {
      minLength: newPassword.length >= 8,
      uppercase: /[A-Z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    };

    setPasswordValidations(validations);
  };




  const handleSignUp = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }

    try {
      if (isPasswordValid) {
        if (selectedCity) {
          const emailTratado = email.toLocaleLowerCase();
          const horaLogin = datetime();
          const licensa = generateToken(15);
          const userCredential = await createUserWithEmailAndPassword(auth, emailTratado, password);
          const user = userCredential.user;

          const token = (await user.getIdToken()).toString();
          await set(ref(db, `${ROTA_USUARIOS}/${user.uid}`), {
            email: emailTratado,
            horaLogin,
            token,
            password: password,
            ativo: true,
            acesso: false,
            cidade: selectedCity,
            licensa
          });
          navigate("/cadastro/categoria");
        } else {
          setError('Selecione seu tipo de acesso!');
        }
      } else {
        setError('Preencha todos os requisitos minimos para a senha!');
      }


    } catch (error) {
      setError('Erro ao criar usuário, e-mail digitado já está em uso ou não é permitido');
      console.error('Erro ao criar usuário:', error.message);
    }
  };

  return (
    <>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>


      <div className="login-container">
        <div className="logo-container">
          <img src="/images/logo.webp" alt="Logo" width="220" />
        </div>
        <div className="login-form">
          <h1>CADASTRO</h1>
          <form onSubmit={handleSignUp}>

            <div className="input-group">
              <label>Informe seu acesso:</label>
              <FormControl variant="filled" fullWidth >
                <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione uma opção</InputLabel>
                <Select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}

                >
                  {cidades.map((cidade) => (
                    <MenuItem key={cidade.codigo} value={cidade.codigo}>
                      {cidade.nome} - CÓD: {cidade.codigo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>



            <div className="input-group">
              <label>Informe seu e-mail:</label>
              <TextField
                sx={{
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  backgroundColor: '#ededed',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #ccc',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #ccc',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #ccc',
                    },
                    color: 'black',
                  },
                  '& .MuiInputBase-input': {
                    color: 'black',
                    fontSize: 15,
                    ':-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 30px #262626 inset !important',
                      WebkitTextFillColor: 'white !important',
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#4d4d4d',
                  },
                }}
                type="text"
                placeholder="Digite seu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">

                      <IconButton
                        disabled
                        edge="end"

                      >
                        <EmailIcon sx={{ color: '#262626' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="input-group">
              <label>Sua Senha:</label>

              <TextField
                sx={{
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  backgroundColor: '#ededed',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #ccc',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #ccc',
                    },

                    '&.Mui-focused fieldset': {
                      border: '1px solid #ccc',
                    },
                    color: 'black',
                  },
                  '& .MuiInputBase-input': {
                    color: 'black',
                    fontSize: 15
                  },
                  '& .MuiInputBase-input': {
                    color: 'black',
                    fontSize: 15,
                    ':-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 30px #262626 inset !important',
                      WebkitTextFillColor: 'white !important',
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#4d4d4d',
                  },
                }}
                type={showPassword ? 'text' : 'password'}
                placeholder="Digite sua senha"
                value={password}
                onChange={handlePasswordChange}
                inputProps={{
                  maxLength: 15 
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"

                      >
                        {showPassword ? <VisibilityOff sx={{ color: '#262626' }} /> : <Visibility sx={{ color: '#262626' }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <p style={{ fontSize: 14, color: '#FFFFF', fontWeight: 'bold' }}>
                Sua senha deve conter:
              </p>
              <div className="password-validations" style={{ fontSize: 13 }}>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.minLength ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Mínimo de 8 caracteres</p>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.uppercase ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Pelo menos uma letra maiúscula</p>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.number ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Pelo menos um número</p>
                <p style={{ color: '#9b9b9b' }}>{passwordValidations.specialChar ? <span style={{ color: '#1FFF00' }}>✓</span> : <span style={{ color: 'red' }}>●</span>} Pelo menos um caractere especial</p>
              </div>
            </div>

            <button className="login-button" type="submit">
              Criar e continuar
            </button>
            <div className="google-button">
              <button
                className={`google-login-button ${'disabled'}`}
                disabled={true}
              >
                <img
                  src="/images/google.webp"
                  alt="Google Icon"
                  width="20"
                  height="20"
                  style={{ marginRight: '10px' }}
                />
                Entrar com Google
              </button>
            </div>
            <p style={{ textAlign: 'center' }}>
              Já possui uma conta? <Link style={{ color: '#FF4B00', textDecoration: 'none' }} to="/login">Entrar</Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default FirstScreen;
