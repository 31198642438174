import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { API_DEV, auth, KEY } from '../../../integration/FirebaseConfig';

import ReactFrappeChart from "react-frappe-charts";
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";



const IdadePacientes = ({ onUpdate, integridade }) => {
    const [idades, setIdades] = useState([0, 0, 0, 0, 0, 0, 0]);

    useEffect(() => {
        const cachedData = localStorage.getItem("cacheIdades");
        if (onUpdate === false) {
            if (cachedData) {
                const cached = JSON.parse(cachedData);
                setIdades(cached.idades);
                integridade(100)
            } else {
                integridade(0)
            }

        } else if (onUpdate === true) {
            fetchData();

        }

    }, [onUpdate]);


    const fetchData = async () => {

        try {
            const userId = auth.currentUser.uid
            const email = auth.currentUser.email
            const token = await auth.currentUser.getIdToken();
            const result = tokenCrypt(userId, email, token)

            const response = await axios.get(`${API_DEV}/dashboard/idades`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { cidade: KEY }
            });

            if (response.status === 200) {
                const { idades } = response.data;
                integridade(100)
                setIdades(idades);
                localStorage.setItem("cacheIdades", JSON.stringify({ idades }));
            } else {
                setIdades([0, 0, 0, 0, 0, 0, 0]);
                integridade(10)
            }

        } catch (error) {
            setIdades([0, 0, 0, 0, 0, 0, 0]);
            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }

    };

    return (
        <Box display="inline-block" gridColumn="span 6" gridRow="span 1" backgroundColor={'#1d1d1d'} borderRadius={5} padding={0} height="150px">
            <Typography
                variant="h5"
                fontWeight="600"
                style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-20px', marginTop: 15 }}
            >
                Faixa Etária dos Pacientes
            </Typography>


            <ReactFrappeChart
                type="percentage"
                animate={0}
                colors={['#FF4B00', '#4ED083', '#762E10', '#3498db']}
                data={{
                    labels: ['1 até 10 anos', '10 até 30 anos', '30 até 50 anos', '50 até 65 anos', 'superior'],
                    datasets: [
                        {
                            name: "Idades",
                            values: idades,
                            chartType: "bar",
                        },
                    ],
                }}
            />
        </Box>
    );
};

export default IdadePacientes;
