import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogContentText,
  Backdrop,
  Divider, Slide
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import HeaderInstituicoes from "../../utils/HeaderInstituicao";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDatabase, ref, get, update, remove } from '@firebase/database';
import firebaseApp, { KEY, BASE_PROD } from '../../integration/FirebaseConfig';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datetime, reloadPage } from "../../services/script";
import { useFuncoes } from "../../services/funcoes";
import HeaderExames from "../../utils/HeaderExames";
import CloseIcon from '@mui/icons-material/Close';
import NovoExame from "./novoExame";
const database = getDatabase(firebaseApp);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Exames = () => {
  const [loading, setLoading] = useState(true);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState('');
  const [quantidadeVagas, setQuantidadeVagas] = useState(0);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState(null);
  const [openSolicitacoes, setOpenSolicitacoes] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();
  const [especialidades, setEspecialidades] = useState([]);
  const [vagasDisponiveis, setVagasDisponiveis] = useState({});
  const [novaQuantidadeVagas, setNovaQuantidadeVagas] = useState(0);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [globalConfirmationDialogOpen, setGlobalConfirmationDialogOpen] = useState(false);
  const [globalVagasConfirmationDialogOpen, setGlobalVagasConfirmationDialogOpen] = useState(false);
  const {
    funcEditarInstituicoes,
    funcExcluirInstituicoes,
    funcCriarNovasInstituicoes,
    funcVisualizarVagas,
    funcAtualizarVagas
  } = useFuncoes();

  const columns = [
    {
      field: "nome",
      headerName: "Tipo de exame",
      flex: 1,
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "datetime",
      headerName: "Atualização",
      flex: 1,
      filterable: true,
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleDeleteConfirmation(row.id)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
            }}
            disabled={!funcExcluirInstituicoes}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleOpenSolicitacoes = () => {
    setOpenSolicitacoes(true);
  };

  const handleCloseSolicitacoes = () => {
    setOpenSolicitacoes(false);
  };

  const fetch = async () => {
    const database = getDatabase();
    const usersRef = ref(database, `${BASE_PROD}/${KEY}/exames`);

    try {
      const snapshot = await get(usersRef);
      const data = snapshot.val();

      if (data) {
        const instituicoes = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setUserData(instituicoes);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
      setUserData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);



  const handleDeleteConfirmation = (id) => {
    setInstituicaoSelecionada(id);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteInstituicao(instituicaoSelecionada);
    setDeleteConfirmationDialogOpen(false);
  };

  const deleteInstituicao = (id) => {
    const instituicaoRef = ref(database, `${BASE_PROD}/${KEY}/exames/${id}`);
    try {
      remove(instituicaoRef);
      console.log('Exame excluída com sucesso!');
      fetch();
    } catch (error) {
      console.error('Erro ao excluir a instituição:', error);
    }
  };


  return (
    <>
      <HelmetProvider><Helmet>
        <title>Painel DHF - Exames</title>
      </Helmet></HelmetProvider>


      <Box m="10px">
        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/brasao2.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            GERENCIAMENTO DE EXAMES
          </Typography>

          <IconButton variant="contained" style={{ backgroundColor: "#FF4B00", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }} onClick={handleOpenSolicitacoes}>
            <MedicalInformationIcon style={{ marginRight: 4 }} /> NOVO EXAME
          </IconButton>




        </Box>
        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
            }}
          >
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={userData}
              columns={columns}
              disableSelectionOnClick
            />
          </Box>
        ) : (
          userData.map((user) => (
            <Box key={user.id} className="user-card" m="10px">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => handleDeleteConfirmation(user.id)}
                  sx={{
                    backgroundColor: '#363636',
                    borderRadius: '4px',
                    border: "1px solid #1D1D1D",
                    padding: '8px',
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>

              </Box>
            </Box>
          ))
        )}
      </Box>

      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>




      <Dialog open={deleteConfirmationDialogOpen} onClose={() => setDeleteConfirmationDialogOpen(false)}>
        <DialogTitle style={{ color: 'white', backgroundColor: '#1D1D1D', padding: '16px', fontWeight: 'bold' }}>
          Confirmar exclusão
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center', padding: '16px' }}>
          <Typography variant="body1" style={{ fontSize: '1.2em' }}>
            Tem certeza de que deseja excluir este exame? Esta ação é irreversível e não será possível recuperar os dados após a exclusão. Por favor, confirme sua escolha.
          </Typography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#1D1D1D', padding: '8px' }}>
          <Button onClick={() => setDeleteConfirmationDialogOpen(false)} style={{ color: 'white', fontWeight: 'bold' }}>
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} style={{ color: 'white', fontWeight: 'bold', backgroundColor: '#FF4B00' }}>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog TransitionComponent={Transition} open={openSolicitacoes} onClose={handleCloseSolicitacoes} fullWidth maxWidth="xl">
        <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: 'white', backgroundColor: '#1D1D1D', padding: '16px 24px' }}>
          <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">CADASTRO DE NOVO EXAME</Divider>

          <IconButton
            edge="end"
            onClick={handleCloseSolicitacoes}
            aria-label="close"
            style={{ color: '#FF4B00', position: 'absolute', right: 16, top: 16 }}
          >
            <CloseIcon fontSize="20" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#1D1D1D', color: 'white', padding: '24px' }}>
          <NovoExame handleClose={handleCloseSolicitacoes} fetch={fetch} />
        </DialogContent>

      </Dialog>

    </>
  );
};

export default Exames;
