import { ResponsiveLine } from "@nivo/line";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../theme";

const LineChart = ({ data, isDashboard = false }) => {
  const theme = useTheme();
  const screenTooSmall = useMediaQuery("(max-width: 700px)");

  const monthsLabels = [
    "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
    "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
  ];

  const formatTooltip = (value, name, props) => {
    const formattedValue = parseFloat(value).toFixed(0); 
    return `Qntd.: ${formattedValue}`;
};


  return !screenTooSmall | isDashboard ? (
    <ResponsiveLine
      data={data}
      colors={["#FF4B00", "#762e10", '#a3a3a3', "#ea916e"]}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: '#FF4B00',
            },
          },
          legend: {
            text: {
              fill: 'white',
              fontWeight: 'bold'
            },
          },
          ticks: {
            line: {
              stroke: 'white',
              fontWeight: 'bold',
              strokeWidth: 1,
            },
            text: {
              display: isDashboard ? "none" : "inline",
              fill: 'white',
              fontWeight: 'bold'
            },
          },
        },
        legends: {
          text: {
            fill: 'white',
            fontWeight: 'bold'
          },
        },
        tooltip: {
          container: {
            color: 'black',
          },
        },
      }}
      tooltip={(tooltipProps) => {
        const { point } = tooltipProps;
        return (
          <div style={{ padding: "12px" }}>
            <Typography variant="body1">
              {monthsLabels[point.index]}
            </Typography>
            <Typography variant="body2">
              {formatTooltip(point.data.yFormatted, point.data.serieId)}
            </Typography>
          </div>
        );
      }}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Mês",
        legendOffset: 36,
        legendPosition: "middle",
        text: {
          fill: 'white',
        },
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5,
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Quantidade",
        legendOffset: -40,
        legendPosition: "middle",
        text: {
          fill: 'white',
        },
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"

      animate={false} 
 

      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: -40,
          translateY: -120,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          text: {
            fill: 'white',
          },
        },
      ]}
    />
  ) : (
    <Typography variant="h5" textAlign="center" mt="70px">
    </Typography>
  );
};

export default LineChart;
