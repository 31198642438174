import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Radio,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  TextField,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Divider,
} from "@mui/material";
import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { KEY } from '../../../integration/FirebaseConfig';
import { BASE_PROD } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { datetime } from "../../../services/script";


const database = getDatabase(firebaseApp);

function gerarID() {
  const numeroAleatorio = Math.floor(10000000 + Math.random() * 90000000);
  return numeroAleatorio.toString();
}


const allSpecialties = [
  "",
  "Angiologia",
  "Cardiologia",
  "Cirurgia Geral",
  "Cirurgia Pediátrica",
  "Cirurgia Plástica",
  "Citologia",
  "Clínica Médica",
  "Dermatologia",
  "Eletrocardiografia",
  "Eletroencefalografia",
  "Endocrinologia",
  "Endoscopia",
  "Fisioterapia",
  "Fonoaudiologia",
  "Gastroenterologia",
  "Genética",
  "Geriatria",
  "Ginecologia",
  "Hematologia",
  "Hemodinâmica",
  "Hemoterapia",
  "Homeopatia",
  "Imaginologia",
  "Imunologia",
  "Medicina Nuclear",
  "Nefrologia",
  "Neurocirurgia",
  "Neurologia",
  "Nutrição",
  "Obstetrícia",
  "Odontologia",
  "Oftalmologia",
  "Oncologia",
  "Ortopedia",
  "Otorrinolaringologia",
  "Pediatria",
  "Psicologia",
  "Psicoterapia",
  "Pneumologia",
  "Proctologia",
  "Psiquiatria",
  "Quimioterapia",
  "Radiologia",
  "Radioterapia",
  "Reumatologia",
  "Ressonância Magnética",
  "Tomografia",
  "Traumatologia",
  "Ultra-sonografia",
  "Urologia",
];

const EditarProfi = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = location.state;
  const [matricula, setMatricula] = useState(userData.matricula || '');
  const [nomeProfissional, setNomeProfissional] = useState(userData.nome || '');
  const [categoria, setCategoria] = useState(userData.especialidade || allSpecialties[0]);

  const [emailProf, setEmailProf] = useState('');

  const handleCategoriaChange = (event, value) => {
    setCategoria(value);
  };


  const handleSalvar = async () => {

    const db = ref(database, `${BASE_PROD}/${KEY}/profissionais/` + userData.id);
    console.log(db)

    await update(db, {
      nome: nomeProfissional,
      matricula: matricula,
      especialidade: categoria,
      email: emailProf,
      datetime: datetime()
    });

    navigate(`/profissionais`);

  };





  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
      <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">EDITAR PROFISSIONAL</Divider>




      <>


        <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}> Dados do Profissional</Typography>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Nome"
                fullWidth
                value={nomeProfissional}
                onChange={(e) => setNomeProfissional(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="CRM ou Matrícula"
                fullWidth
                value={matricula}
                onChange={(e) => setMatricula(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="E-mail"
                fullWidth
                value={emailProf}
                onChange={(e) => setEmailProf(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },

                }}
                InputProps={{
                  readOnly: true,
                  style: { color: '#686868', fontWeight: 'bold' }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                options={allSpecialties}
                value={categoria}
                onChange={(event, value) => handleCategoriaChange(event, value)}
                inputValue={categoria}
                onInputChange={(event, newInputValue) => {
                  setCategoria(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Especialidades"
                    sx={{ bgcolor: '#3338' }}
                    InputLabelProps={{
                      style: { color: 'white' },
                      focused: false,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                onClick={handleSalvar}
              >
                <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                CONCLUIR
              </Button>
            </Grid>

          </Grid>
        </form>

      </>


    </Box >
  );
};

export default EditarProfi;
