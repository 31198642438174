import React from "react";
import { Box, Typography } from "@mui/material";

const ProgressCircle = ({ value1, value2, value3, geral, color1 = '#ff4b00', color2 = '#762e10', color3 = "#ea916e", size = 150 }) => {
  const total = value1 + value2 + value3;

  const angle1 = (value1 / total) * 360;
  const angle2 = (value2 / total) * 360;
  const angle3 = (value3 / total) * 360;

  return (
    <Box
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: '#1d1d1d',
        overflow: 'hidden', // Para esconder partes fora do círculo
      }}
    >
      {/* Fatias coloridas */}
      <Box
        sx={{
          position: 'absolute',
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
          background: `conic-gradient(
            ${color1} 0deg ${angle1}deg,
            ${color2} ${angle1}deg ${angle1 + angle2}deg,
            ${color3} ${angle1 + angle2}deg ${angle1 + angle2 + angle3}deg,
            transparent ${angle1 + angle2 + angle3}deg 360deg
          ),
          #1d1d1d`,
        }}
      />

      {/* Círculo interno */}
      <Box
        sx={{
          width: `${size * 0.7}px`,
          height: `${size * 0.7}px`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          background: '#1d1d1d',
          border: `solid 1px #1d1d1d`,
        }}
      />

      {/* Texto central */}
      <Typography sx={{ color: '#fff', zIndex: 1, fontSize: 30, fontWeight: 'bold' }}>{geral}</Typography>
    </Box>
  );
};

export default ProgressCircle;
