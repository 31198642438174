import { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { KEY, API_DEV, auth } from '../../../integration/FirebaseConfig';
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";

const CategoriesBox = ({ onUpdate, integridade }) => {
    const scrollRef = useRef(null);
    const [growthRates, setGrowthRates] = useState({});
    const [previousMonthCounts, setPreviousMonthCounts] = useState({});
    const [categoryCounts, setCategoryCounts] = useState({});


    useEffect(() => {


        const cachedData = localStorage.getItem("cacheCategories");

        if (onUpdate === false) {
            if (cachedData) {
                const cached = JSON.parse(cachedData);
                setCategoryCounts(cached.categoryCounts || {});
                setPreviousMonthCounts(cached.previousMonthCounts || {});
                setGrowthRates(cached.growthRates || {});
                integridade(100)
            } else {
                integridade(0)
            }

        } else if (onUpdate === true) {
            fetchDataFromFirebase();
        }

    }, [onUpdate]);

    const fetchDataFromFirebase = async () => {
        try {
            const userId = auth.currentUser.uid
            const email = auth.currentUser.email
            const token = await auth.currentUser.getIdToken();
            const result = tokenCrypt(userId, email, token)

            const response = await axios.get(`${API_DEV}/dashboard/categorias`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { cidade: KEY } 
            });

            if (response.status === 200) {
                const { categoryCounts, previousMonthCounts, growthRates } = response.data;

                setCategoryCounts(categoryCounts);
                setPreviousMonthCounts(previousMonthCounts);
                setGrowthRates(growthRates);
                integridade(100)
                localStorage.setItem("cacheCategories", JSON.stringify({
                    categoryCounts: categoryCounts,
                    previousMonthCounts: previousMonthCounts,
                    growthRates: growthRates
                }));
            } else {
                integridade(10)
            }

        } catch (error) {
            setCategoryCounts({})
            setGrowthRates({})
            setPreviousMonthCounts({})
            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }



    };







    const handleScrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= 100;
        }
    };

    const handleScrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += 100;
        }
    };


    return (
        <>
            <Box display="inline-block" gridColumn="span 9" gridRow="span 1" backgroundColor={'#1d1d1d'} borderRadius={5} padding={0} height="150px">
                <Typography
                    fontSize={13}
                    fontWeight="600"
                    style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-20px', marginTop: 10 }}
                >
                    Especialidades (Mês Atual vs. Mês Passado)
                </Typography>


                <Box
                    gridColumn={"span 9"}
                    backgroundColor={'transparent'}
                    borderRadius={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    maxHeight="300px"
                    padding="15px"
                >





                    <IconButton onClick={handleScrollLeft} style={{ backgroundColor: '#FF4B00' }} >
                        <ArrowLeftIcon />
                    </IconButton>
                    <div
                        style={{
                            overflowX: "hidden",
                            overflowY: 'hidden',
                            whiteSpace: 'nowrap',
                            WebkitOverflowScrolling: 'touch',
                            scrollBehavior: 'smooth',
                            width: 'calc(100% - 48px)',
                            borderRadius: 10
                        }}
                        ref={scrollRef}
                    >
                        {Object.keys(categoryCounts).map((category, index) => (
                            <Box key={index}
                                display="inline-block"
                                backgroundColor={'#1d1d1d'}
                                borderRadius={5}
                                justifyContent="center"
                                alignItems="center"
                                width="145px"
                                height="120px"
                                margin="0 7px"
                                position="relative"
                            >
                                <Tooltip title={`Mês anterior: ${previousMonthCounts[category] || 0}`}>

                                    <Box
                                        position="absolute"
                                        bottom="15px"
                                        left="15%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        paddingTop={2}
                                        paddingBottom={2}
                                        sx={{
                                            width: 100,
                                            height: 25,
                                            borderRadius: 4,
                                            color: growthRates[category] >= 0 ? '#4ed08c' : 'red',
                                            bgcolor: growthRates[category] >= 0 ? '#356b4e' : '#962828',
                                            '&:hover': {
                                                bgcolor: '#262626',
                                            },
                                        }}
                                    >
                                        <Typography style={{ color: 'inherit', textAlign: 'center', fontWeight: 'bold' }}>
                                            {growthRates[category] !== undefined ? (
                                                growthRates[category] >= 0 ? (
                                                    <>
                                                        <TrendingUpIcon style={{ marginBottom: '-5px' }} /> +{growthRates[category].toFixed(2)}%
                                                    </>
                                                ) : (
                                                    <>
                                                        <TrendingDownIcon style={{ marginBottom: '-5px' }} /> {growthRates[category].toFixed(2)}%
                                                    </>
                                                )
                                            ) : (
                                                "No growth rate available"
                                            )}
                                        </Typography>
                                    </Box>

                                </Tooltip>
                                <Typography style={{ marginBottom: '0px', color: '#fff', textAlign: 'center', marginTop: 15, fontWeight: 'bold' }}>
                                    {category.toLocaleUpperCase()}
                                </Typography>
                                <Typography style={{ marginBottom: '0px', color: '#fff', textAlign: 'center', fontSize: 23, fontWeight: 'bold' }}>
                                    {categoryCounts[category] || 0}
                                </Typography>


                            </Box>
                        ))}
                    </div>
                    <IconButton onClick={handleScrollRight} style={{ backgroundColor: '#FF4B00' }}>
                        <ArrowRightIcon />
                    </IconButton>
                </Box>


            </Box>
        </>
    );
};

export default CategoriesBox;
