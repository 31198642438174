import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useTheme, useMediaQuery, Tooltip, IconButton } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import firebaseApp, { KEY, BASE_PROD } from "../../../integration/FirebaseConfig";
import { getDatabase, ref, onValue, get } from "firebase/database";
import moment from "moment";
import LineChart from "../../../utils/LineChart";
import ProgressCircle from "../Absenteismo/ProgressCircle";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ReactFrappeChart from "react-frappe-charts";


const database = getDatabase(firebaseApp);

const useStyles = makeStyles({
  orangeDesign: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 160,
    height: "auto",
    backgroundColor: "#FF4B00",
    borderRadius: "45% 10% 10% 45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
    padding: "10px",
    boxSizing: "border-box",
  },
  absenteismoText: {
    position: "absolute",
    top: 1,
    left: 25,
    color: "white",
    fontWeight: "bold",
    fontSize: 20,
  },
  percentNumber: {
    position: "absolute",
    top: 50,
    left: 30,
    color: "#d14f4f",
    fontFamily: "Source Sans 3, sans-serif",
    letterSpacing: 3,
    fontSize: 30,
    fontWeight: "bold",
  },
  monthText: {
    position: "absolute",
    top: 95,
    left: 25,
    fontWeight: "lighter",
    fontSize: 14,
  },
});


const Fake = () => {
  const classes = useStyles();
  const orangeRef = useRef(null);
  const [totalSolicitados, setTotalSolicitados] = useState(0);
  const [totalFaltou, setTotalFaltou] = useState(0);
  const [previousMonthFaltou, setPreviousMonthFaltou] = useState(0);
  const [solicitacoesData, setSolicitacoesData] = useState([]);
  const theme = useTheme();
  const colors = theme.palette.mode === "dark" ? theme.palette.grey[100] : theme.palette.grey[800];

  const calculateAbsenteismoRate = () => {
    if (totalSolicitados > 0) {
      const currentAbsenteismoRate =
        (totalFaltou / totalSolicitados) * 100;
      const previousAbsenteismoRate =
        (previousMonthFaltou / totalSolicitados) * 100;
      return currentAbsenteismoRate - previousAbsenteismoRate;
    }
    return 0;
  };

  const absenteismoRate = calculateAbsenteismoRate();

  return (
    <>
      <Box
        gridColumn="span 4"
        gridRow="span 1"
        backgroundColor="#1d1d1d"
        p="10px"
        borderRadius={5}
        position="relative"
      >
        <h2 className={classes.absenteismoText}>Absenteísmo</h2>
        <Tooltip title={`Faltas mês anterior: ${previousMonthFaltou}`}>
          <Typography className={classes.percentNumber} style={{ color: absenteismoRate >= 0 ? "#d14f4f" : "#4ed08c", fontSize: 30 }}>
            {absenteismoRate >= 0 ? (
              <TrendingUpIcon style={{ marginBottom: "-5px" }} />
            ) : (
              <TrendingDownIcon style={{ marginBottom: "-5px" }} />
            )}
            0.00%
          </Typography>
        </Tooltip>
        <h2 className={classes.monthText}>Mês Atual</h2>

      </Box>

      <Box gridColumn="span 6" gridRow="span 2" backgroundColor="#1D1D1D" borderRadius={5}>
        <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" fontWeight="600" color={colors}>
              Total de consultas (analítico)
            </Typography>
          </Box>
        </Box>
        <Box height="250px" mt="-20px">
          <LineChart data={solicitacoesData} />
        </Box>
      </Box>

      <Box display="inline-block" gridColumn="span 2" gridRow="span 2" backgroundColor={'#1d1d1d'} borderRadius={5} height="300px">




            <Typography
                variant="h6"
                fontWeight="600"
                style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-25px', marginTop: 10 }}
            >
                Avaliações
            </Typography>
            <ReactFrappeChart
                type="bar"
                colors={['#FFA00A']}
                animate={1}
               
                axisOptions={{ xAxisMode: 'tick', yAxisMode: 'tick', xIsSeries: 1 }}
                height={300}
                data={{
                    
                    labels: ['5 ☆', '4 ☆', '3 ☆', '2 ☆', '1 ☆', '0 ☆'],
                    datasets: [
                        {
                            name: " Qntd. Estrelas",
                            values: [0,0,0,0,0,0],
                            chartType: "bar",
                        },

                    ],
                }}
            />


        </Box>

      <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#1d1d1d" p="30px" borderRadius={5}>
        <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center' }}>
          Total por Status
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
          <ProgressCircle value1={0} value2={0} value3={0} color1="#ff4b00" color2="#762e10" color3="#ea916e" size={150} />
          <Box style={{ marginTop: '-100px', textAlign: 'center' }}>
            <Typography style={{ fontSize: 20 }}>Total </Typography>
            <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{0}</Typography>
          </Box>
          <Box style={{ marginTop: '40px', textAlign: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#ff4b00' }} />
              <span>Absenteísmo: ({0})</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#762e10' }} />
              <span>Atendimentos: ({0})</span>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        gridColumn={"span 8"}
        backgroundColor={'transparent'}
        borderRadius={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        maxHeight="300px"
        padding="15px"
      >
        <IconButton style={{ backgroundColor: '#FF4B00' }} >
          <ArrowLeftIcon />
        </IconButton>
        <div
          style={{
            overflowX: "hidden",
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            WebkitOverflowScrolling: 'touch',
            scrollBehavior: 'smooth',
            width: 'calc(100% - 48px)',
            borderRadius: 10
          }}

        >

        </div>
        <IconButton style={{ backgroundColor: '#FF4B00' }}>
          <ArrowRightIcon />
        </IconButton>
      </Box>

      <Box display="inline-block" gridColumn={"span 6"} backgroundColor={'#1d1d1d'} borderRadius={5} padding={0} height="300px">
        <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center', marginBottom: '-30px', marginTop: 20 }}>
          Dias mais solicitados
        </Typography>
        <ReactFrappeChart
          type="axis-mixed"
          colors={['#FF4B00', '#4ED083', '#762E10']}
          axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
          height={300}
          data={{
            labels: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            datasets: [
            
            ],
          }}
        />
      </Box>



      <Box
        display="inline-block"
        backgroundColor={'#1d1d1d'}
        borderRadius={5}
        gridColumn={"span 3"}
        padding={0}
        height="300px"
        position="relative"
      >
        <Typography
          variant="h5"
          fontWeight="600"
          style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-30px', marginTop: 20 }}
        >
          Dias com mais faltas
        </Typography>
        <ReactFrappeChart
          type="bar"
          colors={['#FF4B00', '#4ED083']}
          animate={1}
          axisOptions={{ xAxisMode: 'tick', yAxisMode: 'tick', xIsSeries: 1 }}
          height={300}
          data={{
            labels: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            datasets: [
             
            ],
          }}
        />
      </Box>

      <Box
        gridColumn={"span 3"}
        gridRow="span 2"
        backgroundColor={'#1d1d1d'}
        p="15px"
        borderRadius={5}
      >
        <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center' }}>
          Protocolos
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="25px"
        >
          <ProgressCircle value1={0} value2={0} value3={0} geral={0} color1="#ff4b00" color2="#ea916e" color3="#762e10" size={150} />

          <Box style={{ marginTop: '-100px', justifyContent: 'center', textAlign: 'center' }}>
            <Typography style={{ fontSize: 20 }}>Total </Typography>
            <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{0}</Typography>
          </Box>

          <Box style={{ justifyContent: 'flex-start', marginTop: '40px' }} >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: 2 }}>
              <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#ff4b00' }} />
              <span>Marcadas: ({0})</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#ea916e' }} />
              <span>Aguardando vagas/análise: ({0})</span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box sx={{ width: '20px', height: '6px', borderRadius: '20%', backgroundColor: '#762e10' }} />
              <span>Cancelados: ({0})</span>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        display="inline-block"
        backgroundColor={'#1d1d1d'}
        borderRadius={5}
        gridColumn={"span 12"}
        height="150px"
        position="relative"
      >
        <Typography
          variant="h5"
          fontWeight="600"
          style={{ textJustify: 'center', textAlign: 'center', marginBottom: '-30px', marginTop: 20 }}
        >
          Idades dos pacientes
        </Typography>

        <ReactFrappeChart
          type="percentage"
          animate={1}
          data={{
            labels: ['1 até 10 anos', '10 até 30 anos', '30 até 50 anos', '50 até 65 anos', '65 anos ou superior'],
            datasets: [
              {
                name: "Idades",
                values: 0,
                chartType: "bar",
              },
            ],
          }}
        />
      </Box>
    </>
  );
};

export default Fake;
