import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
import { ref, get, child, getDatabase, onValue } from 'firebase/database';
import { useNavigate } from "react-router-dom";
import { checkToken } from "../services/script";
import firebaseApp, {ROTA_MANUTENCAO, ROTA_USUARIOS } from "../integration/FirebaseConfig";

const AuthProvider = () => {
    const [loading, setLoadingStop] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [acesso, setAcesso] = useState(false);
    const [cpf, setCPF] = useState(false);
    const [category, setCategory] = useState(false);
    const [master, setMaster] = useState(false);
    const [cidade, setCidade] = useState(null);
    const [manutencaoAtiva, setManutencaoAtiva] = useState(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const auth = getAuth(firebaseApp);
    const database = getDatabase(firebaseApp);
    const dbRefAtivo = ref(database, `${ROTA_MANUTENCAO}`);
    const navigate = useNavigate();


    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarExpanded(!isCollapsed);
    };

    const handleManutencaoChange = (snapshot) => {
        if (snapshot.exists()) {
            setManutencaoAtiva(snapshot.val());
        }
    };



    useEffect(() => {
        const dbRef = ref(database, ROTA_USUARIOS);

        onValue(dbRefAtivo, handleManutencaoChange);

        onAuthStateChanged(auth, async (user) => {
            if (!user) {

                setIsAuthenticated(false);
                setLoadingStop(true)
                return;
            }

            try {
                const userId = user.uid;
                const snapshot = await get(child(dbRef, userId));

                if (!snapshot.exists()) return;

                await checkToken(database, auth, userId)
                    .then(async () => {
                        setIsAuthenticated(true);

                        const userData = snapshot.val();
                        const { ativo, cidade, categoria, cpf, acesso, master } = userData;

                        if (!ativo) {
                            signOut(auth);
                            setIsAuthenticated(false);
                        } else {
                            setCidade(cidade);
                            setCategory(categoria != null);
                            setCPF(cpf != null);

                            if (acesso) {
                                setAcesso(true);
                                if (master) setMaster(true);

                            }
                        }
                        setTimeout(() => {
                            setLoadingStop(true);
                        }, 500);

                    })
                    .catch(error => {
                        console.error('Erro ao verificar token:', error);
                    });
            } catch (error) {
                console.error('Erro ao verificar status do usuário:', error);
            }

        });


    }, [auth, database, dbRefAtivo, navigate]);

    return {
        isAuthenticated,
        acesso,
        cpf,
        category,
        master,
        manutencaoAtiva,
        cidade,
        loading,
        isSidebarExpanded,
        handleSidebarToggle
    };

}

export default AuthProvider;