import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Switch, CircularProgress, Dialog, DialogActions, Slide, DialogTitle, DialogContent, ListItemText, ListItem, List, FormControl, InputLabel, Select, MenuItem, Alert, Snackbar, Collapse, IconButton, Menu, InputAdornment, Chip } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { getDatabase, ref, onValue, push, get, update, off } from '@firebase/database';
import firebaseApp, { KEY, NAME, KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, url_api_gzappy } from '../../integration/FirebaseConfig';
import '../../styles/ChatWhatsApp.css';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SearchIcon from '@mui/icons-material/Search';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { formatarId } from '../../services/script';
import Pacientes from '../pacientes';
import Solicitados from '../solicitados';
import { useFuncoes } from '../../services/funcoes';
const GZAPPY_URL = `${url_api_gzappy}`;
const database = getDatabase(firebaseApp);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfigChat = ({ onChatClick }) => {

  const [chats, setChats] = useState([]);
  const [sucesso, setSucesso] = useState(false);
  const [selectedChats, setSelectedChats] = useState([]);
  const [openChatsDialog, setOpenChatsDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openNewConversationDialog, setOpenNewConversationDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const {
    funcComunicacaoChat,
    funcVisualizarPacientes,
    funcVisualizarSolicitacoes,
    funcComunicacaoTransmissao
  } = useFuncoes();

  const [openSolicitacoes, setOpenSolicitacoes] = useState(false);

  const handleOpenSolicitacoes = () => {
    setOpenSolicitacoes(true);
  };

  const handleCloseSolicitacoes = () => {
    setOpenSolicitacoes(false);
  };

  const handleOpenNewConversationDialog = () => {
    setOpenNewConversationDialog(true);
  };

  const handleCloseNewConversationDialog = () => {
    setOpenNewConversationDialog(false);
  };


  const filteredChats = chats.filter(chat =>
    chat.nome.toLowerCase().includes(searchTerm.toLowerCase()) || chat.id.toLowerCase().includes(searchTerm.toLowerCase()) || chat.msg.toLowerCase().includes(searchTerm.toLowerCase())
    || chat.cpf.toLowerCase().includes(searchTerm.toLowerCase()) || chat.cns.toLowerCase().includes(searchTerm.toLowerCase()) || chat.email.toLowerCase().includes(searchTerm.toLowerCase())
    || chat.nomecompleto.toLowerCase().includes(searchTerm.toLowerCase())
  );


  useEffect(() => {
    const chatRef = ref(database, `/db/cidades/${KEY}/chats`);

    const fetchChats = () => {
      onValue(chatRef, (snapshot) => {
        const chatsData = snapshot.val();
        if (chatsData) {
          const chatsArray = Object.keys(chatsData).map((key) => ({
            id: key,
            nome: chatsData[key]?.nome || 'DESCONHECIDO',
            msg: chatsData[key]?.msg || '',
            cpf: chatsData[key]?.cpf || '',
            cns: chatsData[key]?.cns || '',
            email: chatsData[key]?.email || '',
            nomecompleto: chatsData[key]?.nomecompleto || '',
          })).filter(chat => chat.nome !== 'DESCONHECIDO' && chat.nome !== null);

          setChats(chatsArray);
        } else {
          setChats([]);
        }
      });
    };

    fetchChats();

    return () => {
      off(chatRef);
    };
  }, []);


  const handleOpenChatsDialog = () => {
    setOpenChatsDialog(true);
  };

  const handleCloseChatsDialog = () => {
    setOpenChatsDialog(false);
    setSearchTerm('')
    setSelectedChats([]);
  };

  const handleOpenMessageDialog = () => {

    setOpenMessageDialog(true);
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSucesso(false);
  };


  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedChats.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedChats, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedChats.slice(1));
    } else if (selectedIndex === selectedChats.length - 1) {
      newSelected = newSelected.concat(selectedChats.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedChats.slice(0, selectedIndex),
        selectedChats.slice(selectedIndex + 1)
      );
    }

    setSelectedChats(newSelected);
  };

  const handleToggleSelectAll = () => {
    if (selectedChats.length === chats.length) {
      setSelectedChats([]);
    } else {
      setSelectedChats(chats.map(chat => chat.id));

    }
  };


  const handleSaveMessage = async () => {
    if (message.trim() === '' || selectedChats.length === 0) {
      console.error('Erro: Mensagem vazia ou nenhum chat selecionado');
      return;
    }

    try {
      setSending(true);
      for (const chatId of selectedChats) {
        const chat = chats.find(chat => chat.id === chatId);
        if (!chat) {
          console.error(`Erro: Não foi possível encontrar o chat com ID ${chatId}`);
          continue;
        }

        const gzappyData = {
          instance_id: `${KEY_INSTANCE_ID}`,
          instance_token: `${KEY_INSTANCE_TOKEN}`,
          message: `> ⓘ *TRANSMISSÃO*\n\n${message}`,
          phone: chat.id
        };

        const response = await fetch(GZAPPY_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'user_token_id': `${KEY_USER_TOKEN_ID}`
          },
          body: JSON.stringify(gzappyData)
        });

        if (response.ok) {
          setProgress(prevProgress => prevProgress + (1 / selectedChats.length) * 100);
          setSelectedChats(prevSelectedChats => prevSelectedChats.filter(id => id !== chatId));
        } else {
          console.error(`Erro ao enviar mensagem para o chat ${chatId}: ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    } finally {
      setSucesso(true);
      setSending(false);
      setProgress(0);
      setMessage('');
      handleCloseChatsDialog();
      handleCloseMessageDialog();

    }

  };



  return (
    <>


      <Box style={{
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: '5%',
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15,
        borderRadius: 10,
        textAlign: 'end',
      }}>

        <Button
          variant="contained"
          disabled={!funcComunicacaoTransmissao}
          style={{
            width: "100%",
            marginBottom: 10,
            fontSize: 12,
            backgroundColor: "transparent",
            border: "1px solid #FF4B00",
            borderRadius: 10,
            textAlign: 'start',
            justifyContent: 'flex-start',
          }}
          onClick={handleOpenChatsDialog}>
          <ReplyAllIcon style={{ marginRight: 5 }} />
          <Typography style={{ marginLeft: 5, fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
            onClick={handleOpenChatsDialog}>

            NOVA TRANSMISSÃO
          </Typography>
        </Button>

        <Button
          variant="contained"
          style={{
            width: "100%",
            marginBottom: 10,
            fontSize: 12,
            backgroundColor: "transparent",
            border: "1px solid #FF4B00",
            borderRadius: 10,
            textAlign: 'start',
            justifyContent: 'flex-start',
          }}
          disabled={!funcVisualizarPacientes}
          onClick={handleOpenNewConversationDialog} >
          <ContactsIcon style={{ marginRight: 5 }} />

          <Typography style={{ marginLeft: 5, fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
            onClick={handleOpenNewConversationDialog} >

            PACIENTES
          </Typography>
        </Button>


        <Button
          variant="contained"
          style={{
            width: "100%",
            marginBottom: 10,
            fontSize: 12,
            backgroundColor: "transparent",
            border: "1px solid #FF4B00",
            borderRadius: 10,
            textAlign: 'start',
            justifyContent: 'flex-start',
            
          }}
          disabled={!funcVisualizarSolicitacoes}
          onClick={handleOpenSolicitacoes} >

          <AddCommentIcon style={{ marginRight: 5 }} />
          <Typography style={{ marginLeft: 5, fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}>
            SOLICITAÇÕES
          </Typography>
        </Button>
      </Box>


      <Dialog
        fullWidth
        /* maxWidth="md" */
        open={openChatsDialog}
        onClose={handleCloseChatsDialog}
        TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#1D1D1D',
            color: '#fff',
            height: '650px',
            maxWidth: '450px',
            borderRadius: 2,
          }
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" mb={3} mt={2}>
          <Button
            sx={{
              backgroundColor: '#FF4B00',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#ba3703'
              }
            }}
            variant="contained"
            onClick={handleToggleSelectAll}
          >
            {selectedChats.length === chats.length ? 'Desmarcar Todos' : 'Selecionar Todos'}
          </Button>
          <Button
            sx={{
              ml: 3,
              backgroundColor: '#FF4B00',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#ba3703'
              }
            }}
            variant="contained"
            onClick={handleOpenMessageDialog}
            disabled={selectedChats.length === 0 || sending}
          >
            Próximo ({selectedChats.length})
          </Button>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mb={2} px={2}>
          <TextField
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            placeholder="Pesquisar uma conversa"
            sx={{
              width: '100%',
              maxWidth: '400px',
              backgroundColor: '#2C2C2C',
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                '& fieldset': {
                  borderColor: '#444',
                },
                '&:hover fieldset': {
                  borderColor: '#FF4B00',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FF4B00',
                },
              },
              '& .MuiInputAdornment-root': {
                color: '#fff',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#FF4B00' }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <DialogTitle sx={{ textAlign: 'center', fontSize: 18, color: 'white', fontWeight: 'bold' }}>
          {selectedChats.length > 0 ? `Contatos selecionados (${selectedChats.length})` : 'Selecione os Contatos'}
        </DialogTitle>

        <DialogContent>
          <TableContainer sx={{ maxHeight: '420px', overflowY: 'auto' }}>
            <Table>
              <TableBody>
                {filteredChats.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={2} sx={{ textAlign: 'center', color: '#8c8c8c' }}>
                      Nenhum contato encontrado
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredChats.map((chat) => {
                    const isSelected = selectedChats.includes(chat.id);
                    return (
                      <TableRow
                        key={chat.id}
                        sx={{
                          backgroundColor: isSelected ? '#FF4B00' : '#2C2C2C',
                          borderRadius: 2,
                          '&:hover': {
                            backgroundColor: isSelected ? '#FF4B00' : '#333',
                          },
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            color="success"
                            checked={isSelected}
                            onChange={() => handleCheckboxChange(chat.id)}
                            sx={{
                              color: isSelected ? '#000' : 'default',
                              '&.Mui-checked': {
                                color: '#000',
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <img
                              src="/images/user.webp"
                              alt={chat.nome}
                              style={{
                                maxWidth: 35,
                                opacity: 1,
                                marginRight: 10,
                                filter: isSelected ? 'grayscale(1)' : 'none',
                              }}
                            />
                            <Box>
                              <Typography sx={{ fontSize: 14, color: isSelected ? '#FFF' : '#FFF' }}>
                                {chat.nome}
                              </Typography>
                              <Typography sx={{ fontSize: 12, color: isSelected ? '#FFF' : '#8c8c8c' }}>
                                {formatarId(chat.id)}{' '}
                                <WhatsAppIcon
                                  fontSize="small"
                                  style={{ color: isSelected ? '#FFF' : '#0CC243' }}
                                  sx={{ verticalAlign: 'middle', ml: 0.5, marginTop: '-10px' }}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

      </Dialog>

      <Dialog
        open={openMessageDialog}
        onClose={handleCloseMessageDialog}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ backgroundColor: '#1d1d1d', color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <Typography variant="h6" sx={{ mb: 2, fontSize: 18, color: 'white', fontWeight: 'bold' }}>Números Selecionados para transmissão:</Typography>
            <Box
              sx={{
                maxHeight: '250px',
                overflowY: 'auto',
                width: '100%',
                padding: 1,
                border: '1px solid #ff4b00',
                borderRadius: 2,
                textAlign: 'center',
                backgroundColor: '#2c2c2c',
              }}
            >
              <Box display="flex" justifyContent="center" flexWrap="wrap">
                {selectedChats.map(chatId => (
                  <Chip
                    key={chatId}
                    label={formatarId(chatId)}
                    sx={{
                      m: 1,
                      fontWeight: 'bold',
                      backgroundColor: '#1d1d1d',
                      color: '#fff',
                      '& .MuiChip-icon': {
                        color: '#fff'
                      }
                    }}
                    icon={<WhatsAppIcon style={{ color: '#0CC243' }} />}
                  />
                ))}
              </Box>
            </Box>
          </Box>

          <Box mb={2} p={2} sx={{ borderRadius: 2, backgroundColor: '#2c2c2c' }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>Digite sua mensagem de transmissão.</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  backgroundColor: '#1d1d1d',
                  color: '#fff',
                  '& fieldset': {
                    borderColor: '#ff4b00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ff4b00',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ff4b00',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
                '& .MuiInputLabel-root': {
                  color: '#ff4b00',
                }
              }}
            />
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            {sending && (
              <>
                <CircularProgress sx={{ mb: 2, color: '#ff4b00' }} variant="determinate" value={progress} />
                <Typography variant="body1">{progress.toFixed(0)}%</Typography>
                <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center', color: '#ff4b00' }}>
                  Aguarde enquanto enviamos as mensagens. Por favor, não feche esta janela.
                </Typography>
              </>
            )}
            <Button
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #ff4b00',
                color: '#ff4b00',
                '&:hover': {
                  backgroundColor: '#ff4b00',
                  color: '#fff',
                }
              }}
              variant="contained"
              onClick={handleSaveMessage}
              disabled={sending}
            >
              Enviar Mensagem
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={sucesso}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>
          ENVIO FEITO COM SUCESSO!
        </Alert>
      </Snackbar>

      <Dialog open={openNewConversationDialog} TransitionComponent={Transition} onClose={handleCloseNewConversationDialog} fullWidth maxWidth="xl">
        <DialogTitle style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>Nova Conversa
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseNewConversationDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton></DialogTitle>
        <DialogContent >
          <Pacientes />

        </DialogContent>

      </Dialog>

      <Dialog TransitionComponent={Transition} open={openSolicitacoes} onClose={handleCloseSolicitacoes} fullWidth maxWidth="xl">
        <DialogTitle style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>Solicitações
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseSolicitacoes}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton></DialogTitle>
        <DialogContent >
          <Solicitados />

        </DialogContent>

      </Dialog>



    </>
  );
};

export default ConfigChat;
