import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { API_DEV, auth, KEY } from "../../../integration/FirebaseConfig";
import axios from 'axios';
import { paramsAuth, tokenCrypt } from "../../../services/script";
import ReactFrappeChart from "react-frappe-charts";




const ComponeneteTotalConsultas = ({ onUpdate, integridade }) => {
    const [solicitacoesData, setSolicitacoesData] = useState({
        "Marcada": Array(12).fill(0),
        "Cancelado pelo usuário": Array(12).fill(0),
        "Cancelado pela Instituição": Array(12).fill(0),
        "Em Análise": Array(12).fill(0),
        "Aguardando vagas": Array(12).fill(0),
        "Dados divergentes": Array(12).fill(0)
    });

    useEffect(() => {
        const cachedData = localStorage.getItem("cacheTotalConsultas");

        if (onUpdate === false) {
            if (cachedData) {
                const cached = JSON.parse(cachedData);
                setSolicitacoesData(cached.solicitacoesData);
                integridade(100)
            } else {
                integridade(0)
            }
        } else if (onUpdate === true) {
            fetchData();

        }

    }, [onUpdate]);

    const fetchData = async () => {
        const userId = auth.currentUser.uid
        const email = auth.currentUser.email
        const token = await auth.currentUser.getIdToken();
        const result = tokenCrypt(userId, email, token)

        try {
            const response = await axios.get(`${API_DEV}/dashboard/protocolos/status`, {
                headers: {
                    Authorization: `Bearer ${result}`
                },
                params: { cidade: KEY }
            });

            if (response.status === 200) {
                const { solicitacoesData } = response.data;
                setSolicitacoesData(solicitacoesData);
                integridade(100)
                localStorage.setItem("cacheTotalConsultas", JSON.stringify({ solicitacoesData: solicitacoesData }));
            } else {
                integridade(10)
            }
        } catch (error) {
            setSolicitacoesData({
                "Marcada": Array(12).fill(0),
                "Cancelado pelo usuário": Array(12).fill(0),
                "Cancelado pela Instituição": Array(12).fill(0),
                "Em Análise": Array(12).fill(0),
                "Aguardando vagas": Array(12).fill(0),
                "Dados divergentes": Array(12).fill(0)
            })
            integridade(0)
            if (error.response) {
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error);
            }
        }


    };


    return (
        <Box display="inline-block" gridColumn="span 6" gridRow="span 2" backgroundColor={'#1d1d1d'} borderRadius={5} padding={0} height="300px">
            <Typography variant="h5" fontWeight="600" style={{ textAlign: 'center', marginBottom: '-30px', marginTop: 15 }}>
                Status de Protocolos (mês)
            </Typography>
            <ReactFrappeChart
                animate={0}
                type="line"
                colors={['#FF4B00', '#4ED083', '#762E10', '#3498db', '#7CD6FD', '#FFA00A']}
                axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
                height={300}
                truncateLegends={1}
                lineOptions={{
                    dotSize: 5,
                    regionFill: 20,
                    areaFill: 20,

                }}

                data={{
                    labels: [
                        "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
                        "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
                    ],
                    datasets: [
                        {
                            name: "Dados divergentes",
                            values: solicitacoesData["Dados divergentes"],
                            chartType: "line",
                        },
                        {
                            name: "Marcada",
                            values: solicitacoesData["Marcada"],
                            chartType: "line",
                        },
                        {
                            name: "Em Análise",
                            values: solicitacoesData["Em Análise"],
                            chartType: "line",
                        },
                        {
                            name: "Cancel/usuário",
                            values: solicitacoesData["Cancelado pelo usuário"],
                            chartType: "line",
                        },
                        {
                            name: "Cancel/Instituição",
                            values: solicitacoesData["Cancelado pela Instituição"],
                            chartType: "line",
                        },
                        {
                            name: "Aguardando vagas",
                            values: solicitacoesData["Aguardando vagas"],
                            chartType: "line",
                        }

                    ],
                }}
            />
        </Box>
    );
};

export default ComponeneteTotalConsultas;
