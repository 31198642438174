import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert, Select, MenuItem, Backdrop, Box, TextField, IconButton, InputAdornment, Button, Typography, FormControl, InputLabel } from '@mui/material';
import firebaseApp, { ROTA_CIDADES, ROTA_USUARIOS, API_DEV, secretKey } from '../../integration/FirebaseConfig';
import { getUserDate } from '../../integration/UserDate';
import { getDatabase, ref, query, equalTo, get, orderByChild, set, update } from 'firebase/database';
import { GoogleAuthProvider } from "firebase/auth";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Login.css';
import { datetime, generateToken, reloadPage } from '../../services/script';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const database = getDatabase(firebaseApp);


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [cidades, setCidades] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingCity, setLoadingCity] = useState(true);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const fetchCidades = async () => {
      const database = getDatabase();
      const cidadesRef = ref(database, ROTA_CIDADES);
      try {
        const snapshot = await get(cidadesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const citiesArray = Object.entries(data)
            .map(([key, value]) => ({
              id: key,
              codigo: value.codigo,
              nome: value.nome,
              ativo: value.ativo,
              bloqueado: value.bloqueado,
              license_date: value.license_date
            }))
            .filter(city => city.ativo === true);
          setCidades(citiesArray);

        } else {

          setCidades([]);
        }
      } catch (error) {
        console.error("Erro ao obter cidades:", error);
      } finally {
        setLoadingCity(false)
      }
    };

    fetchCidades();
  }, []);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const emailTratado = email.toLocaleLowerCase();
      const database = getDatabase();
      const usuariosRef = ref(database, ROTA_USUARIOS);
      const usuarioQuery = query(usuariosRef, orderByChild('email'), equalTo(emailTratado));
      const snapshot = await get(usuarioQuery);
      const selectedCityObj = cidades.find(city => city.codigo === selectedCity);
      const cityStatus = selectedCityObj ? selectedCityObj.bloqueado : false;


      if (snapshot.exists()) {
        const data = snapshot.val();
        const usuario = Object.values(data)[0];

        if (usuario.cidade === selectedCity) {
          if (!cityStatus) {
            if (usuario) {
              if (!usuario.ativo) {
                setError("Seu usuário está bloqueado.");
                setLoading(false);
              } else {


                const cacheKeys = [
                  'cacheEspecialidadesTimestamp',
                  'cacheCalendar',
                  'cachePacientes',
                  'cacheSolicitadosAnalise',
                  'cacheSolicitadosAguardandoVagas',
                  'cacheSolicitadosCanceladas',
                  'cacheSolicitadosEmProcesso',
                  'cacheSolicitadosCompareceu',
                  'cacheSolicitadosFalta',
                  'cacheUpdateDate',
                  'cacheCardComponent',
                  'cacheTotalConsultas',
                  'cacheAvaliacoes',
                  'cacheAbsenteismo',
                  'cacheDiasMaisSolicitados',
                  'cacheTotalMarcadas',
                  'cacheIdadesFaltantes',
                  'cacheCategories',
                  'cacheDiasComFaltas',
                  'cacheIdades',
                  'cacheEspecialidades',
                  'cacheCalendarTimestamp',
                  'cacheSolicitadosCanceladasUser',
                  'cacheSolicitadosDadosDivergente',
                  'cacheSolicitados',
                  'cachePrioridadeCalendar'
                ];

                await cacheKeys.forEach(key => localStorage.removeItem(key));

                const auth = getAuth();
                signInWithEmailAndPassword(auth, emailTratado, password)
                  .then((userCredential) => {
                    const user = userCredential.user;
                    const uid = user.uid;
                    const horaLogin = datetime();

                    user.getIdToken().then((token) => {
                      update(ref(database, `${ROTA_USUARIOS}/${uid}`), {
                        horaLogin: horaLogin,
                        token: token.toString()
                      }).then(
                        reloadPage()
                      );
                    });
                  })
                  .catch((error) => {
                    console.error("Erro ao fazer login ou atualizar dados:", error);
                  });


              }
            }
          } else {
            setError("Cidade de acesso bloqueado.");
            setLoading(false);
          }

        } else {
          setError("Acesso negado a cidade escolhida.");
          setLoading(false);
        }

      } else {
        setError("Endereço de e-mail não existente ou incorreto.");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Erro durante o login:", error);
      setError("Usuario ou senha incorreto.");
    }
  };


  const handleGoogleLogin = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    setLoading(true);

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const licensa = generateToken(15);
      const usuariosRef = ref(database, `${ROTA_USUARIOS}/${user.uid}`);
      const snapshot = await get(usuariosRef);
      const horaLogin = datetime()

      const token = (await auth.currentUser.getIdToken()).toString();


      if (!snapshot.exists()) {
        const novoUsuario = {
          email: user.email,
          horaLogin,
          token,
          senha: "google",
          authByGoogle: true,
          cidade: selectedCity,
          ativo: true,
          acesso: false,
          licensa
        };
        await set(usuariosRef, novoUsuario);
        navigate("/cadastro/categoria");

      } else {
        const usuario = snapshot.val();

        if (usuario.cidade === selectedCity) {
          if (usuario.ativo === false) {
            setError("Seu usuário está bloqueado.");
            setLoading(false);

          } else {
            const horaLogin = datetime();

            auth.currentUser.getIdToken().then((token) => {
              update(ref(database, `${ROTA_USUARIOS}/${user.uid}`), {
                horaLogin: horaLogin,
                token: token.toString()
              }).then(
                reloadPage()
              );
            });
          }
        } else {
          setError("Seu usuário não tem permissão para acessar esta cidade.");
          navigate(`/${usuario.cidade}/logout`);
        }
      }
    } catch (error) {
      console.error("Erro durante o login com o Google:", error);
      setLoading(false);
      setError("Erro durante o login com o Google. Por favor, tente novamente.");
    }
  };


  return (
    <>


      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <HelmetProvider> <Helmet>
        <title>Painel - Acesso</title>
      </Helmet></HelmetProvider>


      <div className="login-container">
        <div className="logo-container">
          <img src="/images/logo.webp" alt="Logo" width="220" />
        </div>
        <div className="login-form">
          <h1>LOGIN</h1>

          <FormControl variant="filled" fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione seu acesso</InputLabel>
            <Select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              style={{ width: 300 }}
            >
              {loadingCity ? (
                <MenuItem disabled value="">
                  Carregando...
                </MenuItem>
              ) : (
                cidades.map((cidade) => (
                  <MenuItem key={cidade.codigo} value={cidade.codigo}>
                    {cidade.nome} - CÓD: {cidade.codigo}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>


          {selectedCity && (
            <>
              <form onSubmit={handleLogin}>
                <div className="input-group">
                  <label>E-mail:</label>
                  <TextField
                    sx={{
                      width: '100%',
                      border: '0.5px solid #ccc',
                      borderRadius: '5px',
                      backgroundColor: '#ededed',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '0.5px solid #ccc',
                        },
                        '&:hover fieldset': {
                          border: '0.5px solid #ccc',
                        },
                        '&.Mui-focused fieldset': {
                          border: '0.5px solid #ccc',
                        },
                        color: 'black',
                      },
                      '& .MuiInputBase-input': {
                        color: 'black',
                        fontSize: 15,
                        ':-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 30px #262626 inset !important',
                          WebkitTextFillColor: 'white !important',
                        },
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: '#4d4d4d',
                      },
                    }}
                    type="text"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">

                          <IconButton
                            disabled
                            edge="end"

                          >
                            <EmailIcon sx={{ color: '#262626' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>



                <div className="input-group">
                  <label>Senha:</label>
                  <TextField
                    sx={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      backgroundColor: '#ededed',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #ccc',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #ccc',
                        },

                        '&.Mui-focused fieldset': {
                          border: '1px solid #ccc',
                        },
                        color: 'black',
                      },
                      '& .MuiInputBase-input': {
                        color: 'black',
                        fontSize: 15
                      },
                      '& .MuiInputBase-input': {
                        color: 'black',
                        fontSize: 15,
                        ':-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 30px #262626 inset !important',
                          WebkitTextFillColor: 'white !important',
                        },
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: '#4d4d4d',
                      },
                    }}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"

                          >
                            {showPassword ? <VisibilityOff sx={{ color: '#262626' }} /> : <Visibility sx={{ color: '#262626' }} />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <p style={{ textAlign: 'end' }}>
                  <Link style={{ color: '#FF4B00', textAlign: 'end', textDecoration: 'none' }} to="#">Esqueci a senha</Link>
                </p>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '100%',
                    marginTop: 1,
                    fontWeight: 'bold',
                    backgroundColor: '#FF4B00',
                    '&:hover': {
                      backgroundColor: '#e04300',
                    },
                    fontWeight: 'bold',
                  }}
                >
                  Entrar
                </Button>

              </form>
              <div className="google-button">
                <button
                  // className={`google-login-button`}
                  // onClick={handleGoogleLogin}


                className={`google-login-button ${isDisabled ? 'disabled' : ''}`}
                onClick={handleGoogleLogin}
                disabled={isDisabled}
                >
                  <img
                    src="/images/google.webp"
                    alt="Google Icon"
                    width="20"
                    height="20"
                    style={{ marginRight: '10px' }}
                  />
                  Entrar com Google
                </button>
              </div>


            </>
          )}

          <Typography align="center" variant="body2" sx={{ marginTop: 2, fontSize: 15 }}>
            Não tem uma conta?{' '}
            <Link to="/cadastro" style={{ color: '#FF4B00', textDecoration: 'none' }}>
              Cadastre-se
            </Link>
          </Typography>


        </div>
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Login;
