import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  DialogContentText,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import HeaderInstituicoes from "../../utils/HeaderInstituicao";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDatabase, ref, get, update, remove } from '@firebase/database';
import firebaseApp, { KEY, BASE_PROD } from '../../integration/FirebaseConfig';
import GroupIcon from '@mui/icons-material/Group';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datetime, reloadPage } from "../../services/script";
import { useFuncoes } from "../../services/funcoes";

const database = getDatabase(firebaseApp);

const Instituicoes = () => {
  const [loading, setLoading] = useState(true);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState('');
  const [quantidadeVagas, setQuantidadeVagas] = useState(0);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();
  const [especialidades, setEspecialidades] = useState([]);
  const [vagasDisponiveis, setVagasDisponiveis] = useState({});
  const [novaQuantidadeVagas, setNovaQuantidadeVagas] = useState(0);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [globalConfirmationDialogOpen, setGlobalConfirmationDialogOpen] = useState(false);
  const [globalVagasConfirmationDialogOpen, setGlobalVagasConfirmationDialogOpen] = useState(false);
  const {
    funcEditarInstituicoes,
    funcExcluirInstituicoes,
    funcCriarNovasInstituicoes,
    funcVisualizarVagas,
    funcAtualizarVagas
  } = useFuncoes();

  const columns = [
    {
      field: "nome",
      headerName: "Instituição",
      flex: 0.8,
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "logradouro",
      headerName: "Endereço",
      flex: 0.8,
      filterable: true,
    },
    {
      field: "bairro",
      headerName: "Bairro",
      flex: 0.5,
      filterable: true,
    },
    {
      field: "cidade",
      headerName: "Cidade",
      flex: 0.8,
      filterable: true,
    },
    {
      field: "datetime",
      headerName: "Ultima Atualização",
      flex: 0.6,
      filterable: true,
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleDeleteConfirmation(row.id)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
            }}
            disabled={!funcExcluirInstituicoes}
          >
            <DeleteOutlineIcon />
          </IconButton>

          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#363636',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
            disabled={!funcEditarInstituicoes}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleVagasClick(row.id)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #FF4B00",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
            disabled={!funcVisualizarVagas}
          >
            <GroupIcon /> VAGAS
          </IconButton>
        </Box>
      ),
    },
  ];


  const fetchInstituicoes = async () => {
    const database = getDatabase();
    const usersRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes`);

    try {
      const snapshot = await get(usersRef);
      const data = snapshot.val();

      if (data) {
        const instituicoes = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setUserData(instituicoes);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
      setUserData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstituicoes();
  }, []);


  const handleAdd = (user) => {
    navigate(`/instituicoes/editar`, { state: { userData: user } });
  }

  const handleDeleteConfirmation = (id) => {
    setInstituicaoSelecionada(id);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteInstituicao(instituicaoSelecionada);
    setDeleteConfirmationDialogOpen(false);
  };

  const deleteInstituicao = (id) => {
    const instituicaoRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${id}`);
    try {
      remove(instituicaoRef);
      console.log('Instituição excluída com sucesso!');
      fetchInstituicoes();
    } catch (error) {
      console.error('Erro ao excluir a instituição:', error);
    }
  };

  const handleSalvar = () => {
    if (!instituicaoSelecionada || !especialidadeSelecionada || novaQuantidadeVagas < 0) {
      console.error('Por favor, preencha todos os campos.');
      return;
    }

    const especialidadeRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade/${especialidadeSelecionada}`);
    try {
      const dadosVagas = {
        vagas: parseInt(novaQuantidadeVagas),
        datetime: datetime()
      };

      update(especialidadeRef, dadosVagas);
      console.log('Dados de vagas salvos com sucesso!');
      setNovaQuantidadeVagas(0);
      handleCloseDialog();
    } catch (error) {
      console.error('Erro ao salvar os dados de vagas:', error);
    }
  };



  const handleVagasClick = (id) => {
    const instituicao = userData.find((item) => item.id === id);
    if (instituicao) {
      setInstituicaoSelecionada(instituicao);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setInstituicaoSelecionada(null);
    setEspecialidadeSelecionada('');
    setQuantidadeVagas(0);
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchEspecialidades = async () => {
      if (instituicaoSelecionada) {
        const database = getDatabase();
        const especialidadesRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade`);

        try {
          const snapshot = await get(especialidadesRef);
          const data = snapshot.val();

          if (data) {
            setEspecialidades(Object.keys(data));
            setVagasDisponiveis(data);
          } else {
            setEspecialidades([]);
            setVagasDisponiveis({});
          }
        } catch (error) {
          console.error('Erro ao obter especialidades do Firebase:', error);
          setEspecialidades([]);
          setVagasDisponiveis({});
        }
      }
    };

    fetchEspecialidades();
  }, [instituicaoSelecionada]);



  useEffect(() => {
    if (especialidadeSelecionada && vagasDisponiveis[especialidadeSelecionada]) {
      setQuantidadeVagas(vagasDisponiveis[especialidadeSelecionada].vagas);
    } else {
      setQuantidadeVagas(0);
    }
  }, [especialidadeSelecionada, vagasDisponiveis]);


  const handleZerarVagas = () => {
    if (!instituicaoSelecionada || !especialidadeSelecionada) {
      console.error('Por favor, selecione uma instituição e especialidade válida.');
      return;
    }

    const especialidadeRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade/${especialidadeSelecionada}`);

    try {

      const dadosVagas = {
        ...vagasDisponiveis[especialidadeSelecionada],
        preenchido: 0
      };

      update(especialidadeRef, dadosVagas);
      console.log('Vagas preenchidas zeradas com sucesso!');
    } catch (error) {
      console.error('Erro ao zerar as vagas preenchidas:', error);
    } finally {
      setConfirmationDialogOpen(false);
      reloadPage()
    }
  };



  const handleZerarVagasGlobais = async () => {
    if (!instituicaoSelecionada) {
      console.error('Por favor, selecione uma instituição válida.');
      return;
    }

    const database = getDatabase();
    const especialidadesRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade`);

    try {
      const snapshot = await get(especialidadesRef);
      const data = snapshot.val();

      if (data) {

        const updates = {};
        Object.keys(data).forEach((especialidade) => {
          const especialidadeRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade/${especialidade}`);
          updates[especialidadeRef.toString()] = {
            ...data[especialidade],
            preenchido: 0
          };
        });


        await Promise.all(
          Object.keys(updates).map((key) => update(ref(database, key), updates[key]))
        );

        console.log('Vagas preenchidas globais zeradas com sucesso!');
      }
    } catch (error) {
      console.error('Erro ao zerar as vagas preenchidas globais:', error);
    } finally {
      setGlobalConfirmationDialogOpen(false);
      reloadPage();
    }
  };


  const handleZerarVagasDisponiveisGlobais = async () => {
    if (!instituicaoSelecionada) {
      console.error('Por favor, selecione uma instituição válida.');
      return;
    }

    const database = getDatabase();
    const especialidadesRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade`);

    try {
      const snapshot = await get(especialidadesRef);
      const data = snapshot.val();

      if (data) {

        const updates = {};
        Object.keys(data).forEach((especialidade) => {
          const especialidadeRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade/${especialidade}`);
          updates[especialidadeRef.toString()] = {
            ...data[especialidade],
            vagas: 0
          };
        });


        await Promise.all(
          Object.keys(updates).map((key) => update(ref(database, key), updates[key]))
        );

        console.log('Vagas disponíveis globais zeradas com sucesso!');
      }
    } catch (error) {
      console.error('Erro ao zerar as vagas disponíveis globais:', error);
    } finally {
      setGlobalVagasConfirmationDialogOpen(false);
      reloadPage();
    }
  };



  return (
    <>
      <HelmetProvider><Helmet>
        <title>Painel DHF - Instituições</title>
      </Helmet></HelmetProvider>


      <Box m="10px">
        <HeaderInstituicoes title="INSTITUIÇÕES" />
        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: '#1D1D1D',
              },
            }}
          >
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={userData}
              columns={columns}
              disableSelectionOnClick
            />
          </Box>
        ) : (
          userData.map((user) => (
            <Box key={user.id} className="user-card" m="10px">
              <Typography variant="h6">{user.nome.toUpperCase()}</Typography>
              <Typography>{user.logradouro}</Typography>
              <Typography>{user.bairro}</Typography>
              <Typography>{user.localidade}</Typography>
              <Typography>{user.cidade}</Typography>
              <Typography>{user.telefone}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => handleDeleteConfirmation(user.id)}
                  sx={{
                    backgroundColor: '#363636',
                    borderRadius: '4px',
                    border: "1px solid #1D1D1D",
                    padding: '8px',
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleAdd(user)}
                  sx={{
                    backgroundColor: '#363636',
                    borderRadius: '4px',
                    border: "1px solid #1D1D1D",
                    padding: '8px',
                    marginLeft: 1
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleVagasClick(user.id)}
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid #FF4B00",
                    borderRadius: '4px',
                    marginLeft: 1,
                    fontSize: 13,
                    padding: '8px',
                  }}
                >
                  <GroupIcon /> VAGAS
                </IconButton>
              </Box>
            </Box>
          ))
        )}
      </Box>

      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/brasao_loading.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle style={{ color: 'white', backgroundColor: '#1D1D1D', padding: '16px', fontWeight: 'bold' }}>
          Adicionar/Editar Vagas
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#333', padding: '16px', color: 'white', textAlign: 'justify' }}>
          <FormControl fullWidth margin="normal">
            <InputLabel style={{ color: 'white' }}>Especialidade</InputLabel>
            <Select
              value={especialidadeSelecionada}
              onChange={(e) => setEspecialidadeSelecionada(e.target.value)}
              label="Especialidade"
              style={{ color: 'white', backgroundColor: '#444' }}
            >
              {especialidades.map((especialidade) => (
                <MenuItem key={especialidade} value={especialidade}>
                  {vagasDisponiveis[especialidade]?.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {
            especialidadeSelecionada ? (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginTop: 15 }}
                    label="Quantidade de vagas atual"
                    type="number"
                    value={quantidadeVagas}
                    sx={{ bgcolor: '#3338', opacity: 1 }}
                    InputLabelProps={{
                      style: { color: 'white', fontSize: '1.0rem' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: 'white', fontWeight: 'bold', fontSize: '1.2rem' },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginTop: 15 }}
                    label="Vagas Preenchidas"
                    type="number"
                    value={vagasDisponiveis[especialidadeSelecionada]?.preenchido || 0}
                    sx={{ bgcolor: '#3338', opacity: 1 }}
                    InputLabelProps={{
                      style: { color: 'white', fontSize: '1.0rem' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: 'white', fontWeight: 'bold', fontSize: '1.2rem' },
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    style={{ marginTop: 15 }}
                    label="Data atualização"
                    type="text"
                    value={vagasDisponiveis[especialidadeSelecionada]?.datetime || '00:00:00'}
                    sx={{ bgcolor: '#3338', opacity: 1 }}
                    InputLabelProps={{
                      style: { color: 'white', fontSize: '1.0rem' },
                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: 'white', fontWeight: 'bold', fontSize: '1rem' },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Nova quantidade de vagas"
                    type="number"
                    value={novaQuantidadeVagas}
                    onChange={(e) => setNovaQuantidadeVagas(e.target.value)}
                    InputLabelProps={{
                      style: { color: 'white', fontSize: '1.0rem' },
                    }}
                    InputProps={{
                      style: { color: 'white', fontWeight: 'bold', fontSize: '1.2rem' },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={() => setConfirmationDialogOpen(true)}
                    style={{
                      backgroundColor: 'transparent',
                      border: '1px solid red',
                      borderRadius: '4px',
                      padding: '8px',
                      marginLeft: '8px',
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: 'white',
                      marginRight: 1,
                    }}
                  >
                    Zerar vagas preenchidas
                  </Button>
                  <Dialog
                    open={confirmationDialogOpen}
                    onClose={() => setConfirmationDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Ao clicar em "Zerar vagas preenchidas", você irá alterar o contador de VAGAS PREENCHIDAS/CONSULTAS MARCADAS NO ATUAL PERÍODO. Tem certeza de que deseja continuar?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setConfirmationDialogOpen(false)} style={{
                        backgroundColor: 'transparent',
                        border: '1px solid red',
                        borderRadius: '4px',
                        padding: '8px',
                        marginLeft: '8px',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: 'red',
                        marginRight: 1,
                      }}>
                        Cancelar
                      </Button>
                      <Button onClick={handleZerarVagas} autoFocus style={{
                        backgroundColor: 'transparent',
                        border: '1px solid white',
                        borderRadius: '4px',
                        padding: '8px',
                        marginLeft: '8px',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: 'white',
                        marginRight: 1,
                      }}>
                        Zerar vagas preenchidas
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} style={{ paddingTop: '20px' }}>
                <Typography variant="h6" style={{ color: '#ffffff', textAlign: 'center' }}>
                  CONFIGURAÇÕES GLOBAIS - ATENÇÃO
                </Typography>
                <Button
                  onClick={() => setGlobalConfirmationDialogOpen(true)}
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid white',
                    borderRadius: '4px',
                    padding: '12px',
                    marginTop: '16px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'white',
                    width: '100%',
                  }}
                >
                  Zerar vagas preenchidas globais
                </Button>

                <Button
                  onClick={() => setGlobalVagasConfirmationDialogOpen(true)}
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid white',
                    borderRadius: '4px',
                    padding: '12px',
                    marginTop: '16px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'white',
                    width: '100%',
                  }}
                >
                  Zerar vagas disponíveis globais
                </Button>

                <Dialog
                  open={globalVagasConfirmationDialogOpen}
                  onClose={() => setGlobalVagasConfirmationDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Ao clicar em "Zerar vagas disponíveis globais", você irá zerar as vagas disponíveis para todas as instituições. Tem certeza de que deseja continuar?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setGlobalVagasConfirmationDialogOpen(false)} style={{
                      backgroundColor: 'transparent',
                      border: '1px solid blue',
                      borderRadius: '4px',
                      padding: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: 'blue',
                      marginRight: '8px',
                    }}>
                      Cancelar
                    </Button>
                    <Button onClick={handleZerarVagasDisponiveisGlobais} autoFocus style={{
                      backgroundColor: 'blue',
                      borderRadius: '4px',
                      padding: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: '#ffffff',
                    }}>
                      Confirmar
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={globalConfirmationDialogOpen}
                  onClose={() => setGlobalConfirmationDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Ao clicar em "Zerar vagas preenchidas globais", você irá alterar o contador de VAGAS PREENCHIDAS/CONSULTAS MARCADAS NO ATUAL PERÍODO para todas as instituições. Tem certeza de que deseja continuar?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setGlobalConfirmationDialogOpen(false)} style={{
                      backgroundColor: 'transparent',
                      border: '1px solid red',
                      borderRadius: '4px',
                      padding: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: 'red',
                      marginRight: '8px',
                    }}>
                      Cancelar
                    </Button>
                    <Button onClick={handleZerarVagasGlobais} autoFocus style={{
                      backgroundColor: 'red',
                      borderRadius: '4px',
                      padding: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: '#ffffff',
                    }}>
                      Confirmar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

            )
          }
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#1D1D1D', padding: '8px' }}>

          <Button onClick={handleCloseDialog} style={{ color: 'white', fontWeight: 'bold' }}>
            Cancelar
          </Button>
          <Button onClick={handleSalvar} style={{ color: 'white', fontWeight: 'bold', backgroundColor: '#FF4B00' }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>





      <Dialog open={deleteConfirmationDialogOpen} onClose={() => setDeleteConfirmationDialogOpen(false)}>
        <DialogTitle style={{ color: 'white', backgroundColor: '#1D1D1D', padding: '16px', fontWeight: 'bold' }}>
          Confirmar exclusão
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center', padding: '16px' }}>
          <Typography variant="body1" style={{ fontSize: '1.2em' }}>
            Tem certeza de que deseja excluir esta instituição? Esta ação é irreversível e não será possível recuperar os dados da instituição após a exclusão. Por favor, confirme sua escolha, pois todos os registros e informações relacionados a esta instituição serão permanentemente apagados do sistema.
          </Typography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#1D1D1D', padding: '8px' }}>
          <Button onClick={() => setDeleteConfirmationDialogOpen(false)} style={{ color: 'white', fontWeight: 'bold' }}>
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} style={{ color: 'white', fontWeight: 'bold', backgroundColor: '#FF4B00' }}>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Instituicoes;
