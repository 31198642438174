import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  Grid,
  Dialog, DialogContent, DialogActions,
  Checkbox
} from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../../theme";
import { get, getDatabase, onValue, push, ref, update } from "firebase/database";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { BASE_PROD, KEY, KEY_LICENSE, KEY_NAME_CITY, KEY_NAME_USER, KEY_INSTANCE_ID, KEY_INSTANCE_TOKEN, KEY_USER_TOKEN_ID, url_api_gzappy } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format, set } from "date-fns";
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { datetime, getPrioridadeLabel, handleSendError, priorities } from "../../../services/script";
import { LocalizationProvider, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { ptBR as dateFnsPtBR } from 'date-fns/locale';

const database = getDatabase(firebaseApp);

const db = ref(database, `${BASE_PROD}/${KEY}/instituicoes`);


const Marcacao = () => {
  const location = useLocation();
  const { userData } = location.state;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedProcedure, setSelectedProcedure] = useState("");
  const [selectedProfi, setSelectedProfi] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedTurn, setSelectedTurn] = useState("");
  const [textInput, setTextInput] = useState('');
  const [observacoes, setObservacoes] = useState('Olá, a secretaria de Saúde está sempre empenhada no melhor para você. Se houver algum imprevisto, não esqueça de cancelar essa solicitação, afinal, outras pessoas também precisam, desejamos um ótimo dia!');
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [procedimentos, setProcedimentos] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [cor, setCor] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [comunicarPaciente, setComunicarPaciente] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const database = getDatabase();


        const unitsRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes`);
        const unitsSnapshot = await get(unitsRef);
        const unitsData = unitsSnapshot.val();
        if (unitsData) {
          const unitsArray = Object.values(unitsData);
          setUnits(unitsArray);
        }


        const profissionaisRef = ref(database, `${BASE_PROD}/${KEY}/profissionais`);
        const profissionaisSnapshot = await get(profissionaisRef);
        const profissionaisData = profissionaisSnapshot.val();
        if (profissionaisData) {
          const profissionaisArray = Object.values(profissionaisData);
          setProfissionais(profissionaisArray);
        }
      } catch (error) {
        console.error('Erro ao obter dados do Firebase:', error);
      }
    };

    fetchData();
  }, []);


  const handleUnitChange = async (unitId) => {
    console.log(unitId);
    const database = getDatabase();
    const procedimentosRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${unitId}/procedimentos`);
    console.log(procedimentosRef);

    try {
      const procedimentosSnapshot = await get(procedimentosRef);
      const procedimentosData = procedimentosSnapshot.val();
      if (procedimentosData) {
        const procedimentosArray = Object.values(procedimentosData);
        setProcedimentos(procedimentosArray);
      }
    } catch (error) {
      console.error('Erro ao obter dados dos procedimentos:', error);
    }
  };



  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleTextInputChangeObs = (event) => {
    setObservacoes(event.target.value);
  };

  function formatUTCDateToUTCPlusThree(dateString) {
    const selectedEventDate = new Date(dateString);
    selectedEventDate.setUTCHours(selectedEventDate.getUTCHours() + 6);
    const formattedDate = format(selectedEventDate, '(dd/MM/yyyy)');
    return formattedDate;
  }

  const handleSendMessage = async (userData) => {

    const phoneNumber = userData.telefone;
    let message = '';
    if (userData.horario == "00:00") {
      message = `Consulta marcada com sucesso para *${userData.categoria.toUpperCase()}*!\n\nDetalhes da consulta:\n\n- Unidade: *${userData.unidade}*\n- Data: *${formatUTCDateToUTCPlusThree(userData.data)}*\n- Turno: *${userData.turno}*\n- Paciente: *${userData.nome}*\n- Nº Protocolo: *${userData.protocolo}*\n- Código SISREG: *${userData.codigoSisREG}*\n- Observações: *${userData.observacoes}*\n\nAgradecemos por utilizar nosso sistema. Lembre-se que no dia da consulta você deve comparecer com seu cartão Nacional do SUS (CNS) e seus documentos Pessoais (RG/CPF).`;

    } else {
      message = `Consulta marcada com sucesso para *${userData.categoria.toUpperCase()}*!\n\nDetalhes da consulta:\n\n- Unidade: *${userData.unidade}*\n- Data: *${formatUTCDateToUTCPlusThree(userData.data)}*\n- Horário: *${userData.horario}*\n- Turno: *${userData.turno}*\n- Paciente: *${userData.nome}*\n- Nº Protocolo: *${userData.protocolo}*\n- Código SISREG: *${userData.codigoSisREG}*\n- Observações: *${userData.observacoes}*\n\nAgradecemos por utilizar nosso sistema. Lembre-se que no dia da consulta você deve comparecer com seu cartão Nacional do SUS (CNS) e seus documentos Pessoais (RG/CPF).`;

    }

    console.log('Verificando API - token acess...');

    const GZAPPY_URL = `${url_api_gzappy}`

    await fetch(GZAPPY_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user_token_id': `${KEY_USER_TOKEN_ID}`
      },
      body: JSON.stringify({
        instance_id: `${KEY_INSTANCE_ID}`,
        instance_token: `${KEY_INSTANCE_TOKEN}`,
        message: [`${message}`],
        phone: [`${phoneNumber}`]
      })
    })

    const messageData = {
      hora: new Date().toLocaleTimeString(),
      data: new Date().toLocaleDateString(),
      enviado: 'Atendente',
      msg: [`${message}`],
      atendente: `${KEY_NAME_USER}`

    };



    const chatRef = ref(database, `/${BASE_PROD}/${KEY}/chats/${phoneNumber}/conversas`);
    push(chatRef, messageData)
      .then(() => {

        console.log('Mensagem enviada com sucesso!');

      })
      .catch((error) => {
        console.error('Erro ao enviar mensagem:', error);
      });
    console.log('Mensagem enviada sem erros');

  }

  function formatarTelefone(telefone) {

    const numerosTelefone = telefone.replace(/\D/g, '');

    if (numerosTelefone.length === 12) {
      return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 4)}) ${numerosTelefone.slice(4, 8)}-${numerosTelefone.slice(8)}`;
    } else if (numerosTelefone.length === 11) {
      return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 3)}) ${numerosTelefone.slice(3, 7)}-${numerosTelefone.slice(7)}`;
    } else if (numerosTelefone.length === 10) {
      return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 4)}) ${numerosTelefone.slice(4, 8)}-${numerosTelefone.slice(8)}`;

      return telefone;
    }
  }

  function formatUTCDateToUTCPlusThree(dateString) {
    const selectedEventDate = new Date(dateString);
    selectedEventDate.setUTCHours(selectedEventDate.getUTCHours() + 6);
    const formattedDate = format(selectedEventDate, '(dd/MM/yyyy)');
    return formattedDate;
  }

  const handleCalendarChange = (date) => {
    setDate(date);
    setSelectedDate(date.toISOString().split('T')[0])
    console.log(date.toISOString().split('T')[0])
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);

  };


  const handleSave = async () => {
    setLoading(true);
    let turnoMOD = "";
    let horarioMOD = "";
    const userId = userData.protocolo;
    const userRef = ref(database, `${BASE_PROD}/${KEY}/solicitados/${userId}`);


    if (!selectedDate || !selectedProcedure || !selectedUnit || !textInput || !observacoes || !profissionais || !cor) {
      alert(`Por favor, preencha todos os campos!`);
      setLoading(false);
      return;
    }

    if (selectedTime) {
      turnoMOD = selectedTime <= "12:00" ? "Manhã" : "Tarde";
      horarioMOD = selectedTime;
    } else {
      turnoMOD = selectedTurn;
      horarioMOD = "00:00";
    }

    const selectedDateTime = new Date(`${selectedDate}T${horarioMOD}:00`);

    const formattedDateTime = selectedDateTime.toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'America/Sao_Paulo'
    });

    if (selectedProcedure != "Exames") {
      try {


        const instituicaoSelecionada = units.find((unit) => unit.nome === selectedUnit);
        if (!instituicaoSelecionada || !instituicaoSelecionada.especialidade) {
          alert('A instituição selecionada não possui especialidades cadastradas.');
          setLoading(false);
          return;
        }
        const especialidadeSelecionada = Object.values(instituicaoSelecionada.especialidade).find(especialidade => especialidade.nome === userData.categoria);
        if (!especialidadeSelecionada) {
          alert('O procedimento selecionado não está disponível nesta instituição.');
          setLoading(false);
          return;
        }

        const vagasRef = ref(database, `${BASE_PROD}/${KEY}/instituicoes/${instituicaoSelecionada.id}/especialidade/${especialidadeSelecionada.id}`);
        const vagasSnapshot = await get(vagasRef);
        const vagasData = vagasSnapshot.val();
        if (!vagasData) {
          alert('Erro ao obter informações de vagas. Tente novamente.');
          setLoading(false);
          return;
        }


        if (parseInt(vagasData.vagas) <= parseInt(vagasData.preenchido)) {
          alert('Não há vagas disponíveis para este procedimento nesta unidade.');
          setLoading(false);
          return;
        }
        const protocol = userData.protocolo;

        const preenchido = parseInt(vagasData.preenchido) || 0;
        const novoPreenchido = preenchido + 1;

        const protocolosAtualizados = vagasData.protocolos
          ? [...vagasData.protocolos, protocol]
          : [protocol];

        const atualizado = {
          ...vagasData,
          preenchido: novoPreenchido,
          protocolos: protocolosAtualizados
        };

        update(userRef, {
          status: "Marcada",
          data: selectedDate,
          datefinish: formattedDateTime,
          procedimento: selectedProcedure,
          unidade: selectedUnit,
          horario: horarioMOD,
          profissional: selectedProfi,
          turno: turnoMOD,
          KEY_RESPONSAVEL: KEY_NAME_USER,
          KEY_LICENSE: KEY_LICENSE,
          codigoSisREG: textInput,
          observacoes: observacoes.trim() !== "" ? observacoes : "Sem observações",
          prioridade: cor === 'green' ? 'não' : 'sim',
          cor: cor
        }).then(() => {
          return get(userRef);
        }).then((snapshot) => {
          const updatedUserData = snapshot.val();
          if (comunicarPaciente) {
            return handleSendMessage(updatedUserData);
          }
        }).then(async () => {


          return update(vagasRef, atualizado);


        }).then(() => {
          console.log('Quantidade de vagas atualizada com sucesso!');

          const AlterarStatus = {
            status: "Marcada",
            datefinish: formattedDateTime,
            procedimento: selectedProcedure,
            unidade: selectedUnit,
            horario: horarioMOD,
            profissional: selectedProfi,
            turno: turnoMOD,
            codigoSisREG: textInput,
            observacoes: observacoes.trim() !== "" ? observacoes : "Sem observações",
            prioridade: cor === 'green' ? 'não' : 'sim',
            cor: cor
          };
          console.log('aaa: ', userData.protocolo)

          const solicitacoesRef = ref(database, `/${BASE_PROD}/${KEY}/users/${userData.telefone}/solicitacoes/${userData.protocolo}`);

          return update(solicitacoesRef, AlterarStatus);
        }).then(async () => {
          try {
            const indexRef = ref(database, `/${BASE_PROD}/${KEY}/marcados_index`);
            const newData = {
              [userData.protocolo]: { datefinish: formattedDateTime }
            };

            return update(indexRef, newData);
          } catch (error) {
            console.error('Error setting data in marcados_index:', error);
          }
        }).finally(() => {
          let cachePacientes = JSON.parse(localStorage.getItem('cacheSolicitadosAnalise')) || [];
          cachePacientes = cachePacientes.map(paciente => {
            if (paciente.protocolo === userData.protocolo) {
              localStorage.removeItem('cacheSolicitadosAnalise', JSON.stringify(cachePacientes));
            }
            return paciente;
          });

          handleDownloadPDF(userData, formattedDateTime, turnoMOD)
          setLoading(false);
          navigate(`/solicitacoes`);
        })
          .catch((error) => {
            console.error("Error updating data: ", error);
          });

      } catch (error) {
        console.error('Erro ao salvar dados:', error);

        const errorObject = {
          datetime: datetime(),
          errorLineTrace: error.stack,
          errorMessage: error.message,
          errorType: error.constructor.name,
          responseStatus: error.response?.status || 'none',
          responseMsg: error.response?.data?.message || 'none',
          page: 'Tela de Marcação',
          KEY,
          KEY_LICENSE,
          KEY_NAME_CITY,
          KEY_NAME_USER
        };

        console.log("Tentando enviar objeto de erro para o Firebase:", errorObject);

        const firebaseErrorsRef = ref(getDatabase(), 'panel/erros');
        const newErrorRef = push(firebaseErrorsRef);

        const errorString = JSON.stringify(errorObject);
        handleSendError(errorString, KEY_NAME_USER);

        try {
          await set(newErrorRef, errorObject);
          console.log("Objeto de erro enviado com sucesso para o Firebase.");
        } catch (firebaseError) {
          console.error("Erro ao enviar objeto de erro para o Firebase:", firebaseError);
        }
        setLoading(false);
      }
    } else {
      try {


        update(userRef, {
          status: "Marcada",
          data: selectedDate,
          datefinish: formattedDateTime,
          procedimento: selectedProcedure,
          unidade: selectedUnit,
          horario: horarioMOD,
          profissional: selectedProfi,
          turno: turnoMOD,
          KEY_RESPONSAVEL: KEY_NAME_USER,
          KEY_LICENSE: KEY_LICENSE,
          codigoSisREG: textInput,
          observacoes: observacoes.trim() !== "" ? observacoes : "Sem observações",
          prioridade: cor === 'green' ? 'não' : 'sim',
          cor: cor
        }).then(() => {
          return get(userRef);
        }).then((snapshot) => {
          const updatedUserData = snapshot.val();
          if (comunicarPaciente) {
            return handleSendMessage(updatedUserData);
          }
        }).then(() => {

          const AlterarStatus = {
            status: "Marcada",
            datefinish: formattedDateTime,
            procedimento: selectedProcedure,
            unidade: selectedUnit,
            horario: horarioMOD,
            profissional: selectedProfi,
            turno: turnoMOD,
            codigoSisREG: textInput,
            observacoes: observacoes.trim() !== "" ? observacoes : "Sem observações",
            prioridade: cor === 'green' ? 'não' : 'sim',
            cor: cor
          };
          console.log('aaa: ', userData.protocolo)

          const solicitacoesRef = ref(database, `/${BASE_PROD}/${KEY}/users/${userData.telefone}/solicitacoes/${userData.protocolo}`);

          return update(solicitacoesRef, AlterarStatus);
        }).then(async () => {
          try {
            const indexRef = ref(database, `/${BASE_PROD}/${KEY}/marcados_index`);
            const newData = {
              [userData.protocolo]: { datefinish: formattedDateTime }
            };

            return update(indexRef, newData);
          } catch (error) {
            console.error('Error setting data in marcados_index:', error);
          }
        }).finally(() => {
          let cachePacientes = JSON.parse(localStorage.getItem('cacheSolicitadosAnalise')) || [];
          cachePacientes = cachePacientes.map(paciente => {
            if (paciente.protocolo === userData.protocolo) {
              localStorage.removeItem('cacheSolicitadosAnalise', JSON.stringify(cachePacientes));
            }
            return paciente;
          });

          handleDownloadPDF(userData, formattedDateTime, turnoMOD)
          setLoading(false);
          navigate(`/solicitacoes`);
        })
          .catch((error) => {
            console.error("Error updating data: ", error);
          });

      } catch (error) {
        console.error('Erro ao salvar dados:', error);

        const errorObject = {
          datetime: datetime(),
          errorLineTrace: error.stack,
          errorMessage: error.message,
          errorType: error.constructor.name,
          responseStatus: error.response?.status || 'none',
          responseMsg: error.response?.data?.message || 'none',
          page: 'Tela de Marcação',
          KEY,
          KEY_LICENSE,
          KEY_NAME_CITY,
          KEY_NAME_USER
        };

        console.log("Tentando enviar objeto de erro para o Firebase:", errorObject);

        const firebaseErrorsRef = ref(getDatabase(), 'panel/erros');
        const newErrorRef = push(firebaseErrorsRef);

        const errorString = JSON.stringify(errorObject);
        handleSendError(errorString, KEY_NAME_USER);

        try {
          await set(newErrorRef, errorObject);
          console.log("Objeto de erro enviado com sucesso para o Firebase.");
        } catch (firebaseError) {
          console.error("Erro ao enviar objeto de erro para o Firebase:", firebaseError);
        }
        setLoading(false);
      }
    }




  };

  const handleDownloadPDF = (selectedUser, date, turno) => {
    const doc = new jsPDF({
      unit: 'mm',
      format: [210, 297]
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const canvasWidth = 1240;
    const canvasHeight = 1748;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const backgroundImagePath = '/images/status.webp';
    const backgroundImage = new Image();
    backgroundImage.src = backgroundImagePath;

    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);


      const backgroundImageDataURL = canvas.toDataURL('image/jpeg', 1);
      doc.addImage(backgroundImageDataURL, 'JPEG', 0, 0, pageWidth, pageHeight);

      const currentDate = new Date();
      const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
      const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
      const LICENSE = KEY_LICENSE.toUpperCase();

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(`Status: Em análise`, 85, 62);

      doc.setFontSize(13);
      doc.setFont("helvetica", "normal");
      doc.text(`Nº ${selectedUser.protocolo} | ${selectedUser.categoria} | ${selectedUser.nome}`, 50, 72);


      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(`Status: Marcada`, 85, 97);

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Data: ${date} | Turno: ${turno} | Unidade: ${selectedUnit}`, 36, 107);

      doc.setFontSize(11);
      doc.setFont("helvetica", "bold");
      doc.text(`Este documento serve como comprovação oficial do status atual da solicitação em aberto com `, 18, 170);
      doc.text(`o protocolo de número Nº ${selectedUser.protocolo}. Em conformidade com os procedimentos estabelecidos e `, 18, 175);
      doc.text(`como prova de autoria, assinamos abaixo para atestar a veracidade e a integridade das informa-`, 18, 180);
      doc.text(`ções aqui apresentadas.`, 18, 185);


      doc.setDrawColor(0, 0, 0);
      doc.line(35, 240, doc.internal.pageSize.getWidth() - 35, 240);


      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Assinatura do responsável", (pageWidth - doc.getTextWidth("Assinatura do responsável")) / 2, 250);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(`Este documento foi gerado dia ${formattedDate} às ${formattedTime}, certificação: ${LICENSE}`, pageWidth - 30, 285, { align: 'right' });




      doc.save(`protocolo_${selectedUser.protocolo}.pdf`);
    };

    backgroundImage.onerror = (error) => {
      console.error('Erro ao carregar a imagem de fundo:', error);
    };

  };



  const handleAccessClick = () => {
    setDialogOpen(true);
  };

  const columns = [

    {
      field: "nome",
      headerName: "Nome Completo",
      flex: 1,
      cellClassName: "name-column--cell",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "protocolo",
      headerName: "Nº do Protocolo",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "turno",
      headerName: "Preferência",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "categoria",
      headerName: "Especialidade",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "procedimento",
      headerName: "Procedimento",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          {params.value != null ? (<Typography style={{ fontWeight: 'bold' }}> {params.value.toLocaleUpperCase()} </Typography>) : (<Typography> Não definido</Typography>)}
        </div>
      )
    },

    {
      field: "cor",
      headerName: "Prioridade",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <Typography style={{ fontWeight: 'bold' }}>
            {params.value ? getPrioridadeLabel(params.value) : 'Sem Prioridade'}
          </Typography>
        </div>
      )
    },


    {
      field: "edit",
      headerName: "Detalhes",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleAccessClick()}
            sx={{

              backgroundColor: "transparent",
              border: "1px solid #FF4B00",
              borderRadius: '4px',
              padding: '8px',
              marginLeft: '8px',
            }}
          >
            <VisibilityIcon />

          </IconButton>


        </Box>
      ),
    },

  ];

  const handleTimeChange = (newValue) => {
    const formattedTime = format(newValue, 'HH:mm');
    console.log(formattedTime)
    setSelectedTime(formattedTime);
    console.log(formattedTime)

  };

  return (
    <>

      <HelmetProvider>

        <Helmet>
          <title>Painel DHF - Tela de Marcação</title>
        </Helmet>
      </HelmetProvider>


      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "80%",
            margin: "auto",
            border: '3px',
            borderRadius: '4%',
            padding: "20px"
          },
        }}
      >
        <DialogContent dividers sx={{ pb: 4 }}>
          {userData && (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                <Box sx={{ marginRight: '20px' }}>
                  {userData.foto ? (
                    <img src={userData.foto} alt="Foto do usuário" style={{ width: '150px', height: '150px', borderRadius: '10%' }} />
                  ) : (
                    <img src="/images/perfil.jpg" alt="Imagem padrão" style={{ width: '150px', height: '150px', borderRadius: '10%' }} />
                  )}
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.9rem", fontWeight: 'bold' }}>
                    Nome:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                    {userData.nome}
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.9rem", fontWeight: 'bold' }}>
                    Especialidade:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                    {userData.categoria}
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.9rem", fontWeight: 'bold' }}>
                    Procedimento:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem" }}>
                    {userData.procedimento || ""}
                  </Typography>
                </Box>


                <Box id="box-cns-dados" sx={{ marginLeft: '50px' }}>

                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.9rem", fontWeight: 'bold', ml: '20px', mr: '20px' }}>
                    Nº do CNS:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                    {userData.cns}
                  </Typography>

                  <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.9rem", fontWeight: 'bold', ml: '20px', mr: '20px' }}>
                    Nº Protocolo:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.2rem", ml: '20px', mr: '20px' }}>
                    {userData.protocolo}
                  </Typography>
                  <Box>
                    <Typography variant="h6" sx={{ color: "#FF4B00", fontFamily: "Arial, sans-serif", fontSize: "0.9rem", fontWeight: 'bold', ml: '20px', mr: '20px' }}>
                      Turno de Preferência:
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontFamily: "Source Sans 3, sans-serif", fontSize: "1.1rem", ml: '20px', mr: '20px' }}>
                      {userData.turno === "Manha" ? "Manhã" : userData.turno}
                    </Typography>

                  </Box>

                </Box>
              </Box>


              <Typography variant="h6" sx={{ mb: 1, color: "#FF4B00", fontFamily: "Source Sans 3, sans-serif", fontSize: "0.9rem", fontWeight: 'bold', marginBottom: 3 }}>
                Informações do Paciente:
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Telefone"
                    fullWidth
                    value={formatarTelefone(userData.telefone)}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>


                <Grid item xs={12} sm={2}>
                  <TextField
                    label="CPF"
                    fullWidth
                    value={userData.cpf}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}

                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>



                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={userData.email}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase !important' }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Data de Nascimento"
                    fullWidth
                    value={userData.datanascimento}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Logradouro"
                    fullWidth
                    value={userData.logradouro || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Número"
                    fullWidth
                    value={userData.numero || "S/N"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Bairro"
                    fullWidth
                    value={userData.bairro || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Cidade"
                    fullWidth
                    value={userData.cidade || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="CEP"
                    fullWidth
                    value={userData.cep || "SEM INFORMAÇÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Prioridade"
                    fullWidth
                    value={userData.prioridade || "NÃO"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold', textTransform: 'uppercase' }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    label="Email Verificado"
                    fullWidth
                    value={userData.verificado || "Não"}
                    sx={{
                      bgcolor: '#3338',
                      '& .MuiOutlinedInput-root': {
                        pointerEvents: 'none',
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },

                    }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#efefef', fontWeight: 'bold' }
                    }}
                  />
                </Grid>







              </Grid>

            </Box>
          )}
        </DialogContent>



        <DialogActions>

          <Button onClick={handleCloseDialog} sx={{
            backgroundColor: "transparent",
            border: "1px solid #FF4B00", color: 'white'
          }}>Fechar</Button>

        </DialogActions>
      </Dialog>

      <Box
        m="30px"
        p="10px"
        bgcolor="#1D1D1D"
        borderRadius="20px"
      >

        <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress color="inherit" />
            <Typography variant="body1" color="inherit" mt={2}>Salvando Informações...</Typography>
          </Box>
        </Backdrop>
        <Typography variant="h4" style={{ color: "white", borderRadius: "10px", marginLeft: 20, marginRight: 20, backgroundColor: "#FF4B00", padding: "10px", marginBottom: "5px", marginTop: "15px", fontWeight: 'bold' }}>USUÁRIO </Typography>

        <Box
          m="20px 0 0"
          height="22vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${colors.primary[500]}`,
              backgroundColor: "#262626",
              color: "white",
              fontWeight: "bold",
              fontSize: "15px"

            },
            "& .name-column--cell": {
              color: "white",
              fontWeight: "bold"
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#1a1a1a",
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#262626",
              fontWeight: "bold"
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              color: "black"
            },

          }}
        >

          <div className="rounded-datagrid-container">
            <DataGrid
              rows={[userData]}
              columns={columns}
              hideFooter={true}
              rowHeight={103}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />

          </div>


        </Box>

        <Typography variant="h4" style={{ color: "white", borderRadius: "10px", marginLeft: 20, marginRight: 20, backgroundColor: "#FF4B00", padding: "10px", marginBottom: "15px", marginTop: "5px", fontWeight: 'bold' }}>ESPECIALIDADES </Typography>


        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, marginLeft: 2 }}>




          <FormControl variant="filled" fullWidth sx={{ marginLeft: 2 }}>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione a Unidade</InputLabel>
            <Select
              value={selectedUnit}
              onChange={(e) => {
                const selectedUnitId = units.find(unit => unit.nome === e.target.value)?.id;
                setSelectedUnit(e.target.value);
                handleUnitChange(selectedUnitId);
              }}

            >
              {units.map((unit) => (
                <MenuItem key={unit.id} value={unit.nome}>
                  {unit.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>



          <FormControl variant="filled" fullWidth sx={{ marginLeft: 2 }}>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione o Procedimento</InputLabel>
            <Select
              value={selectedProcedure}
              onChange={(e) => setSelectedProcedure(e.target.value)}

            >

              {procedimentos.map((procedimento, index) => (
                <MenuItem key={index} value={procedimento}>
                  {procedimento}
                </MenuItem>
              ))}


            </Select>
          </FormControl>



          <FormControl variant="filled" fullWidth sx={{ marginLeft: 2, marginRight: 4 }}>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Selecione o Profissional</InputLabel>
            <Select
              value={selectedProfi}
              onChange={(e) => setSelectedProfi(e.target.value)}
              inputProps={{
                style: { color: 'white' },
              }}
            >

              {profissionais.map((profissional, index) => (
                <MenuItem key={index} value={profissional.nome}>
                  {profissional.nome} | {profissional.especialidade}
                </MenuItem>
              ))}

            </Select>
          </FormControl>



        </Box >

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>

          <Box className='calendar-container' style={{ marginLeft: '20px', marginRight: '10px' }} >
            <Calendar
              onChange={handleCalendarChange}
              value={date}
              locale="pt-BR"
            />

          </Box>

          <Box sx={{ width: "250px", marginLeft: "50px", marginTop: '0px' }} >

            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Turno</InputLabel>
              <Select
                value={selectedTurn}
                onChange={(e) => setSelectedTurn(e.target.value)}
                inputProps={{
                  style: { color: 'white' },
                }}
              >

                <MenuItem value="Manha">Manhã</MenuItem>
                <MenuItem value="Tarde">Tarde</MenuItem>

              </Select>
            </FormControl>

            <TextField
              style={{ marginRight: '10px', width: '250px', marginBottom: 15 }}
              label="Data"
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              disabled
              sx={{ bgcolor: '#3338' }}
              margin="normal"
              InputLabelProps={{
                shrink: true,

              }}

            />




            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsPtBR}>
              <TimePicker
                label="Horário"
                value={selectedTime ? new Date(`1970-01-01T${selectedTime}:00`) : new Date(`1970-01-01T00:00:00`)}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace' || e.key === 'Delete') {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      focused: false,
                      shrink: true,
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { color: "white", fontSize: "18px" },
                    }}
                  />
                )}
                ampm={false}
              />
            </LocalizationProvider>








          </Box>



          <Box sx={{ width: "400px", marginLeft: "50px" }} >


            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Prioridade</InputLabel>
              <Select
                value={cor}
                onChange={(e) => setCor(e.target.value)}
                inputProps={{
                  style: { color: 'white' },
                }}
              >

                {priorities.map((priority) => (
                  <MenuItem key={priority.value} value={priority.value}>
                    {priority.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Código SISREG"
              value={textInput}
              onChange={handleTextInputChange}
              fullWidth
              sx={{ bgcolor: '#3338' }}
              margin="normal"
              InputLabelProps={{
                style: { color: 'white' },
                focused: false,
              }}
              InputProps={{ style: { color: "white", padding: 1 } }}
            />


            <TextField
              label="Observações"
              value={observacoes}
              onChange={handleTextInputChangeObs}
              fullWidth
              sx={{ bgcolor: '#3338' }}
              margin="normal"
              InputLabelProps={{
                style: { color: 'white' },
                focused: false,
              }}
              InputProps={{ style: { color: "white", padding: 1 } }}
            />





          </Box>

          <Grid item xs={12} sm={1} style={{ padding: 15, textAlign: 'end', marginTop: 3, marginTop: 15, marginLeft: 20 }}>

            <WhatsAppIcon sx={{ color: '#66BB6A', marginBottom: '-6px', marginRight: '-5px' }} />

            <Checkbox
              checked={comunicarPaciente}
              onChange={(event) => setComunicarPaciente(event.target.checked)}
              color="success"
              inputProps={{ 'aria-label': 'Comunicar paciente?' }}
            />
            <Button
              style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
              onClick={handleSave}
            >
              <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
              CONCLUIR
            </Button>
          </Grid>

        </Box>


        {/* <div className="watermark">
        <img src="/images/brasao.webp" alt="Watermark" className="watermark-img" style={{ maxWidth: "500px" }} />
      </div> */}

      </Box>
    </>
  );
};

export default Marcacao;
