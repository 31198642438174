import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,

  TextField,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Divider,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { get, getDatabase, set, onValue, push, ref, update, child } from "firebase/database";
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';
import firebaseApp, { BASE_PROD, KEY } from '../../../integration/FirebaseConfig';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { allSpecialties, datetime } from "../../../services/script";

function gerarID() {
  const numeroAleatorio = Math.floor(1000000000000 + Math.random() * 9000000000000);
  return numeroAleatorio.toString();
}



const EditarInstituicao = () => {
  const database = getDatabase(firebaseApp);
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = location.state;
  const [isDateFocused, setDateFocused] = useState(false);
  const [nomeInstituicao, setNomeInstituicao] = useState(userData.nome || '');

  const [telefone, setTelefone] = useState(userData.telefone || '');
  const [procedimentos, setProcedimentos] = useState('');
  const [cep, setCep] = useState(userData.cep || '');
  const [estado, setEstado] = useState(userData.estado || '');
  const [cidade, setCidade] = useState(userData.cidade || '');
  const [logradouro, setLogradouro] = useState(userData.logradouro || '');
  const [numero, setNumero] = useState(userData.numero || '');
  const [complemento, setComplemento] = useState(userData.complemento || '');
  const [localidade, setLocalidade] = useState(userData.localidade || '');
  const [bairro, setBairro] = useState(userData.bairro || '');
  const [categoria, setCategoria] = useState(allSpecialties[0]);
  const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = useState(Array.isArray(userData.especialidade) ? userData.especialidade : []);
  const [procedimentosSelecionados, setProcedimentosSelecionados] = useState(userData.procedimentos || []);


  useEffect(() => {
    if (userData.especialidade && typeof userData.especialidade === 'object') {
      const especialidadesArray = Object.keys(userData.especialidade).map(key => userData.especialidade[key].nome);
      setEspecialidadesSelecionadas(especialidadesArray);
    } else if (Array.isArray(userData.especialidade)) {
      setEspecialidadesSelecionadas(userData.especialidade);
    } else {
      setEspecialidadesSelecionadas([]);
    }
  }, [userData]);


  const handleCategoriaChange = (event, value) => {
    if (value && Array.isArray(especialidadesSelecionadas) && !especialidadesSelecionadas.includes(value)) {
      setEspecialidadesSelecionadas([...especialidadesSelecionadas, value]);
    }
    setCategoria('');
  };


  const handleRemoveEspecialidade = (especialidade) => {
    const updatedEspecialidades = especialidadesSelecionadas.filter(item => item !== especialidade);
    setEspecialidadesSelecionadas(updatedEspecialidades);
  };

  const handleProcedimentosChange = (event) => {
    const selectedCategory = event.target.value;
    if (!procedimentosSelecionados.includes(selectedCategory)) {
      setProcedimentosSelecionados([...procedimentosSelecionados, selectedCategory]);
    }
    setProcedimentos('');
  };

  const handleRemoveProcedimentos = (procedimento) => {
    const updatedEspecialidades = procedimentosSelecionados.filter(item => item !== procedimento);
    setProcedimentosSelecionados(updatedEspecialidades);
  };

  const handleTelefoneChange = (event) => {
    let { value } = event.target;


    value = value.slice(0, 14);

    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
    setTelefone(formattedValue);
  };

  const now = new Date();

  const datetimeNow = now.toLocaleString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });



  const handleSalvar = async () => {
    const db = ref(database, `${BASE_PROD}/${KEY}/instituicoes/` + userData.id);

    const snapshot = await get(db);
    const dadosAtuais = snapshot.val() || {};
    const especialidadesAtuais = dadosAtuais.especialidade || {};

    const especialidadesFormatadas = { ...especialidadesAtuais };

    especialidadesSelecionadas.forEach(especialidade => {
      if (!Object.values(especialidadesAtuais).some(value => value.nome === especialidade)) {
        const especialidadeId = gerarID();
        especialidadesFormatadas[especialidadeId] = {
          nome: especialidade,
          vagas: 0,
          preenchido: 0,
          id: especialidadeId
        };
      }
    });

    Object.keys(especialidadesAtuais).forEach(especialidadeId => {
      const especialidadeNome = especialidadesAtuais[especialidadeId].nome;
      if (!especialidadesSelecionadas.includes(especialidadeNome)) {
        delete especialidadesFormatadas[especialidadeId];
      }
    });

    const novosDados = {
      nome: nomeInstituicao,
      telefone: telefone,
      localidade,
      cep,
      estado,
      cidade,
      logradouro,
      numero,
      complemento,
      bairro,
      especialidade: especialidadesFormatadas,
      procedimentos: procedimentosSelecionados,
    };

    let dadosDiferentes = false;
    for (const chave in novosDados) {
      if (novosDados[chave] !== dadosAtuais[chave]) {
        dadosDiferentes = true;
        break;
      }
    }

    if (dadosDiferentes) {
      novosDados.datetime = datetimeNow;
    } else {
      novosDados.datetime = dadosAtuais.datetime;
    }

    await update(db, novosDados);

    navigate(`/instituicoes`);
  };









  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" style={{ marginLeft: '80px', marginRight: '80px', marginTop: '40px' }}>
      <Divider sx={{ color: "#FF4B00", marginBottom: "20px", borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">EDITANDO INSTITUIÇÃO - {userData.nome.toUpperCase() || ""}</Divider>




      <>


        <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}> Dados da Instituição</Typography>

        <form>


          <Grid container spacing={2}>


            <Grid item xs={12} sm={1}>
              <TextField
                label="Identificador"
                fullWidth
                value={userData.id}

                sx={{ bgcolor: '#3338' }}

                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
                InputProps={{
                  readOnly: true,
                  style: { color: '#686868', fontWeight: 'bold' }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Nome da Instituição"
                fullWidth
                value={nomeInstituicao}
                onChange={(e) => setNomeInstituicao(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Telefone"
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={telefone}
                onChange={handleTelefoneChange}
                onFocus={() => setDateFocused(true)}
                onBlur={() => setDateFocused(false)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: isDateFocused,
                }}
              />
            </Grid>


            <Grid item xs={12} sm={4}>
              <TextField
                label="Localidade"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={localidade}
                onChange={(event) => setLocalidade(event.target.value)}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="Zona Urbana">Zona Urbana</MenuItem>
                <MenuItem value="Zona Rural">Zona Rural</MenuItem>
              </TextField>
            </Grid>




            <Grid item xs={12} sm={1}>
              <TextField
                label="CEP"
                fullWidth
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <TextField
                label="UF"
                fullWidth
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Município"
                fullWidth
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Endereço"
                fullWidth
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Complemento"
                fullWidth
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Número"
                fullWidth
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Bairro"
                fullWidth
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                sx={{ bgcolor: '#3338' }}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}
              />
            </Grid>


          </Grid>
        </form>

        <Typography style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 17, marginTop: 15 }}>Especialidades e Procedimentos</Typography>

        <form>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={allSpecialties}
                value={categoria}
                onChange={(event, value) => handleCategoriaChange(event, value)}
                inputValue={categoria}
                onInputChange={(event, newInputValue) => {
                  setCategoria(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Especialidades"
                    sx={{ bgcolor: '#3338' }}
                    InputLabelProps={{
                      style: { color: 'white' },
                      focused: false,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField label="Procedimentos"
                select
                fullWidth
                sx={{ bgcolor: '#3338' }}
                value={procedimentos}
                onChange={handleProcedimentosChange}
                InputLabelProps={{
                  style: { color: 'white' },
                  focused: false,
                }}

              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="Consulta">Consulta</MenuItem>
                <MenuItem value="Cirugia">Cirugia</MenuItem>
                <MenuItem value="Exames">Exames</MenuItem>
                <MenuItem value="Retorno">Retorno</MenuItem>
                <MenuItem value="Outros">Outros</MenuItem>


              </TextField>
            </Grid>


            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                {especialidadesSelecionadas.map((especialidade, index) => (
                  <Grid item xs={12} sm={4} key={index} >

                    <Typography style={{
                      margin: '0.5em',
                      fontWeight: 'bold',
                      backgroundColor: "transparent",
                      border: "1px solid #FF4B00",
                      display: 'flex',
                      maxWidth: 220,
                      padding: 5,
                      borderRadius: 10,
                      alignItems: 'center', textAlign: 'center', justifyContent: 'center'
                    }}>



                      <IconButton style={{ fontSize: 13, borderRadius: 4, fontWeight: 'bold' }} onClick={() => handleRemoveEspecialidade(especialidade)}>
                        {especialidade}
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
              <Grid container spacing={1} style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                {procedimentosSelecionados.map((procedimento, index) => (
                  <Grid item xs={12} sm={4} key={index} style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>

                    <Typography style={{
                      margin: '0.5em',
                      fontWeight: 'bold',
                      backgroundColor: "transparent",
                      border: "1px solid #FF4B00",
                      display: 'flex',
                      maxWidth: 220,
                      padding: 5,
                      borderRadius: 10,
                      alignItems: 'center', textAlign: 'center', justifyContent: 'center'
                    }}>



                      <IconButton style={{ fontSize: 13, borderRadius: 4, fontWeight: 'bold' }} onClick={() => handleRemoveProcedimentos(procedimento)}>
                        {procedimento}
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ padding: 15, textAlign: 'right', marginTop: 3 }}>
              <Button
                style={{ backgroundColor: "#FF4B00", color: 'white', fontWeight: 'bold', textAlign: 'right', padding: 7 }}
                onClick={handleSalvar}
              >
                <CheckCircleIcon style={{ marginRight: 2 }} fontSize="large" />
                CONCLUIR
              </Button>
            </Grid>


          </Grid>
        </form>


      </>


    </Box >
  );
};

export default EditarInstituicao;
